import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { connect } from "react-redux";
import theme from "../../assets/css/theme";

const DEFAULT_TRADE_BADGE_SIZE = 20;
class TradeBadge extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      nextProps.ids &&
      (props.ids != nextProps.ids ||
        props.mode != nextProps.mode ||
        props.maxBadges != nextProps.maxBadges ||
        props.trades != nextProps.trades)
    ) {
      let viewMode = nextProps.mode || "auto";
      let isSliced = viewMode == "min";
      let max =
        nextProps.maxBadges ||
        (viewMode == "auto" ? 5 : nextProps.ids.length + 1);
      let showPlus = max < nextProps.ids.length;
      let tradesArray = [];
      let listOfUnshown = [];
      nextProps.ids.forEach((id, i) => {
        if (nextProps.trades.getNested([String(id)])) {
          let currTrade = nextProps.trades.getNested([String(id)]);
          let text = currTrade.getCementoTitle
            ? currTrade.getCementoTitle()
            : "";
          if (i + 1 >= max) {
            listOfUnshown.push(text);
            return;
          }
          let backgroundColor = currTrade.color || "#ffffff";
          let textColor =
            backgroundColor == "#ffffff" ? theme.headerColorDark : "#ffffff";
          let borderColor =
            backgroundColor == "#ffffff"
              ? theme.headerColorDark
              : backgroundColor;
          let fullText = text;
          text = viewMode == "auto" || !isSliced ? text : text.slice(0, 2);
          tradesArray.push({
            id,
            text,
            fullText,
            textColor,
            borderColor,
            backgroundColor,
            isSliced,
          });
        }
      });
      if (showPlus)
        tradesArray.push({
          id: "pluse",
          text: "+",
          isPlus: true,
          textColor: "#d87f90",
          borderColor: "#d87f90",
          backgroundColor: "#ffffff",
          fullText: listOfUnshown.join("\n"),
        });

      newStateChanges.tradesArray = tradesArray;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const {
      containerStyle,
      tradeBadgeSize = DEFAULT_TRADE_BADGE_SIZE,
      textStyle,
      tradeStyle,
    } = this.props;
    const { tradesArray } = this.state;
    if (!tradesArray || tradesArray.length < 1) return null;

    return (
      <div style={Object.assign({ display: "flex" }, containerStyle)}>
        {tradesArray.map(
          ({
            id,
            text,
            fullText,
            textColor,
            borderColor,
            backgroundColor,
            isSliced,
            isPlus,
          }) => (
            <div
              key={id}
              style={Object.assign(
                {
                  display: "flex",
                  padding: isSliced ? "none" : 5,
                  alignItems: "center",
                  justifyContent: "center",
                  height: tradeBadgeSize,
                  width: isSliced || isPlus ? tradeBadgeSize : "none",
                  borderRadius: isSliced ? tradeBadgeSize : 100,
                  border: "1px solid " + borderColor,
                  background: backgroundColor,
                  margin: 5,
                },
                tradeStyle
              )}
            >
              <span
                title={isSliced || isPlus ? fullText : null}
                style={Object.assign(
                  {
                    fontWeight: 400,
                    fontSize: isPlus ? "15px" : "11px",
                    width: "auto",
                    color: textColor,
                    whiteSpace: "nowrap",
                  },
                  textStyle
                )}
              >
                {text}
              </span>
            </div>
          )
        )}
      </div>
    );
  }
}

let styles = {};

TradeBadge = withStyles(styles)(TradeBadge);
const enhance = compose(
  connect(
    (state) => ({
      trades: state.trades.map,
      rtl: state.app.rtl,
    }),
    {}
  )
);
export default enhance(TradeBadge);
