import _ from 'lodash';
import { rankItem, compareItems } from '@tanstack/match-sorter-utils';
import { sortingFns } from '@tanstack/react-table';
import { CSS } from '@dnd-kit/utilities';

const getSummaryRowValue = (acc, newValue) => {
  if (_.isNil(newValue)) return acc;
  if (typeof newValue === 'string') {
    return (acc || 0) + 1;
  }
  if (typeof newValue === 'number') {
    return (acc || 0) + newValue;
  }
};

export const getData = (data, groupBy, parentGroup) => {
  const rowData = Object.values(data).reduce((acc, value) => {
    let totalItems = value.groups ? Object.values(value.groups).length : value.rows.length;
    let groupName = value.groupName;

    let groupIndex = groupBy.indexOf(value.groupName);

    let summaryRow = {
      id: value.id,
      index: value.index,
      groupName,
      groupIndex,
      totalItems,
    };

    if (value.groups) {
      const rows = Object.values(value.groups).flatMap((value) => value?.rows);
      totalItems = rows.length;
      summaryRow.totalItems = rows.length;
      rows.forEach((item) => {
        _.each(item, (value, key) => {
          let processedValue = value;

          if (value?.cementoValue) {
            processedValue = value.cementoValue.getCementoTitle();
          } else {
            processedValue = value?.id ?? value;
          }

          if (typeof processedValue !== 'object') {
            if (groupBy.includes(key)) {
              summaryRow[key] = processedValue;
            } else {
              summaryRow[key] = getSummaryRowValue(summaryRow[key], processedValue, totalItems);
            }
          }
        });
      });
    }

    summaryRow.subRows = value.groups
      ? getData(value.groups, groupBy, groupName)
      : value.rows.map((item) => {
          const res = {};
          _.each(item, (value, key) => {
            let processedValue = value;

            if (value?.cementoValue) {
              processedValue = value.cementoValue.getCementoTitle();
            } else {
              processedValue = value?.id ?? value;
            }

            if (typeof processedValue !== 'object') {
              res[key] = processedValue;

              if (groupBy.includes(key)) {
                summaryRow[key] = processedValue;
              } else {
                summaryRow[key] = getSummaryRowValue(summaryRow[key], processedValue, totalItems);
                summaryRow[parentGroup] = summaryRow[groupName];
              }
            }
          });

          return res;
        });

    acc.push(summaryRow);

    return acc;
  }, []);

  return rowData;
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
export const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank, rowB.columnFiltersMeta[columnId]?.itemRank);
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const getCommonCellStyles = ({ header, column, isDragging = false, transform }) => {
  const pinned = column.columnDef.pinned;
  const isLastLeftPinnedColumn = pinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = pinned === 'right' && column.getIsFirstColumn('right');

  return {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: pinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: pinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: pinned ? 'sticky' : 'relative',
    zIndex: pinned ? 2 : isDragging ? 3 : 1,
    width: header ? `calc(var(--header-${header?.id}-size) * 1px)` : `calc(var(--col-${column.id}-size) * 1px)`,
  };
};
