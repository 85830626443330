import { REPLACE_USER } from '../auth/actions';
import Drawing from './drawing';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { convertDrawingsV2ToV1, isDrawingsInV2, buildDrawingV1Object } from './funcs';
import _ from 'lodash';
import { CLEAR_ALL_DATA } from '../app/actions';
import { drawingsEvents } from './drawingsEvents';

const InitialState = Record({
  map: Map(),
  settings: OrderedMap(),
}, 'drawings');

const initialState = new InitialState;
export const DRAWINGS_KEY = 'drawings';

export default function drawingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case drawingsEvents.GET_DRAWING_SETTINGS + "_SUCCESS": {
      if (!action.payload)
        return state;
      const { projectId, settings } = action.payload;
      if (!settings || !settings.all)
        return state.setIn(['settings', projectId, 'empty'], true);

      var projectSettings = settings.all;
      return state.setIn(['settings', projectId, 'all'], projectSettings);
    }

    case drawingsEvents.DELETE_DRAWING + "_SUCCESS":
    case drawingsEvents.UPDATE_DRAWING + "_SUCCESS":
    case drawingsEvents.CREATE_DRAWING + "_SUCCESS":
    case drawingsEvents.SET_DRAWINGS: {
      try {
        const { projectId, shouldClearPreviousDrawings = true } = action.payload;
        const notConvertedDrawings = action.payload[DRAWINGS_KEY];
        if (!notConvertedDrawings) {
          return state;
        }
        const drawings = isDrawingsInV2(notConvertedDrawings) ? convertDrawingsV2ToV1(notConvertedDrawings, projectId) : buildDrawingV1Object(notConvertedDrawings);
        state = setDrawings(drawings, state, projectId, shouldClearPreviousDrawings);
      }
      catch (err) {
        console.log(err);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}

const setDrawings = (drawings, state, projectId, shouldClearPreviousDrawings) => {
  if (!drawings) {
    return state;
  }
  if (shouldClearPreviousDrawings) {
    state = state.setIn(['map', projectId], new Map());
  }
  drawings.loopEach((currBuildingKey, currBuilding) => {
    let buildingDrawings = state.getNested(['map', projectId, currBuildingKey]);

    if (!buildingDrawings) {
      buildingDrawings = new Map();
    }

    currBuilding.loopEach((currUnitKey, currUnit) => {
      currUnit.loopEach((currDrawingKey, currDrawing) => {
        const drawing = new Drawing({ type: currDrawing?.locationData?.locationType, ...currDrawing });
        buildingDrawings = buildingDrawings.setIn([currUnitKey, drawing.id], drawing);
      });
    });

    state = state.setIn(['map', projectId, currBuildingKey], buildingDrawings);
  });

  return state;
}

