import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { realmInstance } from '../../../configureMiddleware';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import { realmSafeAction } from '../../../../native/RealmWrapper/funcs';
import { deepEqual, realmQueryStringBuilder } from '../../../app/funcs';
import useRefed from '../../../hooks/useRefed';

const useRealmCompanies = (companiesFilters = {}) => {
  // state
  const [companies, setCompanies] = useState({});

  // refs
  const realmCompaniesRef = useRef(null);

  const _companiesFilters = useMemoizedValue(companiesFilters);

  const getCompaniesQuery = useCallback(() => {
    const { companyName, inIds } = _companiesFilters;

    let queryArr = [];

    if (inIds?.length) {
      queryArr.push(inIds.map((id) => `id == "${id}"`));
    }
    if (companyName?.length) {
      queryArr.push(`name == "${companyName}"`);
    }

    return { companiesQuery: realmQueryStringBuilder(queryArr) };
  }, [_companiesFilters.companyName, _companiesFilters.inIds]);

  const [queries, setQueries] = useState(() => getCompaniesQuery());

  useEffect(() => {
    const newQueries = getCompaniesQuery();
    if (!deepEqual(newQueries, queries)) {
      setQueries(newQueries);
    }
  }, [getCompaniesQuery]);

  const getCompaniesMap = useCallback((_companies, changes = null, shouldRevoke = true) => {
    let arr = [];
    _companies.forEach((post, index) => {
      const isPostDeleted = post.isDeleted || changes?.deletions.includes(index);
      if (isPostDeleted) return;
      const isPostModified = changes?.newModifications?.includes(index);
      const isPostAdded = changes?.insertions?.includes(index);
      const shouldRevokePost = shouldRevoke || !companies[post.id] || isPostModified || isPostAdded;
      arr.push(
        shouldRevokePost
          ? JSON.parse(JSON.stringify(post.realmToObject())) // TODO: CEM-6163 - For the love of god, remove this as soon as possible (change usePost to give the realm cursor instead of map / array)
          : companies[post.id]
      );
    });

    arr.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));
    return _.keyBy(arr, 'id');
  }, []);

  const getRealmCompaniesMapRef = useRefed(getCompaniesMap, [getCompaniesMap]);

  const companiesListener = useCallback((values, changes) => {
    const didChange = Boolean(changes.insertions.length || changes.deletions.length || changes.newModifications.length);
    if (didChange) {
      const map = getRealmCompaniesMapRef.current(values, changes); // using a ref because the function is passed to a listener and otherwise would run with old params
      setCompanies(map);
    }
  }, []);

  const updateCompanies = useCallback(async () => {
    if (queries.companiesQuery.length) {
      realmCompaniesRef.current = realmInstance.companies.objects('companies').filtered(queries.companiesQuery);
    } else {
      realmCompaniesRef.current = realmInstance.companies.objects('companies');
    }
    const map = getCompaniesMap(realmCompaniesRef.current);
    setCompanies(map);

    await realmSafeAction(realmInstance.companies, () => {
      return realmCompaniesRef.current.addListener(companiesListener);
    });
  }, [queries, getCompaniesMap]);

  useEffect(() => {
    updateCompanies();
    return () => realmCompaniesRef.current.removeListener(companiesListener);
  }, [queries]);

  const memoizedValue = useMemo(
    () => ({
      companies,
    }),
    [companies]
  );

  return memoizedValue;
};

export default useRealmCompanies;
