import { useContext } from 'react';
import PropertyAnalytics from '../../../web/views/Properties/PropertyAnalytics';
import RemoteConfig from '../../app/featureFlag/RemoteConfig';
import ObjectsWrapper from '../ObjectsWrapper';
import { ProjectContext } from '../../projects/contexts';

const ObjectsWrapperRemoteConfig = (props) => {
  const { contentType, section } = props;
  const projectProps = useContext(ProjectContext);

  return (
    <RemoteConfig>
      {(remoteConfig) => {
        const objectWrapperOn = remoteConfig
          ?.getValue('objectsWrapperAllowedProjectIds')
          ?.asString()
          ?.includes(projectProps?.selectedProjectId);
        if (objectWrapperOn) {
          return <ObjectsWrapper contentType={contentType} section={section} />;
        }
        return <PropertyAnalytics contentType={contentType} section={section} />;
      }}
    </RemoteConfig>
  );
};

export default ObjectsWrapperRemoteConfig;
