import { Map } from 'immutable';
import { Record } from '../transit';
import * as lastUpdatesActions from '../lastUpdates/actions';

import * as appActions from '../app/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import { PROPERTIES_INSTANCES_EVENTS } from './trackPropertiestInstances';

const InitialState = Record({
  sync: Map(),
  lastSynced: Map(),
  recentlySigningUsers: Map()
}, 'propertiesInstances');

const initialState = new InitialState;

export default function propertiesInstancesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['propertiesInstances'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], 0)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }

    case lastUpdatesActions.SYNCED + "_PROPERTIES_INSTANCES_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS":
    case appActions.CLEAN_CACHE: {      
      state = initialState;
      return state;
    }
    case PROPERTIES_INSTANCES_EVENTS.SET_RECENTLY_SIGNING_USERS: {
      const { userId, projectId, signaturesContext } = action.payload;
      if (userId && projectId && signaturesContext)
        state = state.setIn(['recentlySigningUsers', projectId, signaturesContext, userId], { lastUse: Date.now() });
      return state;
    }
  }

  return state;
}

