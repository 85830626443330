export const SUBJECTS = {
  AUTH: 'auth',
  INTL: 'intl',
  CONFIGURATIONS: 'configurations',
  PERMISSIONS: 'permissions',
  VIEWER: 'viewer',
  LAST_UPDATES_V2: 'lastUpdatesV2',
  QUASI_STATICS: 'quasiStatics',
  MENUS: 'menus',
  STAGES: 'stages',
  CHECKLISTS: 'checklists',
  CHECKLIST_ITEMS: 'checklistItems',
  CHECKLIST_SUBSCRIPTIONS: 'checklistSubscriptions',
  BUILDINGS: 'buildings',
  FLOORS: 'floors',
  UNITS: 'units',
  PROJECT: 'project',
  PROJECTS: 'projects',
  USERS: 'users',
  TITLES: 'titles',
  TRADES: 'trades',
  SAFETY: 'safety',
  REPORTS: 'reports',
  LOCATIONS: 'locations',
  PROPERTIES_TYPES: 'properties',
  PROPERTIES_MAPPINGS: 'properties/mappings',
  PROPERTIES_INSTANCES: 'propertiesInstances',
  DRAWINGS: 'drawings',
  CHECKLIST_ITEM_INSTANCES: 'checklistInstances',
  POSTS: 'posts',
  FORMS: 'forms',
  EMPLOYEES: 'employees',
  EQUIPMENT: 'equipment',
  COMMENTS: 'comments',
  COMPANIES: 'companies',
};

export const FORM_TYPES = {
  GENERAL: 'general',
  DAILY_REPORT: 'dailyReport',
  OBJECTS: 'objects',
  CERTIFICATION: 'certification',
  OTHERS: 'others',
  SAFETY: 'safety'
}