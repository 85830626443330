import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, Outlet } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PagesHeader from '../components/Header/PagesHeader.js';
import pagesStyle from '../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx';
import bgImage from '../assets/img/register.jpeg';

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper}>
          <div className={classes.fullPage} style={{ backgroundImage: 'url(' + bgImage + ')' }}>
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle)(Pages);
