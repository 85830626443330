import _ from 'lodash';
import analyticsMessages from '../../common/analytics/analyticsMessages';
import systemMessages from '../../common/app/systemMessages';
import reportsMessages from '../../common/reports/reportsMessages';
import safetyMessages from '../../common/safety/safetyMessages';
import siteControlMessages from '../../common/siteControl/siteControlMessages';
import buttonsMessages from '../../common/app/buttonsMessages';
import { decodeFiltersFromSearch, encodeFilterToSearch } from '../app/funcs';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../app/constants';
import * as issueStates from '../../common/issues/issueStates';
import * as propertyTypes from '../../common/propertiesTypes/propertiesTypes';
import { getPostCreationOptions, getPropTypeByTag } from '../../common/propertiesTypes/funcs';
import { POST_INFO_SUBJECT_NAME, POST_TYPES_SUB_GROUP_TAG, SAFETY_TRADE_ID } from '../../common/app/constants';
import { POSTS_TYPES } from '../../common/app/constants';
import newPostMessages from '../../common/posts/newPostMessages';
import { updateUsingFirebaseProxy } from '../../common/lib/api';
import { getSnapshotData, getUniqueFirebaseId } from '../../common/lib/utils/utils';

const statusReject = { value: 'rejected' };
const statusResolved = { value: 'resolved' };
const statusConfirm = { value: 'confirm' };
const statusConfirm2 = { value: 'confirm2' };
const statusPartial = { value: 'partial' };
const statusIrrelevant = { value: 'irrelevant' };
const allStatusArray = [statusReject, statusResolved, statusConfirm, statusConfirm2, statusPartial, statusIrrelevant];
const favAllStages = { label: analyticsMessages.allStages, values: { status: allStatusArray } };

const getDefaultCompanyMenus = () => {
  let menus = {};

  menus['safety'] = {
    ['general']: {
      caption: safetyMessages.menu.company.main,
      options: [{ label: safetyMessages.menu.company.title, contentType: 'safety', type: 'contentRoot' }],
      num: 0,
      contentType: 'safety',
    },
  };

  menus['settings'] = {
    ['analytics']: {
      caption: analyticsMessages.reports,
      options: [
        {
          label: safetyMessages.employees,
          type: 'analytics',
          id: 'members',
          contentType: 'settings',
          subjectType: 'members',
        },
        {
          label: safetyMessages.companies,
          type: 'analytics',
          id: 'companies',
          contentType: 'settings',
          subjectType: 'companies',
        },
      ],
      num: 0,
      contentType: 'settings',
    },
  };

  return menus;
};

const getDefaultProjectMenus = ({ formsInfoPropTypes, postsInfoPropTypes, postsInfoPropMappings, projectId }) => {
  let defaultStaticMenu = {};

  // Need to take this from the index.js that build the headers (Currently named: dashboard.js)
  {
    ['qa', 'issues', 'records', 'safety'].forEach((mnKey) => {
      if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

      defaultStaticMenu[mnKey]['dashboardSection'] = {
        caption: analyticsMessages.dashboards.menu.main,
        num: 0,
        type: 'dashboardSection',
        options: [{ label: analyticsMessages.dashboards.menu.title, type: 'dashboardView', contentType: mnKey }],
      };
    });
  }

  ['qa', 'drawings', 'details'].forEach((mnKey) => {
    if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

    defaultStaticMenu[mnKey]['buildingViewSection'] = {
      caption: analyticsMessages.detailView,
      num: 2,
      type: 'buildingViewSection',
      options: [
        {
          label: analyticsMessages.buildingView,
          type: 'buildingView',
          contentType: mnKey,
          ...(mnKey === 'records' ? { query: 'itemType=records' } : {}),
        },
      ],
    };
  });
  ['issues', 'records'].forEach((mnKey) => {
    const isIssue = mnKey === 'issues';
    const groupId = isIssue ? POSTS_TYPES.ISSUE : POSTS_TYPES.RECORD;
    const postsGroups = _.get(postsInfoPropTypes, ['groups']);

    const relevantSubGroupsProperties = getPropTypeByTag({
      projectId,
      subjectName: POST_INFO_SUBJECT_NAME,
      tag: POST_TYPES_SUB_GROUP_TAG,
      groupId,
    });
    const curSubGroupProperty = _.head(_.values(relevantSubGroupsProperties));

    const postCreationOptions = getPostCreationOptions({
      groups: postsGroups,
      subGroupsProperties: relevantSubGroupsProperties,
      mapping: postsInfoPropMappings,
      postGroupId: groupId,
    });

    const openIssuesFilterKey = ['values', 'issueState', 'originalValue'].join(FILTER_MENU_PATH_DELIMETER);
    const openIssuesFilter = {
      [openIssuesFilterKey]: [issueStates.ISSUE_STATE_OPENED, issueStates.ISSUE_STATE_RESOLVED],
    };
    let num = 0;
    const generalQuery = isIssue ? encodeFilterToSearch(openIssuesFilter, '', FILTER_URL_KEY) : null;
    let options = [
      {
        label: isIssue ? newPostMessages.allOpenIssues : newPostMessages.allRecords,
        type: 'analytics',
        id: mnKey,
        contentType: mnKey,
        subjectType: 'posts',
        query: generalQuery,
        num: num,
      },
    ];

    const subGroups = _.get(postCreationOptions, ['optionsByGroup', groupId, 'subGroups'], {});
    if (!postCreationOptions.projectHasNonMigratedPostGroups && _.size(subGroups) > 1) {
      options.push(
        ..._.map(subGroups, (subGroup) => {
          if (!subGroup) return;
          const optionId = `${mnKey}_${subGroup?.id}`;
          const filterToSearch = _.assign(
            {
              [['values', curSubGroupProperty?.id, 'originalValue'].join(FILTER_MENU_PATH_DELIMETER)]: [subGroup?.id],
            },
            isIssue ? openIssuesFilter : null
          );

          const extendedQuery = new URLSearchParams({
            itemType: mnKey === 'records' ? 'records' : 'tasks',
            groupId,
            subGroupId: subGroup?.id,
          });

          const query = encodeFilterToSearch(filterToSearch, extendedQuery, FILTER_URL_KEY);

          return {
            label: subGroup.title,
            type: 'analytics',
            id: optionId,
            key: optionId,
            contentType: mnKey,
            subjectType: 'posts',
            query,
            num: ++num,
          };
        })
      );
    }

    defaultStaticMenu[mnKey]['analytics'] = {
      caption: safetyMessages.reports,
      type: 'analytics',
      num: 1,
      options,
    };
  });

  ['info'].forEach((mnKey) => {
    if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

    defaultStaticMenu[mnKey]['analytics'] = {
      caption: safetyMessages.reports,
      type: 'analytics',
      num: 1,
      options: [
        {
          label: systemMessages.specs,
          type: 'analytics',
          id: 'locations',
          contentType: 'info',
          subjectType: 'locations',
          adminOnly: true,
        },
      ],
    };
    defaultStaticMenu[mnKey]['buildingViewSection'] = {
      caption: analyticsMessages.detailView,
      num: 2,
      type: 'buildingViewSection',
      options: [{ label: analyticsMessages.buildingView, type: 'buildingView', contentType: mnKey }],
    };
  });

  let currFormsMenuItemNum = 1;
  ['forms'].forEach((mnKey) => {
    if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

    defaultStaticMenu[mnKey]['analytics'] = {
      caption: reportsMessages.reports,
      type: 'analytics',
      num: 1,
      options: [
        {
          label: buttonsMessages.reports,
          type: 'analytics',
          id: 'dailyReport',
          num: currFormsMenuItemNum++,
          contentType: 'dailyReport',
          subjectType: 'forms',
          isFallback: true,
        },
      ],
    };
  });

  ['forms'].forEach((mnKey) => {
    // Generate and add expanded array props table menu items
    _.values(formsInfoPropTypes)
      .sort((a, b) => (a?.ordinalNo || 0) - (b?.ordinalNo || 0))
      .forEach((prop) => {
        if (propertyTypes.ARRAY !== prop.type || !_.get(prop, ['settings', 'generateSubTable'], false)) return;

        defaultStaticMenu[mnKey]['analytics'].options.push({
          label: prop.getCementoTitle(),
          id: `${prop.sectionId}_${prop.id}`,
          num: currFormsMenuItemNum++,
          contentType: 'forms',
          pageType: 'analytics',
          type: 'analytics',
          subjectType: 'forms',
          values: {
            columnVisibility: {
              [prop.id]: { table: true },
            },
            isSubTable: true,
          },
        });
      });
  });

  ['safety'].forEach((mnKey) => {
    if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

    defaultStaticMenu[mnKey]['analytics'] = {
      caption: safetyMessages.reports,
      type: 'analytics',
      num: 1,
      options: [
        {
          label: safetyMessages.employees,
          type: 'employeesReport',
          id: 'employees',
          contentType: 'safety',
          subjectType: 'employees',
          adminOnly: true,
          isFallback: true,
        },
        {
          label: safetyMessages.equipment,
          type: 'equipmentReport',
          id: 'equipment',
          contentType: 'safety',
          subjectType: 'equipment',
          adminOnly: true,
          isFallback: true,
        },
      ],
    };
    defaultStaticMenu[mnKey]['postsAnalytics'] = {
      caption: safetyMessages.issues.sectionTitle,
      type: 'postsAnalytics',
      num: 2,
      options: [
        {
          label: safetyMessages.forms.captions.issues,
          type: 'postsAnalytics',
          contentType: 'safety',
          subjectType: 'posts',
          id: 'issues',
          query: `itemType=tasks&${encodeFilterToSearch(
            {
              [`values${FILTER_MENU_PATH_DELIMETER}issueState${FILTER_MENU_PATH_DELIMETER}originalValue`]: [
                issueStates.ISSUE_STATE_OPENED,
                issueStates.ISSUE_STATE_RESOLVED,
              ],
              [['values', 'trade', 'originalValue'].join(FILTER_MENU_PATH_DELIMETER)]: [SAFETY_TRADE_ID],
            },
            '',
            FILTER_URL_KEY
          )}&groupId=${POSTS_TYPES.SAFETY_TASK}`,
          isFallback: true,
        },
        {
          label: safetyMessages.issues.docs,
          type: 'postsAnalytics',
          contentType: 'safety',
          subjectType: 'posts',
          id: 'documents',
          query: `itemType=records&${encodeFilterToSearch(
            {
              [['values', 'trade', 'originalValue'].join(FILTER_MENU_PATH_DELIMETER)]: [SAFETY_TRADE_ID],
            },
            '',
            FILTER_URL_KEY
          )}&groupId=${POSTS_TYPES.SAFETY_RECORD}`,
          isFallback: true,
        },
      ],
    };
  });

  return defaultStaticMenu;
};

const getProjectCombinedMenus = ({ menus, defaultProjectMenu }) => {
  let combinedMenu = {};
  let projectsConfiguredMenus = {};

  const menusByContentType = _.groupBy(_.values(menus), 'contentType');
  if (!menusByContentType['qa']?.length)
    combinedMenu['qa'] = {
      ['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'qa' },
    };
  if (!menusByContentType['safety']?.length)
    combinedMenu['safety'] = {
      ['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'safety' },
    };
  if (!menusByContentType['forms']?.length)
    combinedMenu['safety'] = {
      ['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'forms' },
    };
  if (!menusByContentType['info']?.length)
    combinedMenu['info'] = {
      ['analytics']: {
        caption: analyticsMessages.reports,
        options: [{ ...favAllStages, subjectType: 'locations' }],
        num: 1,
        contentType: 'info',
        adminOnly: true,
      },
    };
  if (!menusByContentType['siteControl']?.length)
    combinedMenu['siteControl'] = {
      ['analytics']: {
        num: 1,
        contentType: 'analytics',
        caption: siteControlMessages.general.hr,
        options: [{ label: siteControlMessages.menu.mainTitle, id: 'employees', subjectType: 'employees' }],
      },
    };
  Object.values(menus || {})
    .sort((b, a) => (b.num ? b.num : 0) - (a.num ? a.num : 0))
    .forEach((menu) => {
      let contentType = menu.contentType;
      let pageType = menu.pageType;

      if (!combinedMenu[contentType])
        combinedMenu[contentType] = {
          [pageType]: { num: 1, caption: analyticsMessages.reports, options: [], contentType },
        };

      if (!projectsConfiguredMenus[menu.id]) { // Added a workaround for wrong configs, should not be needed overall
				projectsConfiguredMenus[menu.id] = menu.id;
				combinedMenu[contentType][pageType].options.push(menu);
			}
    });

  // Need to take this from the index.js that build the headers (Currently named: dashboard.js)
  ['members', 'qa', 'issues', 'drawings', 'records', 'details', 'safety', 'info', 'forms'].forEach((mnKey) => {
    if (!combinedMenu[mnKey]) combinedMenu[mnKey] = {};

    if (defaultProjectMenu && defaultProjectMenu[mnKey]) {
      Object.keys(defaultProjectMenu[mnKey]).forEach((menuSubKey) => {
        if (combinedMenu[mnKey][menuSubKey]) {
          let combinedOptions = combinedMenu.getNested([mnKey, menuSubKey, 'options'], []).concat(
            defaultProjectMenu.getNested([mnKey, menuSubKey, 'options'], []).filter((option) => {
              return !option.isFallback && !projectsConfiguredMenus[option.id];
            })
          );
          combinedMenu = combinedMenu.setNested([mnKey, menuSubKey, 'options'], combinedOptions);
        } else combinedMenu = combinedMenu.setNested([mnKey, menuSubKey], defaultProjectMenu[mnKey][menuSubKey]);
      });
    }
  });

  return combinedMenu;
};

const saveMenus = async (projectId, filterValues, filterProps, contentType, pageType, subjectType, isDelete) => {
  let filtersSet = { ...filterProps, contentType, pageType };
  let filterValuesCopy = { ...filterValues };
  const filter = decodeFiltersFromSearch(
    window.location.search,
    FILTER_URL_KEY,
    FILTER_MENU_PATH_DELIMETER
  ).originalFilterObj;

  filterValuesCopy.filter = filter;

  if (!filtersSet.id) filtersSet.id = getUniqueFirebaseId(`settings${projectId}/menu/${contentType}`);

  if (filterValuesCopy.textFilter) delete filterValuesCopy.textFilter;

  filtersSet.values = filterValuesCopy;
  filtersSet.subjectType = subjectType;

  let updates = {};

  if (isDelete) updates['settings/' + projectId + '/menu/' + filtersSet.id] = null;
  else updates['settings/' + projectId + '/menu/' + filtersSet.id] = filtersSet;
  await updateUsingFirebaseProxy({ projectId, type: 'menus', updates });

  return { projectId, filtersSet };
};

const getMenus = async (projectId, viewer, onData) => {
  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    resourceName: 'settings',
    queryParams: { subjects: ['menu'] },
    forceMSClientConfig: true,
  };

  const res = await getSnapshotData(scopeParams, resourceParams, onData, viewer, true);
  return res;
};

export { saveMenus, getMenus, getDefaultProjectMenus, getProjectCombinedMenus, getDefaultCompanyMenus };
