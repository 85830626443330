import { track } from '../lib/reporting/actions';
import _ from 'lodash';
import { batchDispatch } from '../app/funcs';

/**
 * 
 * @param {typeof EQUIPMENT_EVENTS[keyof EQUIPMENT_EVENTS]} event 
 * @param {{}} [params] 
 * @returns 
 */

const trackEquipment = (event, params = {}) => {
  batchDispatch([track(event, params)]);
};

export default trackEquipment;

export const EQUIPMENT_EVENTS = {
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  SAVE_EQUIPMENT: 'SAVE_EQUIPMENT',
  FETCH_EQUIPMENT: 'FETCH_EQUIPMENT',
  START_EQUIPMENT_LISTENER: 'START_EQUIPMENT_LISTENER',
  END_EQUIPMENT_LISTENER: 'END_EQUIPMENT_LISTENER',
};

