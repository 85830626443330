import { v4 as uuidv4 } from 'uuid';
import { lokiInstance } from '../../configureMiddleware';
class LokiLocalDBAdapter {
  constructor(lokiInstance) {
    this.loki = lokiInstance;
  }
  
  /**
   * 
   * @param {string} collection 
   * @param {LokiQuery<{}>} query 
   * @returns 
   */
  get = (collection, query) => {
    return this.loki.getCollection(collection).cementoFind(query);
  }

  /**
   * 
   * @param {string} collection 
   * @param {any[]} data 
   */
  set = (collection, data) => {
    return this.loki.getCollection(collection).cementoUpsert(data);
  }

  /**
   * 
   * @param {string} collection 
   * @param {LokiQuery<{}>} query 
   * @returns 
   */
  unset = (collection, query) => {
    return this.loki.getCollection(collection).cementoDelete(query);
  }

  /**
   * 
   * @param {string} collection 
   */
  deleteCollection = (collection) => {
    return this.loki.getCollection(collection).cementoFullDelete();
  }

  /**
   * 
   * @returns
   */
  deleteAll = () => {
    return this.loki.collections.forEach(collection => {
      collection.cementoFullDelete();
    });
  }; 

  /**
   *
   * @param {string} collection
   * @param {function} callback
   * @param {object} options
   * @returns {function} endListenerFunc
  */
  startListener = (collection, callback, options) => {
    const lokiCollection = lokiInstance.getCollection(collection);
    const currListenerId = uuidv4();
    const wrappedCallback = (changedCollection) => {
      if (changedCollection === collection)
        callback(changedCollection);
    };
    lokiCollection.cementoOn(currListenerId, wrappedCallback);
    return () => lokiCollection.cementoOff(currListenerId);
  };
}

export default LokiLocalDBAdapter;