// eslint-disable-next-line no-unused-vars
import React from 'react';
import ConnectivitySubscriptionTable from './ConnectivitySubscriptionTable';
import UserValidator from '../UserValidator';
import ScopeContextPage from '../views/Projects/ScopeContextPage';
import Alerts from './Alerts';
import theme from '../assets/css/theme';
import DataManagerTable from './DataManagerTable';

const RootRouterComponent = ({ toast, intl, loading, rtl, alert, connectionViewerVisiblity, dataManagerVisibility, onSignOut }) => {
  return (
    <ScopeContextPage>
      <UserValidator onSignOut={onSignOut} />
      <Alerts intl={intl} rtl={rtl} toast={toast} loading={loading} alert={alert} />
      {Boolean(connectionViewerVisiblity.getNested(['visible'])) && (
        <div
          style={{
            overflowY: 'scroll',
            padding: theme.margin,
            height: '100%',
            backgroundColor: theme.backgroundColor,
            position: 'fixed',
            top: theme.headerHeight,
            left: 0,
            direction: 'ltr',
            marginBottom: theme.headerHeight,
          }}>
          <ConnectivitySubscriptionTable />
        </div>
      )}
      {Boolean(dataManagerVisibility.getNested(['visible'])) && <DataManagerTable />}
    </ScopeContextPage>
  );
};
export default RootRouterComponent;
