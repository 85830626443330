import { useState } from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { useDetectClickOutside } from 'react-detect-click-outside';
import systemMessages from '../../app/systemMessages';
import useIntl from '../../intl/useIntl';
import Text from '../../../web/components/CementoComponents/Text';
import theme from '../../../web/assets/css/theme';
import { useMemo } from 'react';
import GroupByIcon from './GroupByIcon';

const SORT_OPTIONS = [
  { value: 'asc', label: 'ASC' },
  { value: 'desc', label: 'DESC' },
];

const GroupsControls = ({ value, groupByOptions, onChange }) => {
  const rtl = useSelector((state) => state.app.rtl);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const [primaryGroup, secondaryGroup] = value;

  const toggleOverlay = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleGroupChange = (group) => (selectedOption) => {
    let result = [primaryGroup, secondaryGroup];
    result[group] = selectedOption.value;
    onChange(result);
  };

  const handleSortChange = (group) => (selectedOption) => {
    console.log({
      handleSortChange: selectedOption,
      group,
    });
  };

  const handleReset = (group) => () => {
    if (group === 0) return onChange([]);
    else return onChange([primaryGroup]);
  };

  const options = useMemo(() => {
    return groupByOptions.map((item) => {
      return { value: item.key, label: <Text>{item.title}</Text> };
    });
  }, [groupByOptions]);

  const groupsValue = [
    options.find((option) => option.value === primaryGroup),
    options.find((option) => option.value === secondaryGroup) || null,
  ];

  return (
    <div className='GroupsControls_container' ref={ref}>
      <div onClick={toggleOverlay} className='GroupsControls_header-button'>
        Group By <GroupByIcon style={{ marginRight: 8 }} />
      </div>
      {isOpen && (
        <div className='GroupsControls_menu'>
          {groupsValue.map((value, index) => (
            <div key={`group${index}`} className='GroupsControls_menu_item'>
              <Select
                value={value}
                onChange={handleGroupChange(index)}
                styles={SortSelectStyles}
                options={options}
                components={{
                  SingleValue: ({ children, ...props }) => (
                    <components.SingleValue {...props}>
                      <div style={{ display: 'flex' }}>{children}</div>
                    </components.SingleValue>
                  ),
                }}
              />
              {/* <Select
                value={SORT_OPTIONS[0]}
                onChange={handleSortChange(index)}
                styles={SortSelectStyles}
                options={SORT_OPTIONS}
                components={{
                  SingleValue: ({ children, ...props }) => (
                    <components.SingleValue {...props}>
                      <div style={{ display: 'flex' }}>{children}</div>
                    </components.SingleValue>
                  ),
                }}
              /> */}
              <div className='GroupsControls_menu_item_reset cursor-pointer' onClick={handleReset(index)}>
                Reset
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SortSelectStyles = {
  ...theme.selectStyles,
  container: (base) => ({
    ...theme.selectStyles.container(base),
    minWidth: 160,
  }),
};

export default GroupsControls;
