import React from 'react';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import withStyles from '@material-ui/core/styles/withStyles';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import CheckToggleButton from './CheckToggleButton';
import Text from "../../components/CementoComponents/Text";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import { Tooltip } from "../../components";
import FunctionalInput from "../../../common/app/components/FunctionalInput";

// Others
import { lokiInstance } from '../../../common/configureMiddleware';
import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import theme from '../../assets/css/theme';
import { ReactComponent as IconReqImage } from '../../assets/img/icons/req_image.svg';
import { ReactComponent as IconReqFile } from '../../assets/img/icons/req_file.svg';
import { ReactComponent as IconReqSig } from '../../assets/img/icons/req_signature.svg';
import textOrange from '../../assets/img/icons/Text_Orange.png';
import textGray from '../../assets/img/icons/Text.png';
import trash from '../../assets/img/icons/trash.png';
import warningClosed from '../../assets/img/icons/checklist_closed_issue.png';
import { mapExtraDataBeforeUpload } from '../../../common/checklists/funcs';
import checklistItemMessages from '../../../common/checklistItems/checklistItemMessages';
import InnerCollapsibleRow from '../../components/CementoComponents/InnerCollapsibleRow';
import * as instancesStatuses from '../../../common/checklistItemsInstances/clItemInstanceStatus';
import * as checklistTypes from "../../../common/checklists/checklistTypes";
import { startToast } from "../../../common/app/actions";
import { ChecklistContext } from "../../../common/checklists/contexts";
import { filterIrrelevantLocationExtraData } from '../../../common/propertiesTypes/funcs';
import { isEmptyValue } from '../../../common/app/funcs';
import { upsertChecklistItemInstance } from '../../../common/checklistItemsInstances/funcs.js';

class ChecklistRow extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleExtraDataChange = this.handleExtraDataChange.bind(this);
    this.cancelExtraDataChanges = this.cancelExtraDataChanges.bind(this);
    this.isExtraDataFulfilled = this.isExtraDataFulfilled.bind(this);
    this.lokiExtraDataListener = this.lokiExtraDataListener.bind(this);
    this.setShouldSaveExtraData = this.setShouldSaveExtraData.bind(this)
    this.listenerUniqId = uuidv4();

    this.state = {
      hover: false,
      extraDataValues: {},
      shouldSaveExtraData: true
    };
  }

  lokiExtraDataListener(collectionName) {
    const { isEditMode } = this.props;
    const { extraDataValues } = this.state;
    if (collectionName == 'propertyInstances' && !isEditMode) {
      let newExtraDataValues = this.getExtraDataValues();

      if (!_.isEqual(extraDataValues, newExtraDataValues))
        this.setState({ extraDataValues: newExtraDataValues });
    }
  }

  UNSAFE_componentWillMount() {
    this.lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
    this.lokiPropertyInstances.cementoOn(`extraDataListener${this.listenerUniqId}`, this.lokiExtraDataListener);
    this.setComponentData({ firstMount: true }, this.props);
    this.props.onRef?.(this);
  }

  componentWillUnmount() {
    this.lokiPropertyInstances.cementoOff(`extraDataListener${this.listenerUniqId}`);
    this.props.onRef?.(null);
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleExtraDataUpdate(prevState.extraDataValues, this.state.extraDataValues);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { isEditMode, extraDataEditMode, isSelected, description, value: checklistItem = {}, issuesCounter, currInstance } = this.props;
    const { isEditMode: nextIsEditMode, extraDataEditMode: nextExtraDataEditMode, isSelected: nextIsSelected, description: nextDescription, value: nextChecklistItem = {}, issuesCounter: nextIssuesCounter, currInstance: nextCurrInstance } = nextProps;

    if (checklistItem?.description !== nextChecklistItem?.description                            ||
        checklistItem?.ordinalNo !== nextChecklistItem?.ordinalNo                                ||
        checklistItem?.trade !== nextChecklistItem?.trade                                        ||
        extraDataEditMode !== nextExtraDataEditMode                                            ||
        issuesCounter !== nextIssuesCounter                                                    ||
        description !== nextDescription                                                        ||
        isEditMode !== nextIsEditMode                                                          ||
        isSelected !== nextIsSelected                                                          ||
        !_.isEqual(currInstance, nextCurrInstance)                                             ||
        !_.isEqual(checklistItem?.extraData, nextChecklistItem?.extraData)                       ||
        !_.isEqual(checklistItem?.requirements, nextChecklistItem?.requirements)                 ||
        !_.isEqual(checklistItem?.requirementsSupplied, nextChecklistItem?.requirementsSupplied)   )
      return true;
        
    const { extraDataValues, hover, isExtraDataFulfilled } = this.state;
    const { extraDataValues: nextExtraDataValues, hover: nextHover, isExtraDataFulfilled: nextIsExtraDataFulfilled } = nextState;

    if (hover !== nextHover                               ||
        isExtraDataFulfilled !== nextIsExtraDataFulfilled ||
        !_.isEqual(extraDataValues, nextExtraDataValues)    )
      return true;

    return false;
  }


  handleExtraDataUpdate(prevExtraData, nextExtraData) {
    if (!_.isEqual(prevExtraData, nextExtraData)) {
      let isExtraDataFulfilled = this.isExtraDataFulfilled(nextExtraData);
      this.setState({ isExtraDataFulfilled });
    }
  }

  setHover(hover) {
    this.setState({ hover });
  }

  onClick(e) {
    const { onClick } = this.props;
    e.stopPropagation();
    if (onClick)
      onClick();
  }

  handleDelete() {
    const { onDelete } = this.props;

    if (onDelete) onDelete();
  }
  
  async componentWillReceiveProps(nextProps) {
    if (this.props.extraDataEditMode && !nextProps.extraDataEditMode && this.state.shouldSaveExtraData)
      await this.saveExtraDataChanges();

    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { value:item, propertiesMappings } = nextProps || this.props;

    let stateChanges = {};
    if (nextProps.isValDiff(props, ['value', 'extraData']) || nextProps.isValDiff(props, ['currInstance', 'extraData']) || nextProps.isValDiff(props, ['locationId'])) {
      const locationGroupId = this.getLocationGroupId()

      stateChanges.extraData = filterIrrelevantLocationExtraData({
        groupId: locationGroupId,
        extraData: _.get(item,'extraData'),
        propertiesMappings: propertiesMappings?.toJS ? propertiesMappings.toJS() : propertiesMappings,
      });

      stateChanges.itemRequireExtraData = Object.values(stateChanges.extraData || {}).some(item => !item.optional);

      if (_.isEmpty(stateChanges.extraData))
        _.unset(stateChanges, ['extraData']);

      stateChanges.extraDataValues = this.getExtraDataValues(nextProps);
      if (props.firstMount){
        stateChanges.isExtraDataFulfilled = this.isExtraDataFulfilled(stateChanges.extraDataValues);
        stateChanges.missingExtraDataProps = {};
        _.values(stateChanges.extraData || nextProps.getNested(['value', 'extraData'], {})).forEach(item => {
          let prop = nextProps.getNested(['propertiesTypes', item.subjectName, item.id]);
          if (!prop) _.set(stateChanges.missingExtraDataProps, [item.id], `${item.subjectName} ${item.id}`);
      });
      }
    };
    if (Object.values(stateChanges).length > 0)
      this.setState(stateChanges);
  }

  getLocationGroupId() {
    const { selectedProjectId, locationId, } = this.props;

    const query = { projectId: selectedProjectId, propId: 'groups', parentId: locationId, subjectName: 'locationsInfo' };
    let instances = this.lokiPropertyInstances.cementoFind(query);
    instances = _.sortBy(instances, inst => inst.updatedTS);
    const instanceData = (_.head(instances) || {}).data;
    return _.head(_.keys(instanceData));
  }

  getExtraDataValues(nextProps, extraData, shouldCancelChanges = false) {
    const { currInstance, selectedProjectId, locationId, value } = nextProps || this.props;
    const originalExtraDataValues = _.get(value, 'extraData');

    let extraDataValues = {};

    const valueExtraData = shouldCancelChanges
      ? extraData || this.state.extraData || originalExtraDataValues
      : originalExtraDataValues;

    if (valueExtraData) {
      _.values(valueExtraData).forEach((extraDataProperty) => {
        let { id: extraDataPropertyId, subjectName } = extraDataProperty;
        if (extraDataPropertyId && subjectName) {
          let query = { projectId: selectedProjectId, propId: extraDataPropertyId };
          if (subjectName == 'locationsInfo') query.parentId = locationId;
          else if (subjectName == 'checklistItemsInfo' && currInstance)
            query.id = _.get(currInstance, ['extraData', extraDataPropertyId, 'instanceId']);
          else return;
          let instances = this.lokiPropertyInstances.cementoFind(query);
          instances = _.sortBy(instances, (inst) => inst.updatedTS);
          extraDataValues[extraDataPropertyId] = (instances[instances.length - 1] || {}).data;
        }
      });
    }

    const shouldIncludeExtraDataChanges = this.state.didExtraDataChange && !shouldCancelChanges;
    if (shouldIncludeExtraDataChanges) {
      _.assign(extraDataValues, this.state.extraDataValues);
    }

    return extraDataValues;
  }

  isExtraDataFulfilled(extraDataValues) {
    let requiredExtraData = this.state.extraData || this.props.getNested(['value', 'extraData'], {});
    return Object.entries(requiredExtraData)
      .every(([id, extraData]) => {
        let value = extraDataValues[id];
        return Boolean(!isEmptyValue(value) || extraData?.optional || extraData?.readOnly);
      });
  }

  handleExtraDataChange(id, value) {
    const { setHasChanges } = this.props;
    let { extraDataValues = {} } = this.state;
    extraDataValues = extraDataValues.setNested([id], value);
    this.setState({ extraDataValues, didExtraDataChange: true });
    setHasChanges(true);
  }

  async saveExtraDataChanges() {
    const { extraDataValues, didExtraDataChange, isExtraDataFulfilled } = this.state;
    const { locationId, checklistType, currInstance, checklistId, selectedProjectId } = this.props;
    const { startToast, setHasChanges } = this.props;
    const checklistItem = this.props.value;

    if (didExtraDataChange) {
      let newInstance = currInstance ? (currInstance.toJS ? currInstance.toJS() : Object.assign({}, currInstance)) : null;
      let newStatus = (newInstance || {}).status || instancesStatuses.CLI_STATUS_NONE;
      let targetTS = null;

      let extraData = mapExtraDataBeforeUpload(selectedProjectId, checklistItem, extraDataValues, currInstance, locationId);

      if (!isExtraDataFulfilled) {
        startToast({ title: checklistItemMessages.toasts.mandatoryData, type: 'error' });
        newStatus = instancesStatuses.CLI_STATUS_NONE;
        return;
      }

      let upsertParams = {}
      if (!newInstance) {
        newInstance = {};
        newInstance.targetTS = checklistType == checklistTypes.ROUTINE ? targetTS : null;
        newInstance.locationId = locationId;
        newInstance.status = newStatus;
        newInstance.checklistItemId = checklistItem.id;
        newInstance.checklistId = checklistId;
        newInstance.isNew = true;
        upsertParams.immediateSave = true;
      }
      else {
        newInstance.status = newStatus;
      }
      upsertParams = {
        ...upsertParams,
        projectId: selectedProjectId,
        inChecklistItemInstance: newInstance,
        extraData
      }
      upsertChecklistItemInstance(upsertParams)

      this.setState({ didExtraDataChange: false });
      setHasChanges(false);
    }
  }

  cancelExtraDataChanges() {
    this.setState({
      extraDataValues: this.getExtraDataValues(undefined, undefined, true),
      didExtraDataChange: false,
    });
  }

  setShouldSaveExtraData(bool){
    this.setState({ shouldSaveExtraData: bool })
  }

  render() {
    const { locationId, currInstance, maxChars, description, indications, checklistType, value, keyId, isSelected, editable, trades, rtl, minMode, onClick, locationsData, viewer } = this.props;
    const { descriptionMaxWidth, issuesCounter, documentationsCounter, linksMode, checklistId, isEditMode, extraDataEditMode, mainContainerStyle } = this.props;
    const { hover, extraDataValues, itemRequireExtraData, isExtraDataFulfilled, missingExtraDataProps, extraData } = this.state;
    const isAdmin = Boolean(viewer.adminMode == 1);

    let source;
    let color;
    let opacity = 1;
    if (indications && indications.source) {
      source = value.getNested(indications.source.path);
      color = indications.source.colors[source];
    }
    if (indications && indications.isDeleted) {
      opacity = Boolean(value.getNested(indications.isDeleted.path)) ? 0.25 : 1;
    }

    let checklistItem = value;

    if (!checklistItem || Object.values(checklistItem).length == 0)
      return <NoItemsFound />;

    let tradeText = trades.getNested([String(checklistItem.trade), 'getTitle'], '');

    if (editable)
      return (
        <div key={keyId} style={{ opacity: opacity, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: isSelected ? '#f1f1f1' : (hover ? '#f9f9f9' : 'transparent') }}>
          <GridContainer alignItems='center' alignContent='center' justify='center' onClick={this.onClick}>
            <GridItem xs={2}>
              <p>{tradeText}</p>
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }} xs={8}>
              {Boolean(source) && <Text style={{ backgroundColor: color, color: theme.backgroundColorBright, overflow: 'hidden', margin: '0px 10px', borderRadius: 15, padding: '0px 10px', width: 80, textAlign: 'center' }}>{source}</Text>}
              {Boolean(source) && <Text style={{ backgroundColor: color, color: theme.backgroundColorBright, overflow: 'hidden', margin: '0px 10px', borderRadius: 15, padding: '0px 10px', width: 140, textAlign: 'center', fontSize: 10 }}>{value.id}</Text>}
              <Text>{description || checklistItem.description || ''}</Text>
            </GridItem>
          </GridContainer>
        </div>
      );
    else {
      let reqIconColor = documentationsCounter ? theme.brandPrimary : theme.brandNeutral;
      let iconReqStyle = { stroke: reqIconColor, fill: reqIconColor, height: 20, width: 20, marginRight: 3, marginLeft: 3 };
      return (
        <InnerCollapsibleRow
          key={keyId}
          keyId={keyId}
          title={(description || checklistItem.getNested(['description'], ''))}
          titleMaxWidth={descriptionMaxWidth}
          isEditMode={isEditMode}
          isSelected={isSelected}
          linksMode={linksMode}
          minMode={minMode}
          maxChars={maxChars}
          onClick={(!extraDataEditMode && onClick) ? onClick : undefined}
          onHover={this.setHover}
          mainContainerStyle={mainContainerStyle}
          leftSideComp={
            <div style={{ display: 'flex' }}>
              <CheckToggleButton
                disabled={!extraDataEditMode}
                checklistId={checklistId}
                hover={hover}
                locationId={locationId}
                locationsData={locationsData}
                instance={currInstance}
                checklistItem={checklistItem}
                checklistType={checklistType}
                extraDataMode={itemRequireExtraData}
                isExtraDataFulfilled={isExtraDataFulfilled}
                extraDataValues={extraDataValues}
                setShouldSaveExtraData={this.setShouldSaveExtraData}
              />
            </div>
          }
          middleComp={
            <div style={{ display: 'flex' }}>
              {Boolean(itemRequireExtraData) && <Tooltip title={checklistItemMessages[isExtraDataFulfilled ? 'requirementsSupplied' : 'requirements'].extraData}><img src={isExtraDataFulfilled ? textOrange : textGray} style={iconReqStyle} /></Tooltip>}
              {Boolean(isAdmin && !_.isEmpty(missingExtraDataProps)) && <div style={{display:'flex', textAlign:'justify', flexDirection:'column', alignItems:'center'}}><Tooltip title={"missing extra data prop!"}><Text style={{ borderRadius: '50%', height: 20, width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.brandDanger, color: theme.inverseTextColor }}>!</Text></Tooltip><Text>{Object.values(missingExtraDataProps).join(', ')}</Text></div>}
              {Boolean(!itemRequireExtraData && (checklistItem.requirements && checklistItem.requirements.desc || (!checklistItem.requirements && documentationsCounter))) && <Tooltip title={checklistItemMessages[documentationsCounter ? 'requirementsSupplied' : 'requirements'].desc}><img src={documentationsCounter ? textOrange : textGray} style={iconReqStyle} /></Tooltip>}
              {Boolean(checklistItem.requirements && checklistItem.requirements.img) && <Tooltip title={checklistItemMessages[documentationsCounter ? 'requirementsSupplied' : 'requirements'].img}><IconReqImage style={iconReqStyle} /></Tooltip>}
              {Boolean(checklistItem.requirements && checklistItem.requirements.file) && <Tooltip title={checklistItemMessages[documentationsCounter ? 'requirementsSupplied' : 'requirements'].file}><IconReqFile style={iconReqStyle} /></Tooltip>}
              {Boolean(checklistItem.requirements && checklistItem.requirements.drawing) && <Tooltip title={checklistItemMessages[documentationsCounter ? 'requirementsSupplied' : 'requirements'].drawing}><IconReqImage style={iconReqStyle} /></Tooltip>}
              {Boolean(checklistItem.requirements && checklistItem.requirements.signature) && <Tooltip title={checklistItemMessages[documentationsCounter ? 'requirementsSupplied' : 'requirements'].signature}><IconReqSig style={iconReqStyle} /></Tooltip>}
              {Boolean(issuesCounter) && <img src={warningClosed} style={iconReqStyle} />}
            </div>
          }
          rightSideComp={
            <div style={{ display: 'flex' }}>
              <TradeBadge containerStyle={{ marginTop: '-5px', marginBottom: '-5px' }} ids={[checklistItem.trade]} mode={'min'} maxBadges={3} />
            </div>
          }
          editIcons={[
            { icon: trash, onClick: this.handleDelete, style: { height: '18px' } },
          ]}>
          {
            Boolean(extraData && Object.keys(extraData).length) && (
              <div style={{ paddingBottom: theme.padding, paddingTop: theme.padding }}>
                {
                  Object.values(extraData || {})
                    .sort((a, b) => (a.ordinalNo && b.ordinalNo) ? (a.ordinalNo - b.ordinalNo) : String(a.id).localeCompare(String(b.id)))
                    .map((prop, index) => (
                        <div key={`${prop.id}_container_${keyId}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <FunctionalInput
                          key={prop.id}
                          propId={prop.id}
                          subjectName={prop.subjectName}
                          disabled={!extraDataEditMode || prop.readOnly}
                          values={extraDataValues}
                          onChange={this.handleExtraDataChange}
                          suffix={!extraDataEditMode ? ': ' : null}
                          containerStyle={
                            extraDataEditMode
                              ? { padding: 0, marginBottom: 3 * theme.verticalMargin }
                              : { padding: '5px 0', flexDirection: 'row', alignItems: 'flex-start', maxWidth: Boolean((!extraDataValues[prop.id] && !minMode)) ? '70%' : null }}
                          disabledValueStyle={{
                            fontSize: theme.fontSize, fontWeight: 700,
                            ...(extraDataEditMode && prop.readOnly)
                              ? { paddingTop: theme.padding } : { fontSize: theme.fontSize, [`padding${rtl ? 'Right' : 'Left'}`]: theme.padding }
                          }}
                          titleStyle={{ flex: 1, textTransform: 'capitalize', ...Boolean(extraDataEditMode) && { fontSize: theme.fontSize - 2, marginBottom: -2 * theme.verticalMargin } }}
                          noValueComponent={extraDataEditMode ? null : <div style={{ flex: 1 }}></div>}
                          alwaysShowOnNullValue={true}
                          isMandatory={!prop.optional}
                        />
                        </div>
                      )
                    )
                }
              </div>
            )
          }
        </InnerCollapsibleRow>

      );
    }
  }
}

class OptimizedChecklistRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { itemsArr = [], currItemId, instancesDocsAndIssues, selectedItemId, minMode, locationsData } = this.props;
		const curr = itemsArr.find(curr => curr.item.id === currItemId) || {};

		return (
			<ChecklistRow
				value={curr.item}
				currInstance={curr.currInstance}
				prevInstance={curr.prevInstance}
				locationId={curr.locationId}
				issuesCounter={
					curr.currInstance && instancesDocsAndIssues.getNested([curr.currInstance.id, 'issues', 'length'])
				}
				documentationsCounter={
					curr.currInstance && instancesDocsAndIssues.getNested([curr.currInstance.id, 'docs', 'length'])
				}
				isSelected={!minMode && (curr.item || {}).id === selectedItemId}
				locationsData={locationsData}
				{...this.props}
			/>
		);
	}
}

OptimizedChecklistRow = withStyles(buttonStyle)(OptimizedChecklistRow);

const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connectContext(ChecklistContext.Consumer),
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{
			startToast,
		},
	),
);

export default enhance(injectIntl(OptimizedChecklistRow));
