import { getDispatch } from '../configureMiddleware';
import { subscribeToLastUpdates, unsubscribeToLastUpdates } from '../lib/utils/utils';
import { enterProject } from './actions';
import { PROJECT_EVENTS } from './trackProjects';
import serverSDK from '@cemento-sdk/server';

export const getUserProjects = async (viewer) => {
  const dispatch = getDispatch();
  const scopeParams = {
    scope: 'global',
  };

  const resourceParams = {
    subject: 'projects',
    getData: () => {
      return serverSDK.projects.getProjects({});
    },
  };

  const onData = (projects) => {
    dispatch({ type: PROJECT_EVENTS.GET_PROJECTS, payload: { projects } });
  };

  const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, true);

  if (result) onData(result);
};

export const getProjectDetails = async (viewer, projectId) => {
  const dispatch = getDispatch();
  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'projects',
    getData: () => {
      return serverSDK.projects.getProject({ id: projectId });
    },
  };

  const onData = (data) => {
    dispatch({ type: PROJECT_EVENTS.GET_PROJECT_DETAILS, payload: { projectId, project: data } });
  };

  const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, true);
  if (result) onData(result);
};

export const upsertProjectMembers = async ({ inProject, members, isAddingMembers }) => {
  const dispatch = getDispatch();
  const project = Object.assign({}, inProject.toJS?.() || inProject);

  if (isAddingMembers) {
    const newMembers = members.reduce((acc, { id }) => {
      acc[id] = { id };
      return acc;
    }, {});

    Object.assign(project.members, newMembers);

    await serverSDK.projects.addOrUpdateMembers({
      id: project.id,
      members: members.map(({ id, displayName }) => ({ id, displayName })),
    });
  } else {
    members.forEach(({ id }) => delete project.members[id]);

    await serverSDK.projects.removeMembers({
      id: project.id,
      memberIds: members.map(({ id }) => id),
    });
  }

  dispatch({ type: PROJECT_EVENTS.UPDATE_PROJECT_MEMBERS, payload: { project, members, isAddingMembers } });
};

export const getProjectById = async ({ projectId, fields }) => {
  if (!projectId) return;

  return await serverSDK.projects.getProjects({
    projectId,
    fields,
  });
};

export const navigateToProject = (
  props,
  projectId,
  toLocation = 'issues/dashboard/',
  overwriteURL = false,
  newTab = false
) => {
  const { history, selectedProjectId } = props;

  if (!projectId) return null;

  let redirectUrl = overwriteURL ? overwriteURL : `/main/projectContainerPage/${projectId}/${toLocation}`;
  if (newTab && window) {
    window.open(redirectUrl, '_blank');
    return;
  }

  if (selectedProjectId !== projectId) {
    const dispatch = getDispatch();
    dispatch(enterProject(projectId, selectedProjectId));
  }

  history.push(redirectUrl);
};

export const endUserProjectsListener = () => {
  const scopeParams = { scope: 'global' };
  const resourceParams = { subject: 'projects' };
  unsubscribeToLastUpdates(scopeParams, resourceParams);

  const dispatch = getDispatch();
  dispatch({ type: PROJECT_EVENTS.END_PROJECTS_LISTENER });
};