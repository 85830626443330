import _ from 'lodash';
import { lokiInstance, realmInstance } from '../configureMiddleware';
import { debugParams, fetchByTS, notifyLongFunctionDuration, removeEmpty, replaceMaxUpdateTSIfNeeded, subscribeToLastUpdates, unsubscribeToLastUpdates } from '../lib/utils/utils';
import { platformActions } from '../platformActions';
import trackEquipment, { EQUIPMENT_EVENTS } from './trackEquipment';
import serverSDK from '@cemento-sdk/server';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { getNewId } from '../lib/api';

export const startEquipmentListener = (viewer, projectId, cleanAll) => {
  if (cleanAll) setEquipmentValues({}, projectId, cleanAll);

  const saveFunc = (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave) return;
    setEquipmentValues(_data, projectId, isRevoke);
  };

  const scopeParams = { scope: 'projects', scopeId: projectId };

  trackEquipment(EQUIPMENT_EVENTS.START_EQUIPMENT_LISTENER, scopeParams);

  return subscribeToLastUpdates(
    viewer,
    scopeParams,
    { 
      subject: 'equipment',
      getLastUpdateTS: () => getLastUpdateTS(projectId),
      getData: (lastUpdateTS) => {
        trackEquipment(EQUIPMENT_EVENTS.FETCH_EQUIPMENT, {...scopeParams, lastUpdateTS});
        return serverSDK.equipment.getEquipments({ ...scopeParams, lastUpdateTS });
      },
      isObjectUseTransactionalDB: true,
    },
    saveFunc,
  );
}

export const endEquipmentListener = (projectId) => {
  const scopeParams = { scope: 'projects', scopeId: projectId };
  unsubscribeToLastUpdates(
    scopeParams,
    { subject: 'equipment' },
  );

  trackEquipment(EQUIPMENT_EVENTS.END_EQUIPMENT_LISTENER, scopeParams);
};

const saveEquipment = (projectId, newEquipment, originalEquipment = null) => {
	if (projectId && Object.values(newEquipment || {}).length) {
    trackEquipment(EQUIPMENT_EVENTS.SAVE_EQUIPMENT, { projectId, newEquipment, originalEquipment });
		newEquipment = Object.values(newEquipment).filter(Boolean).map(equipment => ({ ...equipment, projectId, projectId_objId: projectId + "_" + equipment.id}));
		
		uploadObjectsDispatcher({
			projectId,
			objectsToSave: newEquipment,
			originalObjects: originalEquipment,
			schemaInfo: schemasInfo.equipment,
		});
	}
}

export const upsertEquipment = (inEquipment, projectId) => {
  if (!inEquipment) return;

  let equipmentToSave = { ...inEquipment.realmToObject(), projectId };
  if (!equipmentToSave.id) {
    const id = getNewId()
    equipmentToSave.id = id;
  }

  equipmentToSave = removeEmpty(equipmentToSave, 'upsertEquipment');

  saveEquipment(projectId, [equipmentToSave]);
}

export const deleteEquipment = (equipmentId, projectId) => {
  const equipmentToSave = { id: equipmentId, isDeleted: true };
  trackEquipment(EQUIPMENT_EVENTS.DELETE_EQUIPMENT, { projectId, equipmentToSave });

  saveEquipment(projectId, [equipmentToSave]);
}

function getLastUpdateTS(scopeId) {
  let lastUpdateTS = 0;

  if (platformActions.app.getPlatform() == 'web') {
    let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance
      .getCollection('equipment')
      .chain()
      .find({ projectId: scopeId, isLocal: { '$ne': true } })
      .simplesort('updatedTS', true)
      .limit(1)
      .data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
  } else {
    lastUpdateTS = realmInstance.equipment
      .objects('equipment1')
      .filtered('projectId = "' + scopeId + '"')
      .max('updatedTS');
  }

  return lastUpdateTS || 0;
}

function setEquipmentValues(equipment, projectId, cleanAll) {
  if (platformActions.app.getPlatform() == 'web') saveToLoki(equipment, projectId, cleanAll);
  else
    notifyLongFunctionDuration(
      () => saveToRealm(equipment, projectId, cleanAll),
      `saveToRealm ~ The function took too long`,
      `equipment`
    );
}

function saveToRealm(equipment, projectId, cleanAll) {
  if (!cleanAll && Object.keys(equipment || {}).length == 0) return;

  equipment = Object.values(equipment || {}).sort((equipmentA, equipmentB) =>
    (equipmentA.updatedTS || 0) > (equipmentB.updatedTS || 0) ? -1 : 1
  );
  let currBatchMaxLastUpdateTS = _.get(equipment, [0, 'updatedTS'], 0);

  let realm = realmInstance.equipment;
  realm.write(() => {
    if (cleanAll) {
      let allEquipment = realm.objects('equipment1').filtered(`projectId = "${projectId}"`);
      realm.delete(allEquipment);
    }
    const deletedIdList = [];

    equipment.forEach((curr) => {
      if (curr && curr.id) {
        if (!curr.isDeleted)
          realm.create(
            'equipment1',
            { ...curr.realmToObject(), projectId_objId: projectId + '_' + curr.id, projectId },
            true
          );
        else {
          deletedIdList.push(curr.id);
        }
      } else console.warn('equipment missing ID'); // TODO: Send to bugsnag
    });
    if (deletedIdList?.length) {
      try {
        const allDeletedInstances = realm
          .objects('equipment1')
          .filtered(`projectId = "${projectId}" AND id IN {"${deletedIdList.join('", "')}"}`);

        realm.delete(allDeletedInstances);
      } catch (error) {
        console.log('____ERRROR', error);
      }
    }
    replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'equipment1', `projectId = "${projectId}"`);
  });
}

function saveToLoki(equipment = {}, projectId, cleanAll) {
  if (!cleanAll && Object.keys(equipment || {}).length == 0) return;
  let allEquipment = [];
  let deletedIds = [];

  equipment = Object.values(equipment).sort((equipmentA, equipmentB) =>
    equipmentA.updatedTS > equipmentB.updatedTS ? -1 : 1
  );

  equipment.forEach((curr) => {
    curr.isDeleted ? deletedIds.push(curr.id) : allEquipment.push({ ...curr.realmToObject(), projectId });
  });
  if (cleanAll) {
    lokiInstance.getCollection('equipment').cementoDelete({ projectId });
  }
  if (deletedIds.length > 0)
    lokiInstance.getCollection('equipment').cementoDelete({ projectId, id: { '$in': deletedIds } });
  lokiInstance.getCollection('equipment').cementoUpsert(allEquipment);
}
