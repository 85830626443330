import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { injectIntl } from 'react-intl';
import systemMessages from '../../../common/app/systemMessages';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import theme from '../../assets/css/theme';
import Text from '../CementoComponents/Text';
import { getCamerasStatus, getLastSyncTSFromCamerasMonitor } from '../../../common/siteControl/funcs';
import withStyles from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';
import Tooltip from '../CementoComponents/Tooltip';
import SiteControlToolTip, { SITE_CONTROL_TOOLTIP_TYPES } from './SiteControlToolTip';
import { useSelector } from 'react-redux';

const presentIndicatorSize = 10;
const TOOLTIP_WIDTH = 320;

export const ActivityIndicator = props => {
  const { isActive, status } = props;
  if (_.isNil(isActive) && _.isNil(status))
    return null;
  if (status)
    return <div style={activityIndicatorStyles[status]}></div>;
  else
    return <div style={activityIndicatorStyles[isActive ? 'activeActivityIndicator' : 'nonActiveActivityIndicator']}></div>;
};

const activityIndicatorStyles = {
  activeActivityIndicator: {
    margin: theme.padding,
    height: presentIndicatorSize,
    width: presentIndicatorSize,
    backgroundColor: theme.brandSuccess,
    borderRadius: theme.circleBorderRadius
  },
  partiallyActiveActivityIndicator: {
    margin: theme.padding,
    height: presentIndicatorSize,
    width: presentIndicatorSize,
    backgroundColor: theme.brandWarning,
    borderRadius: theme.circleBorderRadius
  },
  nonActiveActivityIndicator: {
    margin: theme.padding,
    height: presentIndicatorSize,
    width: presentIndicatorSize,
    backgroundColor: theme.placeholderTextColor,
    borderRadius: theme.circleBorderRadius
  },
};

const camerasStatusMessage = {
  activeActivityIndicator: siteControlMessages.presenceLogs.lastUpdated,
  partiallyActiveActivityIndicator: siteControlMessages.presenceLogs.partiallySynched,
  nonActiveActivityIndicator: siteControlMessages.presenceLogs.lastUpdated,
};

const getFormattedTime = (timestamp, lang) => {
  const userTimezone = moment.defaultZone.name;
  return moment(timestamp).tz(userTimezone).locale(lang).format('L, LTS');
};

export default injectIntl(props => {
  const { monitorData, lang } = props;
  const { isRTL } = useSelector(state => ({ isRTL: state.app.rtl }));
  const LightTooltip = withStyles(theme => ({
    tooltip: {
      maxWidth: TOOLTIP_WIDTH,
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: "Assistant",
      padding: 0
    },
  }))(Tooltip);

  if (!monitorData)
    return;

  const camerasStatus = getCamerasStatus(monitorData);
  const lastSync = getLastSyncTSFromCamerasMonitor(monitorData);

  const formattedLastSync = getFormattedTime(lastSync, lang);

  let message = lastSync ? camerasStatusMessage[camerasStatus] : systemMessages.camerasMessages.noData;
  let tooltipComponent = (<SiteControlToolTip type={SITE_CONTROL_TOOLTIP_TYPES.CAMERAS_STATUS} camerasMonitorData={monitorData} styleOverwrite={{ width: TOOLTIP_WIDTH }} lang={lang} />);
  
  return (
    <LightTooltip
      interactive
      leaveDelay={300}
      titleComponent={tooltipComponent}
      TransitionComponent={Fade}
      placement={isRTL ? 'bottom-end' : 'bottom-start'}
    >
      <div style={camerasMonitorStyles.container}>
        <ActivityIndicator status={camerasStatus} />
        <Text values={{ value: formattedLastSync }}>{message}</Text>
      </div>
    </LightTooltip>
  );
});

const camerasMonitorStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};