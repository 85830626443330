export const PROJECT_EVENTS = {
  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_STARTED: 'GET_PROJECTS_STARTED',
  END_PROJECTS_LISTENER: 'END_PROJECTS_LISTENER',
  GET_PROJECT_DETAILS: 'GET_PROJECT_DETAILS',
  PROJECT_DETAILS_LOADING: 'PROJECT_DETAILS_LOADING',
  UPDATE_PROJECT_MEMBERS: 'UPDATE_PROJECT_MEMBERS',
  LEAVE_PROJECT: 'LEAVE_PROJECT',
  ENTER_PROJECT: 'ENTER_PROJECT',
  SAVE_PROJECT_LOKI_STORAGE: 'SAVE_PROJECT_LOKI_STORAGE',
  LOAD_PROJECT_STORAGE: 'LOAD_PROJECT_STORAGE',
  UNLOAD_PROJECT_LOKI_STORAGE: 'UNLOAD_PROJECT_LOKI_STORAGE',
  REMOVE_PROJECTS_STORAGE: 'REMOVE_PROJECTS_STORAGE',
  SET_PROJECT_INTL: 'SET_PROJECT_INTL',
};

export const PROJECT_TYPE_HOUSE = 1;
export const PROJECT_TYPE_COMPLEX = 2;
export const PROJECT_TYPE_BUILDING = 3;
export const PROJECT_TYPE_COMPLEX_BUILDINGS = 4;