import _ from 'lodash';
import * as ioEvents from './io/eventTypes';
import * as ioDisconnectReasons from './io/disconnectReasons';
import SimpleQueue from '../SimpleQueue';
import { getAppState } from '../configureMiddleware';
import { safeToJS } from '../permissions/funcs';
import { batchDispatch } from '../app/funcs';
import { connectionManagerUpdate } from '../app/actions';
import { platformActions } from '../platformActions';
import TableLogger from '../TableLogger';
import * as ioSocket from './io/serverSocket';
import DataManagerInstance from '../dataManager/DataManager';
import { DATA_MANAGER_STATES } from '../dataManager/DataManagerConstants';


/**
 * @typedef {'global' | 'projects' | 'companies'} Scope
 * @typedef {{ data?: any[], attributes: SubscriptionParams }} ChangePayload
 * @typedef {{ id: string, phoneNumber: string }} Viewer
 * @typedef {{ 
 * 	scope: Scope, 
 *  scopeId: string, 
 *  subject: string, 
 *  shouldSendData?: boolean, 
 *  lastUpdateTS?: number, 
 *  params?: Object<string, any>, 
 *  uniqueKey?: string, // used to differentiate between different services with the same scope, scopeId, subject and params -> it also means that the service is not binded to the last updates docs. The fetches and such wont't affect the last updates docs
 * }} SubscriptionParams - if shouldSendData is true, on change will trigger onData, otherwise onEnvelope
 * @typedef {(data: any[]) => void} OnData
 * @typedef {(didChange: boolean) => void} OnConnectionEstablished - Called when we read a value from the lastUpdates table. Won't trigger for revokes
 * @typedef {(metadata: Record<string, any>) => void} OnEnvelope - Receives isRevoke by default
 * @typedef {{ onData?: OnData, onEnvelope?: OnEnvelope, onConnectionEstablished?: OnConnectionEstablished  }} ServiceHandlers
 * @typedef {{ id: string, subscriptionParams: SubscriptionParams, handlers?: ServiceHandlers }} Service
 */

/**
 * @param {Partial<SubscriptionParams>} subscriptionParams
 */
export const getUniqueServiceId = subscriptionParams => {
	const { scope, scopeId, subject, params, uniqueKey } = subscriptionParams;
	return [scope, scopeId, subject, _.values(params).join('+'), uniqueKey].filter(Boolean).join('_');
};

const PROCESS_QUEUE_INTERVAL = 1 * 30 * 1000; // 30 sec
class ClientServerConnectivityManager {
	/** @type {Boolean} */
	#didInitSocketListeners;
	/** @type {Object<string, Service>} */
	registeredServices;
	/** @type {Object<string, boolean>} */
	connectionEstablishedReceived;
  /** @type {import('EventEmitter').default | Socket} */
  #socket;
	#viewer;
	#processQueueInterval = setInterval(() => {
		if (this.changesQueue.idle) {
			this.changesQueue.processQueue();
		}
	}, PROCESS_QUEUE_INTERVAL);

	constructor() {
		this.#didInitSocketListeners = false;
		this.registeredServices = {};
		this.connectionEstablishedReceived = {};
		this.changesQueue = new SimpleQueue();
		this.logger = new TableLogger({
			enabled: process.env.NODE_ENV != "production",
			name: 'Client Server Connectivity Manager',
			columns: [
				['scope', 'Scope'],
				['scopeId', 'Scope ID'],
				['subject', 'Subject'],
				['didFetchOnConnectionEstablished', 'Did fetch on connection established'],
				['status', 'Status'],
			],
			columnsToShowOnLogs: ['status'],
			logTableOnChange: false,
			trackHistory: process.env.NODE_ENV === 'development',
			sortRowsFunc: (a, b) => {
				if (a.scope === 'global' && b.scope === 'global') {
					return a.subject.localeCompare(b.subject);
				} else if (a.scope === 'global' && b.scope !== 'global') {
					return -1;
				} else if (a.scope !== 'global' && b.scope === 'global') {
					return 1;
				} else {
					return a.scopeId.localeCompare(b.scopeId) || a.subject.localeCompare(b.subject);
				}
			}
		}
		);
	}

	/**
	 * 
	 * @param {ChangePayload} payload 
	 * @returns 
	 */
	#processChange = async (payload) => {
		if (!getAppState().getNested(["app", "isConnected"], false)) {
			throw 'Not connected'; // TODO
		}

		if (!payload?.attributes) return;

		const service = this.#getService(payload.attributes);
		if (!service) return;

		const { id: serviceId, handlers, subscriptionParams } = service;
		const { shouldSendData } = subscriptionParams;
		const { onEnvelope, onData } = handlers;
		try {
			if (shouldSendData && payload.data) {
				this.logger.upsertRow({
					id: serviceId,
					status: `Saving ${ioEvents.CHANGE} data...`,
				}, `Socket event "${ioEvents.CHANGE}"\n${serviceId}`);
				this.#updateConnectionStatus({ subscriptionParams, status: `${ioEvents.CHANGE} - Saving data` });
				await onData?.(payload.data);
			} else if (!shouldSendData) {
				this.logger.upsertRow({
					id: serviceId,
					status: `Fetching ${ioEvents.CHANGE} data...`,
				}, `Socket event "${ioEvents.CHANGE}"\n${serviceId}`);
				this.#updateConnectionStatus({ subscriptionParams, status: `${ioEvents.CHANGE} - Fetching` });

				await onEnvelope?.(payload.data);

				this.logger.upsertRow({
					id: serviceId,
					status: `Done fetching ${ioEvents.CHANGE} data`,
				}, `Socket event "${ioEvents.CHANGE}"\n${serviceId}`);
				//this.#updateConnectionStatus({ subscriptionParams, status: `${ioEvents.CHANGE} - Done Fetching` });
			} else {
				this.logger.upsertRow({
					id: serviceId,
					status: `No data ${ioEvents.CHANGE}:`,
				}, `Socket event "${ioEvents.CHANGE}"\n${serviceId}`);
			}
			
		} catch (error) {
			this.logger.upsertRow({
				id: serviceId,
				status: `Error processing ${ioEvents.CHANGE} data: ${error.errorMessages || error.message || error}`,
			}, `Failed to process ${ioEvents.CHANGE} event ${serviceId}`);
			console.log(error);
			throw error;
		}
	}

	#syncQueue = () => {
		return this.changesQueue.processQueue();
	}

	/**
	 *
	 * @param {string[]} strArr
	 */
	#log = (...strArr) => {
		console.info(`Client server connectivity manager:\n`, ...strArr.filter(Boolean).map(str => `${str}\n`));
	};

	#updateConnectionStatus = ({subscriptionParams, status}) => {
		const { scope, scopeId, subject, params } = subscriptionParams;
		let isConnectionViewerVisible = (getAppState?.()?.getNested?.(["app", "connectionViewerVisiblity", "visible"]))

		DataManagerInstance.updateStatus({ scope, scopeId, subject, subjectParams: params, stateType: DATA_MANAGER_STATES.CONNECTION_STATUS, status });

		if (platformActions.app.isWeb() && isConnectionViewerVisible) {
			batchDispatch([connectionManagerUpdate({ status, scope, scopeId, subject, subjectName:params?.subjectName })]);
		}
	}

	/**
	 * Inits server socket listeners
	 *
	 * @returns
	 */
	#initSocketListeners = (force = false) => {
		if (!this.#socket) return; // TODO: throw error?

		if (!this.#didInitSocketListeners || force) {
			/**
			 *
			 * @param {typeof ioDisconnectReasons[keyof typeof ioDisconnectReasons]} reason
			 * @returns
			 */
			const disconnectListener = reason => {
				this.#log(`Socket event "${ioEvents.DISCONNECT}"`, 'Unsubscribe all services...');
				this.#unsubscribeAllServices();

				if (reason === ioDisconnectReasons.CLIENT_DISCONNECT) {
          this.#socket.off(ioEvents.CHANGE, this.#handleChangeListener);
					this.#socket.off(ioEvents.CONNECTION_ESTABLISHED, this.#handleConnectionEstablishedListener);
					this.#socket.off(ioEvents.DISCONNECT, disconnectListener);
					this.#socket.off(ioEvents.RECONNECT, this.#renewServicesSubscriptions);
          this.#socket.off(ioEvents.CONNECT, this.#renewServicesSubscriptions);
					this.#socket.emit(ioEvents.DISCONNECTED);
					this.#didInitSocketListeners = false;
				}
			};

			this.#socket.on(ioEvents.CHANGE, this.#handleChangeListener);
			this.#socket.on(ioEvents.CONNECTION_ESTABLISHED, this.#handleConnectionEstablishedListener);
			this.#socket.on(ioEvents.DISCONNECT, disconnectListener);
			this.#socket.on(ioEvents.RECONNECT, this.#renewServicesSubscriptions);
			this.#socket.on(ioEvents.CONNECT, this.#renewServicesSubscriptions);
			this.#didInitSocketListeners = true;
		}
	};

	/** @param {import('EventEmitter').default | Socket} socket */
	set socket(socket) {
		if (socket !== this.#socket) {
			this.#socket = socket;
			this.#log('New socket', 'Init listeners...', 'Renewing subscriptions...');
			this.changesQueue.flush();
			this.#initSocketListeners(true);
			this.#renewServicesSubscriptions();
		}
	}
	
	set viewer(viewer) {
		viewer = safeToJS(viewer);
		const isDiffViewer = ioSocket.isDiffViewer(viewer, this.#viewer);
		if (isDiffViewer) {
			this.changesQueue.flush();
			this.#unsubscribeAllServices();
			this.#viewer = viewer;
			this.#renewServicesSubscriptions();
		}
	}

	get viewer() {
		return this.#viewer;
	}

	/**
	 * Handles change events from the server, Given to the change listener on the socket
	 *
	 * @param {ChangePayload} payload
	 * @returns
	 */
	#handleChangeListener = payload => {
		if (!payload.attributes) return;

		const service = this.#getService(payload.attributes);
		if (!service){
			return;
		}

		this.changesQueue.enqueue({
			id: service.id,
			job: () => this.#processChange(payload),
			options: { retryTiming: 1000, timingMultiplier: 1.1 },
		});
	};

	/**
	 * Handles connection established event
	 * 
	 * @param {SubscriptionParams} payload 
	 * @param {boolean} didFetch If a fetch was triggered on init
	 * @returns 
	 */
	#handleConnectionEstablishedListener = (payload, didFetch) => {
		const service = this.#getService(payload);
		if (!service) {
			return;
		}

		const { id: serviceId, subscriptionParams, handlers } = service;
		const { onConnectionEstablished } = handlers;

		this.logger.upsertRow({
			id: serviceId,
			status: 'Connection Established',
			didFetchOnConnectionEstablished: didFetch ? 'Yes' : 'No',
		}, `Socket event "${ioEvents.CONNECTION_ESTABLISHED}"\n${serviceId} --> didFetch: ${didFetch}`);
		this.connectionEstablishedReceived[service.id] = true;

		onConnectionEstablished?.(didFetch);
		this.#updateConnectionStatus({ subscriptionParams, status: ioEvents.CONNECTION_ESTABLISHED });
	}

	/**
	 * Sets up listeners on initialization of the socket and emits subscribe event with the given params
	 *
	 * @param {SubscriptionParams} subscriptionParams
	 * @returns
	 */
	#subscribeService = subscriptionParams => {
		if (!this.#socket || !this.#viewer) return; // TODO: throw error?

		let subParams = subscriptionParams;
		if (_.isEmpty(subParams.params)) delete subParams.params;

		this.#socket.emit(ioEvents.SUBSCRIBE, this.#viewer, subscriptionParams);
		this.logger.upsertRow({
			id: this.#getUniqueServiceId(subscriptionParams),
			status: 'Subscribed',
		}, `Service subscribed ${this.#getUniqueServiceId(subscriptionParams)}`);
		this.#updateConnectionStatus({ subscriptionParams, status: ioEvents.SUBSCRIBE });
	};

	/**
	 * Goes over the registeredServices map and subcribes to events again
	 *
	 * @returns
	 */
	#renewServicesSubscriptions = () => {
		_.values(this.registeredServices).forEach(service => {
			const { subscriptionParams } = service;
			this.#subscribeService(subscriptionParams);
		});
	};

	/**
	 * Concats subscriptions params to generate a unique id for the subscription.
	 * This id is then used as key in the registeredServices map
	 *
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	#getUniqueServiceId = subscriptionParams => {
		return getUniqueServiceId(subscriptionParams);
	};

	/**
	 * Registers the service to the registeredServices map and emits subscription
	 *
	 * @param {SubscriptionParams} subscriptionParams
	 * @param {ServiceHandlers} serviceHandlers
	 * @returns
	 */
	#registerService = (subscriptionParams, serviceHandlers) => {
		const uniqueId = this.#getUniqueServiceId(subscriptionParams);

		this.registeredServices[uniqueId] = {
			id: uniqueId,
			subscriptionParams,
			handlers: serviceHandlers,
		};

		this.logger.upsertRow({
			id: uniqueId,
			scope: subscriptionParams.scope,
			scopeId: subscriptionParams.scopeId,
			subject: `${subscriptionParams.subject}/${subscriptionParams.params?.subjectName || ""}`,
			status: 'Service registered',
		}, `Service registered ${uniqueId}`);

		this.#subscribeService(subscriptionParams);
	};

	/**
	 * Update a service on registeredServices map and resubscribes if need be
	 *
	 * @param {SubscriptionParams} subscriptionParams
	 * @param {ServiceHandlers} serviceHandlers
	 * @returns
	 */
	#updateService = (subscriptionParams, serviceHandlers) => {
		const existingService = this.#getService(subscriptionParams);
		if (!existingService) return;

		this.logger.upsertRow({
			id: existingService.id,
			scope: subscriptionParams.scope,
			scopeId: subscriptionParams.scopeId,
			subject: `${subscriptionParams.subject}/${subscriptionParams.params?.subjectName || ""}`,
			status: 'Service updated',
		}, `Service updated ${existingService.id}`);

		const isDiffSubsParams = !_.isEqual(existingService.subscriptionParams, subscriptionParams);
		if (isDiffSubsParams) {
			this.#subscribeService(subscriptionParams);
		}

		Object.assign(existingService, { subscriptionParams, handlers: serviceHandlers });
		if (!isDiffSubsParams && this.connectionEstablishedReceived[existingService.id]) {
			serviceHandlers.onConnectionEstablished?.(false);
		}
	};

	/**
	 * Removes a service from the registeredServices map
	 *
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	#unregisterService = subscriptionParams => {
		const serviceId = this.#getUniqueServiceId(subscriptionParams);

		this.#unsubscribeService(subscriptionParams);

		delete this.registeredServices[serviceId];
		delete this.connectionEstablishedReceived[serviceId];
		this.logger.upsertRow({
			id: serviceId,
			status: 'Unregistered'
		}, `Unregistered service ${serviceId}`);
	};

  #unregisterAllServices = () => {
    _.values(this.registeredServices).forEach(service => {
      const { subscriptionParams } = service;
      this.#unregisterService(subscriptionParams);
    });
  }

	/**
	 * Emits an unsubscribe event to the server
	 *
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	#unsubscribeService = subscriptionParams => {
		if (!this.#socket || !this.#viewer) return; // TODO: throw

		let subParams = subscriptionParams;
		if (_.isEmpty(subscriptionParams.params)) delete subParams.params;

		this.#socket.emit(ioEvents.UNSUBSCRIBE, this.#viewer, subParams);
		const serviceId = this.#getUniqueServiceId(subscriptionParams);
		this.logger.upsertRow({
			id: serviceId,
			status: 'Unsubscribed',
		}, `Service unsubscribed ${serviceId}`);
		this.#updateConnectionStatus({ subscriptionParams, status: ioEvents.UNSUBSCRIBE });
	};

  #unsubscribeAllServices = () => {
    _.values(this.registeredServices).forEach(service => {
      const { subscriptionParams } = service;
      this.#unsubscribeService(subscriptionParams);
    });
  }

	/**
	 * Get a service from registeredService map
	 *
	 * @param {SubscriptionParams} subscriptionParams
	 * @returns
	 */
	#getService = subscriptionParams => {
		const uniqueId = this.#getUniqueServiceId(subscriptionParams);
		return this.registeredServices[uniqueId];
	};

	/**
	 * Get services matching the subscriptions params (which can be partial but must include at least scope and scopeId) from
	 * the registeredServices map
	 * @public
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	getServices = subscriptionParams => {
		const uniqueId = this.#getUniqueServiceId(subscriptionParams);
		/** @type {Service[]} */
		let relevantServices = [];
		_.entries(this.registeredServices).forEach(([uniqueServiceId, service]) => {
			if (uniqueServiceId.indexOf(uniqueId) !== -1) {
				relevantServices.push(service);
			}
		});

		return relevantServices;
	};

	/**
	 * Unsubscribes all registered services of a scope
	 *
	 * @public
	 * @param {Scope} scope
	 * @param {string} scopeId
	 * @returns
	 */
	unregisterAllScopeServices = (scope, scopeId) => {
		if (!(scope && scopeId)) return; // TODO: throw

		const relevantServices = this.getServices({ scope, scopeId });
		relevantServices.forEach(service => {
			this.unregisterService(service.subscriptionParams);
		});
	};

	/**
	 * Unsubscribes all registered services
	 * @public
	 * @returns
	 */
	unregisterAllServices = () => {
		this.#unregisterAllServices();
	}

	/**
	 * - Checks if the service is already subscribed, if so, no need to emit the event again, just update the handlers,
	 * otherwise, subscribe the service
	 *
	 * @public
	 * @param {SubscriptionParams} subscriptionParams
	 * @param {ServiceHandlers} serviceHandlers
	 * @returns
	 */
	registerService = (subscriptionParams, serviceHandlers) => {
		if (this.isServiceRegistered(subscriptionParams)) {
			this.#updateService(subscriptionParams, serviceHandlers);
		} else {
			this.#registerService(subscriptionParams, serviceHandlers);
		}
	};

	/**
	 * Unsubscribes and unregisters service
	 *
	 * @public
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	unregisterService = subscriptionParams => {
		if (!this.isServiceRegistered(subscriptionParams)) return;

		this.#unregisterService(subscriptionParams);
	};

	/**
	 * Check if the service is registered
	 *
	 * @public
	 * @param {SubscriptionParams} subscriptionParams
	 * @returns
	 */
	isServiceRegistered = subscriptionParams => {
		return Boolean(this.#getService(subscriptionParams));
	};

	/**
	 * Check if service of the same subject is registered
	 *
	 * @public
	 * @param {Partial<SubscriptionParams>} subscriptionParams
	 * @returns
	 */
	isSubjectRegistered = subscriptionParams => {
		return Boolean(this.getServices(subscriptionParams).length);
	};

	syncQueue = () => {
		return this.#syncQueue();
	}
}

// export const APIClientServerConnectivityManagerInstance = new ClientServerConnectivityManager(getServerSocket);
const ClientServerConnectivityManagerInstance = new ClientServerConnectivityManager();
globalThis.__ClientServerConnectivityManagerInstance = ClientServerConnectivityManagerInstance;
export const getServices = ClientServerConnectivityManagerInstance.getServices;
export const connectionEstablishedReceived = ClientServerConnectivityManagerInstance.connectionEstablishedReceived;

export default ClientServerConnectivityManagerInstance;
