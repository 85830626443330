import React from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { connectContext } from "react-connect-context";
import {
  ProjectContext,
  FiltersSortsContext,
} from "../../../common/projects/contexts";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import {
  PROJECT_TYPE_COMPLEX,
  PROJECT_TYPE_BUILDING,
  PROJECT_TYPE_COMPLEX_BUILDINGS,
} from "../../../common/projects/trackProjects.js";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  getChecklistStatusReport,
  clearChecklistStatusReport,
} from "../../../common/checklists/actions";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import systemMessages from "../../../common/app/systemMessages";
import { injectIntl } from "react-intl";
import _ from "lodash";
import withRouterHOC from "../../components/Router/util/withRouterHOC.js";

var qs = require("qs");

const DEFAULT_VIEW = { view: { key: "tasks" } };
const DEFUALT_SORT = { trade: { key: "trade", default: true } };
const DEFUALT_SORT_FLOOR = { trade: { key: "trade", default: true } };
const DEFUALT_SORT_UNIT = { trade: { key: "trade", default: true } };

class Sorts extends React.Component {
  constructor(props) {
    super(props);
    this.onSortChangeDirection = this.onSortChangeDirection.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.getMenuValues = this.getMenuValues.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      direction: props.direction || 1,
      selectedSort: props.defaultSort || "trade",
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { sorts, direction } = props;

    let newStateChanges = {};
    newStateChanges = Object.assign(
      newStateChanges,
      this.getMenuValues(nextProps)
    );

    if (props.firstMount) {
      let queryParams = qs.parse(
        this.props.getNested(["location", "search"], "?").replace("?", "")
      );
      if (queryParams.sort) newStateChanges.selectedSort = queryParams.sort;
    }

    if (
      props.getNested(["navigationParams", "page"]) !=
      nextProps.getNested(["navigationParams", "page"])
    )
      newStateChanges.selectedSort = props.defaultSort || "trade";

    if (direction != nextProps.direction)
      newStateChanges.direction = nextProps.direction;

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onSortChangeDirection() {
    let newDirection = this.state.direction * -1;
    if (this.props.onSortChangeDirection)
      this.props.onSortChangeDirection(newDirection);
  }

  getMenuValues(nextProps) {
    const {
      sortByLocation = false,
      selectedProjectId,
      projects,
      navigationParams,
      sortMenu = [],
      sortValuesFunc = () => {},
    } = nextProps;

    var sortFloorMenuData = [
      { title: postsMenuMessages["floor"], key: "floor" },
    ];

    let extraMenuValues = [];
    var sortUnitMenuData = [{ title: postsMenuMessages["unit"], key: "unit" }];
    var project = projects.getNested([selectedProjectId], {});
    var defaultSort = DEFUALT_SORT;
    var isUnitId = navigationParams.getNested(["unitId"]) != "_";
    var isFloorId = navigationParams.getNested(["floorId"]) != "_";
    var isBuildingId = navigationParams.getNested(["buildingId"]) != "_";

    if (
      Boolean(sortByLocation) &&
      (project.type == PROJECT_TYPE_BUILDING ||
        project.type == PROJECT_TYPE_COMPLEX_BUILDINGS ||
        project.type == PROJECT_TYPE_COMPLEX)
    ) {
      if (isBuildingId && !isFloorId) {
        extraMenuValues.push(...sortFloorMenuData, ...sortUnitMenuData);
        defaultSort = DEFUALT_SORT_FLOOR;
      } else if (isBuildingId && isFloorId && !isUnitId) {
        extraMenuValues.push(...sortUnitMenuData);
        defaultSort = DEFUALT_SORT_UNIT;
      }
    }

    return {
      sortMenu: [...(sortMenu || []).sort(sortValuesFunc), ...extraMenuValues],
      sortValue: defaultSort,
    };
  }

  onSort = (e) => {
    const { onChange } = this.props;
    let sort = e.value;
    if (onChange) onChange(sort);
    let queryParams = qs.parse(
      this.props.getNested(["location", "search"], "?").replace("?", "")
    );
    queryParams.sort = sort;
    let newParams = qs.stringify(queryParams);
    this.props.history.push(this.props.location.pathname + "?" + newParams);
    this.setState({ selectedSort: sort });
  };

  render() {
    const { direction, selectedSort, sortMenu } = this.state;
    const { hideSortDirection, intl, rtl } = this.props;

    let selectedOption = null;
    let selectedOptionTitle = "";
    const options = (sortMenu || []).map((sort) => {
      const currOption = { value: sort.key, label: <Text>{sort.title}</Text> };
      if (selectedSort == sort.key) {
        selectedOption = currOption;
        selectedOptionTitle =
          sort && (sort.title || {}).defaultMessage
            ? intl.formatMessage(sort.title)
            : (sort || {}).title || "";
      }

      return currOption;
    });
    
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{ display: "flex", flex: 1, margin: "0px 10px" }}
          title={selectedOptionTitle}
        >
          <Select
            value={selectedOption}
            onChange={this.onSort}
            styles={SortSelectStyles}
            options={options}
            components={{
              SingleValue: ({ children, ...props }) => (
                <components.SingleValue {...props}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        [`margin${rtl ? "Left" : "Right"}`]:
                          theme.verticalMargin,
                      }}
                    >
                      {`${intl.formatMessage(systemMessages.sortBy)}`}
                    </div>
                    {children}
                  </div>
                </components.SingleValue>
              ),
            }}
          />
        </div>
        {Boolean(!hideSortDirection) && (
          <div
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={this.onSortChangeDirection}
          >
            {direction == -1 ? (
              <ArrowDownward style={{ fontSize: 16, margin: 5 }} />
            ) : (
              <ArrowUpward style={{ fontSize: 16, margin: 5 }} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const SortSelectStyles = {
  ...theme.selectStyles,
  container: (base) => ({
    ...theme.selectStyles.container(base),
    minWidth: 160,
  }),
};

const styles = {
  textCenter: {
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    justify: "center",
  },
  outlinedButtons: {
    fontSize: "8.5px",
    borderRadius: 0,
    color: theme.brandNeutral,
  },
  selectedMenu: {
    borderRadius: "5px",
    paddingRight: "5px",
    paddingLeft: "5px",
    backgroundColor: "#e2e2e9",
  },
};

Sorts = withStyles({ ...buttonStyle, ...styles })(Sorts);
const enhance = compose(
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  connectContext(FiltersSortsContext.Consumer),
  injectIntl,
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    { getChecklistStatusReport, clearChecklistStatusReport }
  )
);
export default enhance(Sorts);
