import React from "react";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import {
  ProjectContext
} from "../../../common/projects/contexts";
import { ChecklistPageContext } from "../../../common/checklists/contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import SplitViewPage from "../../layouts/SplitViewPage";
import Checklists from "./Checklists";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import _ from 'lodash';
import withRouterHOC from "../../components/Router/util/withRouterHOC.js";
import { track } from "../../../common/lib/reporting/actions.js";
import { withLoading } from "../../../common/hooks/useLoading.js";
import { SUBJECTS } from "../../../common/dataManager/subjects.js";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import LoadingIndicator from "../../components/Loading/LoadingIndicator.js";

class ChecklistPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSideClose = this.handleSideClose.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.setChecklistsCompleteInfo = this.setChecklistsCompleteInfo.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    let locationsDataType = this.props.match.params.unitId ? 'unit' : (this.props.match.params.floorId ? 'floor' : 'building');
    this.state = {
      locationsData: {
        buildingId: this.props.match.params.buildingId,
        floorId: this.props.match.params.floorId,
        unitId: this.props.match.params.unitId,
        type: locationsDataType,
      },
      selectedStage: null,
      selectedChecklist: null,
      selectedChecklistItem: null,
    };
  }

  onRowClick(
    selectedStage,
    selectedChecklist,
    selectedChecklistItem,
    locationId,
    isNewLocation = false
  ) {
    const { onLocationItemChange } = this.props;
    let updates = {
      selectedStage,
      selectedChecklist,
      selectedChecklistItem,
      selectedLocationId: locationId,
      isNewLocation,
    };
    if (onLocationItemChange) onLocationItemChange(updates);
    this.setState(updates);
  }

  setChecklistsCompleteInfo(checklistsCompleteInfo) {
    const { onChecklistsCompleteInfoChange } = this.props;

    this.setState({ checklistsCompleteInfo });
    if (onChecklistsCompleteInfoChange)
      onChecklistsCompleteInfoChange(checklistsCompleteInfo);
  }

  recalcHeader() {
    const { setHeaderParams } = this.props;
    if (setHeaderParams)
      setHeaderParams({
        headerComponent: <></>,
        sideBarParams: { open: false },
      });
  }

  UNSAFE_componentWillMount() {
    this.recalcHeader();
    this.setComponentData({}, this.props);
  }

  componentDidMount() {
    const { track, selectedProjectId, match } = this.props;
    let trackOptions = { projectId: selectedProjectId }
    if (match.params?.buildingId) trackOptions.buildingId = match.params.buildingId;
    if (match.params?.floorId) trackOptions.floorId = match.params.floorId;
    if (match.params?.unitId) trackOptions.unitId = match.params.unitId;
    track('enterChecklistPage', trackOptions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { changedOccurred, mergedObjects, didFetchedOnce } = this.state;
    let newStateChanges = {};
    if (
      props.isValDiff(nextProps, ["match", "params", "unitId"]) ||
      props.isValDiff(nextProps, ["match", "params", "floorId"]) ||
      props.isValDiff(nextProps, ["match", "params", "buildingId"])
    ) {
      let location = {
        buildingId: nextProps.match.params.buildingId,
        floorId: nextProps.match.params.floorId,
        unitId: nextProps.match.params.unitId,
      };
      const nextPropsUnitId = _.get(nextProps, ['match', 'params', 'unitId'], '');
      const nextPropsFloorId = _.get(nextProps, ['match', 'params', 'floorId'], '');
      const type = nextPropsUnitId !== '_' ? 'unit' : nextPropsFloorId !== '_' ? 'floor' : 'building';
      newStateChanges.selectedLocationId = location[`${type}Id`];
      newStateChanges.locationsData = {
        type,
        ...location,
      };
    }

    if (Object.values(newStateChanges).length)
      this.setState((prevState) => {
        const { selectedStage, selectedChecklist, selectedChecklistItem } =
          prevState;
        if (
          (newStateChanges.selectedLocationId ||
            prevState.selectedLocationId) &&
          newStateChanges.selectedLocationId != prevState.selectedLocationId
        ) {
          newStateChanges.selectedStage = null;
          newStateChanges.selectedChecklist = null;
          newStateChanges.selectedChecklistItem = null;
        } else {
          if (selectedStage) {
            let currSelectedStageId = selectedStage.getNested(["id"]);
            if (props.isValDiff(nextProps, ["stages", currSelectedStageId])) {
              newStateChanges.selectedStage = nextProps.getNested([
                "stages",
                currSelectedStageId,
              ]);
            }
          }

          if (selectedChecklist) {
            let currSelectedChecklistId = selectedChecklist.getNested(["id"]);
            if (
              props.isValDiff(nextProps, [
                "checklists",
                currSelectedChecklistId,
              ])
            )
              newStateChanges.selectedChecklist = nextProps.getNested([
                "checklists",
                currSelectedChecklistId,
              ]);
          }

          if (selectedChecklistItem) {
            let currSelectedChecklistItemId = selectedChecklistItem.getNested([
              "id",
            ]);
            if (
              props.isValDiff(nextProps, [
                "checklistItems",
                currSelectedChecklistItemId,
              ])
            )
              newStateChanges.selectedChecklistItem = nextProps.getNested([
                "checklistItems",
                currSelectedChecklistItemId,
              ]);
          }
        }

        return newStateChanges;
      });
  }

  handleSideClose() {
    this.setState({
      selectedStage: null,
      selectedChecklist: null,
      selectedChecklistItem: null,
      selectedLocationId: null,
    });
  }

  render() {
    const { rtl, uiParams, isEditMode, selectedProjectId, trades, checklists, checklistItems, projectCompanies, setChecklistsMappedByStage, isDataLoading, loadingProgress } = this.props;
    const { locationsData, selectedStage, selectedChecklist, selectedChecklistItem, selectedLocationId, checklistsCompleteInfo, isNewLocation } = this.state;
    let size = uiParams.getNested(['screenBootstrapWidth'])
    let maxChars = size == 'xl' ? 100 : size == 'lg' ? 70 : size == 'md' ? 30 : 20;
    let sideCardType = null;
    let sideCardObject = null;

    let sideCardProps = {
      stage: selectedStage,
      checklist: selectedChecklist,
      checklistItem: selectedChecklistItem,
      locationId: selectedLocationId,
      focusOnInput: isNewLocation,
      locationsData,
    };

    if (selectedChecklistItem)
      sideCardType = "checklistItem";
    else if (selectedChecklist) {
      sideCardType = "checklists";
      sideCardProps.selectedChecklists = { [selectedChecklist.id]: true };
    }
    // else if (selectedStage)
    //   sideCardType = 'stage';

    sideCardObject = {
      type: sideCardType,
      props: sideCardProps,
    };

    const checklistsMap = checklists;
    const checklistItemsMap = checklistItems;

    if (isDataLoading) return <LoadingIndicator loadingProgress={loadingProgress}/>

    return (
      <ChecklistPageContext.Provider value={{
        isEditMode,
        selectedProjectId,
        trades,
        checklistsCompleteInfo,
        projectCompanies,
        sideCardClosed: !selectedStage,
        checklistsMap,
        checklistItemsMap,
        setChecklistsCompleteInfo: this.setChecklistsCompleteInfo,
      }}>
        <SplitViewPage
          rtl={rtl}
          onSideClose={this.handleSideClose}
          SideStack={Boolean(sideCardObject?.type) && [sideCardObject]}
          Main={
            <div style={{}}>
              <Checklists
                maxCharsInItem={sideCardObject ? maxChars : null}
                minMode={false}
                locationsData={locationsData}
                onClick={this.onRowClick}
                setChecklistsMappedByStage={setChecklistsMappedByStage}
              />
            </div>
          }
        />
      </ChecklistPageContext.Provider>
    );
  }
}

ChecklistPage = withStyles(buttonStyle)(ChecklistPage);
const enhance = compose(
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  withLoading((props) => {
    const { selectedProjectId } = props;
  
    const subjectPaths = [
      ['projects', selectedProjectId, SUBJECTS.STAGES],
      ['projects', selectedProjectId, SUBJECTS.CHECKLISTS],
      ['projects', selectedProjectId, SUBJECTS.CHECKLIST_ITEMS],
      ['projects', selectedProjectId, `${SUBJECTS.CHECKLIST_ITEM_INSTANCES}/itemInstances`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
      ['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
    ]
  
    return subjectPaths
  }),
  connect(
    (state) => ({
      uiParams: state.ui.uiParams,
    }),
    { track }
  )
);
export default enhance(ChecklistPage);
