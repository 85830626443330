import React, { useMemo } from 'react';
import useIntl from '../../../common/intl/useIntl';
import useText from "../../../common/hooks/useText";
import _ from 'lodash';
import useUniqueId from '../../../common/hooks/useUniqueId';

const Text = (props) => {
  const {
    children, values,
    maxLines, 
    title, noSelect, onClick,
    maxChars, linksMode, withTooltip = true, tooltipText,
    onMouseOver, onMouseLeave, style,
    shouldSplitLines = true,
    prefix, suffix, uppercase,
    ...rest
  } = props;

  const intl = useIntl();
  const id = useUniqueId('text_');

  const { finalProcessedStringArray } = useText({
    maxChars,
    textString: "",
    children,
    values,
    intl,
    shouldSplitLines,
    linksMode,
    maxLines,
    style,
    prefix,
    suffix,
    uppercase
  });

  const content = useMemo(() => (
    finalProcessedStringArray.map((curr, index) => {
      const currStyle = _.assign({}, curr.style, noSelect && styles.noSelect);
      return _.isNil(curr)
        ? null
        : (
          <div
            key={id + index}
            onClick={onClick}
            title={getTextTitle({ curr, title, tooltipText, withTooltip })}
            style={currStyle}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            {...rest}
          >
            {curr.text}
          </div>
        );
    })),
    [finalProcessedStringArray, onClick, noSelect, onMouseOver, onMouseLeave]);

  return content;
};

const styles = {
  noSelect: {
    '-webkit-touch-callout': 'none' /* iOS Safari */,
    '-webkit-user-select': 'none' /* Safari */,
    '-khtml-user-select': 'none' /* Konqueror HTML */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    'user-select': 'none',
  },
};

function getTextTitle({ title, tooltipText, withTooltip, curr }) {
  if (withTooltip)
    return tooltipText || _.isString(title) ? title : curr?.text;
}

export default React.memo(Text);