// Assets
import { FORM_TYPES, SUBJECTS } from './subjects';
import {
  DATA_MANAGER_STATES,
  DATA_MANAGER_EVENTS,
  SUBJECTS_TO_STORAGE_PATHS,
  STORAGE_LOADED,
  IS_DATA_READY,
  SUBJECTS_WITH_PARAMS,
  OBJECT_DEFINITION_SUBJECTS,
  REALM_MAPPER_TO_SUBJECT,
} from './DataManagerConstants';
import { PROJECT_EVENTS } from '../projects/trackProjects';
import { SUBJECT_NAMES } from '../propertiesTypes/propertiesTypes';
import * as ioEvents from '../lib/io/eventTypes';
import { APP_STORAGE_LOAD, legacyLoadMobileStorage } from '../app/actions';

import { getAppState, getDispatch, lokiInstance } from '../configureMiddleware';
import { platformActions } from '../platformActions';

// Actions and funcs
import { getConfigurations } from '../configurations/funcs';
import { startPermissionsListener } from '../permissions/funcs';
import { getTitles } from '../titles/funcs';
import { getTrades } from '../trades/funcs';
import { getQuasiStatics } from '../quasiStatics/funcs';
import { getProjectDetails, getUserProjects } from '../projects/funcs';
import { getMenus } from '../../web/menus/funcs';
import { shouldLastUpdateV2Fetch } from '../lastUpdatesV2/funcs';
import { getStages } from '../stages/funcs';
import { getChecklists, getChecklistsSubscriptions } from '../checklists/funcs';
import { getChecklistItems } from '../checklistItems/funcs';
import { getBuildings } from '../buildings/funcs';
import { getFloors } from '../floors/funcs';
import { getUnits } from '../units/funcs';
import { getUsersByProject } from '../members/funcs';
import { getPropertiesTypes } from '../propertiesTypes/funcs';
import { getPropertiesMappings } from '../propertiesMappings/funcs';
import { getGlobalStatePathes } from '../configureStorage/statePathes';
import { startCompaniesListener } from '../companies/funcs';
import { startEmployeesListener } from '../employees/funcs';
import { startEquipmentListener } from '../equipment/funcs';
import { startFormsListener } from '../forms/funcs';
import { startPostsListener } from '../posts/funcs';
import { getChecklistItemsInstances } from '../checklistItemsInstances/funcs';
import { getDrawings } from '../drawings/funcs';
import { startPropertiesInstancesListener } from '../propertiesInstances/funcs';
import { batchDispatch } from '../app/funcs';
import { startCommentsListener } from '../comments/funcs';

class DataManager {
  constructor() {
    this.subjectsToStoragePaths = SUBJECTS_TO_STORAGE_PATHS;
    this.subjectsWithParams = SUBJECTS_WITH_PARAMS;
    this.objectDefinitionSubjects = OBJECT_DEFINITION_SUBJECTS;
    this.realmSchemaToSubject = REALM_MAPPER_TO_SUBJECT;
    this.immediateDispatchSubjects = [SUBJECTS.PROJECT, SUBJECTS.CONFIGURATIONS, SUBJECTS.VIEWER, SUBJECTS.POSTS, SUBJECTS.PROPERTIES_INSTANCES];
    this.lokiDidLoad = {};
  }

  loadGlobalAppData = async () => {
    const platform = platformActions.app.getPlatform();
    if (platform === 'web') {
      await lokiInstance.loadProjectDataFromStorage('global');
    }
    let paths = getGlobalStatePathes();

    const subjects = paths.reduce((_subjects, path) => {
      if (_subjects.includes(path[0])) return _subjects;

      if (path.includes(SUBJECTS.VIEWER)) _subjects.push(SUBJECTS.VIEWER);
      else _subjects.push(path[0]);
      return _subjects;
    }, []);

    await this.#loadStorageByPaths({
      paths,
      scope: 'global',
      subjects,
    });
  };

  loadProjectData = async (projectId) => {
    await this.#loadLocalDB(projectId);

    const scopeParams = {
      scope: 'projects',
      scopeId: projectId,
    };

    let subjects = [SUBJECTS.TRADES, SUBJECTS.SAFETY, SUBJECTS.REPORTS];
    let paths = [];
    subjects.forEach((subject) => {
      paths.push(...this.subjectsToStoragePaths[subject]);
    });

    await this.#loadStorageByPaths({ paths, ...scopeParams, subjects });
  };

  #loadLocalDB = async (projectId) => {
    const platform = platformActions.app.getPlatform();
    const scopeParams = {
      scope: 'projects',
      scopeId: projectId,
    };

    if (platform === 'web') {
      await lokiInstance.loadProjectDataFromStorage(projectId, () => {
        this.setProjectLokiLoaded(projectId, true);
      });
    } else {
      const realm = platformActions.localDB.getCementoDB();
      const data = realm.getSchemaNamesWithDataStatus(projectId);
      data.forEach(({ name, hasData }) => {
        const subject = this.realmSchemaToSubject[name];
        if (!subject) return;
        
        let subjectsToUpdate = [];
        
        if (subject === SUBJECTS.PROPERTIES_INSTANCES) {
          Object.values(SUBJECT_NAMES).forEach((subjectName) => {
            subjectsToUpdate.push({ subject, subjectParams: { subjectName } });
          });
        } else {
          subjectsToUpdate = [{ subject }];
        }
        
        subjectsToUpdate.forEach((subjectToUpdate) => {
          const { subject, subjectParams } = subjectToUpdate;
          
          const subjectStorageState = this.#getStorageState({ ...scopeParams, subject, subjectParams });
          if (subjectStorageState !== STORAGE_LOADED) {
            this.updateStatus({
              ...scopeParams,
              subject,
              subjectParams,
              stateType: DATA_MANAGER_STATES.STORAGE_STATUS,
              status: STORAGE_LOADED,
              didFindData: hasData,
            });
          }
        });
      });
    }
  };

  #loadStorageByPaths = async ({ paths = [], subjects, subjectParams, scope, scopeId }) => {
    const platform = platformActions.app.getPlatform();
    let dataToSave = [];

    for (const [feature, ...featurePath] of paths) {
      const lastKey = scope === 'global' ? 'global' : scopeId;
      const configKey = '@' + feature + '_' + featurePath + ':' + lastKey;
      let value = null;

      if (platform === 'web') {
        value = await platformActions.storage.getItem(configKey);
      } else {
        const realm = platformActions.localDB.getCementoDB();
        const query = `id = "${configKey}"`;
        const items = realm.get('reducerPersist', query);
        const item = items?.[0];
        if (item?.json) {
          value = item.json;
        } else {
          // This is a work around to make migration to Realm smooth for our users
          // Code below falls over to the previous approach of storage management
          // https://cemento.atlassian.net/browse/CEM-11732
          // TODO: Remove it in future releases
          value = await legacyLoadMobileStorage({ configKey, platform });
        }
      }

      if (value && value != 'null') dataToSave.push({ feature, featurePath, value });
    }

    await this.#dispatchByScope({ scope, scopeId, dataToSave });

    for (const subject of subjects) {
      const didFindData = dataToSave.length;

      let subjectsToUpdate = [];

      if (subject === SUBJECTS.FORMS) {
        subjectsToUpdate = Object.values(FORM_TYPES).map((formType) => ({
          subject: SUBJECTS.FORMS,
          subjectParams: { formType },
        }));
      } else {
        subjectsToUpdate = [{ subject, subjectParams }];
      }

      for (const subjectToUpdate of subjectsToUpdate) {
        const { subject, subjectParams } = subjectToUpdate;
        this.updateStatus({
          scope,
          scopeId,
          subject,
          subjectParams,
          stateType: DATA_MANAGER_STATES.STORAGE_STATUS,
          status: STORAGE_LOADED,
          didFindData,
        });
      }
    }
  };

  #dispatchByScope = async ({ scope, scopeId, dataToSave }) => {
    const dispatch = getDispatch();

    if (scope === 'global') {
      await dispatch({ type: APP_STORAGE_LOAD, payload: dataToSave });
    } else {
      await dispatch({
        type: PROJECT_EVENTS.LOAD_PROJECT_STORAGE,
        payload: { projectSavedJson: dataToSave, projectId: scopeId, didFind: dataToSave.length > 0 },
      });
    }
  };

  #generateQueryParams = (subject, initialQueryParams) => {
    let queryParams = initialQueryParams;

    if (
      subject === SUBJECTS.PROPERTIES_INSTANCES &&
      initialQueryParams?.subjectName === SUBJECT_NAMES[SUBJECTS.FORMS]
    ) {
      queryParams.parentStatus = [200, 250, 300];
    }

    return queryParams;
  };

  loadAndConnect = async (subscriptionParams) => {
    const {
      subject,
      scope,
      scopeId,
      viewer,
      queryParams: initialQueryParams,
      forceMSClientConfig,
    } = subscriptionParams;
    const queryParams = this.#generateQueryParams(subject, initialQueryParams);

    if (!shouldLastUpdateV2Fetch(scope, scopeId, subject, queryParams)) return;

    const subjectStorageState = this.#getStorageState({ scope, scopeId, subject, subjectParams: queryParams });

    if (subjectStorageState !== STORAGE_LOADED) {
      await this.#loadStorageByPaths({
        scope,
        scopeId,
        subjects: [subject],
        paths: this.subjectsToStoragePaths[subject],
        subjectParams: queryParams,
      });
    }

    let connectionFunc = this.#getConnectionFunc({
      ...queryParams,
      subject,
      scope,
      scopeId,
      viewer,
      forceMSClientConfig,
    });

    let res = await connectionFunc();

    return res;
  };

  #getStorageState = ({ scope, scopeId, subject, subjectParams }) => {
    let stateSubject = subject;
    if (subjectParams?.subjectName) stateSubject += `/${subjectParams.subjectName}`;
    if (subjectParams?.formType) stateSubject += `/${subjectParams.formType}`;

    let path = scopeId ? [scope, scopeId, stateSubject, 'storageStatus'] : [scope, stateSubject, 'storageStatus'];
    return getAppState().dataManager.getNested(path);
  };

  updateStatus = ({ scope, scopeId, subject, subjectParams, stateType, status, didFindData }) => {
    const basePayload = this.#getBasePayload({ subject, subjectParams, scope, scopeId });

    if (status) {
      batchDispatch([
        {
          type: DATA_MANAGER_EVENTS.SET_NEW_STATUS,
          payload: { ...basePayload, stateType, newStatus: status },
        },
      ]);
    }

    const shouldSetDataReady = this.#getShouldSetDataReady({ scope, status, didFindData });

    if (shouldSetDataReady) {
      this.setDataReady({ scope, scopeId, subject, subjectParams });
    }
  };

  #getShouldSetDataReady = ({ scope, status, didFindData }) => {
    const isReady =
      status === ioEvents.DATA_RECEIVED || (status === STORAGE_LOADED && didFindData) || scope === 'global';
    return isReady;
  };

  setDataReady = ({ scope, scopeId, subject, subjectParams }) => {
    const basePayload = this.#getBasePayload({ subject, subjectParams, scope, scopeId });

    const action = {
      type: DATA_MANAGER_EVENTS.SET_DATA_READY,
      payload: { ...basePayload, isDataReady: true },
    };

    if (this.immediateDispatchSubjects.includes(subject)) {
      const dispatch = getDispatch();
      dispatch(action);
    } else {
      batchDispatch([action]);
    }
  };

  isDataReady = ({ scope, scopeId, subject, subjectParams }) => {
    const basePayload = this.#getBasePayload({ scope, scopeId, subject, subjectParams });
    const path = [...basePayload.scopeParams, basePayload.subject, IS_DATA_READY];
    const isReady = getAppState().dataManager.getNested(path);
    return isReady;
  };

  isGlobalStorageLoaded = () => {
    const paths = getGlobalStatePathes();
    const subjectsToLoad = [...new Set(paths.map((path) => path[0]))];
    const allSubjectsLoaded = subjectsToLoad.every((subject) => {
      return DataManagerInstance.isDataReady({ scope: 'global', subject });
    });
    return allSubjectsLoaded;
  };

  isProjectStorageLoaded = (projectId) => {
    const subjects = this.objectDefinitionSubjects;
    const subjectsToLoad = subjects.reduce((_subjectsToLoad, subject) => {
      if (subject === SUBJECTS.PROPERTIES_TYPES || subject === SUBJECTS.PROPERTIES_MAPPINGS) {
        Object.values(SUBJECT_NAMES).forEach((subjectName) => {
          _subjectsToLoad.push({ subject, subjectParams: { subjectName } });
        });
      } else _subjectsToLoad.push({ subject });
      return _subjectsToLoad;
    }, []);

    const allSubjectsLoaded = subjectsToLoad.every((subjectToLoad) => {
      const storageState = this.#getStorageState({
        scope: 'projects',
        scopeId: projectId,
        subject: subjectToLoad.subject,
        subjectParams: subjectToLoad.subjectParams,
      });
      return storageState === STORAGE_LOADED;
    });
    return allSubjectsLoaded;
  };

  #getBasePayload = ({ subject, scope, scopeId, subjectParams }) => {
    let reducerSubject = subject;
    if (subject === SUBJECTS.PROJECTS && scopeId) reducerSubject = SUBJECTS.PROJECT;

    if (subjectParams && this.subjectsWithParams[subject]) {
      const paramName = this.subjectsWithParams[subject];
      const param = subjectParams[paramName];
      reducerSubject += `/${param}`;
    }

    return {
      subject: reducerSubject,
      scopeParams: [...new Set([scope, scopeId].filter(Boolean))],
    };
  };

  #getConnectionFunc = ({ subject, scope, scopeId, viewer, forceMSClientConfig, ...queryParams }) => {
    const { subjectName, formType } = queryParams;

    switch (subject) {
      case SUBJECTS.CONFIGURATIONS:
        return async () => {
          await getConfigurations(scope, scopeId, viewer, forceMSClientConfig);
        };
      case SUBJECTS.PERMISSIONS:
        return async () => {
          await startPermissionsListener(viewer);
        };
      case SUBJECTS.TITLES:
        return async () => {
          await getTitles(viewer);
        };
      case SUBJECTS.TRADES:
        return async () => {
          await getTrades(viewer);
        };
      case SUBJECTS.QUASI_STATICS:
        return async () => {
          await getQuasiStatics(viewer);
        };
      case SUBJECTS.PROJECTS:
        return async () => {
          await getUserProjects(viewer);
        };
      case SUBJECTS.PROJECT:
        return async () => {
          await getProjectDetails(viewer, scopeId);
        };
      case SUBJECTS.USERS:
        return async () => {
          await getUsersByProject(viewer, scopeId);
        };
      case SUBJECTS.MENUS:
        return async () => {
          return await getMenus(scopeId, viewer);
        };
      case SUBJECTS.STAGES:
        return async () => {
          return await getStages(viewer, scopeId);
        };
      case SUBJECTS.CHECKLISTS:
        return async () => {
          return await getChecklists(viewer, scopeId);
        };
      case SUBJECTS.CHECKLIST_ITEMS:
        return async () => {
          return await getChecklistItems(viewer, scopeId);
        };
      case SUBJECTS.CHECKLIST_SUBSCRIPTIONS:
        return async () => {
          return await getChecklistsSubscriptions(viewer, scopeId);
        };
      case SUBJECTS.BUILDINGS:
        return async () => {
          return await getBuildings(scopeId, viewer);
        };
      case SUBJECTS.FLOORS:
        return async () => {
          return await getFloors(scopeId, viewer);
        };
      case SUBJECTS.UNITS:
        return async () => {
          return await getUnits(scopeId, viewer);
        };
      case SUBJECTS.EMPLOYEES:
        return async () => {
          return await startEmployeesListener(viewer, scopeId);
        };
      case SUBJECTS.EQUIPMENT:
        return async () => {
          return await startEquipmentListener(viewer, scopeId);
        };
      case SUBJECTS.FORMS:
        return async () => {
          return startFormsListener(viewer, { projectId: scopeId, formType });
        };
      case SUBJECTS.PROPERTIES_TYPES:
        return async () => {
          return await getPropertiesTypes(viewer, scopeId, subjectName);
        };
      case SUBJECTS.PROPERTIES_MAPPINGS:
        return async () => {
          return await getPropertiesMappings(viewer, scopeId, subjectName);
        };
      case SUBJECTS.PROPERTIES_INSTANCES:
        return async () => {
          await startPropertiesInstancesListener(viewer, scopeId, subjectName, false, queryParams);
        };
      case SUBJECTS.CHECKLIST_ITEM_INSTANCES:
        return async () => {
          await getChecklistItemsInstances(viewer, scopeId);
        };
      case SUBJECTS.POSTS:
        return async () => {
          await startPostsListener(viewer, scopeId);
        };
      case SUBJECTS.COMMENTS:
        return async () => {
          return startCommentsListener(viewer, scopeId, queryParams.parentIds);
        };
      case SUBJECTS.COMPANIES:
        return async () => {
          await startCompaniesListener(scopeId, viewer);
        };
      case SUBJECTS.DRAWINGS:
        return async () => {
          return await getDrawings(viewer, scopeId);
        };
    }
  };

  lokiCollectionLoaded = ({ collection, scopeId }) => {
    const storageParams = {
      scope: scopeId === 'global' ? 'global' : 'projects',
      scopeId: scopeId === 'global' ? null : scopeId,
    };

    let subjectsData = this.#getSubjectsDataFromLokiCollection({ collection });

    for (const data of subjectsData) {
      const { subject, subjectParams } = data;
      const subjectStorageState = this.#getStorageState({
        scope: storageParams.scope,
        scopeId: storageParams.scopeId,
        subject,
        subjectParams,
      });
      if (subjectStorageState === STORAGE_LOADED) continue;

      this.updateStatus({
        ...storageParams,
        subject,
        subjectParams,
        stateType: DATA_MANAGER_STATES.STORAGE_STATUS,
        status: STORAGE_LOADED,
        didFindData: true,
      });
    }
  };

  #getSubjectsDataFromLokiCollection = ({ collection }) => {
    let data = [];

    if (collection === 'propertyInstances') {
      Object.values(SUBJECT_NAMES).forEach((subjectName) => {
        data.push({
          subject: SUBJECTS.PROPERTIES_INSTANCES,
          subjectParams: { subjectName },
        });
      });
    } else if (collection === 'checklistItemInstances') {
      data.push({ subject: `${SUBJECTS.CHECKLIST_ITEM_INSTANCES}/itemInstances` });
    } else if (collection === SUBJECTS.FORMS) {
      Object.values(FORM_TYPES).forEach((formType) => {
        data.push({
          subject: SUBJECTS.FORMS,
          subjectParams: { formType },
        });
      });
    } else {
      data.push({ subject: collection });
    }

    return data;
  };

  unsetProjectsData = ({ projectIds }) => {
    const dispatch = getDispatch();

    dispatch({
      type: DATA_MANAGER_EVENTS.UNSET_ALL_PROJECTS_DATA,
      payload: { projectIds },
    });
  };

  setProjectLokiLoaded(projectId, boolean) {
    this.lokiDidLoad[projectId] = boolean;
  }

  get projectLokiLoaded() {
    return this.lokiDidLoad;
  }
}

const DataManagerInstance = new DataManager();

export default DataManagerInstance;

globalThis.__DataManager = DataManagerInstance;
