import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../../components/CustomButtons/Button.jsx";
import { CloseRounded } from "@material-ui/icons";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import notificationsStyle from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

const Transition = (props) => {
  return <Grow in={props.open} {...props} />;
}

const Modal = (props) => {
  const {
    open, children, classes, onClose,
    hideCloseButton, style,
  } = props;
  const rtl = useSelector(state => state.app.rtl);

  return (
    <Dialog
      classes={{ root: classes.modalRoot, paper: classes.modalJustifyAuto }}
      style={{
        margin: "auto",
        marginTop: "none !important",
        ...style
      }}
      open={open}
      onBackdropClick={onClose ? onClose : undefined}
      keepMounted
      TransitionComponent={Transition}
      onClose={onClose ? onClose : undefined}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
    >
      {children}
      {Boolean(onClose && !hideCloseButton) && (
        <Button
          data-testid="close-button"
          round
          justIcon
          className={classes.modalCloseButton}
          style={{
            position: "absolute",
            [rtl ? "left" : "right"]: 0,
            margin: 5,
          }}
          onClick={onClose ? onClose : undefined}
          key="close"
          aria-label="Close"
          color="transparent"
        >
          <CloseRounded />
        </Button>
      )}
    </Dialog>
  );
};

export default withStyles(notificationsStyle)(Modal);
