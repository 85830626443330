import theme from '../../../../../../web/assets/css/theme';
import toggleRight from '../../../../../../web/assets/img/icons/toggleRight.png';

const ExpandableTableCell = ({ cell, getValue }) => {
  const { row, column } = cell;

  // const isGroupBy = row.depth === row.original.index && column.id === row.original.groupName;
  const isGroupBy = column.columnDef.groupByIndex === 0 && row.original.subRows;

  return (
    <div
      onClick={row.getToggleExpandedHandler()}
      className={`ExpandableTableCell ${
        isGroupBy ? (row.original.index === 0 ? 'primaryGroupByBackground' : 'secondaryGroupByBackground') : ''
      }`}
      style={{
        backgroundColor: isGroupBy && row.getIsExpanded() ? theme.brandPrimary : 'transparent',
        // borderRight: !row.getCanExpand() || row.getIsExpanded() ? `5px solid ${theme.brandPrimary}` : '5px solid transparent',
        cursor: 'pointer',
        paddingRight: `${row.original.index}rem`,
      }}>
      <div>
        {/* <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />{' '} */}
        {isGroupBy ? (
          <div style={{ cursor: 'pointer' }}>
            <img
              className='ExpandableTableCell_arrowIcon'
              src={toggleRight}
              style={{
                transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(180deg)',
                right: (row.original.index + 1) * 15,
                // [rtl ? 'left' : 'right']: theme.margin,
              }}
            />
          </div>
        ) : null}
        {getValue()}
        {isGroupBy && row.original?.totalItems >= 0 ? (
          <span
            className='ExpandableTableCell_count'
            style={{
              color: isGroupBy && row.getIsExpanded() ? theme.brandPrimary : 'white',
              backgroundColor: isGroupBy && row.getIsExpanded() ? 'white' : theme.brandPrimary,
            }}>
            {row.original?.totalItems}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default ExpandableTableCell;
