import { useEffect, useState } from 'react';
import { getFirebaseDeps } from '../../configureMiddleware';

const RemoteConfig = ({ children }) => {
  const [remoteConfig, setRemoteConfig] = useState({
    getValue: () => ({
      asBoolean: () => false,
      asString: () => '',
    }),
  });

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      const remoteConfigInstance = getFirebaseDeps().firebaseRemoteConfig();
      await remoteConfigInstance.fetchAndActivate();
      setRemoteConfig(remoteConfigInstance);
    };

    fetchRemoteConfig();
  }, []);

  if (!remoteConfig) {
    return null; // Render loading state or fallback component
  }

  return children(remoteConfig);
};

export default RemoteConfig;
