import Permission from './permission.js';
import { Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions.js';
import { permissionEvents } from './permissionEvents.js';

const InitialState = Record({
  map: Map(),
}, 'permissions');

const initialState = new InitialState;

export default function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case permissionEvents.GET_PERMISSIONS: {
      const { permissions } = action.payload;    
      if (!permissions) {
        return state;
      }
      var newMap = new Map();
      (permissions || {}).loopEach((key, permission) => { if (permission) newMap = newMap.set(key, new Permission({permissionKey: key, actions: permission})); })
      return state.set('map', newMap);
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }
  
  return state;
}
