import _ from 'lodash';
import { realmTransactionRetry } from '../../native/RealmWrapper/funcs';
import { ALL_BUILDINGS_ID } from '../app/constants';
import * as cliStatus from '../checklistItemsInstances/clItemInstanceStatus';
import * as companiesConsts from '../companies/companiesTypes';
import { getProjectGCs } from '../companies/funcs';
import { getAppState, getDispatch, lokiInstance, realmInstance } from '../configureMiddleware';
import { uploadImage } from '../images/actions';
import * as IssueStates from '../issues/issueStates';
import ExtraError, { errorCodes } from '../lib/errors/extraError';
import { postsSchema } from '../lib/realm/schema';
import {
  convertDateToTS,
  debugParams,
  notifyLongFunctionDuration,
  removeEmpty,
  replaceMaxUpdateTSIfNeeded,
  removeNilAndEmpty,
  subscribeToLastUpdates,
} from '../lib/utils/utils';
import { uploadPostFiles } from './hooks/usePosts/utils.js';
import { getActionPermissions, getFilteredMap, safeToJS } from '../permissions/funcs';
import { platformActions } from '../platformActions';
import { instanceDataToString } from '../propertiesInstances/funcs';
import trackPosts, { POST_EVENTS } from './trackPosts.js';
import Post from './post.js';

import { updateRetryRealm } from '../../common/lib/realm/funcs.js';
import { shouldUseBase64 } from '../app/constants';
import { encodeBase64, storeImagePermanently } from '../app/funcs';
import { getNewId, updateUsingFirebaseProxy } from '../lib/api';
import serverSDK from '@cemento-sdk/server';
import { saveCommentsLocally } from '../comments/funcs.js';
import { tradesEvents } from '../trades/tradesEvents.js';

let instancesMap = {};
const POSTS_SUBJECT_NAME = 'postsInfo';

const upsertPost = async (viewer, projectId, inPost, isUploadRetry, callBack, isNewPost) => {
  let post;
  try {
    post = prepareRealmForFirebase(inPost);
    await savePosts(viewer, projectId, [post], realmInstance, lokiInstance, false, platformActions);

    if (post.images || post.attachments) {
      let imagesTriedUpload = {};
      if (post.images && Object.keys(post.images).length > 0) {
        await Promise.all(
          Object.values(post.images).map(async (currImage) => {
            if (currImage.uri && !currImage.uri.startsWith('http') && currImage.id) {
              let imageId = currImage.id;
              let imageRet;
              if (!post.images[imageId])
                // For a case where while we were offline we remove the file
                post.images[imageId] = currImage;
              try {
                if (currImage && currImage.uri) {
                  imagesTriedUpload[imageId] = true;
                  imageRet = await uploadImage(
                    { data: currImage.uri, extension: 'jpeg' },
                    projectId + '_' + post.id + '_' + currImage.id,
                    'posts',
                    null,
                    imageId
                  );
                }

                if (imageRet && imageRet.uri && imageRet.uri.startsWith('http')) {
                  post.images[imageId].uri = imageRet.uri;
                  delete post.images[imageId].uploading;
                  delete post.images[imageId].isLocal;
                } else if (
                  !currImage.uri ||
                  (currImage.uri.startsWith &&
                    currImage.uri.startsWith('file:/') &&
                    !(await platformActions.fs.exists(currImage.uri.replace('file:/', ''))))
                )
                  throw new ExtraError('Post file is missing', null, null, errorCodes.MISSING_FILE);
              } catch (err) {
                let errorMessage = 'error uploading image';
                if (_.get(err, ['errorCode']) == errorCodes.MISSING_FILE) {
                  errorMessage = 'error uploading image - post with missing file';
                  delete post.images[imageId];
                }
                platformActions.sentry.notify(errorMessage, {
                  isUploadRetry,
                  imageId,
                  projectId,
                  uri: currImage.uri,
                  post,
                  viewerId: _.get(viewer, ['id']),
                });
              }
            }
          })
        );
      }

      if (post.attachments) {
        const attachments = await uploadPostFiles({
          files: post.attachments,
          projectId,
          postId: post.id,
          viewer,
          isUploadRetry,
        });
        post.attachments = attachments;
      }

      let allFiles = {
        ...post.images,
        ...post.attachments,
      };
      const stillUploading = Object.values(allFiles).some(
        (file) => file.uploading || (file.uri && !file.uri.startsWith('http'))
      );

      if (stillUploading) {
        const someFilesUploaded = Object.values(allFiles).some(
          (file) => imagesTriedUpload[file.id] && file.uri?.startsWith?.('http')
        );
        if (someFilesUploaded) {
          didReleaseIsUploading = true;
          if (platformActions.app.isNative()) {
            notifyLongFunctionDuration(
              () =>
                saveToRealm(
                  [{ ...post, isLocal: true, isUploading: false }],
                  null,
                  0,
                  projectId,
                  realmInstance,
                  true,
                  null,
                  platformActions,
                  true,
                  '2'
                ),
              `saveToRealm ~ The function took too long`,
              `posts`
            );
          }
        }
        return { post, projectId, newIssue: true, isUploadRetry };
      } else
        _.values(post.images).forEach((image) => {
          if (post.images[image.id]) delete post.images[image.id]['data'];
        });
    }
    if (!post.updatedTS) post.updatedTS = Date.now();
    if (post.assignTo?.id) post.assignTo = removeEmpty({ id: post.assignTo.id });
    if (post.owner?.id) post.owner = removeEmpty({ id: post.owner.id });
    if (post.severity === 0) delete post['severity'];

    post = removeNilAndEmpty(new Post(post));
    post.projectId = projectId;

    await serverSDK.posts.upsertPost(post);
    callBack?.()
    return { post, projectId, newIssue: isNewPost, isUploadRetry };
  } catch (error) {
    throw error;
  } finally {
    savePosts(
      viewer,
      projectId,
      [{ ...post, isUploading: false, isLocal: true }],
      realmInstance,
      lokiInstance,
      false,
      platformActions
    );
  }
};

const handleImagesAndAttachments = async ({ post, isUploadRetry, projectId }) => {
  let imagesTriedUpload = {};
  if (post.images && Object.keys(post.images).length > 0) {
    await Promise.all(
      Object.values(post.images).map(async (currImage) => {
        if (currImage.uri && !currImage.uri.startsWith('http') && currImage.id) {
          let imageId = currImage.id;
          let imageRet;
          if (!post.images[imageId])
            // For a case where while we were offline we remove the file
            post.images[imageId] = currImage;
          try {
            if (currImage && currImage.uri) {
              imagesTriedUpload[imageId] = true;
              imageRet = await uploadImage(
                { data: currImage.uri, extension: 'jpeg' },
                projectId + '_' + post.id + '_' + currImage.id,
                'posts',
                null,
                imageId
              );
            }

            if (imageRet && imageRet.uri && imageRet.uri.startsWith('http')) {
              post.images[imageId].uri = imageRet.uri;
              delete post.images[imageId]['uploading'];
              delete post.images[imageId]['isLocal'];
            } else if (
              !currImage.uri ||
              (currImage.uri.startsWith &&
                currImage.uri.startsWith('file:/') &&
                !(await platformActions.fs.exists(currImage.uri.replace('file:/', ''))))
            )
              throw new ExtraError('Post file is missing', null, null, errorCodes.MISSING_FILE);
          } catch (err) {
            let errorMessage = 'error uploading image';
            if (_.get(err, ['errorCode']) == errorCodes.MISSING_FILE) {
              errorMessage = 'error uploading image - post with missing file';
              delete post.images[imageId];
            }
            platformActions.sentry.notify(errorMessage, {
              isUploadRetry,
              imageId,
              projectId,
              uri: currImage.uri,
              post,
              viewerId: _.get(viewer, ['id']),
            });
          }
        }
      })
    );

    // If some images are still on uploading mode - dont' upload
    const stillUploading = Object.values(post.images).some(
      (image) => image.uploading || (image.uri && !image.uri.startsWith('http'))
    );

    if (stillUploading) {
      const someFilesUploaded = Object.values(post.images).some(
        (image) => imagesTriedUpload[image.id] && image.uri?.startsWith?.('http')
      );
      if (someFilesUploaded) {
        didReleaseIsUploading = true;
        if (platformActions.app.getPlatform() != 'web' && !isUploadRetry) {
          notifyLongFunctionDuration(
            () =>
              saveToRealm(
                [{ ...post, isLocal: true, isUploading: false }],
                null,
                0,
                projectId,
                realmInstance,
                true,
                null,
                platformActions,
                true,
                '2'
              ),
            `saveToRealm ~ The function took too long`,
            `posts`
          );
        }
      }
      return { post, projectId, newIssue: true, isUploadRetry };
    } else
      Object.values(post.images).forEach((image) => {
        if (post.images[image.id]) delete post.images[image.id]['data'];
      });
  }
  if (post.attachments && Object.keys(post.attachments.length > 0)) {
    Object.values(post.attachments).forEach((file) => {
      delete file.data;
      delete file.isLocal;
      delete file.uploading;
    });
  }

  return post;
};

// TODO: move getPosts back to action
export const startPostsListener = async (viewer, projectId, cleanAll, skipComments = false) => {
  if (cleanAll) {
    savePosts(viewer, projectId, [], realmInstance, lokiInstance, true, platformActions, undefined);
  }

  const saveFunc = async (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave) return; // TODO: is this still needed?
    const sanitizedPosts = _.map(_data, (post) => new Post(post));
    await savePosts(
      viewer,
      projectId,
      sanitizedPosts,
      realmInstance,
      lokiInstance,
      isRevoke,
      platformActions,
      undefined
    );
  };

  subscribeToLastUpdates(
    viewer,
    {
      scope: 'projects',
      scopeId: projectId,
    },
    {
      subject: 'posts',
      getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId),
      getData: (lastUpdateTS) => {
        trackPosts(POST_EVENTS.FETCH_POSTS, { projectId, lastUpdateTS });
        return serverSDK.posts.getPosts({ projectId, lastUpdateTS });
      },
      isObjectUseTransactionalDB: true,
    },
    saveFunc
  );

  trackPosts(POST_EVENTS.START_POSTS_LISTENER, { projectId });
};

export const createPost = (viewer, projectId, inPost, isUploadRetry, callBack) => {
  if (!inPost.owner) throw new Error('Cannot create post with no owner');
  const post = _.assign({}, inPost, {
    createdAt: inPost?.createdAt || Date.now(),
  });
  trackPosts(POST_EVENTS.CREATE_POST, { post, projectId, isUploadRetry });
  const dispatch = getDispatch();
  dispatch({ type: tradesEvents.SET_RECENT_TRADES, payload: { post, projectId, isUploadRetry } });
  return upsertPost(viewer, projectId, post, isUploadRetry, callBack, true);
};

export const updatePost = (viewer, projectId, inPost, isUploadRetry, callBack) => {
  const post = _.assign({}, inPost, {
    editedAt: Date.now(),
    updatedTS: null,
  });
  trackPosts(POST_EVENTS.UPDATE_POST, { post, projectId, isUploadRetry });
  return upsertPost(viewer, projectId, post, isUploadRetry, callBack, false);
};

export const retryPostsUpsert = async (localPosts, viewer) => {
  trackPosts(POST_EVENTS.RETRY_POSTS_UPSERT, { size: localPosts.length });
  let postsRemainedLocal = [];
  for (const post of localPosts) {
    if (post && post.isLocal) {
      try {
        const isNewPost = !post.editedAt;
        await upsertPost(viewer, post.projectId, post, true, null, isNewPost);
      } catch (err) {
        postsRemainedLocal.push(post);
        platformActions.sentry.notify('local post upload retries error', {
          err,
          viewer,
          projectId: post.projectId,
          post,
        });
      }
    }
  }

  return { postsRemainedLocal };
};

export const deletePost = async (post, projectId) => {
  trackPosts(POST_EVENTS.DELETE_POST, { post, projectId });
  const res = await serverSDK.posts.deletePost(post);

  const deletedPosts = [{ ...post.realmToObject(), isDeleted: true }];

  const viewer = getAppState().users.viewer;
  savePosts(viewer, projectId, deletedPosts, realmInstance, lokiInstance, false, platformActions);

  return res;
};

export const updateIssueState = (viewer, inPost, projectId, newState) => {
  if (!projectId || !inPost || !inPost.id || inPost.id == 'undefined') {
    throw new Error('updateIssueState - missing postId');
  }
  const post = _.assign({}, inPost, {
    issueState: newState,
    owner: inPost.owner,
  });

  trackPosts(POST_EVENTS.UPDATE_ISSUE_STATE, { post, projectId, newState });
  return updatePost(viewer, projectId, post, false, null);
};

export const updateIssueAssignTo = (viewer, inPost, projectId, assignTo) => {
  if (!projectId || !inPost || !inPost.id || inPost.id == 'undefined') {
    throw new Error('updateIssueAssignTo - missing postId');
  }
  let post = _.assign({}, inPost, {
    assignTo: assignTo,
    owner: inPost.owner,
    taggedCompanies: inPost.taggedCompanies,
  });

  let assigneeCompanyId = _.get(assignTo, ['companyId']);
  if (assigneeCompanyId && !_.get(inPost, ['taggedCompanies', assigneeCompanyId])) {
    _.set(post, ['taggedCompanies', assigneeCompanyId, 'id'], assigneeCompanyId);
  }

  trackPosts(POST_EVENTS.UPDATE_ISSUE_ASSIGN_TO, { post, projectId, assignTo });
  return updatePost(viewer, projectId, post, false, null);
};

export const getPostInstances = (projectId) => {
  let postsInstances = _.get(instancesMap, [projectId, POSTS_SUBJECT_NAME]);

  if (!postsInstances) {
    const realmQuery = `projectId = "${projectId}" AND subjectName = "${POSTS_SUBJECT_NAME}"`;
    postsInstances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(realmQuery);
    setPostsInstances(projectId, postsInstances);
  }

  return postsInstances;
};

const setPostsInstances = (projectId, instancesCursor) => {
  _.set(instancesMap, [projectId, POSTS_SUBJECT_NAME], instancesCursor);
};

export const getPostsPrefixProp = (postsPropertiesTypes, viewsConfigurations) => {
  const universalIdForTitlePrefix = _.get(viewsConfigurations, [
    'pages',
    'posts',
    'List',
    'titles',
    'primary',
    'prefix',
    'universalId',
  ]);
  let postsPrefixProp = null;

  if (universalIdForTitlePrefix)
    postsPrefixProp = _.head(
      _.filter(postsPropertiesTypes, (propertyType) => propertyType.universalId === universalIdForTitlePrefix)
    );

  return postsPrefixProp;
};

export const getFullPostTitle = (postTitle, postPrefixTitle, delimiter) => {
  const fullPostTitle = [postPrefixTitle, postTitle].filter(Boolean).join(delimiter);

  return fullPostTitle;
};

export const isPostInValid = (post, mandatoryData) => {
  if (!post) return true;

  let drawingExists = false;
  let imagesArray = post.images || [];
  let attachmentsArray = post.attachments || [];
  imagesArray = Array.isArray(imagesArray) ? imagesArray : Object.values(imagesArray);
  attachmentsArray = Array.isArray(attachmentsArray) ? attachmentsArray : Object.values(attachmentsArray);

  imagesArray.forEach((img) => (drawingExists = drawingExists || img.type == 'drawing'));
  let inValid = {
    img: mandatoryData && mandatoryData.img && imagesArray.length == 0,
    desc: mandatoryData && mandatoryData.desc && (!post.title || post.title.trim() == ''),
    file: mandatoryData && mandatoryData.file && attachmentsArray == 0,
    drawing: mandatoryData && mandatoryData.drawing && !drawingExists,
    assignee: post.isIssue && !post.assignTo,
    general:
      (!mandatoryData || (!mandatoryData.drawing && !mandatoryData.file)) &&
      (!post.title || post.title.trim() == '') &&
      imagesArray.length == 0 &&
      attachmentsArray.length == 0,
  };

  let inValidKey = null;
  Object.keys(inValid).forEach((k) => (inValidKey = inValid[k] ? k : inValidKey));

  return inValidKey;
};

export const setDefaultTagSelected = (inNewPost, members, viewer, projectId) => {
  let newPost = Object.assign({}, inNewPost);

  if (!newPost.taggedCompanies) newPost.taggedCompanies = {};

  // Add the company of the assignee
  if (newPost.assignTo) {
    let assignToCompanyId = members.getNested([newPost.assignTo.id, 'companyId']);
    if (assignToCompanyId) newPost.taggedCompanies[assignToCompanyId] = { id: assignToCompanyId };
    else {
      if (!newPost.taggedCompanies[companiesConsts.unknownCompanyId])
        newPost.taggedCompanies[companiesConsts.unknownCompanyId] = {
          id: companiesConsts.unknownCompanyId,
          explicitMembers: {},
        };
      newPost.taggedCompanies = newPost.taggedCompanies.setNested(
        [companiesConsts.unknownCompanyId, 'explicitMembers', newPost.assignTo.id],
        newPost.assignTo
      );
    }
  }

  // Add the company of the viewer
  let viewerCompanyId = members.getNested([viewer.id, 'companyId']);
  if (viewerCompanyId) newPost.taggedCompanies[viewerCompanyId] = { id: viewerCompanyId };
  else {
    if (!newPost.taggedCompanies[companiesConsts.unknownCompanyId])
      newPost.taggedCompanies[companiesConsts.unknownCompanyId] = {
        id: companiesConsts.unknownCompanyId,
        explicitMembers: {},
      };
    newPost.taggedCompanies = newPost.taggedCompanies.setNested(
      [companiesConsts.unknownCompanyId, 'explicitMembers', viewer.id],
      viewer
    );
  }

  // Add menagment companies
  let GCsIds = getProjectGCs(projectId);
  (GCsIds || []).loopEach((i, currGC) => {
    newPost.taggedCompanies[currGC.id] = { id: currGC.id };
  });

  //this.setState({ post: newPost, didChange: true });

  return newPost;
};

export const prepareRealmForFirebase = (inPost, projectId) => {
  let post = JSON.parse(JSON.stringify(inPost));
  arrayToKeyValue(post, 'comments');
  arrayToKeyValue(post, 'images');
  arrayToKeyValue(post, 'attachments');
  arrayToKeyValue(post, 'taggedCompanies');
  arrayToKeyValue(post, 'refs');
  preparePostDateFieldsBeforeUpload(post);

  if (post && post.trade && post.trade.id && post.trade.id.toString) post.trade = { id: post.trade.id.toString() };

  post.projectId = projectId;

  return post;
};

const arrayToKeyValue = (obj, path) => {
  let array = obj[path];

  if (!Object.keys(array || {}).length) {
    delete obj[path];
    return;
  }

  obj[path] = {};
  Object.values(array).forEach((x) => (obj[path][x.id] = x));
};

const postDateFields = _.reduce(
  postsSchema.schema.properties,
  (acc = {}, curr, key) => {
    if (curr === 'date' || curr === 'date?' || curr.type === 'date' || curr.type === 'date?') acc[key] = key;
    return acc;
  },
  {}
);

const preparePostDateFieldsBeforeUpload = (post) => {
  _.forIn(postDateFields, (fieldKey) => {
    const prevVal = post[fieldKey];
    const nextVal = convertDateToTS(prevVal);
    _.set(post, [fieldKey], nextVal);
  });
  return post;
};

export const getPostPrefixData = (intl, prefixProp, instance) => {
  const isNative = platformActions.app.isNative();

  let prefixString = '';

  if (instance) {
    let prefixData = _.get(instance, 'data');
    if (isNative && typeof prefixData === 'string') {
      try {
        prefixData = JSON.parse(prefixData); //because of realm we doing parse
      } catch (error) {}
    }

    if (prefixData) prefixString = instanceDataToString(prefixProp, prefixData, intl);
  }

  return prefixString;
};

const modes = {
  onlyPost: 1,
  onlyDocs: 2,
  all: 3,
};
export const getPostsByFilter = (posts, buildings, viewer, filters, retValue, queryMode, members) => {
  let retArray = [];
  let buildingIssues = {};
  buildingIssues[ALL_BUILDINGS_ID] = 0;

  if (buildings && posts) {
    let arr = [];
    retArray = posts.slice();
    if (queryMode == modes.onlyPosts) retArray = retArray.filter((value) => value.isIssue);
    else if (queryMode == modes.onlyDocs) {
      retArray = retArray.filter((value) => !value.isIssue || value.issueState == 100);
    }
    if (filters) {
      Object.values(filters).forEach((filter) => {
        if (filter.key == 'onMe')
          retArray = retArray.filter(
            (post) =>
              post.isIssue &&
              ((post.issueState == IssueStates.ISSUE_STATE_RESOLVED && post.owner && post.owner.id == viewer.id) ||
                (post.issueState == IssueStates.ISSUE_STATE_OPENED && post.assignTo && post.assignTo.id == viewer.id))
          );
        else if (filter.key == 'byMe')
          retArray = retArray.filter(
            (post) =>
              post.isIssue &&
              ((post.issueState == IssueStates.ISSUE_STATE_OPENED && post.owner && post.owner.id == viewer.id) ||
                (post.issueState == IssueStates.ISSUE_STATE_RESOLVED && post.assignTo && post.assignTo.id == viewer.id))
          );
        else if (filter.key != 'all' && filter.key) {
          let filterKey = filter.key.split(' ')[0];
          retArray = retArray.filter((post) => {
            let path = filterKey.split('/');
            let target = post;
            for (let i = 0; i < path.length && target; i++) {
              if (target[path[i]] != null) target = target[path[i]];
              else target = null;
            }

            if (target == null) return;

            if (filter.key.split(' ')[1]) {
              let outherFilterKey = filter.key.split(' ')[1];
              let outerSource = outherFilterKey.split('_')[0];
              let outerKey = outherFilterKey.split('_')[1];

              let newTarget = members.getNested([target, outerKey]);
              return newTarget == filter.value;
            } else return target == filter.value;
          });
        }
      });
    }

    if (retValue != 'posts') {
      for (let z = 0; z < retArray.length; z++) {
        let location = retArray[z].location;

        if (location) {
          if (location.unit && location.unit.id)
            buildingIssues[location.unit.id] = buildingIssues[location.unit.id]
              ? buildingIssues[location.unit.id] + 1
              : 1;
          if (location.floor && location.floor.id)
            buildingIssues[location.floor.id] = buildingIssues[location.floor.id]
              ? buildingIssues[location.floor.id] + 1
              : 1;
          if (location.building && location.building.id)
            buildingIssues[location.building.id] = buildingIssues[location.building.id]
              ? buildingIssues[location.building.id] + 1
              : 1;
        }
      }
      buildingIssues[ALL_BUILDINGS_ID] = retArray.length;
    }
  }
  if (retValue == 'posts') {
    return retArray;
  } else {
    return buildingIssues;
  }
};

export const getNewPostId = () => getNewId();

export const getAllFailedToUploadPosts = async (locationId) => {
  try {
    var viewer = getAppState().users.viewer;
    if (platformActions.app.getPlatform() == 'web') {
      if (viewer) {
        localPosts = lokiInstance.getCollection('posts').cementoFind({ 'isLocal': true, 'owner.id': viewer.id });
        return { localPosts: localPosts, counter: localPosts.length };
      }
    } else {
      var localPosts = [];
      let realmRetry = realmInstance.retry_posts;
      if (viewer) {
        await realmTransactionRetry(realmRetry);
        try {
          let uploadedLocalPosts = realmRetry.objects('post24').filtered('isLocal = false');
          realmRetry.delete(uploadedLocalPosts);
          realmRetry.commitTransaction();
        } catch (e) {
          realmRetry.cancelTransaction();
          throw e;
        }

        let query = `isLocal = true AND isUploading != true`;
        if (locationId)
          query += ` AND ( location.building.id = "${locationId}" OR location.floor.id = "${locationId}" OR location.unit.id = "${locationId}" )`;

        localPosts = realmRetry.objects('post24').filtered(query);
        return { localPosts, counter: localPosts.length };
      }
    }
  } catch (error) {
    platformActions.sentry.notify('getAllFailedToUploadPosts error', { error });
    throw error;
  }
};

export const createLocalPost = async (viewer, projectId, inPost) => {
  var post = { ...(inPost || {}).realmToObject() };
  var currTime = new Date().getTime();
  if (!post.createdAt) {
    post.createdAt = currTime;
    post.owner = { id: viewer.id };
  }

  if (Object.keys(post.images || {}).length)
    await Promise.all(
      Object.values(post.images).map(async (postImage) => {
        if (
          !postImage.uri ||
          !postImage.uri.startsWith ||
          postImage.uri.startsWith('http') ||
          postImage.uri.indexOf(';base64,') !== -1
        )
          return;

        if (shouldUseBase64()) {
          const base64Str = await encodeBase64(postImage.uri, 'image/jpeg');
          if (base64Str) postImage.uri = base64Str;
        } else if (platformActions.app.getPlatform() !== 'web') {
          const filePath = postImage.uri;
          postImage.uri = await storeImagePermanently(filePath, null, 'jpeg');
        }
      })
    );

  post.updatedTS = currTime;

  // Reduce the tagged users info values
  if (post.taggedCompanies) {
    var taggedCompanies = {};
    Object.values(post.taggedCompanies).forEach((comp) => {
      taggedCompanies[comp.id] = { id: comp.id };
      if (comp.explicitMembersSelection) {
        taggedCompanies[comp.id].explicitMembers = {};
        (comp.explicitMembers || {}).loopEach((i, member) => {
          taggedCompanies[comp.id].explicitMembers[member.id] = { id: member.id };
        });
      }
    });

    post.taggedCompanies = taggedCompanies;
  }

  return { post, projectId };
};

export const areAllOtherConnectedPosts_withWantedStatus = (instanceId, currPostId, projectId, wantedState, viewer) => {
  let relevantPosts = [];
  let allOthersWithWantedState = true;
  let myDefaultActionStatus = cliStatus.CLI_STATUS_RESOLVED;
  let connectedItemId = null;

  if (platformActions.app.getPlatform() == 'web') {
    relevantPosts =
      lokiInstance
        .getCollection('posts')
        .cementoFind({ 'projectId': projectId, 'checklistItemInstance.id': instanceId }) || [];

    let instances =
        lokiInstance
          .getCollection('checklistItemInstances')
          .cementoFind({ 'projectId': projectId, 'id': instanceId }) || []

    connectedItemId = instances.length ? instances[0].checklistItemId : null;
  } else {
    let instance = realmInstance.checklistItemsInstances
      .objects('checklistItemsInstance1')
      .filtered('projectId = "' + projectId + '" AND id = "' + instanceId + '"');
    relevantPosts = realmInstance.posts
      .objects('post24')
      .filtered('projectId = "' + projectId + '" AND checklistItemInstance.id = "' + instanceId + '"');
    relevantPosts = relevantPosts ? Array.from(relevantPosts) : [];
    connectedItemId = instance && instance[0] ? instance[0].checklistItemId : null;
  }

  if (connectedItemId) {
    let connectedItem = getAppState().getNested(['checklistItems', 'map', projectId, connectedItemId]);
    if (connectedItem) {
      if (getActionPermissions(viewer, projectId, 'checklistItems', 'confirm2', connectedItem))
        myDefaultActionStatus = cliStatus.CLI_STATUS_CONFIRMED_2;
      else if (getActionPermissions(viewer, projectId, 'checklistItems', 'confirm', connectedItem))
        myDefaultActionStatus = cliStatus.CLI_STATUS_CONFIRMED;
      else if (getActionPermissions(viewer, projectId, 'checklistItems', 'resolve', connectedItem))
        myDefaultActionStatus = cliStatus.CLI_STATUS_RESOLVED;
    }
  }

  relevantPosts.forEach((p) => {
    if (p.issueState && p.issueState != wantedState && p.id != currPostId) allOthersWithWantedState = false;
  });

  return { allOthersWithWantedState, myDefaultActionStatus };
};

export const savePosts = async (
  viewer,
  projectId,
  rawPosts,
  realmInstance,
  lokiInstance,
  cleanAll,
  platformActions,
  forceLocalSave
) => {
  let deletedPosts = {};
  let posts = {};

  let commentsToSave = [];

  rawPosts.forEach((curr) => {
    if (!curr || !curr.id) {
      return;
    }
    curr.isDeleted ? (deletedPosts[curr.id] = curr) : (posts[curr.id] = curr);
    if (curr.comments && !curr.isDeleted) {
      Object.values(curr.comments).forEach(comment => {
        commentsToSave.push({ ...comment, parentId: curr.id })
      })
    }
  });

  if (commentsToSave.length) saveCommentsLocally(commentsToSave, projectId);

  let filteredPosts = getFilteredMap(viewer, projectId, 'posts', 'read', posts, 'post');
  filteredPosts.unpermitted.loopEach((id, curr) => (deletedPosts[curr?.id] = curr));
  if (platformActions.app.getPlatform() == 'web') {
    saveToLoki(filteredPosts.permitted, deletedPosts, projectId, lokiInstance, null, null, cleanAll);
  } else {
    await notifyLongFunctionDuration(
      () => {
        saveToRealm(
          Object.values(filteredPosts.permitted),
          Object.values(deletedPosts),
          undefined,
          projectId,
          realmInstance,
          null,
          cleanAll,
          platformActions,
          forceLocalSave,
          '1'
        );
      },
      `saveToRealm ~ The function took too long`,
      `posts`
    );
  }
};

const preparePostForRealm = (post, projectId) => {
  let preparePost = {
    ...post.realmToObject(),
    location: null,
    comments: [],
    images: [],
    attachments: [],
    taggedCompanies: [],
    projectId,
    isLocal: Boolean(post.isLocal),
    refs: [],
  };

  if (preparePost.assignTo) preparePost.assignTo = { id: preparePost.assignTo.id };

  preparePost.editedAt = post.editedAt ? new Date(post.editedAt) : null;
  preparePost.createdAt = post.createdAt ? new Date(post.createdAt) : preparePost.editedAt;
  preparePost.updatedTS = post.updatedTS ? new Date(post.updatedTS) : null;
  preparePost.dueDate = post.dueDate ? new Date(post.dueDate) : null;
  preparePost.editedAtOrCreatedAt = preparePost.editedAt || preparePost.createdAt;

  // making sure the fine amount is a number
  if (preparePost.fine?.amount) {
    preparePost.fine = { ...preparePost.fine.realmToObject() };
    if (isNaN(preparePost.fine.amount)) {
      delete preparePost.fine.amount;
    } else {
      preparePost.fine.amount = Number(preparePost.fine.amount);
    }
  }

  // TODO: Make push or update on all of the arrays
  Object.values(post.images || {}).forEach((image) => {
    // making sure height and width of images are integers
    if ((image.width && !Number.isInteger(image.width)) || (image.height && !Number.isInteger(image.height))) {
      image = { ...image };
      if (image.width) image.width = Math.round(image.width);
      if (image.height) image.height = Math.round(image.height);
    }
    preparePost.images.push(image);
  });
  Object.values(post.attachments || {}).forEach((image) => {
    preparePost.attachments.push(image);
  });
  Object.values(post.taggedCompanies || {}).forEach((taggedCompany) => preparePost.taggedCompanies.push(taggedCompany));
  Object.values(post.refs || {}).forEach((postReference) => preparePost.refs.push(postReference));
  if (post.location)
    ['building', 'floor', 'unit'].forEach((loc) => _.set(preparePost, ['location', loc], post.location[loc] || null));

  if (!_.isNil(preparePost.location?.floor?.num) && typeof preparePost.location.floor.num !== 'number') {
    preparePost.location = { ...preparePost.location, floor: { ...preparePost.location.floor } };
    preparePost.location.floor.num = Number(preparePost.location.floor.num);
  }

  return preparePost;
};

const saveToRealm = async (
  posts,
  deletedPosts,
  lastUpdateTS,
  projectId,
  realmInstance,
  ignoreTimestamp,
  cleanAll,
  platformActions,
  forceLocalSave,
  source
) => {
  if (!cleanAll && !posts?.length && !deletedPosts?.length) return;

  posts = (posts || []).sort((postA, postB) => ((postA.updatedTS || 0) > (postB.updatedTS || 0) ? -1 : 1));
  let posts_currBatchMaxLastUpdateTS = _.get(posts, [0, 'updatedTS'], 0);

  deletedPosts = (deletedPosts || []).sort((postA, postB) => (postA.updatedTS > postB.updatedTS ? -1 : 1));
  let deletedPosts_currBatchMaxLastUpdateTS = _.get(deletedPosts, [0, 'updatedTS'], 0);

  let currBatchMaxLastUpdateTS = new Date(
    Math.max(posts_currBatchMaxLastUpdateTS, deletedPosts_currBatchMaxLastUpdateTS) || 0
  );

  let postRealm = realmInstance.posts;
  let preparedPostArray = posts.map((post) => preparePostForRealm(post, projectId));

  updateRetryRealm(preparedPostArray, projectId, realmInstance.retry_posts, 'post24');
  let ignoreList = {};
  if (posts.length == 1 && !forceLocalSave) {
    // TODO: Performance: Update the quary to check for all ids that are on posts on 1 quary, and remove the limit of check only on 1 post case
    const inPost = posts[0];
    let savedPosts = postRealm
      .objects('post24')
      .filtered('projectId = "' + projectId + '" AND id = "' + inPost.id + '"');
    if (savedPosts.length == 1) {
      let savedPost = savedPosts[0].realmToObject();
      if (
        Boolean(inPost.isLocal) == Boolean(savedPost.isLocal) &&
        savedPost.updatedTS &&
        savedPost.updatedTS.getTime &&
        inPost.updatedTS == savedPost.updatedTS.getTime()
      )
        ignoreList[inPost.id] = true;
    }
  }

  if (Object.keys(ignoreList || {}).length < posts.length || (deletedPosts && deletedPosts.length) || cleanAll) {
    let postsMissingIdsToNotify = [];
    await realmTransactionRetry(postRealm);

    try {
      if (cleanAll) {
        let allPosts = postRealm.objects('post24').filtered(`projectId = "${projectId}"`);
        postRealm.delete(allPosts);
      }

      let postsToSave = [];

      preparedPostArray.forEach((post) => {
        post.projectId = projectId;
        if (ignoreList[post.id]) return;
        if (post && post.id) {
          postsToSave.push(post);
        } else {
          postsMissingIdsToNotify.push(post);
        }
      });

      if (postsToSave.length) {
        const localDB = platformActions.localDB.getCementoDB();
        localDB.set('posts', postsToSave);
      }

      if (deletedPosts && deletedPosts.length) {
        let deletedPostsArrays = _.chunk(deletedPosts, 1000);
        deletedPostsArrays.forEach((currentDeletedPostArray) => {
          let query = currentDeletedPostArray.map((post) => `id == "${post.id}"`);
          let queryString = query.join(' OR ');
          let deletedRealms = postRealm.objects('post24').filtered(queryString);
          postRealm.delete(deletedRealms);
        });
      }
      replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, postRealm, 'post24', `projectId = "${projectId}"`);
      postRealm.commitTransaction();
      if (postsMissingIdsToNotify.length && platformActions) {
        console.warn('post missing ID');
        platformActions.sentry.notify('post missing ID', { postsWithMissingIds: postsMissingIdsToNotify, projectId });
      }
    } catch (e) {
      postRealm.cancelTransaction();
      throw e;
    }
  }
};

const saveToLoki = (posts = {}, deletedPosts, projectId, lokiInstance, isLocal, ignoreTimestamp, cleanAll) => {
  let postsArray = Object.values(posts || {});
  let deletedPostsArray = Object.values(deletedPosts || {});
  if (!cleanAll && !postsArray.length && !deletedPostsArray.length) return;

  postsArray = postsArray.sort((postA, postB) => (postA.updatedTS > postB.updatedTS ? -1 : 1));
  deletedPostsArray = deletedPostsArray.sort((postA, postB) => (postA.updatedTS > postB.updatedTS ? -1 : 1));

  let allPosts = [];
  postsArray.forEach((p) => {
    let preparePost = {
      images: [],
      attachments: [],
      taggedCompanies: [],
      isLocal: false,
      ...p,
      projectId,
    };
    preparePost.editedAtOrCreatedAt = preparePost.editedAt || preparePost.createdAt;
    preparePost.isLocal = isLocal;

    allPosts.push(preparePost);
  });

  if (cleanAll) {
    lokiInstance.getCollection('posts').cementoDelete({ 'projectId': projectId });
  } else if (!_.isEmpty(deletedPosts)) {
    let deletedIds = deletedPostsArray.map((p) => p.id);
    lokiInstance.getCollection('posts').cementoDelete({ id: { '$in': deletedIds } });
  }

  lokiInstance.getCollection('posts').cementoUpsert(allPosts);
};

export const getLastUpdateTS = (realmInstance, lokiInstance, scopeId) => {
  let lastUpdateTS = 0;

  if (platformActions.app.getPlatform() == 'web') {
    let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance
      .getCollection('posts')
      .chain()
      .find({ projectId: scopeId })
      .simplesort('updatedTS', true)
      .limit(1)
      .data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
  } else {
    lastUpdateTS = realmInstance.posts
      .objects('post24')
      .filtered(`projectId = "${scopeId}" AND isLocal != TRUE`)
      .max('updatedTS');
    lastUpdateTS = lastUpdateTS?.getTime?.() ?? 0;
  }

  return lastUpdateTS || 0;
};
