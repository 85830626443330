import { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import OriginalTooltip from '@material-ui/core/Tooltip';
import { safeFormatMessage } from '../../../common/app/funcs';
import theme from '../../assets/css/theme';

const Tooltip = ({
	title,
	intl,
	values,
	children,
	titleComponent,
	classes,
	titleGeneratorFunction,
	titleOnHover,
	enterDelay,
	enterTouchDelay,
	leaveDelay,
	leaveTouchDelay,
	...otherProps
}) => {
	const chosenTitle = useMemo(() => {
		if (titleComponent) return titleComponent;
		if (titleGeneratorFunction) return titleGeneratorFunction();
		if (titleOnHover) return titleOnHover;

		let titleString = '';

		if (title && typeof title === 'object' && title.defaultMessage) {
			let innerValues = undefined;
			if (values) {
				innerValues = {};
				values.loopEach((key, value) => (innerValues[key] = safeFormatMessage(intl, value)));
			}
			titleString = intl.formatMessage(title, innerValues);
		} else if (typeof title == 'string') {
			titleString = title;
		}

		return titleString;
	}, [titleComponent, titleGeneratorFunction, titleOnHover]);

	return (
		<OriginalTooltip {...otherProps} enterTouchDelay ={enterTouchDelay} enterDelay={enterDelay} leaveDelay={leaveDelay} leaveTouchDelay={leaveTouchDelay} title={chosenTitle} classes={{ tooltip: classes?.tooltip }} PopperProps={{ style: { zIndex:theme.zIndexes.toolTip }}}>
			{children}
		</OriginalTooltip>
	);
};

export default injectIntl(Tooltip);
