import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { platformActions } from '../../platformActions';
import useMemoizedValue from '../../hooks/useMemoizedValue';
import usePrevious from '../../hooks/usePrevious';
import { ProjectContext } from '../../projects/contexts';
import { realmQueryStringBuilder } from '../../app/funcs';
import DataManagerInstance from '../../dataManager/DataManager';
import { SUBJECTS } from '../../dataManager/subjects';

/**
 * @param {Filters} commentsFilters
 * @returns {Object} filteredComments
 */
const useComments = (_commentsFilters = {}, _options) => {
    const [commentsArray, setCommentsArray] = useState([]);
    const [commentsMap, setCommentsMap] = useState({});
    const { viewer, selectedProjectId } = useContext(ProjectContext);
    const isNative = useMemo(platformActions.app.isNative, []);
    const localDB = useMemo(platformActions.localDB.getCementoDB, []);

    const commentsFilters = useMemoizedValue(_commentsFilters);
    const options = useMemoizedValue(_options);

    const parentIds = commentsFilters.parentIds || [];
    const prevParentIds = usePrevious(parentIds);

    const fetchComments = useCallback(
      async (parentIds) => {
        const endCommentsListener = await DataManagerInstance.loadAndConnect({ viewer, scope: 'projects', scopeId: selectedProjectId, subject: SUBJECTS.COMMENTS, queryParams: parentIds });
        return endCommentsListener;
      },
      [viewer, selectedProjectId]
    );


    useEffect(() => {
      let endCommentsListener = () => {};
      const fetchData = async () => {
        const newParentIds = _.difference(parentIds, prevParentIds);
          if (newParentIds.length && !options?.disabled && !options?.skipFetchComments) {
            endCommentsListener = await fetchComments(newParentIds);
          }
      }
      
      fetchData();
      return () => {
        endCommentsListener();
      };

    }, [parentIds]);


    const localDBQuery = useMemo(() => getLocalDBQuery(commentsFilters, isNative), [commentsFilters]);

    const updateFilteredCommentsState = useCallback(() => {
        const limit = options?.limit;
        const shouldLimit = limit > 0;

        const localDBGetOptions = isNative
            ? { sort: 'createdAt' }
            : {};

        let comments = localDB.get('comments', localDBQuery, localDBGetOptions) || [];
        if (isNative && shouldLimit) {
            comments = comments?.length > limit
                ? comments?.slice(comments?.length - limit)
                : comments;
        }

        if (!isNative) {
            comments = _.sortBy(comments, 'createdAt');
        }
        
        setCommentsMap(_.keyBy(comments, 'id'));
        setCommentsArray(_.values(comments));

    }, [localDBQuery, setCommentsArray, setCommentsMap]);

    useEffect(() => {
        let cleanFunc;
        if (commentsArray.length && !parentIds.length) {
            setCommentsArray([]);
            setCommentsMap({});
        }
        else if (parentIds.length && !options?.disabled) {
            const listenerOptions = (isNative)
                ? { objectName: 'comment5', query: localDBQuery }
                : {};
            cleanFunc = localDB.startListener('comments', updateFilteredCommentsState, listenerOptions);
            updateFilteredCommentsState();
        }
        return cleanFunc;
    }, [localDBQuery, updateFilteredCommentsState]);

    return { commentsMap, commentsArray };
};

export default useComments;


/**
 *
 * @param {{ filters: Filters, children: ChildrenRenderProps }} props
 * @returns {ChildrenRenderProps}
 */
export const CommentsHOC = props => {
    const commentsProps = useComments(props.filters);

    return props.children(commentsProps);
};

const getLocalDBQuery = (_filters, isNative) => {
    const filters = _.assign({ isDeleted: false }, _filters);
    const localDbQuery = ((isNative) ? getLocalDbQueryNative : getLocalDbQueryWeb)(filters);
    return localDbQuery;
};

const getLocalDbQueryNative = (filters) => {
    const { projectId, type, isDeleted } = filters;
    const parentIds = _.get(filters, 'parentIds', []);
    let filterArray = [];

    if (projectId) filterArray.push(`projectId == "${projectId}"`);
    if (type) filterArray.push(`type == "${type}"`);
    if (typeof isDeleted === 'boolean') filterArray.push(
        isDeleted
            ? `isDeleted == ${isDeleted}`
            : [`isDeleted == ${isDeleted}`, `isDeleted == null`]
    );
    if (parentIds?.length == 1) filterArray.push(`parentId == "${parentIds[0]}"`);
    else if (parentIds?.length) filterArray.push(`parentId == ANY {${parentIds.map(p => `"${p}"`)}}`);

    const commentsQueryString = realmQueryStringBuilder(filterArray);
    return commentsQueryString;
};

const getLocalDbQueryWeb = (filters) => {
    let commentsQuery = _.pick(filters, ['projectId', 'isDeleted', 'type']);
    const parentIds = _.get(filters, 'parentIds', []);
    if (parentIds.length == 1) {
        commentsQuery['parentId'] = parentIds[0];
    }
    else if (parentIds.length > 1) {
        commentsQuery['$or'] = _.map(parentIds, parentId => ({ parentId }));
    }
    return commentsQuery;
};
