import { getDispatch } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { configurationEvents } from './configurationEvents';

export async function getConfigurations(scope, scopeId, viewer, forceMSClientConfig = true) {
  const scopeParams = { scope, scopeId };
  const isGlobal = scope === 'global';
  const resourceParams = {
    forceMSClientConfig,
    resourceName: 'configurations',
    firebasePath: isGlobal ? 'configurations/global' : `configurations/${scope}/${scopeId}`,
  };

  const onData = (data) => {
    const dispatch = getDispatch();
    dispatch({
      type: configurationEvents.GET_CONFIGURATIONS,
      payload: { scopeType: scope, scopeId, configurations: data, immediateGlobalStorageSave: isGlobal },
    });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    onData(result);
  }

  return { scope, scopeId };
}
