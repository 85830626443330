import { startToast } from '../app/actions';
import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { batchDispatch } from '../app/funcs';
import { track } from '../lib/reporting/actions';

export const SET_FORM_EMAIL = 'SET_FORM_EMAIL';
export const SET_FORM_EMAIL_MODAL_PARAMS = 'SET_FORM_EMAIL_MODAL_PARAMS';
export const UNSET_FORM_EMAIL_MODAL_PARAMS = 'UNSET_FORM_EMAIL_MODAL_PARAMS';

export function setFormEmail({ projectId, formType, formId, selectedEmail }) {
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!projectId || !formType || !formId || !selectedEmail) {
        return;
      }

      const updates = {
        targetEmails: [selectedEmail],
        emailSentDate: null,
        readyToGenerateTS: Date.now(),
      };

      let success = true;
      try {
        await platformActions.net.fetch(apiServer + `/v1/forms/${formId}?projectId=${projectId}&formType=${formType}`, {
          method: 'PATCH',
          body: JSON.stringify(updates),
        });
      } catch (error) {
        success = false;
      } finally {
        const trackText = success ? 'Set Form Email' : 'Set Form Email Failed';
        const toastText = success ? reportsMessages.savedSetFormEmail : reportsMessages.exportErrors.setFromEmailFailed;

        batchDispatch([
          track(trackText, { projectId, formType, formId, selectedEmail }),
          startToast({ title: toastText }),
        ]);
      }
      return { scopeId: projectId, formType, formId, selectedEmail };
    };

    return {
      type: SET_FORM_EMAIL,
      payload: getPromise(),
    };
  };
}

export const setFormEmailModalParams = ({ isVisible, projectId, formType, formId }) => {
  return {
    type: SET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      scopeId: projectId,
      formType,
      formId,
      isVisible: _.isNil(isVisible) ? Boolean(projectId && formType && formId) : isVisible,
    },
  };
};

export const unsetFormEmailModalParams = () => {
  return {
    type: UNSET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      scopeId: null,
      formType: null,
      formId: null,
      isVisible: false,
    },
  };
};
