import * as actions from './actions';
import Trade from './trade';
import { Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const MIN_RECOMMENDED_TRADES = 5;

const InitialState = Record({
  map: Map(),
  projectTrades: Map(),
  recentlyTrades: Map(),
  recentlyUsersPerTrades: Map(),  
}, 'trades');

const initialState = new InitialState;

export default function tradesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TRADES: {
      if (!action.payload)
        return state;

      const { trades } = action.payload;    
      if (!trades) {
        return state;
      }

      var newMap = new Map();
      (trades || {}).loopEach((key, trade) => { 
        if (trade) {
          let tradesVals = { 
            id: String(trade.id), 
            types: trade.types, 
            title: trade.title, 
            systemTitle: trade.getNested(['title', 'en'],'')
          };
          if (trade.color) tradesVals.color = trade.color;
          newMap = newMap.set(trade.id, new Trade(tradesVals));
        }
      })
      return state.set('map', newMap);
    }

    case actions.SET_RECENT_TRADES:{
      if (!action.payload)
        return state;

      const { projectId, post } = action.payload;
      
      if (!post.trade || !post.trade.id || !projectId) 
        return state;

      state = state.setIn(['recentlyTrades', projectId, post.trade.id], {lastUse: Date.now()});

      // Add to recently trades
      if (state.getIn(['recentlyTrades', projectId]).size > MIN_RECOMMENDED_TRADES) {
       
        var newRecentlyTrades = state.recentlyTrades.sort(function(a, b){
          return (b.lastUse || 0)-(a.lastUse || 0);
        });

        var lastTrade = newRecentlyTrades.last();
        state = state.deleteIn(['recentlyTrades', lastTrade.id]);
      }

      // Add to recently assignTo users per trades by the current user
      if (post.assignTo && post.assignTo.id)
        state = state.setIn(['recentlyUsersPerTrades', projectId, post.trade.id, post.assignTo.id], {lastUse: Date.now()});

      return state;
    }

    case actions.LOAD_PROJECT_TRADES: {
      if (!action.payload)
        return state;

      const { projectId, trades } = action.payload;
      
      if (!trades || !projectId) 
        return state;

      // if trades have same value - don't update state
      if (!difference(Object.keys(trades), Object.keys(state.getIn(['projectTrades', projectId]) || [])).length)
        return state;

      state = state.setIn(['projectTrades', projectId], trades);

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }
  
  return state;
}

const difference = (arr1, arr2) => arr1.filter(x => !arr2.includes(x));