import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import { OrderedMap } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';
import Floor from './floor';
import { floorsEvents } from './floorsEvents';

const InitialState = Record(
  {
    map: OrderedMap(),
  },
  'floors'
);
const initialState = new InitialState();
export default function floorReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;
  switch (action.type) {

    case floorsEvents.GET_FLOORS: {
      if (!action.payload?.projectId) return state;
      const { projectId, floors } = action.payload;
      if (floors) {
        let floorsMap = new OrderedMap();
        let floorsGroupedByBuildings = _.groupBy(floors, 'buildingId');
        for (const buildingId of Object.keys(floorsGroupedByBuildings)) {
          let buildingFloorsMap = new OrderedMap();
          let buildingFloors = floorsGroupedByBuildings[buildingId];
          for (const floor of buildingFloors) {
            let floorToSet = new Floor({ ...floor });
            buildingFloorsMap = buildingFloorsMap.set(floor.id, floorToSet);
          }
          buildingFloorsMap = buildingFloorsMap.sortBy((floor) => floor.num * -1);
          floorsMap = floorsMap.set(buildingId, buildingFloorsMap);
        }
        state = state.setIn(['map', projectId], floorsMap);
      }
      return state;
    }
    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + '_SUCCESS': {
      return initialState;
    }
  }
  return state;
}
