import { platformActions } from '../platformActions';
import * as IssueStates from '../issues/issueStates';
import { track } from '../lib/reporting/actions';
import _ from 'lodash';
import { getDispatch } from '../configureMiddleware';

const trackPosts = (event, params = {}) => {
  const Mixpanel = platformActions.mixpanel;
  const { post, posts, projectId, size } = params;
  const dispatch = getDispatch();

  if (!post && _.isEmpty(posts)) return;

  let trackTitle = event;
  let trackProps = {};

  if (projectId) {
    trackProps['projectId'] = projectId;
  }

  if (post?.owner) {
    trackProps['ownerName'] = post.owner.displayName;
    trackProps['ownerId'] = post.owner.id;
  }

  switch (event) {
    case POST_EVENTS.EXPORT_POST_AS_PDF: {
      const { success, multi, posts, post } = params;

      if (multi) {
        _.assign(trackProps, { success, postCounter: _.values(posts).length });
        trackTitle = 'multiExportPDF';
        Mixpanel.increment('multiExportPDF', 1);
      } else {
        _.assign(trackProps, { success, postTitle: post?.title });
        trackTitle = 'exportPDF';
        Mixpanel.increment('exportPDF', 1);
      }
      break;
    }

    case POST_EVENTS.UPDATE_ISSUE_STATE: {
      const { title, id, issueState } = post;
      const newState = issueStateValToText(issueState);
      _.assign(trackProps, { postId: id, newState, title });

      trackTitle = 'updateIssueState';
      Mixpanel.increment('updateIssueState', 1);
      break;
    }

    case POST_EVENTS.UPDATE_ISSUE_ASSIGN_TO: {
      const { title, id, assignTo } = post;
      _.assign(trackProps, { postId: id, title, assignTo });
      trackTitle = 'reassignIssue';
      Mixpanel.increment('reassignIssue', 1);
      break;
    }

    case POST_EVENTS.CREATE_POST:
    case POST_EVENTS.UPDATE_POST: {
      const { newIssue, isUploadRetry } = params || {};
      const { title, id, images, assignTo, issueState, checklistItemInstance, location, createdAt } =
        post?.safeToJS() || {};
      let newState = issueStateValToText(issueState);
      _.assign(trackProps, {
        createdAt,
        postId: id,
        newState,
        isUploadRetry,
        checklistItemInstance,
        location: JSON.stringify(location || {}),
      });

      if (title) trackProps.title = title;

      if (images && Object.keys(images).length) {
        trackProps.imagesNumber = Object.keys(images).length;
        trackProps.imagesUri = JSON.stringify(Object.values(images).map((img) => img.uri));
      }

      if (!assignTo) {
        trackTitle = 'newPost';
        Mixpanel.increment('newPost', 1);
      } else if (assignTo) {
        if (newIssue) {
          trackTitle = 'newIssue';
          Mixpanel.increment('newIssue', 1);
        } else {
          trackTitle = 'updateIssue';
          Mixpanel.increment('updateIssue', 1);
        }
      }
      break;
    }
    case POST_EVENTS.RETRY_POSTS_UPSERT: {
      trackProps.size = size;
      break;
    }
  }

  dispatch(track(trackTitle, trackProps));
};

export default trackPosts;

export const POST_EVENTS = {
  GET_POSTS: 'GET_POSTS',
  FETCH_POSTS: 'FETCH_POSTS',
  CREATE_POST: 'CREATE_POST',
  UPDATE_POST: 'UPDATE_POST',
  DELETE_POST: 'DELETE_POST',
  UPDATE_ISSUE_ASSIGN_TO: 'UPDATE_ISSUE_ASSIGN_TO',
  UPDATE_ISSUE_STATE: 'UPDATE_ISSUE_STATE',
  EXPORT_POST_AS_PDF: 'EXPORT_POST_AS_PDF',
  RETRY_POSTS_UPSERT: 'RETRY_POSTS_UPSERT',
  START_POSTS_LISTENER: 'START_POSTS_LISTENER',
  END_POSTS_LISTENER: 'END_POSTS_LISTENER',
};

const issueStateValToText = (state) => {
  if (state == IssueStates.ISSUE_STATE_CLOSED) return 'close';
  else if (state == IssueStates.ISSUE_STATE_RESOLVED) return 'resolve';
  else if (state == IssueStates.ISSUE_STATE_OPENED) return 'open';
  else if (state == IssueStates.POST_STATUS) return 'record';
};
