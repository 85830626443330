import { track } from '../lib/reporting/actions';
import _ from 'lodash';
import { batchDispatch } from '../app/funcs';

/**
 * 
 * @param {typeof EMPLOYEES_EVENTS[keyof EMPLOYEES_EVENTS]} event 
 * @param {Record<string, any>} [params] 
 * @returns 
 */

const trackEmployees = (event, params = {}) => {
  batchDispatch([track(event, params)]);
};

export default trackEmployees;

export const EMPLOYEES_EVENTS = {
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  SAVE_EMPLOYEES: 'SAVE_EMPLOYEES',
  START_EMPLOYEES_LISTENER: 'START_EMPLOYEES_LISTENER',
  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  EXPELLED_EMPLOYEES: 'EXPELLED_EMPLOYEES',
  UNEXPELLED_EMPLOYEES: 'UNEXPELLED_EMPLOYEES',
  END_EMPLOYEES_LISTENER: 'END_EMPLOYEES_LISTENER',
};

