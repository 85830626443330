import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProjectContext } from '../projects/contexts';
import { startFormsListener } from '../forms/funcs';
import { platformActions } from '../platformActions';
import _ from 'lodash';
import { getFromPropsOrNav } from '../../native/app/funcs';

export const useForms = ({ formType }) => {
  
  const { selectedProjectId: projectId, viewer } = useContext(ProjectContext);

  const localDB = platformActions.localDB.getCementoDB();

  const [forms, setForms] = useState([]);

  const filterQuery = useMemo(
    () =>
      platformActions.app.isNative()
        ? `projectId == "${projectId}" AND type == "${formType}" AND isDeleted != true`
        : { projectId: projectId, type: formType, isDeleted: { $ne: true } },
    [projectId, formType]
  );

  const updateFormState = useCallback(() => {
    const data = localDB.get('forms', filterQuery) || [];
    const formsMap = _.mapKeys(data, 'id');
    setForms(formsMap);
  }, [setForms, localDB, filterQuery]);


  const startLocalDBListener = useCallback(() => {
    return localDB.startListener(
      'forms',
      updateFormState,
      platformActions.app.isNative() ? { objectName: 'forms', query: filterQuery } : {}
    );
  }, [updateFormState, filterQuery]);

  useEffect(() => {
    const endListenersFuncs = [startLocalDBListener(), startFormsListener(viewer, { projectId, formType })];
    return () => endListenersFuncs.forEach((endListener) => endListener?.());
  }, [projectId, formType]);

  useEffect(() => {
    updateFormState()
  }, []);

  return { forms };
};

export const FormsHOC = (WrappedComponent) => {
  const WithForms = (props = {}) => {
    const formType = getFromPropsOrNav(props, ['formType'], 'general');
    const formsProps = useForms({formType});
    return <WrappedComponent {...formsProps} {...props} />;
  };
  return WithForms;
};
