import { getDispatch } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { titleEvents } from './titleEvents';

export async function getTitles(viewer) {
  const dispatch = getDispatch();

  const scopeParams = { scope: 'global' };
  const resourceParams = {
    resourceName: 'titles',
    firebasePath: `titles`,
    forceMSClientConfig: true,
  };

  const onData = (data) => {
    dispatch({ type: titleEvents.GET_TITLES, payload: { titles: data, lastUpdateTS: 1 } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    dispatch({
      type: titleEvents.GET_TITLES,
      payload: { titles: result },
    });
  }
}
