export const membersEvents = {
  SEARCH_UNLOCAL_USERS_BY_UIDS: 'SEARCH_UNLOCAL_USERS_BY_UIDS',
  GET_USER_BY_UID: 'GET_USER_BY_UID',
  SEARCH_USERS_BY_PHONE_STARTED: 'SEARCH_USERS_BY_PHONE_STARTED',
  SEARCH_USERS_BY_PHONE: 'SEARCH_USERS_BY_PHONE',
  UPDATE_MY_METADATA: 'UPDATE_MY_METADATA',
  COPY_GLOBAL_VIEWER_TO_SCOPE: 'COPY_GLOBAL_VIEWER_TO_SCOPE',
  SEARCH_PROJECT_USERS: 'SEARCH_PROJECT_USERS',
  MATCH_PHONES: 'MATCH_PHONES',
  MATCH_PHONES_SUCCESS: 'MATCH_PHONES_SUCCESS',
  GET_USER_PAGE: 'GET_USER_PAGE',
  SET_CONTACTS_LATEST_USER_UPDATE_AT: 'SET_CONTACTS_LATEST_USER_UPDATE_AT',
  ON_OTHER_USER_PROFILE_UPDATE: 'ON_OTHER_USER_PROFILE_UPDATE',
  INVITE_UNINVITED_USER: 'INVITE_UNINVITED_USER',
  CLEAN_CACHED_MEMBERS: 'CLEAN_CACHED_MEMBERS',
};
