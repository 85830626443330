import { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import theme from "../../assets/css/theme";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import { ReportContext } from '../../../common/analytics/contexts'; 
import iconResolve from '../../assets/img/icons/CheckBrandPrimary.png';
import attachment from '../../assets/img/icons/attachment.png';
import iconRejected from '../../assets/img/icons/CheckFalse.png';
import issue_full from '../../assets/img/icons/issue_full.png'
import issue_warn from '../../assets/img/icons/issue_warn.png'
import _ from 'lodash';
import Text from "../../components/CementoComponents/Text";
import systemMessages from "../../../common/app/systemMessages";
import safetyMessages from '../../../common/safety/safetyMessages';
import CounterCell from "../../components/CementoComponents/CounterCell";
import { Fade, withStyles } from "@material-ui/core";
import { Tooltip } from "../../components";
import DotStatusText from "../../components/CementoComponents/DotStatusText";
import StandardInput from "../../components/CementoComponents/StandardInput";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import useMemoizedValue from '../../../common/hooks/useMemoizedValue';
import { useSelector } from 'react-redux';

const ICON_SIZE = 16;
const DEFAULT_MAX_CHARS = 15;
const TOOLTIP_LEAVE_DELAY = 50;
const TOOLTIP_ENTER_DELAY = 1000;
const PRIMARY_CELL_MAX_ROW_LEVEL = 2;

const TOOLTIP_MIN_LENGTH = 300;
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
    minWidth: TOOLTIP_MIN_LENGTH      
  },
}))(Tooltip);

const cellContentCalculator = (cellValue, rtl, cellStyles) => {
  if (_.isNil(cellValue)){
    return cellValue;
  }

    const { displayValue, displayType, displayParams, originalValue, cellInnerComponent } = cellValue;

    let cellContent;
    const typesAlignedToStart = {
                                 "Number": false,
                                 "Files": false, 
                                 "String": true};
    let justifyContent;
    let alignItems;
    if (_.has(typesAlignedToStart, displayType)) {
      if (typesAlignedToStart[displayType]) {
        justifyContent = _.get(displayParams, 'justifyContent', 'start');
        alignItems = _.get(displayParams, 'alignItems', 'start');
      }
      else {
        justifyContent = _.get(displayParams, 'justifyContent', 'center');
        alignItems = _.get(displayParams, 'alignItems' , 'center');
      }
    }

  if (cellInnerComponent)
    cellContent = cellInnerComponent;
  else
    switch (displayType) {
      case 'Boolean':
        cellContent = typeof displayValue === 'boolean'
          ? <img src={displayValue ? iconResolve : iconRejected} style={{ height: ICON_SIZE, width: ICON_SIZE }} />
          : null;
        break;

        case 'Date': {
          const warningFlagVisible = (displayParams.isWarning || displayParams.isExpired)
          cellContent = (
            <div style={{ ...cellStyles, flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <img 
                src={displayParams.isExpired ? issue_full : issue_warn}
                style={{ 
                  padding: theme.verticalMargin,
                  visibility: warningFlagVisible ? 'visible' : 'hidden',
                }} 
              />
              {
                displayParams.missingRequirementList ?
                  <Text
                    style={{ color: theme.brandDanger }}
                    withTooltip={false}
                  >
                    {safetyMessages.missing}
                  </Text> :
                  <StandardInput
                    disabled
                    type={propertyTypes.DATE}
                    containerStyle={{ flex: null }}
                    valueStyle={{ ...theme.defaultFont, fontWeight: (displayParams.isWarning || displayParams.isExpired) ? theme.strongBold : theme.noBold, color: displayParams.isExpired ? theme.brandDanger : null }}
                    value={displayValue}
                    dateFormat={displayParams.dateFormat || systemMessages.onlyDateFormat}
                    withTooltip={false}
                    isTableCell={true}
                  />
              }
            </div>
          );
          break;
        }
      case 'Files':
        if (!displayValue)
          cellContent = null;
        else
          cellContent = (
            <div style={{ ...cellStyles, flexDirection: 'row', display: 'flex', justifyContent, alignItems }}>
              <img
                src={attachment}
                style={{
                  color: theme.brandPrimary,
                  height: 13,
                  [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin
                }}
              />
              <Text
                style={{ fontWeight: theme.noBold }}
                values={{ filesCounter: displayValue }}
                withTooltip={false}
              >
                {systemMessages.fileCount}
              </Text>
            </div>
          );
          break;
          
      case 'Number':
        cellContent = (
          <Text
            style={{ direction: displayParams.direction || 'inherit', display: 'flex', flex: 1, flexDirection: 'row', padding: 5, justifyContent, alignItems, overflow: 'hidden', fontWeight: 'initial', whiteSpace: 'nowrap' }}
            maxChars={cellStyles.columnCustomMaxChars || DEFAULT_MAX_CHARS}
            maxLines={1}
            tagsMode={'hide'}
            withTooltip={false}
          >
            {displayValue}
          </Text>
        );
        break;

        case 'Status': {
          const dotSize = 10;
          const dotStyle = {
            height: dotSize,
            width: dotSize,
            marginInline: theme.margin / 3,
            flexShrink: 0,
          };
          const labelStyle = {
            flexShrink: 0,
          };
          const containerStyle = {
            justifyContent: displayParams.justifyContent || 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            margin: '0 5px',
          };
          const cellStyle = {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            position: 'relative'
          };

          cellContent = (
            <div style={cellStyle}>
              {displayParams.items?.sort((a, b) => a.color.localeCompare(b.color))
                .map((statusParams) => {
                  const { id, message, color } = statusParams;

                  return (
										<DotStatusText
											key={id}
											label={message}
											color={color}
											dotStyle={dotStyle}
											containerStyle={containerStyle}
											labelStyle={labelStyle}
										/>
									);
                })
              }
            </div>
          )
          break; 
        }

      case 'Trade': {
        let valueId = originalValue;
        if (valueId && typeof valueId === 'object') {
          valueId = Object.keys(valueId)[0];
        }
        if (valueId) {
          cellContent = (<TradeBadge mode={"auto"} ids={[valueId]} />);
        }
        break;
      }
      default:
      case 'String': {
        if (displayParams.hideCounterRow)
          cellContent = null;
        else if (displayParams.isCounterRow)
          cellContent = (<CounterCell value={displayValue} style={displayParams.counterStyle} />);
        else
          cellContent = (
            <Text
              shouldSplitLines={false}
              style={{
                direction: displayParams.direction || 'inherit',
                display: 'inline-block',
                flex: 1,
                flexDirection: 'row',
                padding: theme.padding,
                alignItems,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontWeight: cellStyles.fontWeight || 'initial',
                whiteSpace: 'nowrap',
                textAlign: justifyContent || 'start',
              }}
              maxChars={cellStyles.columnCustomMaxChars
              }
              maxLines={1}
              tagsMode={'hide'}
              withTooltip={false}
            >
              {displayValue}
            </Text>
          );
          break;
      }
    }

  return cellContent;
}

const TableCell = props => {
	const { cellData, isFirstRow, rowIndex, cellClickHandler, isRowSelected, isRTL, onMouseEnter, onMouseLeave } = props;

  const isPrimaryFirstColumn = _.get(cellData, ['column', 'isFirst']) && _.get(cellData, ['row', 'rowLevel']) <= PRIMARY_CELL_MAX_ROW_LEVEL;
	let cellStyles = Object.assign({ borderHeight: 1 }, cellData?.style, isPrimaryFirstColumn && { fontWeight: 'bold'});
  const memoCellValue = useMemoizedValue(cellData ? cellData.title || cellData.row.values[cellData.column.mainColumnId] : undefined);
	const cellContent = useMemo(() => {
		if (_.isNil(cellData)) return undefined;
		return cellContentCalculator(memoCellValue, isRTL, cellStyles);
	}, [memoCellValue, isRTL]);

	if (!cellData) return null;

	const dismissClickHandler = cellData?.row?.dismissClickHandler;
	const addClassNames = cellData?.className || '';

  const undefinedCell = false && typeof cellContent === 'undefined';
  if (!undefinedCell || !dismissClickHandler) cellStyles.cursor = 'pointer';

	return (
    <td
      className={undefinedCell ? addClassNames.concat(' EmptyCell') : addClassNames}
      key={cellData.row.id}
      style={{
        ...cellStyles,
        paddingBottom: isRowSelected && isFirstRow ? cellStyles.borderHeight : 0,
        paddingTop: isRowSelected && isFirstRow ? cellStyles.borderHeight : 0,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() =>
        cellClickHandler(
          cellData,
          false,
          cellData.row.id.concat(cellData.column.HeaderValue),
          rowIndex,
        )
      }
    >
      {isRowSelected && (
        <div style={{ backgroundColor: theme.brandPrimary, height: cellStyles.borderHeight }} />
      )}
      <div
        className={cellData.row.isActive === false ? addClassNames.concat(' NotActiveCell') : null}
        style={{
          ...cellStyles,
          alignItems: cellStyles.customAlignItems || 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isFirstRow && !isRowSelected ? cellStyles.borderHeight : 0,
          height: cellStyles.height - (isRowSelected ? cellStyles.borderHeight * 3 : 0 + (isFirstRow ? 0 : 0)),
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {cellContent}
        </div>
      </div>
      {isRowSelected && (
        <div style={{ backgroundColor: theme.brandPrimary, height: cellStyles.borderHeight }} />
      )}
    </td>
	);
};

const TableOptimizedCell = props => {
  const { cellData, isFirstRow, rowIndex, noStyle, style } = props;
  const { selectedCell, cellClickHandler } = useContext(ReportContext);
  const isRTL = useSelector(state => state.app.rtl);
  const [isSelected, setIsSelected] = useState(false);

  const [renderTooltip, setRenderTooltip] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    const newIsSelected = selectedCell?.cell?.row?.id === cellData?.row?.id;
    if (isSelected !== newIsSelected)
      setIsSelected(newIsSelected);
  }, [selectedCell?.cell?.row?.id, cellData?.row?.id]);

  let cellValue = cellData.title || cellData.row.values[cellData.column.mainColumnId];
  const { displayParams = {} } = cellValue || {};

  const debouncedSetRenderTooltip = useCallback((shouldRender) => {
    if (renderTooltip) return;

    if (timeoutRef.current || !shouldRender) {
      clearTimeout(timeoutRef.current);
    }

    if (shouldRender) {
      timeoutRef.current = setTimeout(() => {
        setRenderTooltip(true);
      }, TOOLTIP_ENTER_DELAY);
    }
  }, [renderTooltip]);

  const renderWithToolTip = useCallback((child) => {
    if (!displayParams?.toolTipParams) {
      return child
    }
    const { titleComponent, style, titleGeneratorFunction, titleOnHover } = displayParams.toolTipParams || {};
      return (
        <LightTooltip
          PopperProps={{ style: {zIndex: theme.zIndexes.toolTip, ...style}}}
          interactive
          enterDelay={TOOLTIP_ENTER_DELAY}
          enterTouchDelay={TOOLTIP_ENTER_DELAY}
          leaveDelay={TOOLTIP_LEAVE_DELAY}
          leaveTouchDelay={TOOLTIP_LEAVE_DELAY}
          titleComponent={titleComponent}
          titleGeneratorFunction={titleGeneratorFunction}
          titleOnHover={titleOnHover}
          title={child}
          placement={isRTL ? 'left' : 'right'}
          TransitionComponent={Fade}
        >
          <td style={{ outline: 'none' }}>{child}</td>
        </LightTooltip>
      ); // The <td> is needed for the tooltip to work becouse it's must have an element with ref to work. It's an ugly workaround but it's the only way to make it work.
  }, [displayParams, isRTL]);

  const cell = (
    <TableCell
      onMouseEnter={() => debouncedSetRenderTooltip(true)} 
      onMouseLeave={() => debouncedSetRenderTooltip(false)}
      isFirstRow={isFirstRow}
      rowIndex={rowIndex}
      cellData={cellData}
      cellClickHandler={cellClickHandler}
      isRowSelected={isSelected}
      isRTL={isRTL}
      noStyle={noStyle}
      style={style}
    />
  );

  return renderTooltip ? renderWithToolTip(cell) : cell;
}

export default TableOptimizedCell;