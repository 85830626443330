import { batchDispatch } from '../app/funcs';
import { setServerSdkAuthClientParams } from '../lib/api';
import { PROJECT_EVENTS } from './trackProjects';
import ClientServerConnectivityManager from '../lib/ClientServerConnectivityManager';
import { lokiInstance } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import DataManagerInstance from '../dataManager/DataManager';

let IS_ALREADY_RUNNING_A_SAVE = false;

export const saveProjectLokiStorage = (projectId) => {
  return () => {
    const getPromise = async () => {
      if (!IS_ALREADY_RUNNING_A_SAVE) {
        IS_ALREADY_RUNNING_A_SAVE = true;

        try {
          if (platformActions.app.isWeb()) {
            await lokiInstance.saveProjectDataToStorage(projectId);
            await lokiInstance.saveProjectDataToStorage('global');
          }
        } catch (error) {
          platformActions.sentry.notify(error, { projectId });
          console.log('saveProjectStorage error:', error);
        } finally {
          IS_ALREADY_RUNNING_A_SAVE = false;
        }
      }

      return { projectId };
    };

    return {
      type: PROJECT_EVENTS.SAVE_PROJECT_LOKI_STORAGE,
      payload: getPromise(),
    };
  };
};

export const removeProjectsStorage = (projectIdsArray, projectStateToRemove) => {
  return () => {
    const getPromise = async () => {
      try {
        await Promise.all(
          projectIdsArray.map(async (projectId) => {
            await Promise.all(
              projectStateToRemove.map(async ([feature, ...featurePath]) => {
                try {
                  var configKey = '@' + feature + '_' + featurePath + ':' + projectId;
                  if (platformActions.app.getPlatform() == 'web') await platformActions.storage.removeItem(configKey);
                  else {
                    const realm = platformActions.localDB.getCementoDB();
                    const query = `id = "${configKey}"`;
                    realm.unset('reducerPersist', query);
                  }
                } catch (err) {
                  console.warn('REMOVE_PROJECTS_STORAGE error:' + projectId);
                  console.warn(err);
                }
              })
            );
          })
        );
      } catch (error) {
        console.log('REMOVE_PROJECTS_STORAGE error: ' + error);
        throw error;
      }
    };

    return {
      type: PROJECT_EVENTS.REMOVE_PROJECTS_STORAGE,
      payload: getPromise(),
    };
  };
};

export const unloadProjectLokiStorage = (projectId) => {
  return () => {
    const getPromise = async () => {
      if (platformActions.app.isWeb()) {
        await lokiInstance.unloadProjectDataFromStorage(projectId, () =>
          DataManagerInstance.setProjectLokiLoaded(projectId, false)
        );
      }
    };

    return {
      type: PROJECT_EVENTS.UNLOAD_PROJECT_LOKI_STORAGE,
      payload: getPromise(),
    };
  };
};

export const enterProject = (projectId, lastVisitedProjectId) => {
  return ({ dispatch }) => {
    const getPromise = async () => {
      setServerSdkAuthClientParams({ projectId });
      await DataManagerInstance.loadProjectData(projectId, lastVisitedProjectId);

      return { projectId, lastVisitedProjectId };
    };

    return {
      type: PROJECT_EVENTS.ENTER_PROJECT,
      payload: getPromise(),
    };
  };
};

export const leaveProject = (projectId) => {
  return ({ dispatch }) => {
    ClientServerConnectivityManager.unregisterAllScopeServices('projects', projectId);
    DataManagerInstance.unsetProjectsData({ projectIds: [projectId] });

    if (platformActions.app.getPlatform() === 'web') {
      dispatch(saveProjectLokiStorage(projectId));
      dispatch(unloadProjectLokiStorage(projectId));
    }

    return {
      type: PROJECT_EVENTS.LEAVE_PROJECT,
      payload: { projectId },
    };
  };
};

export const setProjectIntl = (intl) => {
  return {
    type: PROJECT_EVENTS.SET_PROJECT_INTL,
    payload: { intl },
  };
};
