import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { injectIntl } from "react-intl";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import { startLoading } from "../../../common/app/actions";
import projectsMessages from "../../../common/projects/projectsMessages";
import defaultProject from "../../assets/img/defaultProject.png";
import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import toggleDown from "../../assets/img/icons/toggleDown.png";
import companiesMessages from "../../../common/companies/companiesMessages";
import { endUserProjectsListener, navigateToProject } from "../../../common/projects/funcs";
import _ from "lodash";
import { deepEqual, isStringMatch } from '../../../common/app/funcs';
import withRouterHOC from "../../components/Router/util/withRouterHOC";
import { CompaniesHOC } from "../../../common/companies/hooks/useCompanies";
import List from 'react-virtualized/dist/commonjs/List';
class ProjectsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onProjectSelect = this.onProjectSelect.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.state = {
      viewersCompany: null,
      isCompanyViewPermitted: false,
      scope: null,
      selectedCompany: null,
    };
  }

  componentDidMount() {
    const { selectedProjectId } = this.props;
    if (selectedProjectId) this.navigateToProject(selectedProjectId, false);
  }

  componentWillUnmount() {
    endUserProjectsListener();
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  async setComponentData(props, nextProps) {
    const { projects, viewer, selectedProjectId, location } = props;
    let newStateChanges = {};

    let differentViewer = Boolean(
      _.get(props, ["viewer", "id"]) != _.get(nextProps, ["viewer", "id"])
    );
    let differentViewerMode = Boolean(
      _.get(props, ["viewer", "adminMode"]) !=
        _.get(nextProps, ["viewer", "adminMode"])
    );
    let prevProjects = props.getNested(["viewerProjects"]);
    let nextProjects = nextProps.getNested(["viewerProjects"]);
    let prevViewersProjectsCompanies = [];
    (prevProjects || []).forEach((p) =>
      prevViewersProjectsCompanies.push(p.companyId)
    );
    prevViewersProjectsCompanies = _.uniq(
      prevViewersProjectsCompanies.filter(_.identity)
    ).sort();
    let nextViewersProjectsCompanies = [];
    (nextProjects || []).forEach((p) =>
      nextViewersProjectsCompanies.push(p.companyId)
    );

    nextViewersProjectsCompanies = _.uniq(
      nextViewersProjectsCompanies.filter(_.identity)
    ).sort();
    let isAdminMode = nextProps.getNested(["viewer", "adminMode"], 0) > 0;

    if (
      nextProps.viewer &&
      nextProps.companies &&
      (differentViewer ||
        differentViewerMode ||
        _.difference(prevViewersProjectsCompanies, nextViewersProjectsCompanies)
          .length ||
        !_.isEqual(
          props.companiesConfigurations,
          nextProps.companiesConfigurations
        ) ||
        !_.isEqual(props.companies, nextProps.companies))
    ) {
      newStateChanges.viewersCompanies = {};
      nextViewersProjectsCompanies.forEach((companyId) => {
        let isCompanyViewPermitted = true;
          /** nextProps.companiesConfigurations.getNested([
            companyId,
            "features",
            "companyView",
          ]);**/
        let company = nextProps.getNested(["companies", companyId]);
        if (
          company &&
          (isAdminMode ||
            (isCompanyViewPermitted &&
              !newStateChanges.viewersCompanies[companyId]))
        ) {
          newStateChanges.viewersCompanies[companyId] = company.toJS
            ? company.toJS()
            : company;
        }
      });
      if (!Object.keys(newStateChanges.viewersCompanies).length) delete newStateChanges.viewersCompanies;
    }

    if (props.isValDiff(nextProps, ["navigationParams", "scope"]))
      newStateChanges.scope = nextProps.getNested(
        ["navigationParams", "scope"],
        "project"
      );

    if (props.isValDiff(nextProps, ["navigationParams", "selectedCompanyId"]) || !deepEqual(this.state.viewersCompanies || {}, newStateChanges.viewersCompanies)) {
      let selectedCompanyId = nextProps.getNested([
        "navigationParams",
        "selectedCompanyId",
      ]);
      newStateChanges.selectedCompany = nextProps.getNested([
        "companies",
        selectedCompanyId,
      ]);
    }

    let scope = newStateChanges.scope || this.state.scope;
    if (
      (scope != "company" &&
        nextProps.projects &&
        projects != nextProps.projects) ||
      selectedProjectId != nextProps.selectedProjectId
    ) {
      newStateChanges.viewerProjects = nextProps.viewerProjects.safeToJS();
    }

    let didNavigateToScopePage = nextProps.getNested(['navigationParams', 'scope']);
    const hasProjects = !_.isEmpty(nextProps.viewerProjects)

    if (!didNavigateToScopePage && hasProjects) {
      const nextProjects = nextProps.getNested(["viewerProjects"]).safeToJS() || {}
      const projectId = nextProps.getNested(["lastVisitedProject", "id"]) || Object.values(nextProjects)[0]?.id
      if (projectId) {
        newStateChanges.selectedProjectId = this.navigateToProject(projectId,
          !nextProps.selectedProjectId || nextProps.selectedProjectId == "_",
          nextProps,
          props.firstMount
        );
      }
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onProjectSelect(selectedProjectId) {
    this.navigateToProject(selectedProjectId, true, null, null, true);
    this.setState({ selectedProjectId });
  }

  onCompanySelect(selectedCompany) {
    this.navigateToCompany(selectedCompany);
    this.setState({ selectedProjectId: null, isSelected: false });
  }

  navigateToCompany(selectedCompanyId) {
    const { history } = this.props;
    let redirectToUrl = `/main/companyContainerPage/${
      selectedCompanyId || "_"
    }/_`;
    

    history.push(redirectToUrl);
  }

  navigateToProject(
    projectId,
    navigate,
    nextProps,
    firstMount,
    userSelectAction
  ) {
    const { location } = nextProps || this.props;
    if (
      !projectId ||
      (!userSelectAction && location.pathname.includes("companyContainerPage"))
    ) {
      return null;
    }

    if (navigate) {
      let overwriteURL =
        (!firstMount && userSelectAction) ||
        location.pathname.length <= "/main/projectContainerPage/".length
          ? "/main/projectContainerPage/" + projectId + "/_"
          : location.getNested(["pathname"], "") +
            location.getNested(["search"], "") +
            location.getNested(["hash"], "");
      navigateToProject(this.props, projectId, null, overwriteURL);
    } 

    return projectId;
  }

  render() {
    const { viewer, rtl, selectedProjectId } = this.props;
    const { viewersCompanies, scope, selectedCompany, viewerProjects } =
      this.state;

    return (
      <MySelect
        isSearchable={true}
        style={{ height: 60 }}
        selectedProjectId={selectedProjectId}
        selectedCompany={scope == "company" ? selectedCompany : null}
        onProjectSelect={this.onProjectSelect}
        onCompanySelect={this.onCompanySelect}
        projects={viewerProjects}
        companies={viewersCompanies}
        viewer={viewer}
        rtl={rtl}
      />
    );
  }
}
const enhance = compose(
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  CompaniesHOC,
  connect(null,
    {
      startLoading,
      endUserProjectsListener,
    }
  )
);
export default enhance(ProjectsSelector);

const ITEM_HEIGHT = 60;
class MySelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      isOpen: false,
      filter: "",
      listHeight: window.innerHeight - theme.headerHeight,
    };
  }

  handleClose() {
    this.setState({ isOpen: false, filter: "" });
  }

  componentDidUpdate = () => {
    if (this.state.isOpen && !this.windowResizeListener) {
      const listener = () => {
        this.setState({ listHeight: window.innerHeight - theme.headerHeight });
      };
      window.addEventListener('resize', listener);
      listener()
      this.windowResizeListener = () => window.removeEventListener('resize', listener);
    } else if (!this.state.isOpen && this.windowResizeListener) {
      this.windowResizeListener();
      this.windowResizeListener = null;
    }
  }

  componentWillUnmount() {
    if (this.windowResizeListener) {
      this.windowResizeListener();
    }
  }

  render() {
    const {
      projects,
      selectedProjectId,
      onProjectSelect,
      rtl,
      intl,
      viewer = {},
      companies = {},
      onCompanySelect,
      selectedCompany,
    } = this.props;
    const { isOpen, filter, listHeight } = this.state;
    const menuWidth = theme.sidebarWidth;
    if (_.isEmpty(projects)) return null;

    const projectsObject = projects;
    const nextFilter = filter || '';
    let projectsArray = Object.values(projectsObject || {}).filter(
      (x) =>
        filter == "" ||
        (x.title && isStringMatch(x.title, nextFilter)) ||
        (x.address && isStringMatch(x.address, nextFilter)) ||
        ((viewer || {}).adminMode &&
          x.id &&
          x.id.toLowerCase().indexOf((filter || "").toLowerCase()) != -1)
    );
    projectsArray = projectsArray.sort((a, b) =>
      (a.title || a.address || "").localeCompare(b.title || b.address || "")
    );
    let companiesArray = _.values(companies);
    companiesArray = companiesArray.filter(
      (x) =>
        !filter ||
        filter == "" ||
        (x.name &&
          isStringMatch(x.name, nextFilter)) ||
        (x.address && isStringMatch(x.address, nextFilter)) ||
        ((viewer || {}).adminMode &&
          x.id &&
          x.id.toLowerCase().indexOf((filter || "").toLowerCase()) != -1)
    );
    companiesArray = companiesArray.sort(
      (a, b) => a.name && b.name && a.name.localeCompare(b.name)
    );

    const closedComponent = (
      <Item
        key={selectedCompany ? selectedCompany.id : selectedProjectId}
        scopeId={selectedCompany ? selectedCompany.id : selectedProjectId}
        title={
          selectedCompany
            ? selectedCompany.name
            : projectsObject.getNested(
                [selectedProjectId, "title"],
                projectsObject.getNested([selectedProjectId, "address"])
              )
        }
        img={
          selectedCompany
            ? selectedCompany.darkLogo || selectedCompany.logo
            : projectsObject.getNested(
                [selectedProjectId, "images", "main"],
                defaultProject
              )
        }
        onClick={() => this.setState({ isOpen: true })}
        isHeader={true}
        rtl={rtl}
        imageStyle={{ objectFit: "contain", objectPosition: "center" }}
      />
    );

    return (
      <>
        {Boolean(isOpen) && (
          <div
            onClick={this.handleClose}
            style={{
              zIndex: theme.zIndexes.projectSelectBackdrop,
              top: 0,
              bottom: theme.headerHeight,
              right: 0,
              left: 0,
              height: theme.headerHeight,
              width: '100wh',
              position: 'absolute',
            }}
          />
        )}
        {Boolean(isOpen) && (
          <div
            onClick={this.handleClose}
            style={{
              zIndex: theme.zIndexes.projectSelectBackdrop,
              top: theme.headerHeight,
              bottom: 0,
              right: 0,
              left: 0,
              height: 'calc(100vh - ' + theme.headerHeight + 'px)',
              width: '100wh',
              position: 'absolute',
              backgroundColor: 'black',
              opacity: 0.55,
            }}
          />
        )}

        <div style={{ backgroundColor: theme.headerColorDark, width: menuWidth }}>
          {!isOpen ? (
            closedComponent
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                position: 'absolute',
                zIndex: theme.zIndexes.projectSelector,
                backgroundColor: theme.headerColorDark,
              }}>
              <div
                style={{
                  direction: rtl ? 'rtl' : 'ltr',
                  display: 'flex',
                  height: ITEM_HEIGHT,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: theme.margin,
                }}>
                <input
                  type='text'
                  autoFocus={true}
                  placeholder={intl.formatMessage(projectsMessages.searchProject)}
                  style={{ color: theme.backgroundColorBright }}
                  value={filter}
                  onChange={(e) => this.setState({ filter: e.target.value })}
                />
              </div>
              <div
                style={{
                  height: 'calc(100vh - ' + theme.headerHeight + 'px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  direction: rtl ? 'rtl' : 'ltr',
                  width: menuWidth,
                }}>
                {projectsArray.length == 0 && companiesArray.length == 0 ? (
                  <div style={{ direction: rtl ? 'rtl' : 'ltr' }}>
                    <Text
                      style={{
                        margin: theme.margin,
                        fontSize: 14,
                        color: theme.backgroundColorBright,
                      }}>
                      {projectsMessages.noProjectsFound}
                    </Text>
                  </div>
                ) : (
                  <MenuScrollbar
                    verticalStyle={{
                      left: rtl ? 0 : null,
                      right: rtl ? null : 0,
                    }}>
                    <List
                      width={menuWidth}
                      height={listHeight}
                      rowCount={projectsArray.length + companiesArray.length}
                      rowHeight={ITEM_HEIGHT}
                      rowRenderer={({ index, key, style }) => {
                        const isCompany = index < companiesArray.length;
                        const item = isCompany ? companiesArray[index] : projectsArray[index - companiesArray.length];
                        if (isCompany) {
                          return (
                            <div
                              key={`company_${key}`}
                              style={{
                                direction: rtl ? 'rtl' : 'ltr',

                                borderBottom: 1,
                                borderBottomColor: theme.darkSeparator,
                                borderBottomStyle: 'solid',
                                width: menuWidth,
                                ...style,
                              }}>
                              <Item
                                key={item.id}
                                title={item.name}
                                subtitle={
                                  selectedCompany && item.id == selectedCompany.id
                                    ? null
                                    : companiesMessages.companyView
                                }
                                isSelected={selectedCompany && item.id == selectedCompany.id}
                                img={item.darkLogo || item.logo}
                                scopeId={item.id}
                                onClick={() => {
                                  onCompanySelect(item.id);
                                  this.handleClose();
                                }}
                                imageStyle={{
                                  objectFit: 'contain',
                                  objectPosition: 'center',
                                }}
                              />
                              <div></div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={item.id + key}
                              style={{
                                direction: rtl ? 'rtl' : 'ltr',
                                borderBottom: 1,
                                borderBottomColor: theme.darkSeparator,
                                borderBottomStyle: 'solid',
                                width: menuWidth,
                                ...style,
                              }}>
                              <Item
                                title={item.title || item.address}
                                subtitle={item.address}
                                img={item.getNested(['images', 'main'], defaultProject)}
                                scopeId={item.id}
                                onClick={(e) => {
                                  onProjectSelect(e.value);
                                  this.handleClose();
                                }}
                                isSelected={item.id == selectedProjectId}
                              />
                            </div>
                          );
                        }
                      }}
                    />
                  </MenuScrollbar>
                )}
              </div>
            </div>
          )}
          <img
            src={toggleDown}
            onClick={() => (isOpen ? this.handleClose() : this.setState({ isOpen: true }))}
            style={{
              cursor: 'pointer',
              zIndex: theme.zIndexes.projectSelector + 1,
              position: 'absolute',
              top: (ITEM_HEIGHT - 7) / 2,
              [rtl ? 'right' : 'left']: menuWidth - theme.margin * 2,
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              margin: 1,
              height: 7,
            }}
          />
        </div>
      </>
    );
  }
}
MySelect = injectIntl(MySelect);

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.state = {
      isHover: false,
    };
  }

  setHover(event, isHover) {
    const { onHover } = this.props;

    this.setState({ isHover });
    if (onHover) onHover(isHover);
  }

  render() {
    const {
      title,
      subtitle,
      img,
      scopeId,
      isHeader,
      onClick,
      isSelected,
      rtl,
      imageStyle = {},
    } = this.props;
    const { isHover } = this.state;

    return (
      <div
        onClick={(e) => onClick({ ...e, value: scopeId })}
        onMouseOver={(e) => this.setHover(e, true)}
        onMouseOut={(e) => this.setHover(e, false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: ITEM_HEIGHT,
          cursor: "pointer",
          [rtl ? "paddingLeft" : "paddingRight"]: isHeader
            ? theme.padding + theme.margin * 2
            : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            height: ITEM_HEIGHT,
            width: 100,
            overflow: "hidden",
          }}
        >
          <img
            type={"project"}
            style={{
              objectPosition: "top",
              objectFit: "cover",
              height: ITEM_HEIGHT,
              width: 100,
              ...imageStyle,
            }}
            src={img}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: theme.padding,
            marginRight: theme.padding,
          }}
        >
          <Text
            style={{
              ...theme.defaultFont,
              fontWeight: 500,
              fontSize: isHeader ? 18 : 14,
              color:
                (isHover || isSelected) && !isHeader
                  ? theme.brandPrimary
                  : theme.backgroundColorBright,
              textTransform: "capitalize",
            }}
          >
            {title || subtitle}
          </Text>
          {Boolean(!isHeader && title && subtitle && title != subtitle) && (
            <Text
              style={{
                ...theme.defaultFont,
                fontWeight: 500,
                fontSize: 12,
                color:
                  (isHover || isSelected) && !isHeader
                    ? theme.brandPrimary
                    : theme.backgroundColorBright,
              }}
            >
              {subtitle}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
