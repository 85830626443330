import React, { Component } from "react";
import theme from "../assets/css/theme";

import "../assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "../components/Snackbar/Snackbar.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import notificationsStyle from "../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import { PropagateLoader } from "react-spinners";
import _ from "lodash";

const toastTypeColorMap = {
  warning: "warning",
  error: "danger",
  success: "success",
};

function Transition(props) {
  return <Grow in={props.open} {...props} />;
}

class Alerts extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sideToastVisible: false,
      alertVisible: false,
    };
    this.closeToast = this.closeToast.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { toast, loading } = this.props;
    let newStateChanges = {};

    if (toast != nextProps.toast || loading != nextProps.loading) {
      if (loading != nextProps.loading) {
        newStateChanges.alertVisible = Boolean(nextProps.loading);
      }

      if (toast != nextProps.toast) {
        let toastVisible = this.showToast(nextProps);
        newStateChanges.alertVisible =
          toastVisible.alertVisible || newStateChanges.alertVisible;
        newStateChanges.sideToastVisible = toastVisible.sideToastVisible;
      }
    }
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  showToast(nextProps) {
    let sideToastVisible = Boolean(nextProps.toast && !nextProps.toast.overlay);
    let alertVisible = Boolean(nextProps.toast && nextProps.toast.overlay);
    let timeout =
      nextProps.toast && nextProps.toast.overwriteTimeout
        ? nextProps.toast.overwriteTimeout
        : 5000;

    if (sideToastVisible) {
      if (this.toastTimeout) clearInterval(this.toastTimeout);

      this.toastTimeout = setTimeout(
        (() => {
          this.setState({ sideToastVisible: false });
          clearInterval(this.toastTimeout);
        }).bind(this),
        timeout
      );
    }

    return { sideToastVisible, alertVisible };
  }

  closeToast() {
    this.setState({ sideToastVisible: false });
  }

  closeAlert() {
    this.setState({ alertVisible: false });
  }

  render() {
    const { classes, toast, intl, loading, rtl } = this.props;
    const { sideToastVisible, alertVisible } = this.state;

    let color = null; // white

    if (toast && toast.type && toastTypeColorMap[toast.type])
      color = toastTypeColorMap[toast.type];

    let values = {};
    if (toast && toast.values) {
      if (typeof toast.values === "object" && toast.values !== null)
        Object.keys(toast.values).forEach((key) => {
          values[key] =
            typeof toast.values[key] === "object" && toast.values[key] !== null
              ? intl.formatMessage(toast.values[key])
              : toast.values[key];
        });
      else values = toast.values;
    }

    let toastTitle =
      toast && toast.title && toast.title.id && toast.title.defaultMessage
        ? intl.formatMessage(toast.title, values)
        : _.get(toast, ["title"], "");

    return (
      <div style={{ display: "flex", flex: 1 }}>
        {/* SIDE TOAST */}
        <Snackbar
          close={toast && toast.close}
          place={rtl ? "br" : "bl"}
          open={sideToastVisible}
          closeNotification={this.closeToast}
          color={color}
          message={toastTitle}
        />

        {/* ALERT MODAL */}
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modalJustify + " " + classes.modalSmall,
          }}
          style={{
            marginTop: "none !important",
            zIndex: theme.zIndexes.alertsModal,
            direction: rtl ? "rtl" : "ltr",
          }}
          open={alertVisible}
          keepMounted
          TransitionComponent={Transition}
          onClose={
            (toast && (toast.actions || toast.mandatory)) || (loading && !loading?.hideOnBackgroundPress)
              ? null
              : () => this.closeAlert()
          }
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          {Boolean(!loading && toast) && (
            <DialogTitle
              id="small-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              {Boolean(!toast.mandatory && !toast.actions) && (
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  onClick={this.closeAlert}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                >
                  <Close className={classes.modalClose} />
                </Button>
              )}
              {toastTitle}
            </DialogTitle>
          )}
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            {Boolean(loading && loading.toast && loading.toast.title) && (
              <h5>
                {intl.formatMessage(loading.toast.title, loading.toast.values)}
              </h5>
            )}
            {Boolean(loading) && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  margin: "15px",
                  padding: "15px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PropagateLoader
                  size={15}
                  color={theme.brandPrimary}
                  loading={true}
                />
              </div>
            )}
            {Boolean(!loading && toast && toast.message) && (
              <h5 style={{ direction: "inherit" }}>
                {!toast.message.defaultMessage
                  ? (toast.message || "").split("\n").map((x) => (
                      <>
                        {x}
                        <br />
                      </>
                    ))
                  : intl.formatMessage(toast.message, toast.values)}
              </h5>
            )}
          </DialogContent>
          {Boolean(!loading && toast && toast.actions) && (
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              {toast.actions.map((currAction) => (
                <Button
                  className={classes.modalSmallFooterFirstButton}
                  onClick={() => {
                    if (currAction.onClick) currAction.onClick();
                    this.closeAlert();
                  }}
                  color={currAction.color || "transparent"}
                  simple
                >
                  {currAction.message && currAction.message.defaultMessage
                    ? intl.formatMessage(currAction.message)
                    : typeof currAction.message === "string"
                    ? currAction.message
                    : ""}
                </Button>
              ))}
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(notificationsStyle)(Alerts);
