export const CLEAN_CACHED_CONTACTS = 'CLEAN_CACHED_CONTACTS';
export const GET_CONTACTS  = 'GET_CONTACTS';
export const GET_NATIVE_CONTACTS_STARTED  = 'GET_NATIVE_CONTACTS_STARTED';
export const GET_NATIVE_CONTACTS  = 'GET_NATIVE_CONTACTS';
export const GET_NATIVE_CONTACTS_COMPLETED  = 'GET_NATIVE_CONTACTS_COMPLETED';
export const SAVE_ON_STROAGE  = 'SAVE_ON_STROAGE';
export const NATIVE_CONTACTS_LOADED  = 'NATIVE_CONTACTS_LOADED';
export const CONTACTS_PERMISSION_DENIED = 'CONTACTS_PERMISSION_DENIED';

export function getContacts(intl, contactsResolver, callback) { // TODO: Return error and ask the permission there and not from herer
  return ({ dispatch, getState, removeEmpty }) => {
    const getPromise = async () => {
      var myNumber = getState().auth.number;
      contactsResolver({ myNumber, callback }, { dispatch, removeEmpty, getState, intl })
    }
    return {
      type: GET_CONTACTS,
      payload: getPromise()
    };
  };
}
