import { CementoRecordObject, Record } from '../transit';

import * as checklistsActions from '../checklists/actions';
import * as appActions from '../app/actions';
import * as checklistAction from '../checklists/actions';

import ChecklistItem from './checklistItem';
import _ from 'lodash';
import { checklistItemsEvents } from './checklistItemsEvents';

const InitialState = Record({
  map: new CementoRecordObject,
}, 'checklistItems', false);

const initialState = new InitialState;

export default function checklistItemReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case checklistsActions.DUPLICATE_CHECKLIST + "_SUCCESS": {
      if (!action.payload || !action.payload.checklistItems)
        return state;

      const { projectId, checklistItems } = action.payload;

      let itemsMap = state.getNested(['map', projectId], new CementoRecordObject);
      _.forIn(checklistItems, currChecklistItem => {
        if (_.has(currChecklistItem, 'id'))
          itemsMap = itemsMap.set(currChecklistItem.id, new ChecklistItem({ ...currChecklistItem, weight: Number(currChecklistItem.weight) || null }));
      });

      state = state.setIn(['map', projectId], itemsMap);
      return state;
    };

    case checklistItemsEvents.GET_CHECKLIST_ITEMS: {
      if (!action.payload?.projectId)
        return state;

      const { projectId, checklistItems } = action.payload;
      if (checklistItems) {
        let itemsMap = new CementoRecordObject;
        checklistItems.loopEach((currChecklistItemKey, currChecklistItem) => {
          if (currChecklistItem) {
            currChecklistItem = currChecklistItem.toJS ? currChecklistItem.toJS() : currChecklistItem;
            itemsMap = itemsMap.set(currChecklistItem.id, new ChecklistItem({ ...currChecklistItem, weight: Number(currChecklistItem.weight) || null }));
          }
        });
        state = state.setIn(['map', projectId], itemsMap);
      }
      
      return state;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case checklistAction.CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES+ "_SUCCESS":
    case checklistItemsEvents.CLEAN_CHECKLIST_ITEMS_CACHE_DATA + "_SUCCESS":
      return initialState;
  }

  return state;
}