import SignatureCanvas from 'react-signature-canvas';
import Modal from '../../web/components/CementoComponents/Modal';
import theme from '../../web/assets/css/theme';
import Button from '../../web/app/standardComponents/Button';
import CloseButton from '../../web/app/CloseButton';
import User from '../../web/components/CementoComponents/User';
import SelectableUsers from '../../web/components/CementoComponents/SelectableUsers';
import * as permissionsFunc from '../permissions/funcs';
import StandardInput from '../../web/components/CementoComponents/StandardInput';
import Select from 'react-select';
import { uploadImage } from './actions';
import { useCallback, useRef, useContext, useState, useEffect, useMemo } from 'react';
import { startToast, startLoading, hideLoading } from '../app/actions';
import { injectIntl } from 'react-intl';
import { getDispatch } from '../configureMiddleware';
import { ProjectContext } from '../projects/contexts';
import systemMessages from '../app/systemMessages';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const SignatureModal = ({
  isOpen,
  handleClose,
  projectId,
  intl,
  signatureObjs,
  shouldUploadImmediately,
  onSign,
}) => {
  const { viewer, projectMembers = {}, lang } = useContext(ProjectContext);
  const [signaturesToUpload, setSignaturesToUpload] = useState({});
  const [isSignInProgress, setIsSignInProgress] = useState(false);
  const [currentSelectedMembers, setCurrentSelectedMembers] = useState([]);

  const [currSelectedLang, setCurrSelectedLang] = useState(lang);
  const dispatch = getDispatch();

  const currSignatureObjectIndex = useMemo(() => {
    if(shouldUploadImmediately) {
      return 0;
    }
    return signatureObjs.findIndex((signature) => !signaturesToUpload[signature.id]?.uri);
  }, [signaturesToUpload, signatureObjs, shouldUploadImmediately]);

  const currSignatureObject = useMemo(() => {
    return signatureObjs[currSignatureObjectIndex] || signatureObjs[signatureObjs.length - 1]
  }, [currSignatureObjectIndex]);

  const currentSignatureId = useMemo(() => {
    return currSignatureObject?.id;
  }, [currSignatureObjectIndex]);

  const nextSignatureObject = useMemo(() => {
    return signatureObjs[currSignatureObjectIndex + 1]
  }, [currSignatureObjectIndex]);

  const titleCaption = useMemo(() => {
    const title = currSignatureObject.title?.[lang] || '';
    if(typeof title === 'object') {
      return intl.formatMessage(title);
    }
    return currSignatureObject.title?.[lang] || '';
  }, [currSignatureObject, lang]);

  const aboveComponents = useMemo(() => {
    return Object.values(currSignatureObject.components || {}).filter((component) => component.placement === 'above');
  }, [currSignatureObject]);

  const embeddedComponents = useMemo(() => {
    return Object.values(currSignatureObject.components || {}).filter((component) => !component.placement || component.placement === 'embeded');
  }, [currSignatureObject]);

  const mandatoryComponents = useMemo(() => {
    return Object.values(currSignatureObject.components || {}).filter((component) => component.isMandatory);
  }, [currSignatureObject]);

  const isMandatoryComponentsFilled = useMemo(() => {
    return mandatoryComponents.every((component) => {
      return Boolean(signaturesToUpload[currentSignatureId]?.components?.[component.id]?.value);
    });
  }, [mandatoryComponents, signaturesToUpload]);

  const handleTextInput = useCallback((value, component) => {
    const currSignatures = { ...signaturesToUpload };
    currSignatures[currentSignatureId] = currSignatures[currentSignatureId] || {};
    currSignatures[currentSignatureId] = {
      ...currSignatures[currentSignatureId],
      components: {
        ...currSignatures[currentSignatureId].components,
        [component.id]: { value },
      },
    };
    setSignaturesToUpload(currSignatures);
  });

  const shouldGetNextSignatureMember = useMemo(() => {
    return Boolean(
      Object.keys(projectMembers).length && nextSignatureObject && currSignatureObject.nextSignatureMember?.enabled
    );
  }, [projectMembers, currSignatureObject]);

  const filteredProjectMembers = useMemo(() => {
    if (!shouldGetNextSignatureMember) {
      return projectMembers;
    }
    return Object.values(projectMembers).reduce((acc, member) => {
      if (permissionsFunc.isPermitted(member, projectId, 'signatures', 'write', nextSignatureObject)) {
        acc[member.id] = member;
      }
      return acc;
    }, {});
  }, [projectMembers, currSignatureObject]);

  useEffect(() => {
    if (shouldGetNextSignatureMember) {
      setCurrentSelectedMembers([]);
      return;
    }
    const member =
      Object.values(filteredProjectMembers).find((member) => member.id === viewer.id) ||
      Object.values(filteredProjectMembers)[0];
    setCurrentSelectedMembers([member]);
  }, [filteredProjectMembers, shouldGetNextSignatureMember]);

  const translatedLanguageNames = useMemo(() => {
    return Object.keys({
      ...currSignatureObject.multiLangText?.content,
    }).reduce((acc, key) => {
      acc.push({ value: key, label: intl.formatMessage(systemMessages[key]) });
      return acc;
    }, []);
  }, [currSignatureObject]);

  useEffect(() => {
    if (isSignInProgress) {
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
    } else {
      dispatch(hideLoading());
    }
  }, [isSignInProgress]);

  const signatureRef = useRef(null);

  const clearSignature = useCallback(() => {
    signatureRef.current?.clear?.();
  });

  const uploadAllSignatures = useCallback(
    async (signaturesToUpload) => {
      const uploadedSignatures = await Promise.all(
        Object.entries(signaturesToUpload).map(async ([signatureId, signature]) => {
          const uri = await uploadImage(
            signature.uri,
            'signature_' + Date.now() + signatureId + '.jpg',
            'signatures/' + projectId + '/'
          );
          return [
            signatureId,
            { 
              ...signature,
              uri,
            },
          ];
        })
      );
      return Object.fromEntries(uploadedSignatures);
    },
    [signaturesToUpload]
  );

  const onSignClick = useCallback(async () => {
    if (currSignatureObject.isMandatory && signatureRef.current.isEmpty()) {
      await dispatch(startToast({ title: intl.formatMessage(systemMessages.mandatorySignature), type: 'error' }));
      return;
    }
    if(!isMandatoryComponentsFilled) {
      await dispatch(startToast({ title: intl.formatMessage(systemMessages.mandatoryFields), type: 'error' }));
      return;
    }
    const signature = signatureRef.current.toDataURL('image/jpeg');
    
    const newSignaturesToUpload = {
      ...signaturesToUpload,
      [currentSignatureId]: {
        ...signaturesToUpload[currentSignatureId],
        uri: signature,
        user: { displayName: viewer.displayName, id: viewer.id, idNumber: viewer.idNumber },
        lang: currSelectedLang,
      },
    };

    if (shouldGetNextSignatureMember) {
      newSignaturesToUpload[currentSignatureId].usersToNotify = currentSelectedMembers.map((member) => member.value.id);
    }

    if (currSignatureObject.alert) {
      const shouldSign = await new Promise((resolve) => {
        dispatch(
          startToast({
            overlay: true,
            mandatory: true,
            title: currSignatureObject.alert?.title?.[lang] || '',
            message: currSignatureObject.alert?.content?.[lang] || '',
            actions: [
              {
                message: currSignatureObject.alert?.confirm?.[lang] || systemMessages.yes,
                onClick: () => resolve(true),
                color: 'success',
              },
              { message: systemMessages.no, onClick: () => resolve(false) },
            ],
          })
        );
      });

      if (!shouldSign) return;
    }

    setSignaturesToUpload(newSignaturesToUpload);
    if(!shouldUploadImmediately && nextSignatureObject) {
      clearSignature();
      return;
    }
    try {
      setIsSignInProgress(true);
      let uploadedSignatures = await uploadAllSignatures(newSignaturesToUpload);
      await onSign(uploadedSignatures);
    } finally {
      setIsSignInProgress(false);
    }
  }, [currSignatureObject, signatureRef, signaturesToUpload, currentSelectedMembers, currSelectedLang]);

  const hasMultiLangText = translatedLanguageNames?.length > 1;

  return (
    <Modal
      hideCloseButton={false}
      onClose={() => {
        setSignaturesToUpload({});
        clearSignature();
        handleClose();
      }}
      open={isOpen}
      style={{ zIndex: theme.zIndexes.propertyAnalyticsDatePicker }}>
      <div style={{ padding: theme.biggerPadding, maxHeight: '90vh', maxWidth: '632px' }}>
        <div
          style={{
            fontSize: theme.fontSizeH5,
            marginTop: hasMultiLangText ? theme.margin * 2 : 0,
            marginBottom: theme.margin,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          {intl.formatMessage(systemMessages.approveAndSign)}
          {hasMultiLangText && (
            <Select
              onChange={(selected) => setCurrSelectedLang(selected.value)}
              value={translatedLanguageNames.find((lang) => lang.value === currSelectedLang)}
              options={translatedLanguageNames}></Select>
          )}
        </div>
        <div
          style={{ border: theme.borderLineHeaderInfo, borderRadius: theme.borderRadius, marginBottom: theme.margin }}>
          <div
            style={{
              fontSize: theme.fontSizeH5,
              marginBottom: theme.margin,
              fontWeight: 600,
              padding: theme.padding,
              borderBottom: theme.borderLineHeaderInfo,
            }}>
            {titleCaption}
            {Boolean(currSignatureObject.isMandatory) && <span style={{ color: 'red' }}>*</span>}
            {Boolean(currSignatureObject?.multiLangText?.content?.[currSelectedLang]) && (
              <div style={{ fontWeight: 300 }}>{currSignatureObject?.multiLangText?.content?.[currSelectedLang]} </div>
            )}
          </div>
          <div style={{ position: 'relative', borderBottom: theme.borderLineHeaderInfo, padding: theme.padding }}>
            {Boolean(aboveComponents.length) &&
              aboveComponents.map((component) => {
                return (
                  <StandardInput
                    key={component.id}
                    multiline={component.isMultiline}
                    value={signaturesToUpload[currentSignatureId]?.components?.[component.id]?.value || ''}
                    onChange={(val) => handleTextInput(val, component)}
                    title={component.title?.[lang]}
                    type='String'
                    placeholder={component.placeholder?.[lang]}></StandardInput>
                );
              })}
            <div style={{ position: 'relative' }}>

              <SignatureCanvas
                backgroundColor={'#fff'}
                ref={signatureRef}
                penColor='black'
                canvasProps={{ width: 550, height: 200, style: { borderBottom: theme.borderLineHeaderInfo } }}
              />
            </div>
            {Boolean(embeddedComponents.length) &&
              embeddedComponents.map((component) => {
                return (
                  <StandardInput
                    key={component.id}
                    multiline={component.isMultiline}
                    value={signaturesToUpload[currentSignatureId]?.components?.[component.id]?.value || ''}
                    onChange={(val) => handleTextInput(val, component)}
                    title={component.title?.[lang]}
                    type='String'
                    placeholder={component.placeholder?.[lang]}></StandardInput>
                );
              })}

            <div>
              {Boolean(Object.keys(projectMembers).length) && <User  userId={viewer.id}></User>}
              {shouldGetNextSignatureMember && (
                <SelectableUsers
                  placeholder={currSignatureObject?.nextSignatureMember?.caption?.[lang]}
                  isMulti={true}
                  showLine={true}
                  defaultValue={currentSelectedMembers[0]}
                  onChange={(assignTo) => {
                    setCurrentSelectedMembers(assignTo);
                  }}
                  users={filteredProjectMembers}></SelectableUsers>
              )}
            </div>
          </div>
        </div>

        <Button
          isDisabled={isSignInProgress}
          onClick={onSignClick}
          shouldInvertColors={true}
          style={{ marginInline: 'auto' }}>
          {intl.formatMessage(systemMessages.sign)}
        </Button>
      </div>
    </Modal>
  );
};
const enhance = compose(connect(null, { }), injectIntl);

export default enhance(SignatureModal);
