import React from 'react';
import { Card, GridItem } from '../../../../../web/components';

const GenericCardWrapper = ({ onClick, style, xs = 12, children }) => {
  return (
    <GridItem xs={xs}>
      <Card plain onClick={onClick} style={style}>
        {children}
      </Card>
    </GridItem>
  );
};

export default GenericCardWrapper;
