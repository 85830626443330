import ProjectContainerPage from "../views/Projects/ProjectContainerPage.js";
import CompanyContainerPage from "../views/Companies/CompanyContainerPage.js";
import CompanyHomePage from "../views/Companies/CompanyHomePage.js";
import projectsMessages from "../../common/projects/projectsMessages.js";
import issuesMessages from "../../common/issues/issuesMessages.js";
import postsMessages from "../../common/posts/postsMessages.js";
import systemMessages from "../../common/app/systemMessages.js";
import drawingsMessages from "../../common/drawings/drawingsMessages.js";
import safetyMessages from "../../common/safety/safetyMessages.js";
import companiesMessages from "../../common/companies/companiesMessages.js";
import reportsMessages from "../../common/reports/reportsMessages";
import siteControlMessages from "../../common/siteControl/siteControlMessages.js";

const containerRoutes = [
	{ path: '/main/companyContainerPage/:selectedCompanyId/home', component: CompanyHomePage }, // TODO
	{ path: '/main/companyContainerPage/:selectedCompanyId/:contentType?/:section?/:reportId?', component: CompanyContainerPage },
	{ path: '/main/projectContainerPage/:selectedProjectId/:contentType/:section?/:reportId?', component: ProjectContainerPage },
];

const headerMainRoutes = [
  ///////////////////////////////////////
  ////                               ////
  ////     COMPANY CONTEXT ROUTES    ////
  ////                               ////
  ///////////////////////////////////////
  {
    path: "/main/companyContainerPage/:selectedCompanyId/home",
    name: companiesMessages.companiesHome,
    component: CompanyContainerPage,
    contentType: 'settings',
    hideCondition: { key: 'scope', value: 'project' }
  },
  {
    path: "/main/companyContainerPage/:selectedCompanyId/:contentType/:section/:reportId",
    name: companiesMessages.companiesSettings,
    component: CompanyContainerPage,
    contentType: 'settings',
    hideCondition: { key: 'scope', value: 'project' },
    checkPermissions: ['companySettings', 'read'],
  },
  {
    path: "/main/companyContainerPage/:selectedCompanyId/:contentType/:section/:reportId",
    name: projectsMessages.dashboard,
    component: CompanyContainerPage,
    contentType: 'qa',
    hideCondition: { key: 'scope', value: 'project' }
  },
  {
    path: "/main/companyContainerPage/:selectedCompanyId/:contentType/:section/:reportId",
    name: safetyMessages.safetyTitle,
    component: CompanyContainerPage,
    contentType: 'safety',
    hideCondition: { key: 'scope', value: 'project' }
  },

  ///////////////////////////////////////
  ////                               ////
  ////    PROJECT CONTEXT ROUTES     ////   
  ////                               ////   
  ///////////////////////////////////////
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: safetyMessages.safetyTitle,
    component: ProjectContainerPage,
    contentType: 'safety',
    hideCondition: { key: 'scope', value: 'company' }
  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/siteControl/analytics/employees",
    name: siteControlMessages.general.hr,
    component: ProjectContainerPage,
    contentType: 'siteControl',
    hideCondition: { key: 'scope', value: 'company' }
  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: reportsMessages.reportTitles.dailyReport,
    component: ProjectContainerPage,
    contentType: 'forms',
    hideCondition: { key: 'scope', value: 'company' }
  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: projectsMessages.dashboard,
    component: ProjectContainerPage,
    contentType: 'qa',
    hideCondition: { key: 'scope', value: 'company' }
  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: issuesMessages.title,
    component: ProjectContainerPage,
    sidebar: 'building',
    contentType: 'issues',
    hideCondition: { key: 'scope', value: 'company' }

  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: postsMessages.title,
    component: ProjectContainerPage,
    sidebar: 'building',
    contentType: 'records',
    hideCondition: { key: 'scope', value: 'company' }

  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/:section/:reportId",
    name: systemMessages.specs,
    component: ProjectContainerPage,
    sidebar: 'building',
    contentType: 'info',
    hideCondition: { key: 'scope', value: 'company' }

  },
  {
    path: "/main/projectContainerPage/:selectedProjectId/:contentType/locationContainerPage",
    name: drawingsMessages.title,
    component: ProjectContainerPage,
    sidebar: 'building',
    contentType: 'drawings',
    checkPermissions: ['drawings', 'read'],
    hideCondition: { key: 'scope', value: 'company' },
    hide: true
  }
];

export { containerRoutes, headerMainRoutes };