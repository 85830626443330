import { useCallback, useContext } from 'react';
import { Menu, Item, useContextMenu } from 'react-contexify';
// import 'react-contexify/ReactContexify.css';
import 'react-contexify/dist/ReactContexify.css';
import { ObjectsWrapperManipulationContext } from '../../../../ObjectsWrapper';

function Filter({ columnId, className = '' }) {
  const { setSortBy, setGroupBy } = useContext(ObjectsWrapperManipulationContext);

  const MENU_ID = `table-filter-menu-${columnId}`;

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event) {
    show({
      event,
    });
  }

  const handleItemClick = useCallback(
    ({ id }) => {
      console.log({
        handleItemClick: {
          id,
          columnId,
        },
      });
      switch (id) {
        case 'sort_asc':
          setSortBy({ column: columnId, direction: 'asc' });
          break;
        case 'sort_desc':
          setSortBy({ column: columnId, direction: 'desc' });
          break;
        case 'group_by_primary':
          setGroupBy(([, secondary]) => [columnId, secondary]);
          break;
        case 'group_by_secondary':
          setGroupBy(([primary]) => [primary, columnId]);
          break;
      }
    },
    [columnId, setSortBy, setGroupBy]
  );

  return (
    <div className={`cursor-pointer ${className}`}>
      <p onClick={handleContextMenu}>...</p>
      <Menu id={MENU_ID} className='contextMenu'>
        <Item id='sort_asc' onClick={handleItemClick}>
          Sort ASC
        </Item>
        <Item id='sort_desc' onClick={handleItemClick}>
          Sort DESC
        </Item>
        <Item id='group_by_primary' onClick={handleItemClick}>
          Group by as primary
        </Item>
        <Item id='group_by_secondary' onClick={handleItemClick}>
          Group by as secondary
        </Item>
      </Menu>
    </div>
  );
}

export default Filter;
