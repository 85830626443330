import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import Avatar from './Avatar';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies';
import _ from 'lodash';
import { getCompaniesById } from '../../../common/companies/funcs';

const CompanyAvatar = (props) => {
  const { companyId, avatarSize, shadow, companies } = props;
  const [logo, setLogo] = useState(null);
  const minSize = 25;

  useEffect(() => {
    if (logo) return;

    if (companyId) {
      const company = companies.getNested([companyId]);

      if (_.size(companies) && !company) {
        getCompaniesById({ [companyId]: companyId });
      } else if (company) {
        const newLogo = companies.getNested([companyId, 'logo']);
        setLogo(newLogo);
      }
    }
  }, [companyId, companies]);

  return (
    <div style={{ width: avatarSize, minWidth: minSize, maxWidth: 50 }}>
      <Avatar src={logo} type={'company'} shadow={shadow} />
    </div>
  );
};

const enhance = compose(CompaniesHOC);
export default enhance(CompanyAvatar);
