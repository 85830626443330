export const COMPANY_TYPES_GC = 'GC'; // GeneralContractor
export const COMPANY_TYPES_SUB = 'SUB'; // SubContractor
export const COMPANY_TYPES_DEV = 'DEV'; // Develepper
export const COMPANY_TYPES_CONS = 'CONS'; // Consultant
export const COMPANY_TYPES_PROP = 'PROP'; // PropertyManagement
export const COMPANY_TYPES_SUPPLIER = 'SUPPLIER';
export const COMPANY_TYPES_SUPERVISION = 'SUPERVISION';

export const unknownCompanyId = 'unknownCompanyId';

export const getAllCompanyTypes = () => {
  return [
    COMPANY_TYPES_GC,
    COMPANY_TYPES_SUB,
    COMPANY_TYPES_DEV,
    COMPANY_TYPES_CONS,
    COMPANY_TYPES_PROP,
    COMPANY_TYPES_SUPPLIER,
    COMPANY_TYPES_SUPERVISION,
  ];
};
