import React from "react";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { connect } from "react-redux";

import { ProjectContext } from "../../../common/projects/contexts";
import { DashboardContext } from "../../../common/analytics/contexts";
import theme from "../../assets/css/theme";
import defaultProject from "../../assets/img/defaultProject.png";
import moment from "moment";
import { injectIntl } from "react-intl";

import { getDashboardData } from "../../../common/analytics/funcs";
import { getChecklistColorByStatus } from "../../../common/checklistItemsInstances/clItemInstanceStatus";
import { getPostColorByStatus } from "../../../common/issues/issueStates";

import systemMessages from "../../../common/app/systemMessages";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import safetyMessages from "../../../common/safety/safetyMessages";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import issuesMessages from "../../../common/issues/issuesMessages";

import Text from "../../components/CementoComponents/Text";
import SplitViewPage from "../../layouts/SplitViewPage";
import Select from "react-select";
import Chart, { Doughnut, Line, Bar } from "react-chartjs-2";
import { FadeLoader } from "react-spinners";
import _ from "lodash";
import withRouterHOC from "../../components/Router/util/withRouterHOC";

const HorizontalBar = (props) => <Chart {...props} type="horizontalBar" />;

const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;
const millisecondsToDays = (millisec) =>
  Math.round(millisec / 24 / 60 / 60 / 1000);
const DEFAULT_LIMIT = 5;

class MultiProjectWidgetWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { projectIds, api, showRangeDatePicker, children, userProjects } =
      this.props;
    const { maxValue } = this.state;

    let ret = [];
    //let DS = DashboardContext.Consumer;

    return (projectIds || []).map((projectId) => (
      <div
        key={projectId + "MultiProjectWidgetWrapper"}
        style={{ display: "flex", flex: 1, flexDirection: "row" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingBottom: theme.margin,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: theme.margin,
              paddingBottom: theme.verticalMargin,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "bottom",
                display: "flex",
                color: theme.brandPrimary,
                fontSize: theme.fontSizeH5,
                fontWeight: 600,
              }}
            >
              <div
                style={{
                  display: "flex",
                  /*alignItems: 'center',*/ height: "inherit",
                  width: 130,
                  overflow: "hidden",
                }}
              >
                <img
                  type={"project"}
                  style={{
                    objectPosition: "top",
                    objectFit: "cover",
                    height: "inherit",
                    width: 130,
                  }}
                  src={userProjects.getNested(
                    [projectId, "images", "main"],
                    defaultProject
                  )}
                />
              </div>
              <Text
                style={{
                  marginLeft: theme.margin,
                  marginRight: theme.margin,
                  alignSelf: "flex-end",
                }}
              >
                {userProjects.getNested([projectId, "title"])}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {(children || []).map((currChildren) =>
              Object.assign({}, currChildren, {
                props: Object.assign({}, currChildren.props, {
                  projectId: projectId,
                  multiProjectViewMode: false,
                  key: currChildren.props.key + "_" + projectId,
                }),
              })
            )}
          </div>
        </div>
      </div>
    ));
  }
}

class MultiWidgetWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMaxValFound = (maxValue, projectId, projectIds) => {
    return null;
    //this.maxValue = Math.max(this.maxValue, maxValue);
    if (!this.maxValues) this.maxValues = {};

    if (maxValue == this.maxValues[projectId]) return;

    this.maxValues[projectId] = maxValue;
    if (
      Object.keys(this.maxValues).length == Object.keys(projectIds || {}).length
    )
      this.setState({
        maxValue: Object.values(this.maxValues).reduce((a, b) =>
          Math.max(a, b)
        ),
      });
  };

  render() {
    const { title, api, showRangeDatePicker } = this.props;
    const { maxValue } = this.state;

    let ret = [];
    //let projectsList = props.projectIds || [props.selectedProjectId];
    //let DS = DashboardContext.Consumer;

    return (
      <DashboardContext.Consumer>
        {(DSprops) =>
          (DSprops.projectIds || []).length > 1 ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                paddingBottom: theme.margin,
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: theme.margin,
                  paddingBottom: theme.verticalMargin,
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    color: theme.brandPrimary,
                    fontSize: theme.fontSizeH5,
                    fontWeight: 600,
                  }}
                >
                  {title}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {(DSprops.projectIds || []).map((currProjectId) => (
                  <WidgetWrapper
                    {...this.props}
                    maxValue={maxValue}
                    onMaxValFound={(currMaxValue) =>
                      this.onMaxValFound(
                        currMaxValue,
                        currProjectId,
                        DSprops.projectIds
                      )
                    }
                    key={currProjectId + JSON.stringify(api)}
                    projectId={currProjectId}
                  />
                ))}
              </div>
            </div>
          ) : (
            (DSprops.projectIds || []).map((currProjectId) => (
              <WidgetWrapper
                {...this.props}
                key={currProjectId + JSON.stringify(api)}
                projectId={currProjectId}
              />
            ))
          )
        }
      </DashboardContext.Consumer>
    );
  }
}

MultiWidgetWrapper.defaultProps = {
  displayValue: "total",
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.getExpirationLabels = this.getExpirationLabels.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.recalcHeader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.getNested(["configurations", "features"]) !=
      nextProps.getNested(["configurations", "features"])
    )
      this.recalcHeader(nextProps);
  }

  recalcHeader(props) {
    const { setHeaderParams, project, contentType, configurations } =
      props || this.props;
      
    if (setHeaderParams) {
      let featureName = null;
      if (contentType == "safety") featureName = "safety";
      else if (contentType == "info") featureName = "spec";

      if (
        featureName != null &&
        !configurations.getNested(["features", featureName, "isActive"], false)
      )
        setHeaderParams({
          headerComponent: null,
          sideBarParams: { hidden: true },
        });
      else
        setHeaderParams({
          headerComponent: null,
          sideBarParams: { open: true, alwaysOpen: true },
        });
    }
  }

  getExpirationLabels() {
    const { intl } = this.props;
    let ret = {};

    ["ok", "warning_3monthes", "warning", "expired", "total"].forEach((key) => {
      ret[key] = { title: intl.formatMessage(safetyMessages.expirations[key]) };
    }); // TODO: intl
    return ret;
  }

  getMembersNamesByIds(ids, projectMembers) {
    let ownersNames = {};
    if (projectMembers)
      ids.forEach((ownerId) => {
        let ownerName = projectMembers.getNested([ownerId, "displayName"]);
        ownersNames[ownerId] = { title: ownerName };
      });
    return ownersNames;
  }

  render() {
    const {
      trades,
      contentType,
      selectedProjectId,
      multiProjectViewMode,
      members,
      rtl,
      intl,
      projectMembers,
      userProjects,
      configurations,
    } = this.props;

    let sideBarWidth = theme.sidebarWidth;
    let widgetInARow = Math.min(
      4,
      Math.max(
        2,
        Math.floor(
          (window.innerWidth - sideBarWidth) / (cardWidth + theme.margin * 2)
        )
      )
    );

    let widgetsContent = null;

    if (
      contentType == "safety" &&
      !configurations.getNested(["features", "safety", "isActive"], false)
    )
      return (
        <div
          style={{
            justifyContent: "start",
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "column", margin: theme.margin * 3 }}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: theme.fontSizeH3,
                color: theme.brandPrimary,
              }}
            >
              {safetyMessages.noReport.title}
            </Text>
            <br />
            <Text style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
              {safetyMessages.noReport.content}
            </Text>
          </div>
        </div>
      );

    //let multiProjectViewMode = true ;
    let projectIds = multiProjectViewMode
      ? Object.keys(userProjects.toObject()).slice(0, 3) //[Object.keys(userProjects.toObject())[6],Object.keys(userProjects.toObject())[1]]
      : [selectedProjectId];

    if (multiProjectViewMode) {
      projectIds = [
        "-NWfN0m58AGHBsJc5cvj",
        "-NRSe5LSeC7chzqBhzhf",
      ];
    }

    if (multiProjectViewMode) {
      widgetsContent = (
        <MultiProjectWidgetWrapper
          userProjects={userProjects}
          projectIds={projectIds}
        >
          <WidgetWrapper
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                subjectType: "employees",
                groupBy: ["trades"],
                condition: { universalId: "hightCertification" },
              },
            }}
            ratio={1}
            limit={20}
            type={"stackedBar"}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.certificatedExpiration
            )}
            // title={'תוקף הסמכת גובה לפי תפקיד'}
            keepOneLine={true}
            valType={"expiration"}
            postProcessing={(apiResult) => {
              apiResult.data = Object.keys(apiResult.data || {}).reduce((acc, tradeId) => {
                const statuses = Object.keys(apiResult.data[tradeId].total);

                statuses.forEach((status) => {
                  if (!acc[status]) acc[status] = {};
                  acc[status][tradeId] = {
                    total: apiResult.data[tradeId].total[status],
                  };
                });
                return acc;
              }, {});
      
              return apiResult;
            }}
          />

          <WidgetWrapper
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                groupBy: ["types"],
                subjectType: "equipment",
                primaryUniversalId: "review",
              },
            }}
            ignoreTotal={true} // TODO: Remove this
            type={"doughnut"}
            valType={"expiration"}
            postProcessing={(apiResult) => {
              apiResult.groupByMetaData = [this.getExpirationLabels()];
              if (apiResult && apiResult.data) {
                apiResult.data = apiResult.data.primary;

                Object.keys(apiResult.data || {}).forEach((currGroupKey) => {
                  apiResult.data[currGroupKey] = {
                    total: apiResult.data[currGroupKey],
                  };
                });

                if (apiResult.data && apiResult.data.total)
                  delete apiResult.data["total"];
              }
              return apiResult;
            }}
            ratio={1}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles
                .equipmentCertificatedExpiration
            )}
            //title={'תוקף תסקירי ציוד'}
          />

          <WidgetWrapper
            api={{
              type: "timeline",
              name: "postsTimeline",
              params: {
                period: ["Monthly"],
                condition: { trade: "1038" },
              },
            }}
            type={"line"}
            valType={"issue"}
            ratio={2}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.history
            )}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.date,
              y: analyticsMessages.dashboards.axesLabels.safetyIssues,
            }}
            //title={'היסטוריה'}
          />
        </MultiProjectWidgetWrapper>
      );
    } else if (contentType == "qa") {
      widgetsContent = [
        <MultiWidgetWrapper
          key={"checklistsActivity"}
          api={{
            type: "history",
            name: "checklistsActivity",
            params: {
              groupBy: ["status", "trade"],
              count: 5,
              condition: { trade: { $ne: "1038" } },
            },
          }}
          type={"stackedBar"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.confirmedChecklistByTrade
          )}
          //title={'בדיקות לפי מקצוע'}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.trade,
            y: analyticsMessages.dashboards.axesLabels.checklistsItems,
          }}
          keepOneLine={true}
          valType={"checklist"}
          postProcessing={(apiResult) => {
            let newApiResult = {};
            if (apiResult)
              newApiResult = Object.assign({}, apiResult, {
                groupByMetaData: [
                  apiResult.groupByMetaData[1],
                  apiResult.groupByMetaData[0],
                ],
              });

            if (apiResult && apiResult.data && apiResult.data["99"]) {
              Object.keys(apiResult.data || {}).forEach((k) => {
                apiResult = apiResult.setNested(
                  ["data", k],
                  apiResult.data.getNested(["100", k], 0) +
                    apiResult.data["99"][k]
                );
              });
            }
            // let newApiResult = { data: {'100':{}, '200':{}}, groupByMetaData: [apiResult.groupByMetaData[0], {'100': {}, '200': {}} ]};
            // Object.keys(apiResult.data || {}).forEach(tradeId => {
            //   newApiResult.data['100'][tradeId] = {total: apiResult.data[tradeId].total - apiResult.data[tradeId].rejected};
            //   newApiResult.data['200'][tradeId] = {total: apiResult.data[tradeId].rejected};
            // });

            return newApiResult;
          }}
        />,

        <MultiWidgetWrapper
          key={'snapshot_checklistsSnapshot_waitingForFix'}
          api={{
            type: "snapshot",
            name: "checklistsSnapshot",
            params: {
              groupBy: ["trade"],
              condition: { status: 200, trade: { $ne: "1038" } },
            },
          }}
          colors={[theme.brandDanger, theme.brandWarning, theme.brandSuccess]}
          valType={"trades"}
          type={"doughnut"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.waitingForFix
          )}
          toolTipText={analyticsMessages.dashboards.tooltips.checklistsItems}
          //title={'בדיקות שנכשלו וממתינות לתיקון'}
        />,

        <MultiWidgetWrapper
          key={"checklistsFlowtimeAVG"}
          api={{
            type: "flow",
            name: "checklistItemsFlowtimeAVG",
            params: {
              statusFlow: [200, 100],
              groupBy: ["trade"],
            },
          }}
          postProcessing={(apiResult) => {
            if (apiResult && apiResult && apiResult.data)
              Object.values(apiResult.data).forEach((currVal) => {
                if (currVal.total > 0)
                  currVal.total = millisecondsToDays(currVal.total);
              });

            return apiResult;
          }}
          type={"bar"}
          valType={"trades"}
          horizontal={true}
          limit={5}
          ratio={1}
          showTrade={true}
          ignoreTotalLabel={true}
          //colorsSource={'trade'}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.avarageTimeForFix
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.trade,
            y: analyticsMessages.dashboards.axesLabels.days,
          }}
          toolTipText={analyticsMessages.dashboards.tooltips.days}
          //title={'זמן ממוצע לאישור בדיקות שנכשלו'}
        />,

        // <MultiWidgetWrapper
        //   api={{
        //     "type":"history",
        //     "name":"checklistsActivity",
        //     "params" : {
        //       "groupBy": ["status","user"],
        //       "condition": {"trade": {'$ne':'1038'}}
        //     }
        //   }}
        //   postProcessing={(apiResult) => {
        //     if (apiResult && apiResult.groupByMetaData && apiResult.groupByMetaData.length > 1) {
        //       let newGroupByMetadata = [apiResult.groupByMetaData[1], apiResult.groupByMetaData[0]];

        //       apiResult.groupByMetaData = newGroupByMetadata;
        //     }
        //       // Add name to each owner
        //     if (members && apiResult && apiResult.groupByMetaData && apiResult.groupByMetaData.length > 0) {
        //       let ownersNames = this.getMembersNamesByIds(Object.keys(apiResult.groupByMetaData[0]), members);
        //       apiResult.groupByMetaData[0] = ownersNames;
        //     }

        //     return apiResult;
        //   }}

        //   requiredParams={[members]}
        //   horizontal={true}
        //   backgroundColor={theme.bransSuccessDark}
        //   colorsShadesArray={true}

        //   type={'stackedBar'}
        //   multiColumn={true}
        //   valType={'checklist'}
        //   filterStatus={{'100':true, '200': true}}

        //   title={intl.formatMessage(analyticsMessages.dashboards.titles.mostActiveChecklistReporter)}
        //   axesLabels={{x:analyticsMessages.dashboards.axesLabels.employeeName, y:analyticsMessages.dashboards.axesLabels.checklistsItems}}
        //   //toolTipText={analyticsMessages.dashboards.tooltips.checklistsItems}
        //   //title={'עובדים שביצעו הכי הרבה בדיקות'}
        // />,
        <MultiWidgetWrapper
          key={"checklistsGrade"}
          api={{
            type: "history",
            name: "checklistsGrade",
            params: {
              groupBy: ["user"],
            },
          }}
          postProcessing={(apiResult = {}) => {
            let data = {};
            if (apiResult == null) apiResult = {};

            Object.keys(apiResult.data || {}).forEach((id) => {
              let curr = apiResult.data[id].total;
              if (curr && curr.current != null)
                data[id] = { total: curr.current };
            });

            apiResult.data = data;
            apiResult.groupByMetaData.push({
              current: {
                title: intl.formatMessage(
                  analyticsMessages.dashboards.axesLabels.checklistNumber
                ),
              },
            });
            return apiResult;
          }}
          backgroundColor={theme.bransSuccessDark}
          colorsShadesArray={true}
          ratio={1}
          horizontal={true}
          type={"bar"}
          tooltipCallback={(tooltipItem, data) => {
            let val =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return intl.formatMessage(
              analyticsMessages.dashboards.tooltips.checklistNumber,
              {
                val: Number(val)
                  .toFixed(Number(val) < 1 ? 1 : 0)
                  .toString(),
              }
            );
          }}
          keepOneLine={true}
          filterStatus={{ current: true }}
          limit={10}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.mostActiveChecklistReporter
          )}
          axesLabels={{
            y: analyticsMessages.dashboards.axesLabels.checklistNumber,
          }}
          //title={'עובדים שביצעו הכי הרבה בדיקות'}
        />,
        <MultiWidgetWrapper
          key={"topRejectedChecklists"}
          api={{
            type: "history",
            name: "topRejectedChecklists",
            params: {
              condition: {
                trade: { $ne: "1038" },
              },
              groupBy: ["checklistItem"],
              count: 6,
            },
          }}
          type={"bar"}
          //displayValue={'rejected'}
          horizontal={true}
          limit={6}
          ratio={2}
          showParentsTitle={true}
          showTrade={true}
          backgroundColor={theme.brandDanger}
          minXAxesValue={3}
          //percentagesTooltips={true}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.highFreqRejection
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.checklistItemName,
            y: analyticsMessages.dashboards.axesLabels
              .numOfRejectedChecklistItems,
          }}
          displayValue={"rejected"}
          //title={'בדיקות שנכשלות בתדירות גבוהה'}
        />,

        <MultiWidgetWrapper
          key={"checklistsTimeline"}
          api={{
            type: "timeline",
            name: "checklistsTimeline",
            params: {
              period: "Weekly",
              condition: { status: [100, 200], trade: { $ne: "1038" } },
            },
          }}
          valType={"checklistStatus"}
          type={"line"}
          ratio={widgetInARow == 4 ? 2 : widgetInARow}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.checklistHistory
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.date,
            y: analyticsMessages.dashboards.axesLabels.checklistsItems,
          }}
          //title={'היסטורית בדיקות'}
        />,
      ];

      if (configurations.getNested(["dashboards", "qc", "grades"]))
        widgetsContent = widgetsContent.concat([
          <MultiWidgetWrapper
            key={"checklistsGrade"}
            hideZeroValue={false}
            api={{
              type: "history",
              name: "checklistsGrade",
              params: {
                groupBy: ["building"],
                onlyIncludeInGrade: true,
              },
            }}
            postProcessing={(apiResult = {}) => {
              let data = { current: {}, minimal: {} };
              if (apiResult == null) apiResult = {};
              Object.keys(apiResult.data || {})
                .sort()
                .forEach((id) => {
                  let curr = apiResult.data[id].total;
                  if (curr) {
                    if (curr.current != null)
                      data["current"][id] = {
                        total: curr.current - (curr.minimal || 0),
                      };
                    if (curr.minimal != null)
                      data["minimal"][id] = { total: curr.minimal };
                  }
                });

              apiResult.data = data;
              apiResult.groupByMetaData.push({
                minimal: {
                  title: intl.formatMessage(
                    analyticsMessages.dashboards.tooltips.minmumGradeLegand
                  ),
                },
                current: {
                  title: intl.formatMessage(
                    analyticsMessages.dashboards.tooltips.finalGradeLegand
                  ),
                },
              });
              return apiResult;
            }}
            horizontal={false}
            type={"stackedBar"}
            valType={"minGrades"}
            keepOneLine={true}
            sortFunc={(a, b) => a.localeCompare(b)}
            tooltipCallback={(tooltipItem, data) => {
              let val =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (tooltipItem.datasetIndex == 1)
                val =
                  data.datasets[0].data[tooltipItem.index] +
                  data.datasets[1].data[tooltipItem.index];
              return intl.formatMessage(
                analyticsMessages.dashboards.tooltips[
                  tooltipItem.datasetIndex ? "finalGrade" : "minmumGrade"
                ],
                {
                  val: Number(val).toFixed(2).toString(),
                }
              );
            }}
            limit={300}
            hideLabelColorBox={true}
            reverseTooltipItemSort={true}
            filterStatus={{ current: true, minimal: true }}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.gradesByBuilding
            )}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.building,
              y: analyticsMessages.dashboards.axesLabels.grade,
            }}
            //title={'ציון פרויקטאלי - לפי בניין'}
          />,

          <MultiWidgetWrapper
            key={"checklistsGrade"}
            api={{
              type: "history",
              name: "checklistsGrade",
              params: {
                groupBy: ["trade"],
                onlyIncludeInGrade: true,
              },
            }}
            postProcessing={(apiResult) => {
              let data = { current: {}, minimal: {} };
              if (apiResult == null) apiResult = {};
              Object.keys(apiResult.data || {}).forEach((id) => {
                let curr = apiResult.data[id].total;
                if (curr) {
                  if (curr.current != null)
                    data["current"][id] = {
                      total: curr.current - (curr.minimal || 0),
                    };
                  if (curr.minimal != null)
                    data["minimal"][id] = { total: curr.minimal };
                }
              });

              apiResult.data = data;
              apiResult.groupByMetaData.push({
                minimal: {
                  title: intl.formatMessage(
                    analyticsMessages.dashboards.tooltips.minmumGradeLegand
                  ),
                },
                current: {
                  title: intl.formatMessage(
                    analyticsMessages.dashboards.tooltips.finalGradeLegand
                  ),
                },
              });
              return apiResult;
            }}
            ratio={widgetInARow == 4 ? 3 : 2}
            horizontal={false}
            type={"stackedBar"}
            valType={"minGrades"}
            tooltipCallback={(tooltipItem, data) => {
              let val =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (tooltipItem.datasetIndex == 1)
                val =
                  data.datasets[0].data[tooltipItem.index] +
                  data.datasets[1].data[tooltipItem.index];
              return intl.formatMessage(
                analyticsMessages.dashboards.tooltips[
                  tooltipItem.datasetIndex ? "finalGrade" : "minmumGrade"
                ],
                {
                  val: Number(val).toFixed(2).toString(),
                }
              );
            }}
            sortFunc={(aTradeId, bTradeId) => {
              let aTrade =
                aTradeId &&
                trades.get(aTradeId.toString()) &&
                trades.get(aTradeId.toString()).getCementoTitle();
              let bTrade =
                bTradeId &&
                trades.get(bTradeId.toString()) &&
                trades.get(bTradeId.toString()).getCementoTitle();

              return (aTrade || "").localeCompare(bTrade || "");
            }}
            keepOneLine={true}
            filterStatus={{ current: true, minimal: true }}
            limit={300}
            hideLabelColorBox={true}
            reverseTooltipItemSort={true}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.gradesByTrade
            )}
            axesLabels={{ y: analyticsMessages.dashboards.axesLabels.grade }}
            //title={'ציון פרויקטאלי - לפי מלאכה'}
          />,
        ]);

      if (widgetInARow < 3)
        [widgetsContent[2], widgetsContent[3]] = [
          widgetsContent[3],
          widgetsContent[2],
        ];
    } else if (contentType == "issues") {
      widgetsContent = [
        <MultiWidgetWrapper
          key={"issuesActivity"}
          api={{
            type: "snapshot",
            name: "postsSnapshot",
            params: {
              condition: {
                isIssue: true,
                trade: { $ne: "1038" },
              },
              groupBy: ["issueState", "trade"],
            },
          }}
          type={"stackedBar"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.tasksByTrade
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.trade,
            y: analyticsMessages.dashboards.axesLabels.tasks,
          }}
          keepOneLine={true}
          hidden={{ 100: true }}
          horizontal={true}
          limit={14}
          postProcessing={(apiResult) => {
            if (
              apiResult &&
              apiResult.groupByMetaData &&
              apiResult.groupByMetaData.length > 1
            ) {
              let newGroupByMetadata = [
                apiResult.groupByMetaData[1],
                apiResult.groupByMetaData[0],
              ];
              apiResult.groupByMetaData = newGroupByMetadata;
            }
            return apiResult;
          }}
        />,

        <MultiWidgetWrapper
          key={"postsActivity"}
          api={{
            type: "history",
            name: "postsActivity",
            params: {
              condition: {
                isIssue: true,
                trade: { $ne: "1038" },
              },
              groupBy: ["issueState", "user"],
            },
          }}
          postProcessing={(apiResult) => {
            if (
              apiResult &&
              apiResult.groupByMetaData &&
              apiResult.groupByMetaData.length > 1
            ) {
              let newGroupByMetadata = [
                apiResult.groupByMetaData[1],
                apiResult.groupByMetaData[0],
              ];

              apiResult.groupByMetaData = newGroupByMetadata;
            }
            // Add name to each owner
            if (
              members &&
              apiResult &&
              apiResult.groupByMetaData &&
              apiResult.groupByMetaData.length > 0
            ) {
              let ownersNames = this.getMembersNamesByIds(
                Object.keys(apiResult.groupByMetaData[0]),
                members
              );
              apiResult.groupByMetaData[0] = ownersNames;
            }
            return apiResult;
          }}
          requiredParams={[projectMembers]}
          horizontal={true}
          backgroundColor={theme.bransSuccessDark}
          colorsShadesArray={true}
          type={"stackedBar"}
          multiColumn={true}
          filterStatus={{ 100: true, 300: true }}
          pastSentance={true}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.mostActiveIssues
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.employeeName,
            y: analyticsMessages.dashboards.axesLabels.tasks,
          }}
          //toolTipText={analyticsMessages.dashboards.tooltips.checklistsItems}
          //title={'העובדים הפעילים ביותר'}
        />,

        <MultiWidgetWrapper
          key={"postsFlowtimeAVG"}
          api={{
            type: "flow",
            name: "postsFlowtimeAVG",
            params: {
              condition: {
                trade: { $ne: "1038" },
              },
              statusFlow: [300, 100],
              groupBy: ["trade"],
            },
          }}
          postProcessing={(apiResult) => {
            if (apiResult && apiResult.data)
              Object.values(apiResult.data).forEach((currVal) => {
                if (currVal.total > 0)
                  currVal.total = millisecondsToDays(currVal.total);
              });

            return apiResult;
          }}
          type={"bar"}
          horizontal={true}
          withTotalAVG={true}
          limit={5}
          valType={"trades"}
          toolTipText={analyticsMessages.dashboards.tooltips.days}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.avarageTimeToCloseTask
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.trade,
            y: analyticsMessages.dashboards.axesLabels.days,
          }}
          //title={'זמן ממוצע לסגירת משימה)'}
        />,

        <MultiWidgetWrapper
          key={"postsSnapshot"}
          //fetchApi={async () => getDashboardData(selectedProjectId, 'snapshot', 'topCountOfPosts', {"groupBy": ["source"], "condition": { "isIssue": (Boolean(contentType == "issues")) }})}
          api={{
            type: "snapshot",
            name: "topCountOfPosts",
            params: {
              groupBy: ["source"],
              condition: {
                trade: { $ne: "1038" },
                isIssue: Boolean(contentType == "issues"),
              },
            },
          }}
          colors={[theme.brandPrimary, theme.headerColorDark]}
          labels={{
            checklist: {
              title: intl.formatMessage(
                issuesMessages.source.checklistRejection
              ),
            },
            random: { title: intl.formatMessage(issuesMessages.source.random) },
          }}
          type={"doughnut"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.taskCreationSource
          )}
          //toolTipText={analyticsMessages.dashboards.tooltips.tasks}
          //title={'מקור המשימות שנפתחו'}
        />,
        <MultiWidgetWrapper
          key={"postsTimeline"}
          api={{
            type: "timeline",
            name: "postsTimeline",
            params: {
              period: "Weekly",
              condition: {
                trade: { $ne: "1038" },
                isIssue: true,
              },
            },
          }}
          type={"line"}
          valType={"issue"}
          ratio={widgetInARow == 4 ? 4 : 2}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.history
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.date,
            y: analyticsMessages.dashboards.axesLabels.checklistsItems,
          }}
          //title={'היסטוריה'}
        />,
      ];

      // if (widgetInARow < 3)
      //   [widgetsContent[2], widgetsContent[3]] = [widgetsContent[3], widgetsContent[2]];
    } else if (contentType == "records") {
      widgetsContent = [
        <MultiWidgetWrapper
          key={"recordsPostsActivity"}
          api={{
            type: "snapshot",
            name: "postsSnapshot",
            params: {
              condition: {
                isIssue: false,
                trade: { $ne: "1038" },
              },
              groupBy: ["trade"],
            },
          }}
          type={"bar"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.recordsByTrade
          )}
          //title={'תיעודים לפי מלאכה'}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.trade,
            y: analyticsMessages.dashboards.axesLabels.recordsAmount,
          }}
          valType={"trades"}
          keepOneLine={true}
          horizontal={true}
          limit={14}
          postProcessing={(apiResult) => {
            if (apiResult && apiResult.data) delete apiResult.data["undefined"];
            return apiResult;
          }}
        />,

        <MultiWidgetWrapper
          key={"recordsTopCountOfPosts"}
          //fetchApi={async () => getDashboardData(selectedProjectId, 'snapshot', 'topCountOfPosts', {"groupBy": ["source"], "condition": { "isIssue": (Boolean(contentType == "issues")) }})}
          api={{
            type: "snapshot",
            name: "topCountOfPosts",
            params: {
              groupBy: ["source"],
              condition: {
                trade: { $ne: "1038" },
                isIssue: Boolean(contentType == "issues"),
              },
            },
          }}
          colors={[theme.brandSuccess, theme.bransSuccessDark]}
          labels={{
            checklist: {
              title: intl.formatMessage(issuesMessages.source.checklist),
            },
            random: {
              title: intl.formatMessage(issuesMessages.source.randomRecords),
            },
          }}
          type={"doughnut"}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.recordsCreationSource
          )}
          //toolTipText={analyticsMessages.dashboards.tooltips.records}
          //title={'מקור התיעודים שנפתחו'}
        />,

        <MultiWidgetWrapper
          key={"recordsTopCountOfPosts1"}
          api={{
            type: "snapshot",
            name: "topCountOfPosts",
            params: {
              groupBy: ["owner"],
              condition: {
                trade: { $ne: "1038" },
                isIssue: Boolean(contentType == "issues"),
              },
            },
          }}
          postProcessing={(apiResult) => {
            // Add name to each owner
            if (members && apiResult && apiResult.data) {
              let ownersNames = this.getMembersNamesByIds(
                Object.keys(apiResult.data || {}),
                members
              );
              apiResult.groupByMetaData = [ownersNames];
            }
            return apiResult;
          }}
          requiredParams={[members]}
          horizontal={true}
          type={"bar"}
          backgroundColor={theme.bransSuccessDark}
          colorsShadesArray={true}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.mostActiveRecordsReporter
          )}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.employeeName,
            y: analyticsMessages.dashboards.axesLabels.recordsAmount,
          }}
          //title={'עובדים שיצרו הכי הרבה תיעודים'}
          toolTipText={analyticsMessages.dashboards.tooltips.records}
        />,
        <MultiWidgetWrapper
          key={"recordsPostsTimeline"}
          api={{
            type: "timeline",
            name: "postsTimeline",
            params: {
              period: ["Weekly"],
              condition: {
                trade: { $ne: "1038" },
                isIssue: false,
              },
            },
          }}
          postProcessing={(apiResult) => {
            if (apiResult) {
              delete apiResult.data["200"];
              delete apiResult.data["300"];
            }
            return apiResult;
          }}
          type={"line"}
          valType={"records"}
          ratio={widgetInARow}
          title={intl.formatMessage(
            analyticsMessages.dashboards.titles.history
          )}
          //title={'היסטוריה'}
          axesLabels={{
            x: analyticsMessages.dashboards.axesLabels.date,
            y: analyticsMessages.dashboards.axesLabels.checklistsItems,
          }}
        />,
      ];

      // if (widgetInARow < 3)
      //   [widgetsContent[2], widgetsContent[3]] = [widgetsContent[3], widgetsContent[2]];
    } else if (contentType == "safety") {
      widgetsContent = (
        <>
          <MultiWidgetWrapper
            key={"safetyGradesHistory1"}
            api={{
              type: "timeline",
              name: "safetyGradesHistory",
              // ,"params" : {
              //   "count": 7
              // }
            }}
            //showRangeDatePicker={true}
            type={"grade"}
            defaultRange={"fromLastWeek"}
            ratio={1}
            showZeroValues={true}
            //title={'ציון בטיחות'}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.safetyGrade
            )}
            // postProcessing={(apiResult) => {
            //   let newRet = {lables:[],data:[]};
            //   if (apiResult && apiResult.data) {
            //     let labels = [];
            //     Object.keys(apiResult.data || {}).sort().forEach(currGroupKey => {
            //       labels.push(currGroupKey);
            //       newRet.data.push(apiResult.data[currGroupKey]);
            //     })

            //     newRet.groupByMetaData = [labels];
            //   }
            //   return newRet;
            // }}
          />

          <MultiWidgetWrapper
            key={"safetyGradesHistory2"}
            api={{
              type: "timeline",
              name: "safetyGradesHistory",
            }}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.date,
              y: analyticsMessages.dashboards.axesLabels.grade,
            }}
            type={"gradesHistory"}
            toolTipText={analyticsMessages.dashboards.tooltips.safetyGrade}
            ratio={widgetInARow == 3 ? 2 : 2}
            showZeroValues={true}
            //title={'היסטורית ציוני בטיחות'}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.safetyGradeHistory
            )}
            postProcessing={(apiResult) => {
              let newRet = { lables: [], data: [] };
              if (apiResult && apiResult.data) {
                let labels = [];
                Object.keys(apiResult.data || {})
                  .sort()
                  .forEach((currGroupKey) => {
                    labels.push(currGroupKey);
                    newRet.data.push(apiResult.data[currGroupKey]);
                  });

                newRet.groupByMetaData = [labels];
              }
              return newRet;
            }}
          />

          <MultiWidgetWrapper
            key={"expirationSafetySnapshot"}
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                subjectType: "employees",
                groupBy: ["trades"],
              },
            }}
            ratio={1}
            limit={20}
            type={"stackedBar"}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.certificatedExpiration
            )}
            //title={'תוקף הסמכות לפי תפקיד'}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.title,
              y: analyticsMessages.dashboards.axesLabels.numberOfCertificates,
            }}
            keepOneLine={true}
            valType={"expiration"}
            postProcessing={(apiResult) => {
              if (!apiResult) return {};

              apiResult.data = Object.keys(apiResult.data || {}).reduce((acc, tradeId) => {
                const statuses = Object.keys(apiResult.data[tradeId].total);

                statuses.forEach((status) => {
                  if (!acc[status]) acc[status] = {};
                  acc[status][tradeId] = {
                    total: apiResult.data[tradeId].total[status],
                  };
                });
                return acc;
              }, {});
      
              return apiResult;
            }}
          />

          {/* <MultiWidgetWrapper
           //fetchApi={async () => getDashboardData(selectedProjectId, 'timeline', 'postsTimeline', {"period": "Monthly", "condition": { "trade": "1033" }})}
           api={{
             "type":"timeline",
             "name":"postsTimeline",
             "params" : {
               "period": ["Monthly"],
               "condition": { "trade": "1033" }
             }
           }}    
           type={'grade'} 
           title={intl.formatMessage(analyticsMessages.dashboards.titles.safetyGrade)}
           labels={["100", "200", "300"]}
           //data={Object.values(dashboardData.safetyGradeIssuesTimeline.data)}            
         /> 
       } */}

          <MultiWidgetWrapper
            key={"employeesExpirationSafetySnapshot"}
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                subjectType: "employees",
                groupBy: ["trades"],
                condition: { universalId: "siteTraining" },
              },
            }}
            ratio={1}
            limit={20}
            type={"stackedBar"}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.siteCertificatedExpiration
            )}
            //title={'תוקף הדרכת אתר לפי תפקיד'}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.title,
              y: analyticsMessages.dashboards.axesLabels.numberOfSiteTraining,
            }}
            keepOneLine={true}
            valType={"expiration"}
            postProcessing={(apiResult) => {
              if (!apiResult) return {};

              apiResult.data = Object.keys(apiResult.data || {}).reduce((acc, tradeId) => {
                const statuses = Object.keys(apiResult.data[tradeId].total);

                statuses.forEach((status) => {
                  if (!acc[status]) acc[status] = {};
                  acc[status][tradeId] = {
                    total: apiResult.data[tradeId].total[status],
                  };
                });
                return acc;
              }, {});
      
              return apiResult;
            }}
          />

          <MultiWidgetWrapper
            key={"employeesExpirationSafetySnapshot2"}
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                subjectType: "employees",
                groupBy: ["trades"],
                condition: { universalId: "hightCertification" },
              },
            }}
            ratio={1}
            limit={20}
            type={"stackedBar"}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.heightCertificatedExpiration
            )}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.title,
              y: analyticsMessages.dashboards.axesLabels
                .numberOfHeightCertificates,
            }}
            keepOneLine={true}
            valType={"expiration"}
            postProcessing={(apiResult) => {
              if (!apiResult) return {};
              
              apiResult.data = Object.keys(apiResult.data || {}).reduce((acc, tradeId) => {
                const statuses = Object.keys(apiResult.data[tradeId].total);

                statuses.forEach((status) => {
                  if (!acc[status]) acc[status] = {};
                  acc[status][tradeId] = {
                    total: apiResult.data[tradeId].total[status],
                  };
                });
                return acc;
              }, {});
      
              return apiResult;
            }}
          />

          <MultiWidgetWrapper
            key={"employeesExpirationSafetySnapshot3"}
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                groupBy: ["types"],
                subjectType: "employees",
              },
            }}
            postProcessing={(apiResult) => {
              if (apiResult == null) apiResult = {};
              if (apiResult && apiResult.data)
                Object.keys(apiResult.data || {}).forEach((currGroupKey) => {
                  apiResult.data[currGroupKey].total =
                    apiResult.data[currGroupKey]["ok"] || 0;
                });
              return apiResult;
            }}
            legend={{
              display: false,
            }}
            type={"doughnut"}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.validCertificatinEmpoylees
            )}
            toolTipText={
              analyticsMessages.dashboards.tooltips
                .employeesWithValidCertificate
            }
            limit={20}
            //title={'סוגי הסמכות - מספר עובדים פעילים עבור כל סוג הסמכה'}
          />

          <MultiWidgetWrapper
            key={"equipmentExpirationSafetySnapshot1"}
            api={{
              type: "snapshot",
              name: "expirationSafetySnapshot",
              params: {
                groupBy: ["types"],
                subjectType: "equipment",
              },
            }}
            ignoreTotal={true} // TODO: Remove this
            //type={'doughnut'}
            valType={"expiration"}
            type={"stackedBar"}
            postProcessing={(apiResult) => {
              if (!apiResult) return {};
              let newRet = { data: {} };
              newRet.labels = [this.getExpirationLabels()];

              Object.keys(newRet.labels[0]).forEach((status) => {
                newRet.data[status] = {};
                Object.keys(apiResult.data).forEach((x) => {
                  if (apiResult.data[x][status])
                    newRet.data = newRet.data.setNested([status, x], {
                      total: apiResult.data[x][status],
                    });
                });

                // GroupByMetadata - First row it key and title
                //newRet.groupByMetaData = [];
              });
              newRet.groupByMetaData = [
                apiResult.groupByMetaData[0],
                newRet.labels[0],
              ];
              // if (apiResult && apiResult.data) {
              //   apiResult.data = apiResult.data.primary;

              //   Object.keys(apiResult.data || {}).forEach(currGroupKey => {
              //     apiResult.data[currGroupKey] = {total:apiResult.data[currGroupKey]};
              //   })

              //   if (apiResult.data && apiResult.data.total)
              //     delete apiResult.data['total'];
              // }
              return newRet;
            }}
            limit={20}
            ratio={1}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles
                .equipmentCertificatedExpiration
            )}
            //title={'תוקף אישורי ציוד'}
          />

          <MultiWidgetWrapper
            key={"equipmentTypeCount"}          
            api={{
              type: "snapshot",
              name: "equipmentTypeCount",
            }}
            ignoreTotal={true} // TODO: Remove this
            type={"bar"}
            postProcessing={(apiResult) => {
              if (apiResult && apiResult.data) {
                Object.keys(apiResult.data || {}).forEach((currGroupKey) => {
                  apiResult.data[currGroupKey] = {
                    total: apiResult.data[currGroupKey],
                  };
                });

                if (apiResult.data && apiResult.data.total)
                  delete apiResult.data["total"];
              }
              return apiResult;
            }}
            ratio={1}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.equipmentByType
            )}
            axesLabels={{
              x: analyticsMessages.dashboards.axesLabels.itemType,
              y: analyticsMessages.dashboards.axesLabels.itemsAmount,
            }}
            //title={'סוגי פרטי ציוד'}
          />

          <MultiWidgetWrapper
            key={"safetyPostsTimeline"}          
            api={{
              type: "timeline",
              name: "postsTimeline",
              params: {
                period: ["Monthly"],
                condition: { trade: "1038", isIssue: true },
              },
            }}
            type={"line"}
            valType={"issue"}
            ratio={widgetInARow == 3 ? 3 : 2}
            title={intl.formatMessage(
              analyticsMessages.dashboards.titles.history
            )}

            //title={'היסטוריה'}
          />
        </>
      );
    }

    return (
      <SplitViewPage
        rtl={rtl}
        withHorizontalScroll={true}
        Main={
          <div
            style={{
              [rtl ? "right" : "left"]: sideBarWidth,
              position: "absolute",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flex: 3,
                flexShrink: 3,
                flexDirection: multiProjectViewMode ? "column" : "row",
                width:
                  cardWidth * widgetInARow + theme.margin * 2 * widgetInARow,
              }}
            >
              <DashboardContext.Provider value={{ projectIds }}>
                {widgetsContent}
              </DashboardContext.Provider>
            </div>
          </div>
        }
      />
    );
  }
}

const enhance = compose(
  injectIntl,
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    headerTitle: state.ui.headerTitle,
    userProjects: state.projects.map,
    members: state.members.map,
    rtl: state.app.rtl,
  }))
);

export default enhance(Dashboard);

const innerItemMargin = 100;
const cardHeight = 379;
const cardWidth = 343;

const rangeSupportedWidget = {
  postsActivity: true,
  postsTimeline: true,
  safetyGradesHistory: true,
  grade: true,
  checklistsTimeline: true,
  checklistItemsFlowtimeAVG: true,
  postsFlowtimeAVG: true,
  topRejectedChecklists: true,
  checklistItemsFlowtimeAVG: true,
  topCountOfPosts: true,
  checklistsActivity: true,
  checklistsGrade: true,
};
class WidgetWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.fetchResult = this.fetchResult.bind(this);
    this.afterfetchResult = this.afterfetchResult.bind(this);

    const { intl } = props;
    let startOfProj = {
      id: "startOfProject",
      label: intl.formatMessage(
        analyticsMessages.dashboards.timeSelection.startOfProject
      ),
      avarageLabel: intl.formatMessage(
        analyticsMessages.dashboards.timeSelection.avarage.startOfProject
      ),
      startTS: 0,
      value: 0,
    };
    this.timingOptions = [
      {
        id: "fromLastWeek",
        label: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.fromLastWeek
        ),
        avarageLabel: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.avarage.fromLastWeek
        ),
        value: 1,
        startTS: new Date().setHours(0, 0, 0, 0) - daysToMilliseconds(7),
        period: "Daily",
      },
      {
        id: "currMonth",
        label: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.currMonth
        ),
        avarageLabel: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.avarage.currMonth
        ),
        value: 2,
        ...this.getCurrMonth(),
      },
      {
        id: "lastMonth",
        label: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.lastMonth
        ),
        avarageLabel: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.avarage.lastMonth
        ),
        value: 3,
        ...this.getLastMonth(),
      },
      {
        id: "fromLastQuarter",
        label: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.fromLastQuarter
        ),
        avarageLabel: intl.formatMessage(
          analyticsMessages.dashboards.timeSelection.avarage.fromLastQuarter
        ),
        value: 4,
        startTS: moment().startOf('quarter').valueOf(),
      },
      startOfProj,
    ];

    let range = startOfProj;
    if (
      props.defaultRange &&
      this.timingOptions.filter((x) => x.id == props.defaultRange).length
    )
      range = this.timingOptions.filter((x) => x.id == props.defaultRange)[0];
    this.state = { didLoad: false, range };
  }

  getCurrMonth() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    let lastDay = new Date().getTime();
    return { startTS: firstDay, endTS: lastDay };
  }

  getLastMonth() {
    const startTS = moment().subtract(1, 'months').startOf('month').valueOf();
    const endTS = moment().subtract(1, 'months').endOf('month').valueOf();
    return { startTS, endTS };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchResult();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.range != this.state.range) this.fetchResult();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { originalApiResult } = this.state;
    const { requiredParams } = this.props;

    if (!requiredParams) return;

    let currHaveRequiredParams =
      requiredParams && (!requiredParams[0] || requiredParams[0].size === 0);
    let nextHaveRequiredParams =
      nextProps.requiredParams &&
      (!nextProps.requiredParams[0] || nextProps.requiredParams[0].size === 0);

    if (!currHaveRequiredParams && nextHaveRequiredParams) {
      this.didPostProcessing = false;
      if (originalApiResult)
        this.afterfetchResult(originalApiResult, nextProps);
    }
  }

  async fetchResult() {
    const {
      api,
      selectedProjectId,
      projectId,
      withTotalAVG,
      ignoreTotal,
      type,
      showZeroValues,
      ignoreTotalLabel,
    } = this.props;
    const { range, period } = this.state;
    let originalApiResult;

    let mergedParams = Object.assign({}, api.params);
    if (range) {
      if (type == "line" || type == "gradesHistory" || type == "grade")
        mergedParams.filterFromDate = range.startTS;
      else mergedParams.startTS = range.startTS;

      if (range.endTS) mergedParams.endTS = range.endTS;

      if (range.period) mergedParams.period = range.period;
    }

    if (period) mergedParams.period = period;
    if (withTotalAVG) mergedParams.withTotalAVG = withTotalAVG;

    if (api) {
      this.setState({ didLoad: false });
      this.didPostProcessing = false;

      originalApiResult = await getDashboardData(
        projectId || selectedProjectId,
        api.type,
        api.name,
        mergedParams,
        ignoreTotal,
        showZeroValues,
        ignoreTotalLabel
      );
    }

    this.afterfetchResult(originalApiResult, this.props);
    this._isMounted && this.setState({ originalApiResult });
  }

  afterfetchResult(apiResult, props) {
    const { requiredParams, postProcessing } = props;

    let isRequiredParamsMissing = false;
    if (requiredParams != null)
      requiredParams.forEach((x) => {
        if (x == null || x.size === 0) isRequiredParamsMissing = true;
      });

    if (isRequiredParamsMissing) return;

    if (this.didPostProcessing) return;

    this.didPostProcessing = true;

    if (!apiResult) {
      this.setState({ didLoad: true });
      return;
    }

    if (postProcessing) apiResult = postProcessing(apiResult);

    this._isMounted && this.setState({ apiResult, didLoad: true });
  }

  initRange = (range) => {
    this.setState({ range });
  };

  render() {
    const { didLoad, apiResult, range = {} } = this.state;
    const {
      multiProjectViewMode,
      type,
      api,
      showRangeDatePicker,
      title,
      userProjects,
      projectId,
      intl,
      hideZeroValue = true,
    } = this.props;

    let combinedTitle = title;
    if (multiProjectViewMode && projectId)
      combinedTitle = userProjects.getNested([projectId, "title"], title);

    let labels =
      apiResult &&
      apiResult.groupByMetaData &&
      apiResult.groupByMetaData.length > 0
        ? apiResult.groupByMetaData[0]
        : null;
    if (labels && labels["NaN"])
      labels["undefined"] = {
        id: "undefined",
        title: intl.formatMessage(systemMessages.none),
      };

    return (
      <Widget
        hideZeroValue={hideZeroValue}
        isLoading={!didLoad}
        labels={labels}
        data={apiResult ? apiResult.data : {}}
        extraData={apiResult ? apiResult.extraData : {}}
        stackedLabels={
          Boolean(type == "stackedBar") &&
          apiResult &&
          apiResult.groupByMetaData &&
          apiResult.groupByMetaData.length > 1
            ? apiResult.groupByMetaData[1]
            : {}
        }
        onRangeChange={this.initRange}
        showRangeDatePicker={Boolean(
          !(showRangeDatePicker === false) && rangeSupportedWidget[api.name]
        )}
        timingOptions={this.timingOptions}
        range={range}
        {...this.props}
        title={combinedTitle}
        requiredParams={null}
        postProcessing={null}
        api={null}
      />
    );
  }
}

WidgetWrapper.defaultProps = {
  hidden: {},
  displayValue: "total",
};

const WidgetWrapperEnhance = compose(
  injectIntl,
  connectContext(ProjectContext.Consumer),
  connectContext(DashboardContext.Consumer),
  connect((state) => ({
    userProjects: state.projects.map,
  }))
);

WidgetWrapper = WidgetWrapperEnhance(WidgetWrapper);

export class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.buildGradesData = this.buildGradesData.bind(this);
    this.buildStackedData = this.buildStackedData.bind(this);
    this.buildBarData = this.buildBarData.bind(this);
    this.splitData = this.splitData.bind(this);
    this.setAxesLabels = this.setAxesLabels.bind(this);
    this.state = {};
  }

  splitData(data, labels) {
    console.log("Widget -> splitData -> data", data);
    const { intl } = this.props;

    // TODO: Remove total?
    let ret = Object.keys(data).map((x) => ({
      data: { ...data[x] },
      labels: {
        ok: { title: intl.formatMessage(safetyMessages.expirations.ok) },
        warning_3monthes: {
          title: intl.formatMessage(
            safetyMessages.expirations.warning_3monthes
          ),
        },
        warning: {
          title: intl.formatMessage(safetyMessages.expirations.warning),
        },
        expired: {
          title: intl.formatMessage(safetyMessages.expirations.expired),
        },
        total: { title: intl.formatMessage(safetyMessages.expirations.total) },
      },
      title: labels.getNested(
        [x, "title"],
        intl.formatMessage(
          analyticsMessages.dashboards.labels.otherCertificates
        )
      ),
    }));

    // let ret = Object.keys(data).map(x =>
    //   ({
    //     data:{[x]: {total:data[x].rejected}, empty: {total:data[x].total-data[x].rejected}},
    //     labels:{[x]: {title: 'Rejected during process'}, empty: {title:'Verified on first check'}},
    //     title: labels.getNested([x, 'title'])
    //   }))

    return ret;
  }

  buildStackedData(data, labels, stackedLabels, type, limit) {
    const {
      intl,
      hidden,
      filterStatus,
      pastSentance,
      keepOneLine,
      sortFunc,
      hideZeroValue = true,
    } = this.props;

    let stackedDynamicBarData = null;
    if (type == "expiration") {
      //let labels = intl.formatMessage(safetyMessages.expirations['ok']);
      stackedDynamicBarData = {
        labels: [],
        datasets: [
          {
            id: "ok",
            label: intl.formatMessage(safetyMessages.expirations["ok"]),
            data: [],
            backgroundColor: expirationColors["ok"], // green
            hidden: Boolean(hidden["ok"]),
          },
          {
            id: "warning_3monthes",
            label: intl.formatMessage(
              safetyMessages.expirations["warning_3monthes"]
            ),
            data: [],
            backgroundColor: expirationColors["warning_3monthes"], // yellow
            hidden: Boolean(hidden["warning_3monthes"]),
          },
          {
            id: "warning",
            label: intl.formatMessage(safetyMessages.expirations["warning"]),
            data: [],
            backgroundColor: expirationColors["warning"], // red
            hidden: Boolean(hidden["warning"]),
          },
          {
            id: "expired",
            label: intl.formatMessage(safetyMessages.expirations["expired"]),
            data: [],
            backgroundColor: expirationColors["expired"], // red
            hidden: Boolean(hidden["expired"]),
          },
        ],
      };
      // stackedDynamicBarData = { labels: [], datasets: [] };
      // Object.keys(labels).forEach(labelId => stackedDynamicBarData.datasets.push({
      //   id: labelId,
      //   label: labels[labelId].title,
      //   data: []
      // }))
    } else if (type == "records")
      stackedDynamicBarData = {
        labels: [],
        datasets: [
          {
            id: "100",
            label: intl.formatMessage(issuesMessages.counterStatus[1000]),
            data: [],
            backgroundColor: getPostColorByStatus(100), // green
          },
        ],
      };
    else if (type == "checklist")
      // TODO: Make this more generic
      stackedDynamicBarData = {
        labels: [],
        datasets: [
          {
            id: "100",
            label: stackedLabels.getNested(
              ["100", "title"],
              intl.formatMessage(checklistItemMessages.status["100"])
            ),
            data: [],
            backgroundColor: getChecklistColorByStatus(100),
            hidden: Boolean(hidden["100"]),
          },
          {
            id: "200",
            label: stackedLabels.getNested(
              ["200", "title"],
              intl.formatMessage(checklistItemMessages.status["200"])
            ),
            data: [],
            backgroundColor: getChecklistColorByStatus(200),
            hidden: Boolean(hidden["200"]),
          },
        ],
      };
    else if (type == "minGrades")
      // TODO: Make this more generic
      stackedDynamicBarData = {
        labels: [],
        datasets: [
          {
            id: "minimal",
            label: stackedLabels.getNested(
              ["minimal", "title"],
              intl.formatMessage(checklistItemMessages.status["100"])
            ),
            data: [],
            backgroundColor: getChecklistColorByStatus(100),
            hidden: Boolean(hidden["minimal"]),
          },
          {
            id: "current",
            label: stackedLabels.getNested(
              ["current", "title"],
              intl.formatMessage(checklistItemMessages.status["200"])
            ),
            data: [],
            backgroundColor: getChecklistColorByStatus(200),
            hidden: Boolean(hidden["current"]),
          },
        ],
      };
    else
      stackedDynamicBarData = {
        labels: [],
        datasets: [
          {
            id: "100",
            label: intl.formatMessage(
              issuesMessages[
                pastSentance ? "pastCounterStatus" : "counterStatus"
              ][100]
            ),
            data: [],
            backgroundColor: getPostColorByStatus(100), // green
            hidden: Boolean(hidden["100"]),
          },
          {
            id: "200",
            label: intl.formatMessage(
              issuesMessages[
                pastSentance ? "pastCounterStatus" : "counterStatus"
              ][200]
            ),
            data: [],
            backgroundColor: getPostColorByStatus(200), // yellow
            hidden: Boolean(hidden["200"]),
          },
          {
            id: "300",
            label: intl.formatMessage(
              issuesMessages[
                pastSentance ? "pastCounterStatus" : "counterStatus"
              ][300]
            ),
            data: [],
            backgroundColor: getPostColorByStatus(300), // red
            hidden: Boolean(hidden["300"]),
          },
        ],
      };

    if (filterStatus) {
      let newDataSets = [];
      stackedDynamicBarData.datasets.forEach((x) =>
        Boolean(filterStatus[x.id] && newDataSets.push(x))
      );
      stackedDynamicBarData.datasets = newDataSets;
    }

    // Sum top trades and keep only them
    let sumTrades = {};
    Object.keys(labels).forEach((lableId) => {
      sumTrades[lableId] = 0;
      stackedDynamicBarData.datasets.forEach((currDataSet) => {
        //if (!Boolean(hidden[currDataSet.id]))
        sumTrades[lableId] += data.getNested(
          [currDataSet.id, lableId, "total"],
          0
        );
      });
      //data.getNested(['100', lableId, 'total'], 0) + data.getNested(['200', lableId, 'total'], 0) + data.getNested(['300', lableId, 'total'], 0);
    });

    let keysSorted = Object.keys(sumTrades).sort(
      sortFunc || ((a, b) => sumTrades[b] - sumTrades[a])
    );
    keysSorted.slice(0, limit || DEFAULT_LIMIT).forEach((lableId) => {
      stackedDynamicBarData.labels.push(
        labels.getNested([lableId, "title"], "").substring(0, 40)
      );

      let currTotalVal = 0;

      stackedDynamicBarData.datasets.forEach((currDataSet) => {
        //if (!Boolean(hidden[currDataSet.id]))
        currTotalVal += data.getNested([currDataSet.id, lableId, "total"], 0);
        currDataSet.data.push(
          data.getNested([currDataSet.id, lableId, "total"], 0)
        );
      });

      if (currTotalVal == 0 && hideZeroValue) {
        (stackedDynamicBarData.labels || []).pop();
        stackedDynamicBarData.datasets.forEach((currDataSet) => {
          (currDataSet.data || []).pop();
        });
      }
    });

    // Add /n if line is row
    if (stackedDynamicBarData.labels && !keepOneLine)
      stackedDynamicBarData.labels = stackedDynamicBarData.labels.map((x) => {
        if (x.length && x.length > 10 && x.lastIndexOf) {
          let lastIndex = x.lastIndexOf(" ");
          if (lastIndex != -1)
            return [
              x.substr(0, lastIndex),
              x.substr(lastIndex, x.length - lastIndex),
            ];
        }

        return x;
      });

    return stackedDynamicBarData;
  }

  buildBarData(
    data,
    labels,
    limit,
    showParentsTitle,
    showTrade,
    percentagesTooltips
  ) {
    const { trades, withTotalAVG, intl, displayValue } = this.props;

    let countLimit = limit || 5;
    let barData = { metadata: [], labels: [], data: [] };
    let keysSorted = Object.keys(data);

    if (withTotalAVG) {
      keysSorted = Object.keys(data).filter((x) => x != "total");

      barData.labels.push(
        intl.formatMessage(analyticsMessages.dashboards.labels.allAvarage)
      );
      barData.data.push(
        data.getNested(["total", percentagesTooltips ? "percent" : "total"])
      );
      barData.metadata.push(Object.assign({}, data["total"], { key: "total" }));
    }

    keysSorted = keysSorted.sort(
      (a, b) =>
        data.getNested([b, displayValue], 0) -
        data.getNested([a, displayValue], 0)
    );
    let allTotalVal = 0;
    Object.values(data || {}).forEach((val) => {
      allTotalVal += val.total || 0;
    });
    keysSorted.slice(0, countLimit).forEach((dataId) => {
      let label = [];

      let firstRow = "";
      if (showTrade) {
        let tradeId = labels.getNested([dataId, "trade"], "");

        if (
          tradeId &&
          trades.get(tradeId.toString()) &&
          trades.get(tradeId.toString()).getCementoTitle()
        )
          firstRow = firstRow.concat(
            trades.get(tradeId.toString()).getCementoTitle()
          );
      }

      if (showParentsTitle) {
        let separator = "";
        if (firstRow != "") separator = " - ";

        firstRow = firstRow.concat(
          separator,
          labels
            .getNested([dataId, "parentTitle"], "parentTitle")
            .substring(0, 40)
        );
        label.push(firstRow);
      }

      if (labels)
        label.push(labels.getNested([dataId, "title"], "").substring(0, 40));

      barData.labels.push(label);
      barData.data.push(
        data.getNested([dataId, percentagesTooltips ? "percent" : displayValue])
      );
      barData.metadata.push(Object.assign({}, data[dataId], { key: dataId }));
      // barData.metadata.push(data[dataId]);
    });

    // Return object with 2 arrays - data and label
    barData.allTotalVal = allTotalVal;
    return barData;
  }

  buildGradesData(data, labels, medGradeMin, highGradeMin) {
    const { intl } = this.props;

    // Build the array
    let multiColorLineDynamicData = {
      datasets: [
        {
          label: "low",
          pointBackgroundColor: theme.brandDanger,
          fill: false,
          data: [],
        },
        {
          label: "mid",
          pointBackgroundColor: theme.brandWarning,
          fill: false,
          data: [],
        },
        {
          label: "high",
          pointBackgroundColor: theme.brandSuccess,
          fill: false,
          data: [],
        },
        {
          label: "all",
          borderColor: "#B7B7B7",
          backgroundColor: "rgba(239,239,239, 0.6)",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          fill: true,
          data: [],
          lineTension: 0,
        },
      ],
    };
    //multiColorLineDynamicData.tooltipLabel = safetyGrade;
    multiColorLineDynamicData.labels = (data.labels || labels || []).map(
      (value) =>
        moment(parseInt(value)).format(
          intl.formatMessage(systemMessages.onlyMonthAndDayFormat)
        )
    );
    data.forEach((valObject) => {
      let val = valObject.total;
      multiColorLineDynamicData.datasets[0].data.push(
        val < medGradeMin ? val : NaN
      );
      multiColorLineDynamicData.datasets[1].data.push(
        val >= medGradeMin && val < highGradeMin ? val : NaN
      );
      multiColorLineDynamicData.datasets[2].data.push(
        val >= highGradeMin ? val : NaN
      );
      multiColorLineDynamicData.datasets[3].data.push(val);
    });

    return multiColorLineDynamicData;
  }

  dataValidator(data, multiData) {
    let ret = false;
    if (Object.values(data || {}).length == 0) return ret;

    let dataArray = multiData ? Object.values(data || {}) : [data];

    dataArray.forEach((currData) => {
      Object.values(currData || {}).forEach((currSubData) => {
        if (
          currSubData &&
          Boolean(Object.values(currSubData || {}).length > 0) &&
          currSubData.total != null &&
          !(
            Object.entries(currSubData.total).length === 0 &&
            currSubData.total.constructor === Object
          )
        )
          ret = true;
      });
    });

    return ret;
  }

  setAxesLabels(mergedBarOptions) {
    const { axesLabels, intl } = this.props;

    if (axesLabels) {
      if (axesLabels.x) {
        let currSettings = [
          ...mergedBarOptions.getNested(["scales", "xAxes"], [{}]),
        ];
        currSettings[0] = Object.assign({}, currSettings[0]);
        currSettings[0].scaleLabel = {
          display: true,
          labelString: intl.formatMessage(axesLabels.x),
        };
        mergedBarOptions = mergedBarOptions.setNested(
          ["scales", "xAxes"],
          currSettings
        );
      }
      if (axesLabels.y) {
        let currSettings = [
          ...mergedBarOptions.getNested(["scales", "yAxes"], [{}]),
        ];
        currSettings[0] = Object.assign({}, currSettings[0]);
        currSettings[0].scaleLabel = {
          display: true,
          labelString: intl.formatMessage(axesLabels.y),
        };
        mergedBarOptions = mergedBarOptions.setNested(
          ["scales", "yAxes"],
          currSettings
        );
      }
    }

    return mergedBarOptions;
  }

  // addAxesObject(scaleAxe = [{}], name, val) {
  //   let currSettings = [...scaleAxe];// mergedBarOptions.getNested(['scales', 'yAxes'], [{}]);
  //   currSettings[0][name] = val;
  //   return currSettings;
  // }

  render() {
    const {
      onMaxValFound,
      maxValue,
      isLoading,
      toolTipText,
      tooltipCallback,
      ratio,
      type,
      title,
      data,
      extraData,
      minXAxesValue,
      multiColumn,
      labels,
      colorsShadesArray,
      colors,
      intl,
      medGradeMin,
      trades,
      valType,
      colorsSource,
      showTotalValue,
      highGradeMin,
      stackedLabels,
      legend,
      limit,
      showParentsTitle,
      showTrade,
      backgroundColor,
      horizontal,
      percentagesTooltips,
      onlyComponent,
      showRangeDatePicker,
      onRangeChange,
      range,
      timingOptions,
      project,
      hideLabelColorBox,
      reverseTooltipItemSort,
      hideZeroValue = true,
    } = this.props;

    let mainComponent;
    let dataArrays = [];

    if (data || !isLoading)
      if (
        (!isLoading && !data) ||
        !this.dataValidator(
          data,
          type == "multiDoughnut" || type == "stackedBar"
        )
      )
        mainComponent = (
          <Text>{analyticsMessages.dashboards.noValueMessage.general}</Text>
        );
      else
        switch (type) {
          case "multiDoughnut": {
            const splitedDoughnut = this.splitData(data, labels, colors);

            mainComponent = (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  marginTop: innerItemMargin / 2,
                }}
              >
                {Object.values(splitedDoughnut)
                  .reverse()
                  .map((val) => (
                    <div
                      style={{ width: cardWidth / 2, height: cardHeight / 2 }}
                    >
                      <Widget
                        hideZeroValue={false}
                        valType={valType}
                        data={val.data}
                        labels={val.labels}
                        type="doughnut"
                        legend={{
                          position: "bottom",
                          labels: {
                            boxWidth: 0,
                            filter: (x) => x.index == 0,
                            generateLabels: () => {
                              return [{ text: val.title, index: 0 }];
                            },
                          },
                        }}
                        percentagesTooltips={false}
                        onlyComponent={true}
                      />
                    </div>
                  ))}
              </div>
            );

            break;
          }
          case "line": {
            // Merge dates array into one
            let allDates = {};
            Object.values(data).forEach((currSubData) => {
              Object.keys(currSubData.total).forEach(
                (currDate) => (allDates[currDate] = true)
              );
            });

            let datesArray = Object.keys(allDates).sort();

            // Build per date each value
            let dataPerDates = datesArray.map((currDate) => {
              let ret = {};
              Object.keys(data).forEach((dataKey) => {
                ret[dataKey] = data[dataKey].total[currDate];
              });
              return ret;
            });

            let datasets = Object.keys(data).map((dataKey, i) => {
              let borderColor = theme.brandPrimary;
              let labels = {};
              if (valType == "checklistStatus") {
                borderColor = getChecklistColorByStatus(dataKey);
                labels[dataKey] = intl.formatMessage(
                  checklistItemMessages.status[dataKey]
                );
              } else if (valType == "issue") {
                borderColor = getPostColorByStatus(dataKey);
                labels[dataKey] = intl.formatMessage(
                  issuesMessages.counterStatus[dataKey]
                );
              } else if (valType == "records") {
                borderColor = getPostColorByStatus(dataKey);
                labels[dataKey] = intl.formatMessage(
                  issuesMessages.counterStatus[1000]
                );
              } else if (colors && i < colors.length - 1)
                borderColor = colors[i];

              return Object.assign(
                {},
                { data: dataPerDates.map((x) => x[dataKey]) },
                {
                  fill: false,
                  borderColor,
                  label: labels.getNested([dataKey], dataKey),
                }
              );
            });

            let dateLables = datesArray.map((value) =>
              moment(parseInt(value)).format(
                intl.formatMessage(systemMessages.onlyMonthAndDayFormat)
              )
            );
            let mergedData = Object.assign({}, lineAbstractData, {
              labels: dateLables,
              datasets,
            });

            let newLineOptions = this.setAxesLabels(lineOptions);
            mainComponent = (
              <Line
                options={newLineOptions}
                data={mergedData}
                height={180 - ratio * 30}
              />
            );
            break;
          }
          case "bar": {
            let barData = this.buildBarData(
              data,
              labels,
              limit,
              showParentsTitle,
              showTrade,
              percentagesTooltips
            );
            let mergedBarOptions = barOptions;

            //let mergedBarOptions = barOptions;
            if (maxValue) {
              let tempYAxesArray = [
                ...mergedBarOptions.getNested(["scales", "yAxes"], [{}]),
              ];
              let tempYAxes = tempYAxesArray[0].setNested(
                ["ticks", "max"],
                maxValue
              );
              tempYAxesArray[0] = tempYAxes;
              mergedBarOptions = mergedBarOptions.setNested(
                ["scales", "yAxes"],
                tempYAxesArray
              );
            }

            mergedBarOptions = this.setAxesLabels(mergedBarOptions);

            if (horizontal) {
              let tempBarOptions = mergedBarOptions;
              mergedBarOptions = mergedBarOptions.setNested(
                ["scales", "xAxes"],
                tempBarOptions.scales.yAxes
              );
              mergedBarOptions = mergedBarOptions.setNested(
                ["scales", "yAxes"],
                tempBarOptions.scales.xAxes
              );
            }

            if (minXAxesValue) {
              let currItem = mergedBarOptions.getNested(
                ["scales", "xAxes"],
                [{}]
              );
              currItem[0].ticks = {
                beginAtZero: true,
                suggestedMax: minXAxesValue,
                precision: 0,
              };
              mergedBarOptions = mergedBarOptions.setNested(
                ["scales", "xAxes"],
                currItem
              );
            }

            if (percentagesTooltips) {
              let currItem = mergedBarOptions.getNested(
                ["scales", "xAxes"],
                [{}]
              );
              currItem[0].ticks = {
                precision: 0,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return String(Math.round(value * 100)).concat("%");
                },
              };
              mergedBarOptions = mergedBarOptions.setNested(
                ["scales", "xAxes"],
                currItem
              );
            }

            if (toolTipText || tooltipCallback)
              mergedBarOptions = Object.assign({}, mergedBarOptions, {
                tooltips: {
                  mode: "label",
                  callbacks: {
                    label:
                      tooltipCallback ||
                      function (tooltipItem, data) {
                        //var label = data.datasets[tooltipItem.datasetIndex].label;
                        return intl.formatMessage(toolTipText, {
                          val: tooltipItem.value.toString(),
                        });
                      },
                  },
                },
              });

            let legendColors;
            if (valType == "trades") {
              legendColors = (barData.metadata || []).map((val, i) => {
                let ret = null;
                if (val.key == "total") ret = theme.brandPrimary;
                else if (val.key)
                  ret = trades.getNested([val.key, "color"], TRADE_NO_COLOR);
                else ret = TRADE_NO_COLOR;

                return ret;
              });
            } else if (valType == "expiration")
              legendColors = (barData.metadata || []).map((val, i) =>
                val.key && expirationColors[val.key]
                  ? expirationColors[val.key]
                  : theme.brandInfo
              );
            else if (colorsShadesArray && backgroundColor) {
              let arrayLength = (barData.data || []).length || 1;
              const minColor = 0;
              const maxColor = 0.75;
              let step = (minColor + maxColor) / arrayLength;
              let colorsArray = (barData.data || []).map(
                (x, i) => -1 * minColor + step * i
              );
              legendColors = (barData.data || []).map((val, i) =>
                ColorLuminance(backgroundColor, colorsArray[i])
              ); // Color(backgroundColor).lighten((0.5/arrayLength)*i)); //val.total
            } else legendColors = backgroundColor || colors || randomColorList;

            const mergedDatasets = [
              Object.assign(
                {},
                { data: barData.data },
                barAbstractData.datasets[0],
                { metadata: barData.metadata },
                { backgroundColor: legendColors }
              ),
            ];
            const mergedData = Object.assign({}, barAbstractData, {
              labels: barData.labels,
              datasets: mergedDatasets,
            });

            // Count the max of the values from the given array
            if (onMaxValFound && mergedDatasets)
              mergedDatasets.forEach((x) => {
                dataArrays = dataArrays.concat(x.data);
              });

            let barProps = {
              options: mergedBarOptions,
              data: mergedData,
              width: 90 * ratio,
              height: 80,
            };

            if (horizontal) mainComponent = <HorizontalBar {...barProps} />;
            else mainComponent = <Bar {...barProps} />;

            break;
          }
          case "stackedBar": {
            let stackedData = this.buildStackedData(
              data,
              labels,
              stackedLabels,
              valType,
              limit
            );

            // Count the max of the values from the given array
            if (onMaxValFound && stackedData && stackedData.datasets)
              stackedData.datasets.forEach((x) => {
                dataArrays = dataArrays.concat(x.data);
              });

            let currStackedBarOptions = Object.assign({}, stackedBarOptions);

            if (multiColumn) {
              currStackedBarOptions.scales = Object.assign(
                {},
                stackedBarOptions.scales
              );
              currStackedBarOptions = currStackedBarOptions.setNested(
                ["scales", "yAxes"],
                [...stackedBarOptions.scales.yAxes]
              );
              currStackedBarOptions.getNested(["scales", "yAxes"])[0] =
                Object.assign({}, currStackedBarOptions.scales.yAxes[0], {
                  stacked: false,
                  minRotation: 90,
                });

              currStackedBarOptions.scales.xAxes = [{}];
              currStackedBarOptions.getNested(["scales", "xAxes"])[0] =
                Object.assign({}, currStackedBarOptions.scales.xAxes[0], {
                  id: "100",
                  stacked: false,
                });
              currStackedBarOptions
                .getNested(["scales", "xAxes"])
                .push({ id: "300", display: false });
            }

            if (maxValue) {
              let tempYAxesArray = [
                ...currStackedBarOptions.getNested(["scales", "yAxes"], [{}]),
              ];
              let tempYAxes = tempYAxesArray[0].setNested(
                ["ticks", "max"],
                maxValue
              );
              tempYAxesArray[0] = tempYAxes;
              currStackedBarOptions = currStackedBarOptions.setNested(
                ["scales", "yAxes"],
                tempYAxesArray
              );
            }

            currStackedBarOptions = this.setAxesLabels(currStackedBarOptions);

            if (horizontal) {
              let tempCurrStackedBarOptions = Object.assign(
                {},
                currStackedBarOptions
              );

              currStackedBarOptions = currStackedBarOptions.setNested(
                ["scales", "xAxes"],
                tempCurrStackedBarOptions.scales.yAxes
              );
              currStackedBarOptions = currStackedBarOptions.setNested(
                ["scales", "yAxes"],
                tempCurrStackedBarOptions.scales.xAxes
              );
            }

            currStackedBarOptions = currStackedBarOptions.setNested(
              ["maintainAspectRatio"],
              false
            );
            currStackedBarOptions.testVar = "test2";

            if (toolTipText || tooltipCallback)
              currStackedBarOptions = Object.assign({}, currStackedBarOptions, {
                tooltips: {
                  itemSort: (a, b, data) => {
                    return reverseTooltipItemSort
                      ? b.datasetIndex - a.datasetIndex
                      : a.datasetIndex - b.datasetIndex;
                  },
                  custom: function (tooltip) {
                    if (!tooltip) return;
                    // disable displaying the color box;
                    if (hideLabelColorBox) tooltip.displayColors = false;
                  },
                  mode: "label",
                  callbacks: {
                    label:
                      tooltipCallback ||
                      function (tooltipItem, data) {
                        return intl.formatMessage(toolTipText, {
                          val: data.datasets[0].data[
                            tooltipItem.index
                          ].toString(),
                        });
                      },
                  },
                },
              });

            let barProps = {
              options: currStackedBarOptions,
              data: stackedData,
              width: 90,
              height: 120 - ratio * 30,
              //plugins:[stackbarChartJSPlugin]
            };
            if (horizontal) mainComponent = <HorizontalBar {...barProps} />;
            else mainComponent = <Bar {...barProps} />;

            mainComponent = (
              <div
                style={{
                  paddingTop: theme.margin,
                  flex: 1,
                  alignSelf: "stretch",
                }}
              >
                {mainComponent}
              </div>
            );

            break;
          }
          case "doughnut": {
            let doughnutData = this.buildBarData(
              data,
              labels,
              limit,
              showParentsTitle,
              showTrade,
              percentagesTooltips
            );
            let legendColors;
            if (valType == "trades")
              legendColors = (doughnutData.metadata || []).map((val, i) =>
                val.key
                  ? trades.getNested([val.key, "color"], randomColorList[i])
                  : randomColorList[i]
              );
            else if (valType == "expiration")
              legendColors = (doughnutData.metadata || []).map((val, i) =>
                val.key && expirationColors[val.key]
                  ? expirationColors[val.key]
                  : theme.brandInfo
              );
            else legendColors = colors || randomColorList;

            const mergedDatasets = [
              Object.assign(
                {},
                {
                  data: doughnutData.data,
                  backgroundColor: legendColors,
                  hoverBackgroundColor: legendColors,
                },
                doughnutsAbstractData.datasets[0]
              ),
            ];
            const mergedData = Object.assign({}, doughnutsAbstractData, {
              labels: doughnutData.labels,
              datasets: mergedDatasets,
            });
            let mergedDoghnutsOptions = Object.assign({}, doughnutsOptions, {
              allTotalVal: doughnutData.allTotalVal,
              showTotalValue,
              legend: legend || doughnutsOptions.legend,
            });

            if (toolTipText || tooltipCallback)
              mergedDoghnutsOptions = Object.assign({}, mergedDoghnutsOptions, {
                tooltips: {
                  mode: "label",
                  callbacks: {
                    label:
                      tooltipCallback ||
                      function (tooltipItem, data) {
                        //var label = data.datasets[tooltipItem.datasetIndex].label;
                        return intl.formatMessage(toolTipText, {
                          val: data.datasets[0].data[
                            tooltipItem.index
                          ].toString(),
                          trade: data.labels[tooltipItem.index][0],
                        });
                      },
                  },
                },
              });
            mainComponent = (
              <Doughnut
                options={mergedDoghnutsOptions}
                data={mergedData}
                width={90 * ratio}
                height={80}
                plugins={[myChartJSPlugin]}
              />
            );
            break;
          }
          case "grade": {
            let avg = Math.round(
              Object.values(data)
                .map((x) => x.total)
                .reduce((a, b) => a + b, 0) / Object.values(data).length
            );
            let currGrade = _.get(extraData, ["currGrade", "grade"]);
            if (!_.isNil(currGrade)) {
              let currGradeColor =
                gradeColorsList[
                  Math.ceil(Math.min(100, currGrade + 1) / 10) - 1
                ];
              let avgGradeColor = "black"; //gradeColorsList[(Math.ceil(Math.min(100, avg+1)/10)) - 1];

              mainComponent = (
                <div
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flex: 1,
                      marginRight: theme.padding,
                      marginLeft: theme.padding,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: multiColorPointWidth,
                        height: multiColorPointWidth,
                        backgroundColor: currGradeColor,
                        borderRadius: multiColorPointWidth,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span
                        style={{ fontSize: theme.fontSizeH1, color: "white" }}
                      >
                        {currGrade}
                      </span>
                    </div>
                    <span
                      style={{
                        fontSize: theme.fontSizeH5,
                        color: currGradeColor,
                        marginTop: theme.verticalMargin,
                      }}
                    >
                      {intl.formatMessage(
                        analyticsMessages.dashboards.tooltips.currGrade
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: theme.margin * 3,
                      minWidth: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      inlineSize: "max-content",
                    }}
                  >
                    <span
                      style={{
                        fontSize: theme.fontSizeH5,
                        color: avgGradeColor,
                        marginTop: theme.verticalMargin,
                        marginLeft: theme.verticalMargin,
                      }}
                    >
                      {range.avarageLabel}
                    </span>
                    <span
                      style={{
                        fontSize: theme.fontSizeH5,
                        color: avgGradeColor,
                        marginTop: theme.verticalMargin,
                      }}
                    >
                      {avg}
                    </span>
                  </div>
                </div>
              );
            }
            break;
          }
          case "gradesHistory": {
            let gradeData = this.buildGradesData(
              data,
              labels,
              medGradeMin,
              highGradeMin
            );
            let newLineOptions = this.setAxesLabels(multiLineOptions);

            if (toolTipText || tooltipCallback)
              newLineOptions = Object.assign({}, newLineOptions, {
                tooltips: {
                  mode: "label",
                  callbacks: {
                    label:
                      tooltipCallback ||
                      function (tooltipItem, data) {
                        var label =
                          data.datasets[tooltipItem.datasetIndex].label;
                        if (label == "all") return null;
                        return intl.formatMessage(toolTipText, {
                          val: tooltipItem.value.toString(),
                        });
                      },
                  },
                },
              });
            mainComponent = (
              <Line
                options={newLineOptions}
                data={gradeData}
                height={180 - ratio * 30}
              />
            );
            break;
          }
        }

    if (isLoading)
      mainComponent = (
        <>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              opacity: 0.2,
              flex: 1,
              display: "flex",
              width: "100%",
              backgrounColor: "red",
              height: "100%",
            }}
          >
            {mainComponent}
          </div>
          <div style={{ opacity: 0.7, position: "absolute" }}>
            <FadeLoader size={8} color={theme.brandPrimary} loading={true} />
          </div>
        </>
      );

    if (dataArrays && dataArrays.length > 0)
      onMaxValFound(dataArrays.reduce((a, b) => Math.max(a, b)));

    if (onlyComponent) return mainComponent;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: cardHeight,
          width: cardWidth * ratio + theme.margin * (ratio - 1) * 2,
          backgroundColor: "white",
          margin: theme.margin,
          boxShadow: "0 0 16px rgba(0, 0, 0, 0.09)",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "solid 1px #e1e1e1",
            padding: theme.margin,
            paddingTop: theme.verticalMargin,
            paddingBottom: theme.verticalMargin,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              color: theme.brandPrimary,
              fontSize: theme.fontSizeH6,
              fontWeight: 600,
            }}
          >
            {title}
            {Boolean(showRangeDatePicker) && (
              <Select
                isMulti={false}
                onChange={(selection) => onRangeChange(selection)}
                styles={selectStyles}
                value={range}
                options={timingOptions}
              />
            )}
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flex: 1,
            marginRight: theme.padding,
            marginLeft: theme.padding,
            flexDirection: "column",
          }}
        >
          {mainComponent}
        </div>
      </div>
    );
  }
}

Widget.defaultProps = {
  ratio: 1,
  medGradeMin: 50,
  highGradeMin: 80,
  showTotalValue: true,
};

const defaultSuggestedMaxValue = 10;
let suggestedMax = defaultSuggestedMaxValue;

const myTooltips = {
  callbacks: {
    title: function (tooltipItem, data) {
      return data.labels[tooltipItem[0].index];
    },
    label: function (tooltipItem, data) {
      var dataset = data.datasets[tooltipItem.datasetIndex];
      //var currentValue = dataset.data[tooltipItem.index];

      let ret = "";
      if (dataset.getNested(["metadata", tooltipItem.index, "percent"])) {
        var percent = parseFloat(
          (dataset.metadata[tooltipItem.index].percent * 100).toFixed(1)
        );
        ret = ret.concat(String(percent), "%");
      }

      if (
        dataset.getNested(["metadata", tooltipItem.index, "total"]) &&
        dataset.getNested(["metadata", tooltipItem.index, "rejected"])
      )
        ret = ret.concat(
          " ",
          String(dataset.metadata[tooltipItem.index].rejected),
          "/",
          String(dataset.metadata[tooltipItem.index].total)
        );

      if (ret == "") ret = dataset.data[tooltipItem.index];

      return ret;
    },
  },
};

const doughnutsAbstractData = {
  datasets: [],
};

const barAbstractData = {
  datasets: [
    {
      backgroundColor: theme.brandDanger, // red
    },
  ],
};

const doughnutsOptions = {
  tooltips: myTooltips,
  legend: {
    position: "bottom",
    display: true,
  },
  cutoutPercentage: 70,
  elements: {
    center: {
      text: "90%",
      color: "#FF6384", // Default is #000000
      fontStyle: "Arial", // Default is Arial
      sidePadding: 20, // Defualt is 20 (as a percentage)
    },
  },
};

const stackedBarOptions = {
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label;
        return label.toString().concat(": ", tooltipItem.value.toString());
      },
    },
  },
  legend: {
    position: "bottom",
    display: true,
  },
  scales: {
    xAxes: [
      {
        display: "auto",
        barPercentage: 0.75,
        stacked: true,
        ticks: {
          display: "auto",
          beginAtZero: true,
          precision: 0,
          suggestedMax,
        },
        gridLines: {
          display: false,
          drawOnChartArea: false,
        },
      },
    ],
    yAxes: [
      {
        display: "auto",
        stacked: true,
        ticks: {
          display: "auto",
          beginAtZero: true,
          precision: 0,
          suggestedMax,
        },
      },
    ],
  },
};

const barOptions = {
  tooltips: myTooltips,
  legend: {
    position: "bottom",
    display: false,
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.75,
        ticks: {
          beginAtZero: true,
          precision: 0,
          suggestedMax,
        },
        gridLines: {
          display: false,
          drawOnChartArea: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          precision: 0,
          suggestedMax,
        },
      },
    ],
  },
};

// const gradeLineOptions = {
//   responsive: true,
//   legend: {
//     position: 'bottom',
//     display: false
//   },
//   scales: {
//     xAxes: [{
//             display: true
//         }],
//     yAxes: [{
//             display: true,
//             ticks: {
//                 beginAtZero: true,
//                 steps: 10,
//                 stepValue: 5,
//                 max: 100
//             }
//         }]
//   },
// }

const lineOptions = {
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label;
        return label.toString().concat(": ", tooltipItem.value.toString());
      },
    },
  },
  elements: {
    line: {
      tension: 0,
    },
  },
  ticks: {
    beginAtZero: true,
    precision: 0,
  },
  legend: {
    position: "bottom",
    display: true,
  },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    ],
  },
};

const multiLineOptions = {
  //maintainAspectRatio: true,
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label;
        if (label == "all") return null;
        return label.toString().concat(": ", tooltipItem.value.toString());
      },
    },
  },
  legend: {
    position: "bottom",
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
      },
    ],
    yAxes: [
      {
        display: true,
        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 5,
          max: 100,
          precision: 0,
        },
      },
    ],
  },
};

const lineAbstractData = {
  datasets: [
    {
      fill: false,
      borderColor: theme.brandPrimary,
    },
  ],
};

const multiColorPointWidth = 110;

// TODO: This is the way to resolve a bug, where for example "closed tasks" is hidden by default, and have no values of opened tasks. in that case we want the colunm to not be presented at all
const stackbarChartJSPlugin = {
  beforeDraw: (chart) => {
    let ctx = chart.chart.ctx;

    // Find values that are all zero on visible rows

    // Remove the items that have no visible values
    //let hiddenDatasets = [];
    let atLeastOneVisibleItem = new Array(chart.data.labels.length);
    for (let j = 0; j < chart.data.labels.length; j++)
      atLeastOneVisibleItem[j] = false;

    for (let i = 0; i < chart.data.datasets.length; i++) {
      // It seems some value updated in metadata, but there is a method available.
      // var metaDatasets = myLineChart.getDatasetMeta(i);
      if (chart.isDatasetVisible(i) && chart.data.datasets[i].data) {
        for (var z = 0; z < chart.data.datasets[i].data.length; z++) {
          if (!atLeastOneVisibleItem[z] && chart.data.datasets[i].data[z])
            atLeastOneVisibleItem[z] = true;
        }
        // or myLineChart.getDatasetMeta(i);
        //hiddenDatasets.push(chart.data.datasets[i]);
      }
    }

    for (let i = 0; i < chart.data.datasets.length; i++) {
      let originalData = chart.data.datasets[i].data;
      let originalLabels = chart.data.labels;
      if (i == 0) chart.data.labels = [];

      chart.data.datasets[i].data = [];

      for (let x = 0; x < atLeastOneVisibleItem.length; x++) {
        if (atLeastOneVisibleItem[x]) {
          chart.data.datasets[i].data.push(originalData[x]);
          if (i == 0) chart.data.labels.push(originalLabels[x]);
        }
      }
    }

    //chart.config.data.datasets.forEach(x => { x.display = false; x.hidden = true; });
    // chart.config.data.datasets[0].hidden = true;
    // chart.config.data.datasets[0].display = false;
    // chart.config.data.datasets[1].hidden = true;
    // chart.config.data.datasets[1].display = false;
    //  chart.config.data.datasets[2].hidden = true;
    //  chart.config.data.datasets[2].display = false;
    // chart.config.data.datasets[3].hidden = true;
    console.log("beforeDraw stackbarChartJSPlugin");

    //ctx.restore();
    ctx.save();
  },
};

const myChartJSPlugin = {
  beforeDraw: (chart,...args) => {
    
    var width = chart.chart.width,
      height = chart.chart.height,
      legHeight = chart.legend.height,
      ctx = chart.chart.ctx,
      showTotalValue = chart.options.showTotalValue;

    // let totalVal = 0;
    // chart.config.data.datasets[0].data.forEach(x => totalVal+=x);

    //let myLineChart = chart;
    // var hiddenDatasets = [];
    // for(var i=0; i<myLineChart.data.datasets.length; i++) {
    //     // It seems some value updated in metadata, but there is a method available.
    //     // var metaDatasets = myLineChart.getDatasetMeta(i);
    //     if (!myLineChart.isDatasetVisible(i)) {
    //         // or myLineChart.getDatasetMeta(i);
    //         hiddenDatasets.push(myLineChart.data.datasets[i]);
    //     }
    // }

    ctx.restore();
    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    const legendItems = chart?.legend?.legendItems;
    const text = String(
      chart?.data?.datasets?.[0]?.data.reduce((acc, dataItem, index) => {
        if (!legendItems?.[index]?.hidden) {
          return acc + dataItem;
        }
        return acc;
      }, 0) || 0
    );
    const textX = Math.round( 
        width / 2 +
          (ctx.measureText(text).width / 2) *
            (chart.getNested(["legend", "options", "display"]) ? -1 : 1)
      )
    const textY = Math.round((height - legHeight) / 2);
    if (showTotalValue) ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

const randomColorList = [
  "#EBCEB2",
  "#b1cbbb",
  "#EEE8AA",
  "#F2B774",
  "#A9A9A9",
  "#BC8F8F",
  "#BC8F8F",
  "#eea29a",
  "#b5e7a0",
  "#66CDAA",
  "#f18973",
  "#6B8E23",
  "#800080",
  "#9370DB",
  "#CD853F",
  "#D3D3D3",
  "#DAA520",
  "#DC143C",
  "#E0FFFF",
  "#EEE8AA",
  "#F0FFF0",
  "#F4A460",
  "#FAEBD7",
  "#FF0000",
  "#FFA07A",
  "#FFC0CB",
  "#FFFF00",
  "#EBCEB2",
  "#b5e7a0",
  "#f18973",
  "#eea29a",
  "#b1cbbb",
  "#F2B774",
  "#66CDAA",
  "#6B8E23",
  "#800080",
  "#9370DB",
  "#A9A9A9",
  "#BC8F8F",
  "#CD853F",
  "#D3D3D3",
  "#DAA520",
  "#DC143C",
  "#E0FFFF",
  "#EEE8AA",
  "#F0FFF0",
  "#F4A460",
  "#FAEBD7",
  "#FF0000",
  "#FFA07A",
  "#FFC0CB",
  "#FFFF00",
  "#EBCEB2",
  "#b5e7a0",
  "#f18973",
  "#eea29a",
  "#b1cbbb",
  "#F2B774",
  "#66CDAA",
  "#6B8E23",
  "#800080",
  "#9370DB",
  "#A9A9A9",
  "#BC8F8F",
  "#CD853F",
  "#D3D3D3",
  "#DAA520",
  "#DC143C",
  "#E0FFFF",
  "#EEE8AA",
  "#F0FFF0",
  "#F4A460",
  "#FAEBD7",
  "#FF0000",
  "#FFA07A",
  "#FFC0CB",
  "#FFFF00",
  "#EBCEB2",
  "#b5e7a0",
  "#f18973",
  "#eea29a",
  "#b1cbbb",
  "#F2B774",
  "#66CDAA",
  "#6B8E23",
  "#800080",
  "#9370DB",
  "#A9A9A9",
  "#BC8F8F",
  "#CD853F",
  "#D3D3D3",
  "#DAA520",
  "#DC143C",
  "#E0FFFF",
  "#EEE8AA",
  "#F0FFF0",
  "#F4A460",
  "#FAEBD7",
  "#FF0000",
  "#FFA07A",
  "#FFC0CB",
  "#FFFF00",
];

const selectStyles = {
  input: (styles) => ({
    ...styles,
    ...theme.defaultFont,
    paddingTop: 0,
    margin: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    ...theme.defaultFont,
    paddingTop: 0,
    margin: 0,
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    minWidth: 138,
    height: 10,
    border: "0px",
    borderRadius: "0px",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: 0,
    boxShadow: 0,
    cursor: "pointer",
    minHeight: 0,
    fontWeight: 100,
    fontSize: 6,
    ...theme.defaultFont,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#808184",
    lineHeight: 2,
    padding: 0,
    paddingLeft: 3,
    paddingRight: 3,
  }),
  indicatorSeparator: (styles) => ({ visibility: "hidden" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 100,
      fontSize: 6,
      ...theme.defaultFont,
      zIndex: theme.zIndexes.dashboard,
      cursor: "pointer",
      backgroundColor: "transparent",
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? theme.brandPrimary
        : isFocused
        ? theme.brandPrimary
        : theme.brandNeutralDark,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  menuList: (styles) => ({ ...styles, zIndex: theme.zIndexes.dashboard, borderRadius: "0px" }),
  valueContainer: (styles) => ({ ...styles, padding: 0 }),
  menu: (styles) => ({
    ...styles,
    zIndex: theme.zIndexes.dashboard,
    marginTop: 15,
    borderRadius: "0px",
  }),
};

let expirationColors = {};
expirationColors["ok"] = theme.bransSuccessDark;
expirationColors["warning_3monthes"] = theme.brandSuccess;
expirationColors["warning"] = theme.brandWarning;
expirationColors["expired"] = theme.brandRealDanger;
expirationColors["total"] = theme.brandInfo;
const TRADE_NO_COLOR = "#d4d4d4";

function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

{
  /* <MultiWidgetWrapper
        //fetchApi={async () => getDashboardData(selectedProjectId, 'snapshot', 'topCountOfPosts', {"groupBy": ["owner"], "condition": { "isIssue": (Boolean(contentType == "issues")) }})}
        api={{
          "type":"snapshot",
          "name":"topCountOfPosts",
          "params" : {
            "groupBy": ["owner"], 
            "condition": { 
              "isIssue": (Boolean(contentType == "issues"))
            }
          }
        }} 
        postProcessing={(apiResult) => {
          // Add name to each owner
          if (members && apiResult && apiResult.data) {
            let ownersNames = this.getMembersNamesByIds(Object.keys(apiResult.data), members);
            apiResult.groupByMetaData = [ownersNames];
          }
          return apiResult;
        }}
        requiredParams={[members]}
        horizontal={true}
        backgroundColor={theme.brandPrimary}
        colorsShadesArray={true}
        type={'bar'}
        title={intl.formatMessage(analyticsMessages.dashboards.titles.mostActiveIssues)}
        toolTipText={analyticsMessages.dashboards.tooltips.tasks}
        //title={'העובדים הפעילים ביותר'}
      /> */
}

{
  /* <MultiWidgetWrapper
        api={{
          "type":"history",
          "name":"postsActivity",
          "params" : {
            "groupBy": ["user"]
          }
        }} 
        postProcessing={(apiResult) => {
          // Add name to each owner
          if (members && apiResult && apiResult.data) {
            let ownersNames = this.getMembersNamesByIds(Object.keys(apiResult.data), members);
            apiResult.groupByMetaData = [ownersNames];
          }
          return apiResult;
        }}
        requiredParams={[members]}
        horizontal={true}
        backgroundColor={theme.bransSuccessDark}
        colorsShadesArray={true}
        type={'bar'}
        title={intl.formatMessage(analyticsMessages.dashboards.titles.mostActiveIssuesCloser)}
        //toolTipText={analyticsMessages.dashboards.tooltips.checklistsItems}
        //title={'עובדים שביצעו הכי הרבה בדיקות'}
      /> */
}

// {false && <MultiWidgetWrapper
//   api={{
//     "type":"flow",
//     "name":"postsFlowtimeAVG",
//     "params" : {
//       "groupBy": ["trade"]
//     }
//   }}
//   postProcessing={(apiResult) => {
//     if (apiResult && apiResult.data)
//       Object.values(apiResult.data).forEach(currVal => { if (currVal.total > 0) currVal.total = millisecondsToDays(currVal.total); })
//     return apiResult;
//   }}
//   showRangeDatePicker={false}
//   type={'bar'}
//   horizontal={true}
//   limit={5}
//   valType={'trades'}
//   withTotalAVG={true}
//   //colorsSource={'trade'}
//   toolTipText={analyticsMessages.dashboards.tooltips.days}
//   title={intl.formatMessage(analyticsMessages.dashboards.titles.avarageAgeOfOpenTasks)}
//   //title={'גיל המשימות שאינן סגורות'}
// />}

// {(false) &&
//   <MultiWidgetWrapper
//     //fetchApi={async () => getDashboardData(selectedProjectId, 'timeline', 'postsTimeline', {"period": "Monthly", "condition": { "trade": "1033" }})}
//     api={{
//       "type":"timeline",
//       "name":"postsTimeline",
//       "params" : {
//         "period": ["Monthly"],
//         "condition": { "trade": "1033" }
//       }
//     }}
//     type={'grade'}
//     title={intl.formatMessage(analyticsMessages.dashboards.titles.safetyGrade)}
//     //title={'ציון בטיחות'}
//     labels={Object.keys(dashboardData.safetyGradeIssuesTimeline.data)}
//     data={Object.values(dashboardData.safetyGradeIssuesTimeline.data)}
//   />
// }

const gradeColorsList = [
  "#681b1b",
  "#881515",
  "#b40e0e",
  "#df0606",
  "#ff0000",
  "#ff4500",
  "#ff6200",
  "#f77d04",
  "#ffdd67",
  theme.brandSuccess,
];
