import React, { useContext, useMemo } from 'react';
import { ProjectContext } from '../../projects/contexts';
import { useCementoRouter } from '../../../web/components/Router/util/withRouterHOC';
import ObjectsWrapperView from '../ObjectsWrapperView';
import PostCard from '../views/grid/components/PostCard';
import { preProcessInstances } from '../utils';
import { useSelector } from 'react-redux';
import { lokiInstance } from '../../configureMiddleware';
import useSavedMenus from '../../../web/components/Header/useSavedMenus';
import _ from 'lodash';
import { fillBusinessType } from '../../propertiesTypes/funcs';
import useCompanies from '../../companies/hooks/useCompanies';

const usePreProcessedInstances = ({ objects, instances, propTypes }) => {
  return useMemo(() => {
    const preProcessedInstances = preProcessInstances(objects, instances, propTypes);
    return preProcessedInstances;
  }, [objects, instances, propTypes]);
};

const InstancesPreProcessorHOC = (WrappedComponent) => {
  const WithInstances = ({ contentType, section }) => {
    const outletProps = useCementoRouter();
    const projectProps = useContext(ProjectContext);
    const { selectedFav } = useSavedMenus({
      contentType,
      section,
    });
    const { companies } = useCompanies();
    const { trades, members, subCategories } = useSelector((state) => ({
      trades: state.trades.map,
      subCategories: state.quasiStatics.subCategoriesMap,
      members: state.members.map,
    }));

    const subjectName = `${selectedFav?.subjectType}Info`;
    const rawPropTypes = projectProps.propertiesTypes[subjectName];
    const richPropTypes = rawPropTypes
      ? Object.entries(rawPropTypes).reduce((acc, [key, prop]) => {
          if (prop.businessType) {
            acc[key] = fillBusinessType(prop, {
              trades,
              companies,
              members,
              subCategories,
            });
          } else {
            acc[key] = prop;
          }
          return acc;
        }, rawPropTypes)
      : rawPropTypes;

    const lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
    const propertiesInstances = lokiPropertyInstances.cementoFind({
      'projectId': projectProps.selectedProjectId,
      subjectName,
    });

    const objects = usePreProcessedInstances({
      objects: outletProps.currViewPosts, // TODO: replace with dynamic field
      instances: propertiesInstances,
      propTypes: richPropTypes,
    });

    const shouldRender = !!(objects && richPropTypes);
   
    if (!shouldRender) return null;

    return (
      <WrappedComponent objects={objects} propTypes={richPropTypes}>
        <ObjectsWrapperView ItemComponent={PostCard} />
      </WrappedComponent>
    );
  };

  return WithInstances;
};

export default InstancesPreProcessorHOC;
