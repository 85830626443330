import { track } from '../lib/reporting/actions';
import _ from 'lodash';
import { batchDispatch } from '../app/funcs';

/**
 * 
 * @param {typeof PROPERTIES_INSTANCES_EVENTS[keyof PROPERTIES_INSTANCES_EVENTS]} event 
 * @param {{}} [params] 
 * @returns 
 */

const trackPropertiesInstances = (event, params = {}) => {
  batchDispatch([track(event, params)]);
};;

export default trackPropertiesInstances;

export const PROPERTIES_INSTANCES_EVENTS = {
  FETCH_PROPERTIES_INSTANCES: 'FETCH_PROPERTIES_INSTANCES',
  UPSERT_PROPERTIES_INSTANCES: 'UPSERT_PROPERTIES_INSTANCES',
  UPDATE_PROPERTIES_INSTANCES: 'UPDATE_PROPERTIES_INSTANCES',
  START_PROPERTIES_INSTANCES_LISTENER: 'START_PROPERTIES_INSTANCES_LISTENER',
  END_PROPERTIES_INSTANCES_LISTENER: 'END_PROPERTIES_INSTANCES_LISTENER',
  SET_RECENTLY_SIGNING_USERS: 'SET_RECENTLY_SIGNING_USERS',
};

