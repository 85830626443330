import { getDispatch } from '../configureMiddleware';
import { getSnapshotData, writeLogOnce } from '../lib/utils/utils';
import { platformActions } from '../platformActions';
import { propertiesMappingsEvents } from './propertiesMappingsEvents';

export async function getPropertiesMappings(viewer, projectId, subjectName) {
  const dispatch = getDispatch();

  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    resourceName: 'properties/mappings',
    firebasePath: `properties/mapping/projects/${projectId}${subjectName ? `/${subjectName}` : ''}`,
    queryParams: subjectName ? { subjectName } : undefined,
  };

  const onData = (data) => {
    dispatch({ type: propertiesMappingsEvents.GET_PROPERTIES_MAPPINGS, payload: { projectId, subjectName, propertiesMappings: data } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    onData(result);
  }

  return { projectId };
}

export async function getCompanyPropertiesMappings(companyId) {
  const firebase = platformActions.firebase.getFirebase();
  const mapping =
    (await firebase.database().ref(`properties/mapping/companies/${companyId}`).once('value')).val() || {};
  writeLogOnce('info', 'getCompanyPropertiesMappings - get Firebase', {
    scope: 'companies',
    scopeId: companyId,
    type: 'propertiesMappings',
  });

  let newMapping = null;
  if (mapping) {
    newMapping = {};
    Object.entries(mapping).forEach(([subjectName, subjectProps]) => {
      newMapping[subjectName] = subjectProps.groups;
    });
  }

  return newMapping;
}
