import * as companyTypes from './companiesTypes';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { uploadImage } from '../images/actions';
import { platformActions } from '../platformActions';
import { envParams, getAppState } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';

let subscriptions = {};

export const startCompaniesListener = async (projectId, viewer) => {
  let scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    resourceName: 'companies',
    forceMSClientConfig: true,
  };

  const onData = (_data) => {
    if (!_data) return;

    let companies = Object.values(_data).reduce((companiesArray, company) => {
      const projectExistsInCompany = _.get(company, ['projects', projectId]);
      const shouldAddCompany = company.id && projectExistsInCompany;
      if (shouldAddCompany) companiesArray.push(company);
      return companiesArray;
    }, []);

    saveCompaniesToLocalDB(companies);
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    onData(result);
  }

  subscriptions[projectId] = true;
};

const endCompaniesListener = (projectId) => {
  ClientServerConnectivityManagerInstance.unregisterService({
    scope: 'projects',
    scopeId: projectId,
    subject: 'companies',
  });

  delete subscriptions[projectId];
};

export const removeAllCompaniesListeners = () => {
  Object.keys(subscriptions).forEach((projectId) => {
    endCompaniesListener(projectId);
  });
};

export const getAllProjectsCompanies = () => {
  let projectsMap = getAppState().projects.map.safeToJS();

  let companyIdsMap = Object.values(projectsMap).reduce((map, project) => {
    map[project.companyId] = project.companyId;
    return map;
  }, {});

  return getCompaniesById(companyIdsMap);
};

export const getCompaniesById = async (companyIdsMap) => {
  const companiesIdArr = Object.keys(companyIdsMap || {});

  let url = `${envParams.apiServer}/v1/alternative/companies`;

  let params = {
    method: 'POST',
    body: JSON.stringify({ ids: companiesIdArr }),
  };

  let resp = await platformActions.net.fetch(url, params);
  const companies = await resp.json();
  saveCompaniesToLocalDB(Object.values(companies));

  return companies;
};

export const getCompanyById = async (companyId) => {
  let resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/companies/${companyId}`);
  const company = await resp.json();

  return company;
};

export const getCompanyByName = async (companyName) => {
  let resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/companies?names=["${companyName}"]`);
  const company = await resp.json();
  if (!Object.keys(company.safeToJS()).length) return null;

  saveCompaniesToLocalDB([company]);
  return company;
};

export const upsertCompany = async (companyParams) => {
  const isCreation = !companyParams.id;

  let updatedCompanyParams = {
    name: companyParams.name,
    projects: companyParams.projects,
    trades: companyParams.trades,
    types: companyParams.types,
    logo: companyParams.logo,
    darkLogo: companyParams.darkLogo,
  };

  if (!isCreation) updatedCompanyParams.id = companyParams.id;

  try {
    const images = [
      { key: 'logo', image: companyParams.logo },
      { key: 'darkLogo', image: companyParams.darkLogo },
    ];

    for (const { image, key } of images) {
      if (!image) continue;

      const uploadedImageUri = await uploadCompanyImage(key, image, companyParams.name);
      if (uploadedImageUri) updatedCompanyParams[key] = uploadedImageUri;
    }

    let url = envParams.apiServer + '/v1/companies';
    if (!isCreation) url += `/${encodeURIComponent(companyParams.id)}`;

    let method = isCreation ? 'POST' : 'PUT';
    let body = JSON.stringify(updatedCompanyParams);

    let resp = await platformActions.net.fetch(url, { method, body });

    const newCompany = await resp.getJson();
    saveCompaniesToLocalDB([{ ...newCompany }]);

    return newCompany;
  } catch (error) {
    return { success: false, errorType: error };
  }
};

export const getProjectGCs = (projectId) => {
  let projectGCs = [];

  const localDB = platformActions.localDB.getCementoDB();
  let companies = localDB.get('companies');
  companies.loopEach((compId, comp) => {
    if (comp && comp.getNested(['projects', projectId, 'types', companyTypes.COMPANY_TYPES_GC])) {
      projectGCs.push(comp);
    }
  });
  return projectGCs;
};

export const saveCompaniesToLocalDB = (companies, isDeleted) => {
  const localDB = platformActions.localDB.getCementoDB();

  const processedCompanies = companies
    .map((company) => {
      let proccessedCompany = {
        ...company.safeToJS(),
        isDeleted: Boolean(isDeleted),
      };
      return proccessedCompany;
    })
    .sort((a, b) => a.createdAt - b.createdAt);

  localDB.set('companies', processedCompanies);
};

const uploadCompanyImage = async (key, image, companyName) => {
  const imageUri = image?.uri || image || ''; // if img is not an object then it's a string
  const isImageUploadedAlready = imageUri.startsWith('https://');

  if (!imageUri || isImageUploadedAlready) return imageUri;

  const uploadedImageUri = await uploadImage(
    { uri: imageUri },
    'company_' + companyName + '_' + uuidv4() + '_' + key,
    'companies'
  );

  return uploadedImageUri;
};
