import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { Outlet } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { saveUIParams } from '../../../common/ui/actions';
import { envParams } from '../../../common/configureMiddleware';
import { startLoading, hideLoading, setLang } from '../../../common/app/actions';
import { HeaderLinks } from '../../components';
import { CompanyContext } from '../../../common/companies/contexts';
import { navigateToProject } from '../../../common/projects/funcs';
import { ProjectContext } from '../../../common/projects/contexts';
import _ from 'lodash';
import { platformActions } from '../../../common/platformActions';
import systemMessages from '../../../common/app/systemMessages';
import { v4 as uuidv4 } from 'uuid';

import theme from '../../assets/css/theme';
import Text from '../../components/CementoComponents/Text';
import companiesMessages from '../../../common/companies/companiesMessages';
import withRouterHOC from '../../components/Router/util/withRouterHOC';
import Paging from '../../components/CementoComponents/Paging';
import {
  getAllProjectsCompanies,
  removeAllCompaniesListeners,
} from '../../../common/companies/funcs';

const DEFAULT_LANG = 'en';
const PROJECTS_PER_PAGE = 10;
const MIN_PROJECTS_FOR_PAGINATION = 20;

const CompanyContainerPage = (props) => {
  const {
    project,
    detailedProjects,
    viewer,
    configurations,
    match,
    lang,
    hideLoading,
    startLoading,
    userId,
    isPdfMode,
    menus,
    uiParams,
    saveUIParams,
    setLang,
    intl,
    rtl,
    formUniversalIdsMap,
    tradesMap,
  } = props;

  const [page, setPage] = useState(0);
  const [headerParams, setHeaderParams] = useState({});

  useEffect(() => {
    hideLoading();
    updateLanguage();
  }, []);

  const contentType = useMemo(() => {
    return match?.params?.contentType;
  }, [match?.params?.contentType]);

  useEffect(() => {
    setPage(0);
  }, [contentType]);

  const selectedCompanyId = useMemo(() => {
    return match?.params?.selectedCompanyId;
  }, [match?.params?.selectedCompanyId]);

  const handleSetHeaderParams = useCallback(
    (newHeaderParams) => {
      let isOnlySideNav = Boolean(
        (!newHeaderParams || !newHeaderParams.headerComponent) && menus && menus[contentType]
      );

      if (uiParams.getNested(['onlySideNav']) !== isOnlySideNav) {
        saveUIParams({ onlySideNav: isOnlySideNav });
      }

      setHeaderParams(newHeaderParams);
    },
    [contentType, menus]
  );

  const updateLanguage = useCallback(() => {
    const projectLang = project.lang;
    if (projectLang && lang !== projectLang) setLang(projectLang);
  }, [project]);

  const isAdmin = useMemo(() => {
    let adminMode = viewer.getNested(['adminMode']);
    return Boolean(adminMode);
  }, [viewer]);

  const numberOfProjects = useMemo(() => {
    return Object.values(detailedProjects || {}).filter((project) => project.companyId === selectedCompanyId).length;
  }, [detailedProjects, selectedCompanyId]);

  const shouldUsePagination = useMemo(() => {
    const contentTypesThatShouldIncludePagination = ['safety', 'qa'];
    return (
      contentTypesThatShouldIncludePagination.includes(contentType) && numberOfProjects > MIN_PROJECTS_FOR_PAGINATION
    );
  }, [contentType, numberOfProjects]);

  const isFeatureActive = useMemo(() => {
    let feature = contentType === 'settings' ? ['settings', 'members'] : [contentType];
    const isActive = configurations.getNested(['features', 'companyView', ...feature, 'isActive']);
    return Boolean(isActive);
  }, [configurations, contentType]);

  const handleNavigateToProject = useCallback((projectId, innerPath) => {
    if (!projectId) return null;

    navigateToProject(props, projectId, innerPath, false, true);
  }, []);

  const setNextPage = useCallback((currPage) => {
    setPage(currPage);
  }, []);

  const fetchCompanyTablesData = useCallback(
    async (subject, newSelectedCompanyId, startTS, endTS) => {
      const { apiServer } = envParams;
      let companyId = newSelectedCompanyId || selectedCompanyId;
      let viewerId = isPdfMode ? userId : viewer.id;

      let validSubjects = ['safety', 'quality'];
      if (!validSubjects.includes(subject) || !companyId || !viewerId) {
        return null;
      }

      let operationId = uuidv4();
      startLoading({
        title: systemMessages.loadingMessage,
        overlay: true,
        operationId,
      });

      let tablesData;
      let url = `${apiServer}/v1/gateway/${subject}?companyId=${companyId}&viewerId=${viewerId}`;
      if (startTS) url += `&startTS=${startTS}`;
      if (endTS) url += `&endTS=${endTS}`;
      if (shouldUsePagination) url += `&page=${page}&limit=${PROJECTS_PER_PAGE}`;

      let res;

      try {
        let resp = await platformActions.net.fetch(url);
        let jsonResp = await resp.json();
        if (!jsonResp) res = null;

        res = jsonResp;
      } catch (error) {
        console.error(error);
        if (shouldUsePagination) {
          let newPage = page === 0 ? 0 : page - 1;
          setPage(newPage);
        }
        res = null;
      } finally {
        hideLoading(operationId);

        tablesData = typeof res === 'object' ? res : {};
        tablesData = Object.entries(tablesData).map(([key, value]) => ({
          id: key,
          ...value,
        }));
      }
      return tablesData;
    },
    [selectedCompanyId, page, shouldUsePagination]
  );

  const isPermitted = useMemo(() => {
    return isFeatureActive || isAdmin || contentType === '_' || contentType === 'home';
  }, [isFeatureActive, isAdmin, contentType]);

  if (!isPermitted) return <NotPermitted contentType={contentType} />;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: headerParams && headerParams.headerComponent ? 'column' : 'row',
        flex: 1,
      }}>
      <HeaderLinks
        key={`HeaderLinks_${contentType}`}
        defaultSelection={null}
        headerParams={headerParams}
        menus={menus}
        intl={intl}
        routingMode={false}
        menuMode={true}
        isSecondary={true}
      />
      <Outlet
        context={{
          selectedCompanyId: selectedCompanyId,
          onProjectClick: handleNavigateToProject,
          rtl: rtl,
          tradesMap: tradesMap,
          isPdfMode: isPdfMode,
          setHeaderParams: handleSetHeaderParams,
          viewer: viewer,
          fetchCompanyTablesData: fetchCompanyTablesData,
          page: page,
          formUniversalIdsMap: formUniversalIdsMap,
          shouldUsePagination,
        }}
      />
      <CompanySafetyPagination
        onClick={setNextPage}
        currPage={page}
        numberOfProjects={numberOfProjects}
        disable={!shouldUsePagination}
      />
    </div>
  );
};

const NotPermitted = (props) => {
  const { contentType } = props;
  return (
    <>
      <div
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}>
        <div style={{ flexDirection: 'column', margin: theme.margin * 3 }}>
          <Text
            key={`${contentType}Title`}
            style={{
              fontWeight: 'bold',
              fontSize: theme.fontSizeH3,
              color: theme.brandPrimary,
            }}>
            {_.get(companiesMessages, ['notPermittedFeature', 'companyView', `${contentType}Title`])}
          </Text>
          <br />
          <Text key={`${contentType}Content`} style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
            {_.get(companiesMessages, ['notPermittedFeature', 'companyView', `${contentType}Content`])}
          </Text>
        </div>
      </div>
    </>
  );
};

const CompanySafetyPagination = (props) => {
  const { currPage, numberOfProjects, disable, onClick } = props;
  const maxButtonsToShow = Math.max(Math.ceil(numberOfProjects / 100), 5);
  if (disable) return null;
  return (
    <Paging
      style={{
        display: 'flex',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        left: '50vw',
        bottom: theme.paddingSize,
        zIndex: theme.zIndexes.tablePagination,
      }}
      currPage={currPage}
      onClick={onClick}
      dataCount={numberOfProjects}
      countPerPage={PROJECTS_PER_PAGE}
      maxButtonsToShow={maxButtonsToShow} // TODO: this is a problem in safety table because we don't know the number of pages until we fetch
    />
  );
};

const enhance = compose(
  injectIntl,
  withRouterHOC,
  connectContext(CompanyContext.Consumer),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      projectsMap: state.projects.map,
      lang: state.app.lang,
      rtl: state.app.rtl,
      uiParams: state.ui.uiParams,
      formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
      tradesMap: state.trades.map,
      viewer: state.users.viewer,
    }),
    {
      startLoading,
      hideLoading,
      saveUIParams,
      setLang,
    }
  )
);
export default enhance(CompanyContainerPage);
