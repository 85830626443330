import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { platformActions } from '../platformActions';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { debugParams, getRoundedDate, removeEmpty, subscribeToLastUpdates } from '../lib/utils/utils';
import { getNewId, updateUsingFirebaseProxy } from '../lib/api';
import { hideLoading } from '../app/actions';
import { onError } from '../app/funcs';
import { envParams, getDispatch } from '../configureMiddleware';
import ExtraError from '../lib/errors/extraError';
import Form from './form';
import { checkAndUploadLocalObjects } from '../../native/app/funcs';

export const fetchForms = async (queryParams, lastUpdateTS) => {
  const apiServer = envParams.apiServer;
  let url = new URL(`${apiServer}/v1/forms`);
  _.forIn(queryParams, (val, key) => {
    url.searchParams.set(key, _.isString(val) ? val : JSON.stringify(val));
  });

  if (lastUpdateTS) {
    url.searchParams.set('lastUpdateTS', lastUpdateTS);
  }

  url = url.toString();
  const res = await platformActions.net.fetch(url);
  const forms = await res.json();
  return forms;
};

export const startFormsListener = (viewer, queryParams, onUpdate, immediateFetch) => {
  const { projectId, formType, locationId, formTemplateId, ids } = queryParams || {};

  let uniqueKey = null;
  if (ids?.length || locationId || formTemplateId) {
    uniqueKey = `formIdsListener_${ids?.join(',')}_${locationId}_${formTemplateId}`;
  }

  const saveFunc = async (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave) return;
    await saveFormsLocally(_data, projectId, formType, isRevoke);
    if (onUpdate) onUpdate?.(_data);
  };

  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    subject: `forms`,
    uniqueKey,
    queryParams,
    isObjectUseTransactionalDB: !uniqueKey,
    getData: (lastUpdatedTS) => fetchForms(queryParams, lastUpdatedTS),
    getLastUpdateTS: uniqueKey ? undefined : () => getLastUpdateTS(queryParams),
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, saveFunc, immediateFetch).then((data) => {
    if (immediateFetch) {
      saveFunc(data, false);
    }
  });

  return () => endFormsListener(projectId, formType, locationId, formTemplateId, uniqueKey);
};

export const saveFormsLocally = async (forms, projectId, formType, cleanAll) => {
  const localDB = platformActions.localDB.getCementoDB();

  if (cleanAll) {
    const deleteQuery = getLocalDBFormQuery({ projectId, formType, includeDeleted: true });
    await localDB.unset('forms', deleteQuery);
  }

  if (_.size(forms)) {
    await localDB.set(
      'forms',
      _.map(forms, (f) => removeEmpty(new Form({ ...f, projectId })))
    );
  }
};

function getLastUpdateTS(queryParams) {
  let lastUpdateTS = 0;
  const forms = getLocalForms(queryParams);
  lastUpdateTS = _.maxBy(forms, 'updatedTS')?.updatedTS || 0;
  return lastUpdateTS;
}

export function endFormsListener(projectId, formType, locationId, formTemplateId, uniqueKey) {
  ClientServerConnectivityManagerInstance.unregisterService({
    uniqueKey,
    scope: 'projects',
    scopeId: projectId,
    subject: 'forms',
    params: removeEmpty({ formType, locationId, formTemplateId }),
  });
}

const getRealmQuery = (query, includeDeleted) => {
  let realmQuery = '';
  realmQuery = Object.entries(_.omit(query, ['ids', 'id', 'locationId']))
    .map(([key, value]) => `${key} == "${value}"`)
    .join(' AND ');

  if (!includeDeleted) {
    realmQuery = realmQuery + ` AND isDeleted != true`;
  }

  if (query?.ids?.length > 1) {
    realmQuery = realmQuery + ` AND id IN (${query?.ids.map((id) => `"${id}"`).join(',')})`;
  } else if (query?.id) {
    realmQuery = realmQuery + ` AND id == "${query.id}"`;
  }

  if (query?.locationId) {
    realmQuery =
      realmQuery +
      ` AND (location.unit.id == "${query.locationId}" OR location.floor.id == "${query.locationId}" OR location.building.id == "${query.locationId}")`;
  }

  return realmQuery;
};

const getLokiQuery = (query, includeDeleted) => {
  let lokiQuery = { ..._.omit(query, ['ids', 'id', 'locationId']) };

  if (!includeDeleted) {
    lokiQuery.isDeleted = { $ne: true };
  }

  if (query.locationId) {
    lokiQuery = {
      ...lokiQuery,
      ['$or']: [
        { 'location.unit.id': query.locationId },
        { 'location.floor.id': query.locationId },
        { 'location.building.id': query.locationId },
      ],
    };
  }

  if (query.ids?.length > 1) {
    lokiQuery.id = { $in: query.ids };
  } else if (query.id) {
    lokiQuery.id = query.id;
  }

  return lokiQuery;
};

export const getLocalDBFormQuery = ({ projectId, formType, ids, formTemplateId, locationId, includeDeleted }) => {
  const isNative = platformActions.app.isNative();
  let queryParams = removeEmpty({
    projectId,
    type: formType,
    formTemplateId,
    id: ids?.length === 1 ? ids[0] : undefined,
    ids: ids?.length > 1 ? ids : undefined,
    locationId,
  });

  queryParams = isNative ? getRealmQuery(queryParams, includeDeleted) : getLokiQuery(queryParams, includeDeleted);

  return queryParams;
};

export const getLocalForms = (queryParams) => {
  const { projectId, formType, ids, formTemplateId, locationId } = queryParams;
  let forms = [];
  if (projectId && (formType || ids)) {
    const localDB = platformActions.localDB.getCementoDB();
    const query = getLocalDBFormQuery({ projectId, formType, ids, formTemplateId, locationId, includeDeleted: false });
    forms = localDB.get('forms', query);
  }
  return forms;
};

export async function deleteForm(projectId, form, type = 'general') {
  let updates = {};
  updates[`forms/${projectId}/full/${type}/${form.id}/isDeleted`] = true;
  await updateUsingFirebaseProxy({ projectId, type: `forms_${type}`, updates }); /// TODO: PUT through api server
  await saveFormsLocally([{ ...form, generator: { id: form.generator.id }, isDeleted: true }], projectId, form.type);
}

export const upsertForm = async (projectId, viewer, form, type = 'general', shouldAlertUser = true) => {
  const originalForm = getLocalForms({ projectId, formType: type, ids: [form.id] })[0];
  const generator = originalForm?.generator || { id: viewer.id, displayName: viewer.displayName };

  if (!type) throw new ExtraError('upsertForm error - form type missing', { form, type });

  const dispatch = getDispatch();

  let newForm = _.pick(form, [
    'parentId',
    'certifications',
    'formTemplateId',
    'id',
    'title',
    'uri',
    'owner',
    'status',
    'checklists',
    'location',
    'universalIds',
    'posts',
    'signatures',
    'reportDate',
    'targetEmails',
    'sections',
    'type',
    'usersToNotify',
    'isDocx',
    'createdTS',
    'parentId',
    'isDeleted',
  ]);

  if (!newForm.type) newForm.type = type;

  if (!newForm.createdTS) newForm.createdTS = Date.now();

  if (!newForm.reportDate) newForm.reportDate = getRoundedDate().timestamp;

  if (newForm.location) {
    const { unitId, buildingId, floorId } = newForm.location;
    const locationId = unitId || floorId || buildingId;

    if (locationId && platformActions.app.isNative()) {
      await checkAndUploadLocalObjects(projectId, locationId);
    }
  }

  newForm.id = newForm.id || getNewId();

  if (!newForm.parentId && newForm.type === 'dailyReport') newForm.parentId = newForm.id;

  const shouldRemoveOldUri = originalForm?.uri && (Object.keys(newForm.signatures || {}) !== Object.keys(originalForm?.signatures || {}));
  if (shouldRemoveOldUri) newForm.uri = null;

  newForm.updatedTS = Date.now();
  newForm.generator = generator;
  newForm = removeEmpty(newForm, 'upsertForm');

  saveFormsLocally([{ ...newForm, isLocal: true }], projectId, newForm.type);

  const timeout = 45 * 1000;
  let promise = async function (projectId, type, newForm) {
    return new Promise(function (resolve, reject) {
      if (!projectId || !newForm) reject('Missing projectId or form');

      setTimeout(() => {
        if (!didResolved) {
          didRejected = true;
          reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');
        }
      }, timeout);

      let updates = {};
      updates['forms/' + projectId + '/full/' + type + '/' + newForm.id] = newForm;

      let didResolved = false;
      let didRejected = false;

      updateUsingFirebaseProxy({
        projectId,
        type: `forms`,
        updates,
        callback: (error) => {
          if (error) {
            didRejected = true;
            reject(error);
          }

          if (didRejected) return;
          didResolved = true;
          resolve(newForm);
        },
      });
    });
  };

  let upsertFormRes;
  let success = true;

  try {
    upsertFormRes = await promise(projectId, type, newForm);
    if (upsertFormRes) {
      upsertFormRes = _.omit(upsertFormRes, 'updatedTS');
    }
  } catch (error) {
    success = false;
    onError({
      errorMessage: 'Failed to upsert form',
      error,
      alertParams: !shouldAlertUser
        ? null
        : {
            title: reportsMessages.exportErrors.title,
            message: reportsMessages.exportErrors.content,
          },
      methodMetaData: {
        name: 'upsertForm',
        args: { projectId, viewer, form, type },
      },
    });
  } finally {
    dispatch(hideLoading());
  }

  return { projectId, reportId: newForm.id, form: upsertFormRes, success };
};

export const sendFormViaMailServer = async (projectId, formId, formType, targetEmails, subject, text) => {
  await platformActions.net.fetch(envParams.apiServer + '/v1/services/email/send/forms', {
    method: 'POST',
    body: JSON.stringify({
      projectId,
      formId,
      formType,
      targetEmails,
      subject,
      text,
    }),
  });

  return { projectId, formId, formType, targetEmails, subject, text };
};
