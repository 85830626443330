import React from "react";
import { connectContext } from 'react-connect-context';
import { ReportContext } from '../../../common/analytics/contexts'; 
import { newColumnWidth } from './TableColumnHeader.js';

class TableSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { section, columnFilter } = this.props;

    let subColumnsCounter = 0;
    section.columns.forEach(currSubColumn => {
      if (Boolean(columnFilter) && !columnFilter(currSubColumn))
        return;
      subColumnsCounter++;
    });

    return (
      <th key={'th-section-header-'.concat(section.original.id)} style={{...section.style, background: 'white', width: subColumnsCounter * newColumnWidth, borderTop: 0, whiteSpace: 'nowrap'}} colSpan={subColumnsCounter}>
        {section.Header(section.original)}
      </th>
    )
  }
}

export default connectContext(ReportContext.Consumer)(TableSection)
