import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { injectIntl } from 'react-intl';
import { ProjectContext } from '../../../common/projects/contexts';
import theme from '../../assets/css/theme';
import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import { DetailsSection, SubcontractorSection } from './UsersManagementCardConfig';
import trash from '../../assets/img/icons/trash.png';
import { updateOtherUserProfile, createFromLocalContact } from '../../../common/members/actions';
import { upsertCompany } from '../../../common/companies/funcs';
import { onDraftModeChange, draftValidator } from '../../../common/ui/actions';
import { startToast, hideLoading, startLoading } from '../../../common/app/actions';
import { getObjectFromServer } from '../Reports/funcs';
import { checkError } from '../../../common/propertiesTypes/funcs';
import createClass from 'create-react-class';
import _ from 'lodash';
import * as companiesTypes from '../../../common/companies/companiesTypes.js';

//Components
import InnerCollapsibleRow from '../../components/CementoComponents/InnerCollapsibleRow';
import AddNewButton from '../../components/CementoComponents/AddNewButton';
import MenuScrollbar from '../../components/CementoComponents/MenuScrollbar';
import InnerCollapsible from '../../components/CementoComponents/InnerCollapsible';
import InputField from '../../components/CementoComponents/InputField';
import Text from '../../components/CementoComponents/Text';
import Select, { components } from 'react-select';
import StandardInput from '../../components/CementoComponents/StandardInput';

//Messages
import projectMessages from '../../../common/projects/projectsMessages';
import usersMessages from '../../../common/users/usersMessages';
import systemMessages from '../../../common/app/systemMessages';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import newPostMessages from '../../../common/posts/newPostMessages';
import companiesMessages from '../../../common/companies/companiesMessages';

//material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Button } from '@material-ui/core';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies/index.js';

class UsersManagementCard extends React.Component {
	constructor(props) {
		super(props);
		this.setComponentData = this.setComponentData.bind(this);
		this.calcSection = this.calcSection.bind(this);
		this.calcInputField = this.calcInputField.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onProjectDelete = this.onProjectDelete.bind(this);
		this.onSelectNewProject = this.onSelectNewProject.bind(this);
		this.updateMemberTrades = this.updateMemberTrades.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.filterProjects = this.filterProjects.bind(this);
		this.handleConfirmDeleteProject = this.handleConfirmDeleteProject.bind(this);
		this.validateCompanyName = this.validateCompanyName.bind(this);
		this.getObjectFromServer = this.getObjectFromServer.bind(this);
		this.handleDeleteMemberConfirm = this.handleDeleteMemberConfirm.bind(this);
		this.fieldsRef = {};
		this.state = {
			objectToShow: {},
			projectsList: {},
			newSelection: false,
			companyProjects: {},
			currActiveSection: null,
			currOpenedSections: {},
			newCompany: false,
			beforeValidate: true,
			companySubcontractors: {},
			error: null,
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	componentWillUnmount() {
		const { onDraftModeChange } = this.props;
		onDraftModeChange(false);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.objectId !== nextProps.objectId || this.props.editMode !== nextProps.editMode)
			this.setComponentData(this.props, nextProps);
	}

	async setComponentData(props, nextProps) {
		let { projects, companies, objectId, onCardLoad, creation, editMode, objectType, hideNavigation } = nextProps;
		const { draftValidator, changeEditMode = () => {} } = this.props;
		let object = {};
		let newStateChanges = {};
		let newSideCardEditMode = nextProps.editMode;
		companies = companies.toJS ? companies.toJS() : companies;

		let optionalCompanies = {};

		(Object.values(projects || [])).forEach(project => {
			Object.values(companies).forEach(company => {
				let projectInCompany = company.getNested(['projects', project.id], null);
				if (projectInCompany && !projectInCompany.isDeleted) optionalCompanies[company.id] = company;
			});
		});

		newStateChanges.companySubcontractors = optionalCompanies;

		if (props.objectId !== nextProps.objectId) {
			newSideCardEditMode = false;
			if (_.isNull(nextProps.objectId) && nextProps.creation) {
				newSideCardEditMode = true;
				newStateChanges.objectToShow = {};
				newStateChanges.projectsList = {};
			}
		}

		hideNavigation(editMode);

		if (!creation) {
			object = await this.getObjectFromServer(objectId);
			if (_.keys(object).length) {
				newStateChanges.objectToShow = object;
				newStateChanges.projectsList = this.filterProjects(object.projects, object.companyId);
			}
		}

		let headerOptions = {
			title: object ? object.displayName || object.name : '',
			editable: true,
			onCancel: () => new Promise((resolve) =>
				draftValidator(
				() => {
					this.handleCancel()
					resolve(true);
				},
				() => resolve(false)
				)
		  	),
			onSave: this.onSave,
		};

		if (objectType == 'members') headerOptions.onDelete = () => this.onDelete();

		onCardLoad(headerOptions, {}, newSideCardEditMode);

		this.setState(newStateChanges, () => {
			if (nextProps.editMode !== newSideCardEditMode || props.editMode !== nextProps.editMode)
				changeEditMode(newSideCardEditMode);
		});
	}

	handleInputChange(pathToValue, val) {
		const { objectToShow } = this.state;
		const { onDraftModeChange } = this.props;
		let member = { ...objectToShow };
		if (pathToValue == 'title' || pathToValue == 'companyId') member[pathToValue] = Object.keys(val)[0];
		else member[pathToValue] = val;

		onDraftModeChange(true);
		this.setState({ objectToShow: member });
	}

	onProjectDelete(projectToDelete) {
		const { startToast, projects, intl, subjectType, objectId } = this.props;
		const { yes, no } = systemMessages;
		const { title, content, extraWarning } = systemMessages.deleteMemberProjectAlert;
		const membersCount = Object.values(projects.getNested([projectToDelete, 'members']) || {}).filter(
			member => member.companyId == objectId,
		).length;
		const membersCountMessage = intl.formatMessage(extraWarning, {
			num: membersCount,
		});
		let warningContent;

		if (subjectType == 'companies' && membersCount) warningContent = content.defaultMessage + ' ' + membersCountMessage;
		else warningContent = content;

		startToast({
			overlay: true,
			mandatory: true,
			title: title,
			message: warningContent,
			actions: [
				{
					message: yes,
					color: 'danger',
					onClick: () => this.handleConfirmDeleteProject(projectToDelete),
				},
				{ message: no },
			],
		});
	}

	handleConfirmDeleteProject(projectToDelete) {
		const { creation } = this.props;
		const { projectsList } = this.state;
		let newProjects = { ...projectsList };

		if (creation) delete newProjects[[projectToDelete]];
		else
			newProjects[projectToDelete] = {
				...newProjects[projectToDelete],
				isDeleted: true,
			};

		onDraftModeChange(true);
		this.setState({ projectsList: newProjects });
	}

	onSelectNewProject(newProject) {
		const { onDraftModeChange, companyProjects } = this.props;
		const { projectsList } = this.state;

		let newProjectList = Object.assign({}, projectsList);
		const newProjectId = _.head(_.keys(newProject));
		const newProjectTitle = _.get(companyProjects, [newProjectId], {}).getCementoTitle();
		newProjectList[newProjectId] = { id: newProjectId, title: newProjectTitle };
		onDraftModeChange(true);
		this.setState({ projectsList: newProjectList });
	}

	async onSave() {
		const {
			intl,
			updateOtherUserProfile,
			createFromLocalContact,
			startLoading,
			hideLoading,
			startToast,
			onMemberUpdate,
			onCompanyUpdate,
			onDraftModeChange
		} = this.props;
		const { changeEditMode, objectId, creation, subjectType, selectedProjectId } = this.props;
		const { displayName, phoneNumber, email, title, trades, companyId, name, logo, darkLogo, id } =
			this.state.objectToShow;
		const { projectsList, newCompany } = this.state;
		const { ok, errorOnSave, invalidPhoneNumber, updating, existsCompanyName } = systemMessages;
		const { profileUpdated, profileCreated } = usersMessages;
		const { newCompanyPropertiesValidation } = companiesMessages;
		let projectsObject = {};
		let tradesObject = {};
		let saveContent = creation ? profileCreated : profileUpdated;
		if (this.fieldsRef) {
			let errors = {};
			let propName = intl.formatMessage(usersMessages.titles.projects);
			this.fieldsRef.loopEach((propId, ref) => {
				const { intl, prop, value } = ref.component.props;
				let err = checkError(value, prop, null, null, intl, selectedProjectId);
				if (err) errors[propId] = err.join(', ');
				else if (creation && Object.values(projectsList || {}).filter(p => !p.isDeleted).length == 0)
					errors['projects'] = intl.formatMessage(propertiesMessages.errors.mandatory, { propName });
			});
			if (Object.keys(errors).length) {
				startToast({
					title: systemMessages.invalidDetailsDescription,
					values: { errors: Object.values(errors).join('\n') },
					type: 'error',
				});
				this.setState({ error: Object.values(errors) });
				return;
			}
		}

		if (subjectType == 'companies') {
			Object.keys(trades || {}).forEach(trade => (tradesObject[trade] = trade));

			Object.values(projectsList).forEach(project => {
				projectsObject[project.id] = {};
				if (project.isDeleted) projectsObject[project.id] = { isDeleted: true };
			});

			const projectsLength = Object.values(projectsObject || {}).filter(proj => !proj.isDeleted).length;
			const tradesLength = Object.values(tradesObject || {}).length;
			const companyValidate = name.length && (!creation || (creation && projectsLength > 0 && tradesLength > 0));

			if (companyValidate) {
				startLoading({
					title: updating,
					overlay: true,
					hideOnBackgroundPress: false,
					operationId: 'companyUpdate',
				});
				let companyToUpdate = {};
				const isCreation = creation && newCompany;
				let companyProjects = {};
				Object.keys(projectsObject).forEach(projectId => {
					companyProjects[projectId] = {
						types: {
							[companiesTypes.COMPANY_TYPES_SUB]: companiesTypes.COMPANY_TYPES_SUB
						},
						updatedTS: Date.now()
					}
				})
				const companyParams = {
					id: isCreation ? null : id,
					name,
					trades: tradesObject,
					projects: projectsObject,
					logo,
					darkLogo,
				};

				companyToUpdate = await upsertCompany(companyParams)

				const didSavedSuccessfully = _.get(companyToUpdate, 'success', true);

				if (didSavedSuccessfully) {
					onCompanyUpdate(companyToUpdate);
					this.setState({ objectToShow: companyToUpdate });
					changeEditMode(false);
					onDraftModeChange(false);
				}

				hideLoading('companyUpdate');
				let toastDetails = { title: saveContent, type: 'success' };
				if (!didSavedSuccessfully) {
					toastDetails.type = 'error';
					const errorType = _.get(companyToUpdate, 'errorType');

					if (errorType === 'Conflict') toastDetails.title = existsCompanyName;
					else toastDetails.title = errorOnSave;
				}
				startToast(toastDetails);
			} else {
				startToast({
					overlay: true,
					mandatory: true,
					message: newCompanyPropertiesValidation,
					actions: [{ message: ok }],
				});
				hideLoading();
			}
		} else {
			Object.values(projectsList).forEach(project => {
				projectsObject[project.id] = { companyId: companyId };
				if (project.isDeleted)
					projectsObject[project.id] = {
						...projectsObject[project.id],
						isDeleted: true,
					};
			});

			startLoading({
				title: updating,
				overlay: true,
				hideOnBackgroundPress: false,
				operationId: 'UpdatingMember',
			});
			let updatedMember = {};
			if (creation)
				updatedMember = await createFromLocalContact(
					{},
					{ displayName, email, title, companyId, trades, phoneNumber, projectsObject },
					'global',
					null,
					true,
				);
			else
				updatedMember = await updateOtherUserProfile(
					objectId,
					{ displayName, email, companyId },
					{ trades, projectsObject, title },
					'global',
					true,
				);

			if (updatedMember) {
				onMemberUpdate(updatedMember.DBMember || updatedMember);
				changeEditMode(false);
				onDraftModeChange(false);
			} 
			hideLoading('UpdatingMember');
			startToast(updatedMember ? { title: saveContent, type: 'success' } : { title: invalidPhoneNumber, type: 'error' });
		}
	}

	onDelete() {
		const { startToast } = this.props;
		const { removeEmployee } = propertiesMessages;

		startToast({
			overlay: true,
			mandatory: true,
			message: removeEmployee,
			actions: [
				{
					message: systemMessages.yes,
					color: 'danger',
					onClick: () => this.handleDeleteMemberConfirm(),
				},
				{ message: systemMessages.no },
			],
		});
	}

	async handleDeleteMemberConfirm() {
		const { startToast, updateOtherUserProfile, objectId, onMemberUpdate, onCancel, startLoading, hideLoading } =
			this.props;
		const { profileDeleted } = usersMessages;
		const { updating } = systemMessages;
		const { projects } = this.state.objectToShow;
		let projectsObject = {};
		Object.entries(projects || {}).map(
			([projectId, project]) => (projectsObject[projectId] = { ...project, isDeleted: true }),
		);
		startLoading({
			title: updating,
			overlay: true,
			hideOnBackgroundPress: false,
		});

		let deletedMember = await updateOtherUserProfile(objectId, {}, { projectsObject }, 'global', true);
		if (deletedMember && deletedMember.DBMember) {
			onMemberUpdate(deletedMember.DBMember);
			startToast({ title: profileDeleted, type: 'success' });
			if (onCancel) onCancel();
		}
		hideLoading();
	}

	async handleCancel() {
		const { changeEditMode, onClose, creation } = this.props;

		if (changeEditMode) changeEditMode(false);

		if (creation && onClose) onClose();

		this.setState({ newSelection: false });
	}

	calcInputField(field, key, sectionId) {
		let { name, type, pathToValue, props = {} } = field;
		const { innerStyle, style, mandatory = false } = props;
		const { projectCompanies } = this.props;
		let { objectToShow, companySubcontractors, beforeValidate, error } = this.state;
		let { titles, editMode, creation, subjectType, rtl } = this.props;
		const beforeCompanyCreationValidate = Boolean(
			subjectType == 'companies' && creation && beforeValidate && type == 'Picture',
		);
		const companyNameChosen = Boolean(
			subjectType == 'companies' && pathToValue == 'name' && creation && !beforeValidate,
		);
		const isPhoneField = Boolean(pathToValue == 'phoneNumber' && !creation);
		const customInnerStyle =
			pathToValue == 'phoneNumber' && rtl ? Object.assign(innerStyle, { textAlignLast: 'end' }) : innerStyle;
		titles = Object.values(titles.toJS())
			.map(t => ({ id: t.id, title: t.getCementoTitle(), value: t.id, label: t.getCementoTitle() }))
			.sort((a, b) => (a.title || '').localeCompare(b.title));
		companySubcontractors = Object.values(projectCompanies || {})
			.map(c => ({ id: c.id, title: c.name }))
			.sort((a, b) => (a.title || '').localeCompare(b.title));
		let value = objectToShow.getNested(pathToValue, null);

		const titleStyle = {
			color: mandatory && error && !value ? 'red' : theme.headerColorDark,
			display: 'flex',
			marginBottom: 5,
			fontWeight: theme.strongBold,
		};

		if (value) {
			if (type === 'SelectionList') value = { [value]: value };
			else if (type === 'Picture') value = { uri: value.data || value };
		}
		return (
			!beforeCompanyCreationValidate && (
				<InnerCollapsibleRow doPropagate fullWidth key={`choose_new_company_name_${key}`}>
					<StandardInput
						disabled={companyNameChosen || isPhoneField || !editMode}
						disabledValueStyle={customInnerStyle}
						title={name}
						type={type}
						key={`UsersManagementCard_${sectionId}_${key}`}
						value={value}
						options={pathToValue == 'title' ? titles : companySubcontractors}
						onChange={val => this.handleInputChange(pathToValue, val)}
						style={style}
						innerStyle={customInnerStyle}
						titleStyle={titleStyle}
						error={error}
						prop={{
							mandatory: Boolean(mandatory && !value),
							title: name,
							type: type,
						}}
						onRef={(e, c) => {
							this.fieldsRef[pathToValue] = { element: e, component: c };
						}}
					/>
				</InnerCollapsibleRow>
			)
		);
	}

	calcSection(section) {
		const { title, fields, id } = section;
		const { trades, editMode, creation, classes, intl, lang, subjectType } = this.props;
		const { objectToShow, beforeValidate } = this.state;
		let newTrades = Object.values(trades.toJS())
			.filter(t => t.getNested(['title', lang]))
			.map(t => ({ value: t.id, label: t.getCementoTitle() }))
			.sort((a, b) => (a.label || '').localeCompare(b.label));
		let memberTrades = Object.keys(objectToShow.trades || {}).map(t => ({
			value: t,
			label: trades.get(t) ? trades.get(t).getCementoTitle() : null,
		}));
		const beforeCompanyCreationValidate = Boolean(subjectType == 'companies' && creation && beforeValidate);
		const customStyles = {
			valueContainer: styles => ({
				...styles,
				padding: '0px',
				margin: '0px',
				border: '0px',
				borderRadius: '0px',
			}),
			container: styles => ({
				...styles,
				width: '100%',
				border: '0px',
				borderRadius: '0px',
			}),
			option: (styles, { isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					zIndex: theme.zIndexes.usersManagementCard,
					backgroundColor: theme.backgroundColorBright,
					color: isDisabled
						? '#ccc'
						: isSelected
						? theme.brandPrimary
						: isFocused
						? theme.brandPrimary
						: theme.brandNeutralDark,
					cursor: isDisabled ? 'not-allowed' : 'default',
				};
			},
			control: (styles, { isFocused, isSelected }) => {
				return {
					...styles,
					backgroundColor: 'transparent',
					border: '0px',
					borderBottom: isFocused || isSelected ? '1.5px solid ' + theme.brandPrimary : '1px solid hsl(0,0%,80%)',
					borderRadius: '0px',
					boxShadow: '0px',
					cursor: 'pointer',
				};
			},
			menuList: styles => ({ ...styles, zIndex: theme.zIndexes.usersManagementCard }),
			menu: styles => ({ ...styles, zIndex: theme.zIndexes.usersManagementCard }),
		};

		//border-bottom: 1px solid hsl(0,0%,80%)
		const MultiValue = props => {
			return (
				<components.MultiValue {...props}>
					<span>{props.data.label}</span>
				</components.MultiValue>
			);
		};

		const Option = createClass({
			render() {
				return (
					<div>
						<components.Option {...this.props}>
							<Checkbox
								color={theme.brandPrimary}
								checked={this.props.isSelected}
								style={{ height: '8px', width: '8px' }}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{ checked: classes.checked }}
							/>{' '}
							<label style={{ color: 'black' }}>{this.props.label} </label>
						</components.Option>
					</div>
				);
			},
		});

		const filteredMemberTrades = memberTrades.filter(t => Boolean(t.label))

		return (
			<InnerCollapsible
				fullWidth
				key={`managerCard_${title}_${id}`}
				keyId={`managerCard_${title}_${id}`}
				title={title}
				open={true}
				isFocused={true}
				isSelected={true}
			>
				{fields.map((field, index) => this.calcInputField(field, index, id))}
				{beforeCompanyCreationValidate && (
					<Button fullWidth onClick={() => this.validateCompanyName()}>
						<Text>{systemMessages.continue}</Text>
					</Button>
				)}
				{!beforeCompanyCreationValidate && (
					<InnerCollapsibleRow doPropagate fullWidth>
						{!editMode ? (
							<InputField
								name={usersMessages.trade}
								type={'String'}
								value={filteredMemberTrades
									.map(t => t.label)
									.join(', ')}
								disabled={true}
							/>
						) : (
							<>
								<div
									style={{
										transition: 'all 150ms ease 0s',
										color: theme.headerColorDark,
										display: 'flex',
										justifyContent: 'space-between',
										marginBottom: 5,
										fontWeight: theme.strongBold,
									}}
								>
									<Text>{usersMessages.trade}</Text>
								</div>
								<Select
									fullWidth
									isMulti={true}
									isDisabled={!editMode}
									options={newTrades}
									value={filteredMemberTrades}
									placeholder={intl.formatMessage(newPostMessages.title_3)}
									onChange={changedTrades => this.updateMemberTrades(changedTrades)}
									styles={customStyles}
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									components={{ Option, MultiValue }}
									theme={originalTheme => ({
										...originalTheme,
										colors: {
											...originalTheme.colors,
											primary: theme.brandPrimary,
										},
									})}
								/>
							</>
						)}
					</InnerCollapsibleRow>
				)}
			</InnerCollapsible>
		);
	}

	validateCompanyName() {
		const { name } = this.state.objectToShow;
		const { companies, projects, startToast } = this.props;
		const { writeCompanyName } = companiesMessages;
		let newStateChanges = {};

		if (!name || (name && !name.trim())) {
			startToast({
				overlay: true,
				mandatory: true,
				message: writeCompanyName,
				actions: [{ message: systemMessages.continue }],
			});
			return;
		}

		const isExist = Object.values(companies.safeToJS() || {}).filter(x => x.getNested(['name']) == (name || '').trim());

		if (isExist.length > 0) {
			const newObject = isExist.getNested([0], null);
			newStateChanges.objectToShow = newObject;

			let objectProjects = this.filterProjects(newObject.projects, newObject.id);
			let projectsList = {};
			Object.keys(objectProjects).forEach(projId => {
				let currProjectTitle = projects.getNested([projId, 'title']) || projects.getNested([projId, 'address']);
				projectsList[projId] = { id: projId, title: currProjectTitle };
			});
			newStateChanges.projectsList = projectsList;
		} else {
			newStateChanges.newCompany = true;
			newStateChanges.objectToShow = { name };
		}

		newStateChanges.beforeValidate = false;
		this.setState(newStateChanges);
	}

	updateMemberTrades(changedTrades) {
		let updatedMember = Object.assign({}, this.state.objectToShow);
		let trades = {};
		changedTrades.forEach(trade => {
			//trade.value hold the id
			trades[trade.value] = trade;
		});
		updatedMember['trades'] = trades;
		onDraftModeChange(true);
		this.setState({ objectToShow: updatedMember });
	}

	async getObjectFromServer(id) {
		const { subjectType } = this.props;
		let object = {};

		if (subjectType === 'members') {
			let member = await getObjectFromServer(id, 'users');
			object = _.get(member, [id, 'user_metadata'], {});
		} else if (subjectType === 'companies') {
			let company = await getObjectFromServer(id, 'companies');
			object = _.get(company, id, {});
		}

		return object;
	}

	filterProjects(projectsMap, companyId) {
		const { companyProjects } = this.props;
		let projectsList = {};

		Object.entries(projectsMap || {}).forEach(([projectId, project]) => {
			let currentProjectObjet = _.get(companyProjects, [projectId], null);
			if (!project.isDeleted && currentProjectObjet && project.companyId === companyId) {
				const projectTitle = _.get(
					companyProjects,
					[projectId, 'title'],
					_.get(companyProjects, [projectId, 'title'], ''),
				);
				projectsList[projectId] = { id: projectId, title: projectTitle };
			}
		});

		return projectsList;
	}

	render() {
		const { companyProjects, rtl, editMode, creation, subjectType } = this.props;
		const { projectsList, newSelection, beforeValidate } = this.state;
		const sectionToRender = subjectType == 'members' ? DetailsSection : SubcontractorSection;
		const projectsToShow = Object.values(projectsList || {}).filter(project => !project.isDeleted);
		const beforeCompanyCreationValidate = Boolean(subjectType == 'companies' && creation && beforeValidate);

		return (
			<MenuScrollbar>
				<div style={{ padding: theme.padding * 2 }}>
					{sectionToRender.map(section => this.calcSection(section))}
					{!beforeCompanyCreationValidate && (
						<InnerCollapsible fullWidth title={usersMessages.titles.projects} open={true}>
							{(projectsToShow || {})
								.sort((a, b) => a.getNested(['title'], '').localeCompare(b.getNested(['title'], '')))
								.map(project => (
									<InnerCollapsibleRow
										key={project.title}
										fullWidth
										title={project.title}
										isEditMode={editMode}
										editIcons={[
											{
												icon: trash,
												onClick: () => this.onProjectDelete(project.id),
												style: { height: '18px' },
											},
										]}
									/>
								))}
							{Boolean(editMode && newSelection) && (
								<InnerCollapsibleRow fullWidth>
									<InputField
										type={'SelectionList'}
										key={'projectsList'}
										placeholder={projectMessages.selectProject}
										values={Object.entries(companyProjects || {})
											.map(([projectId, project]) => ({
												id: projectId,
												title: project.getCementoTitle(),
											}))
											.filter(p => !projectsList[p.id])}
										onChange={val => this.onSelectNewProject(val)}
										onRef={(e, c) => {
											this.fieldsRef['projectsList'] = {
												element: e,
												component: c,
											};
										}}
									/>
								</InnerCollapsibleRow>
							)}
							{Boolean(editMode) && (
								<AddNewButton
									title={projectMessages.addNewProject}
									onClick={() => {
										this.setState({ newSelection: true });
									}}
									style={{
										...styles.addNewButtonStyles,
										[rtl ? 'marginRight' : 'marginLeft']: 10,
									}}
								/>
							)}
						</InnerCollapsible>
					)}
				</div>
			</MenuScrollbar>
		);
	}
}

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
	addNewButtonStyles: {
		margin: theme.margin,
	},
	checkedIcon: {
		color: theme.brandPrimary,
		width: '20px',
		height: '20px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	uncheckedIcon: {
		color: theme.brandPrimary,
		width: '0px',
		height: '0px',
		padding: '9px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
};

UsersManagementCard = injectIntl(UsersManagementCard);
UsersManagementCard = withStyles(theme.combineStyles(buttonStyle, styles))(UsersManagementCard);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	CompaniesHOC,
	connect(
		state => ({
			titles: state.titles.map,
			trades: state.trades.map,
			rtl: state.app.rtl,
		}),
		{
			updateOtherUserProfile,
			startToast,
			createFromLocalContact,
			hideLoading,
			startLoading,
			draftValidator,
			onDraftModeChange,
		},
	),
);
export default enhance(UsersManagementCard);
