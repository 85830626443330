import CompanyContainerPage from '../../views/Companies/CompanyContainerPage';

import Home from './nested/company/Home';
import Settings from './nested/company/Settings';
import Safety from './nested/company/Safety';
import Quality from './nested/company/Quality';
import SelectedCompanyRedirects from './nested/company/SelectedCompanyRedirects';

export default [
  {
    path: ':selectedCompanyId',
    element: <CompanyContainerPage />,
    children: [Home, Settings, Safety, Quality, ...SelectedCompanyRedirects],
  },
];
