import React, { useEffect, useMemo, useState } from 'react';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import Text from '../CementoComponents/Text';
import EmployeesPresenceLogs from '../../../common/app/components/EmployeesPresenceLogs';
import { populateObject } from '../../../common/propertiesInstances/funcs';
import { ActivityIndicator } from './CamerasMonitor';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import * as propertyTypesConst from '../../../common/propertiesTypes/propertiesTypes';

import _ from 'lodash';
import Avatar from '../CementoComponents/Avatar';
import theme from '../../assets/css/theme';
import moment from 'moment';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies';

const TOOLTIP_WIDTH = 275;
export const SITE_CONTROL_TOOLTIP_TYPES = {
  CAMERAS_STATUS: 'camerasStatus',
  EMPLOYEE: 'employee',
  COMPANY: 'company',
  DAY: 'day'
};


export default props => {
  const { ts, logs, id, type, presentCount, nonPresentCount, companyName, camerasMonitorData, styleOverwrite, lang } = props;
  const components = getInnerComponentsByType({ logs, id, type, presentCount, nonPresentCount, ts, companyName, camerasMonitorData, lang });

  return (
    <div style={ styleOverwrite || { width: TOOLTIP_WIDTH }}>
      {components}
    </div>
  );
};


const getInnerComponentsByType = ({ logs, type, presentCount, nonPresentCount, id, ts, companyName, camerasMonitorData, lang }) => {

  let components;
  switch (type) {
    case SITE_CONTROL_TOOLTIP_TYPES.EMPLOYEE:
      components = <>
        <EmployeeAvatarBox employeeId={id} />
        <EmployeesPresenceLogs bigFontSize={theme.fontSizeH4} smallFontSize={theme.mediumFontSize} logs={logs} shouldSquash shouldDisablePresenceIndicator />
      </>;
      break;

    case SITE_CONTROL_TOOLTIP_TYPES.COMPANY:
      components = <>
        <CompanyAvatarBox companyId={id} companyName={companyName} />
        <CountersBox presentCount={presentCount} nonPresentCount={nonPresentCount} />
      </>;
      break;

    case SITE_CONTROL_TOOLTIP_TYPES.DAY:
      components = <>
        <DayAvatarBox ts={ts} />
        <CountersBox presentCount={presentCount} nonPresentCount={nonPresentCount} />
      </>;
      break;
    case SITE_CONTROL_TOOLTIP_TYPES.CAMERAS_STATUS:
      components = <>
        {
          _.values(camerasMonitorData).map(currCamera => {
            const { isActive, lastSync, id, title } = currCamera;
            return <CameraStatusIndicator isActive={isActive} lastSync={lastSync} cameraId={id} cameraTitle={title} lang={lang}/>
          })
        }
      </>
      break;
  }
  return components;
};


const InnerAvatarBox = props => {
  const { src, avatarAltText, title, details } = props || {};
  const memoizedAvatarWrapperStyle = useMemo(() => _.assign(styles.avatar, Boolean(avatarAltText) && styles.avatarAltText), []);
  return (
    <div style={styles.innerAvatarBoxContainer}>
      <div style={memoizedAvatarWrapperStyle}>
        {
          Boolean(avatarAltText)
            ? <Text style={styles.avatarAltText}>{avatarAltText}</Text>
            : <Avatar size='50px' type='user' src={src} />
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: theme.padding }}>
        <Text style={styles.avatarTitle}>{title}</Text>
        <Text style={styles.avatarDetails}>{details}</Text>
      </div>
    </div>
  );
};

const DayAvatarBox = props => {
  const { ts } = props;
  const { avatarAltText, title, details } = useMemo(() => {
    const momentObj = moment(ts);
    return {
      avatarAltText: momentObj.format('dd'),
      title: momentObj.format('D MMMM'),
      details: momentObj.format('y')
    };
  }, [ts]);

  return <InnerAvatarBox avatarAltText={avatarAltText} title={title} details={details} />;
};

let EmployeeAvatarBox = props => {
  const { selectedProjectId, subjectType, propertiesSections, propertiesTypes, propertiesMappings, intl, employeeId, } = props;
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    const object = populateObject({
      selectedProjectId,
      subjectType: 'employees',
      propertiesSections,
      propertiesTypes,
      inPropertiesMappings: propertiesMappings,
      intl,
      objectsIds: [employeeId]
    });
    const newEmployee = extractDataFromPopulatedEmployee(object);
    setEmployee(newEmployee);
  }, [employeeId]);

  const { avatar, fullName, profession, companyName } = employee;
  const details = useMemo(() => _.compact([profession, companyName]).filter(_.isString).join(', '), [profession, companyName]);

  if (_.isEmpty(employee))
    return null;

  return <InnerAvatarBox src={avatar} title={fullName} details={details} />;
};

let CompanyAvatarBox = props => {
  const { intl, companyId, companies, trades: allTrades, companyName } = props;
  const company = companies.getNested([companyId], {});
  const { name = companyName, logo, trades } = company;
  const tradesString = useMemo(() => _.keys(trades).map(key => allTrades.getNested([key, 'title', intl.locale])).join(', '), [trades]);
  return <InnerAvatarBox src={logo} title={name} details={tradesString} />;
};

let CameraStatusIndicator = props => {
  const { isActive, lastSync, cameraId, cameraTitle, lang } = props;
  const message = siteControlMessages.presenceLogs.cameraLastSync;
  const formattedCameraName = _.get(cameraTitle, [lang], cameraId);
  return (
    <div style={styles.activityIndicatorContainer}>
      <ActivityIndicator isActive={isActive} />
      <Text style={{ fontSize: theme.fontSize, fontWeight: theme.fontWeight }} values={{ cameraName: formattedCameraName, lastSync: moment(lastSync).format('DD/MM/YYYY, hh:mm:ss') }}>{message}</Text>
    </div>
  );
};

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  injectIntl,
  CompaniesHOC,
  connect(state => ({
    trades: state.trades.map,
  }), {}));
EmployeeAvatarBox = enhance(EmployeeAvatarBox);
CompanyAvatarBox = enhance(CompanyAvatarBox);



const CountersBox = props => {
  const { presentCount, nonPresentCount } = props;
  return (
    <div style={styles.counterBoxContainer}>
      <CountersRow counter={nonPresentCount} isActive={false} />
      {Boolean(presentCount) && <CountersRow counter={presentCount} isActive />}
    </div>
  );
};

const CountersRow = injectIntl(props => {
  const { counter, isActive, intl } = props;
  const message = isActive ? siteControlMessages.tooltip.presentCount : siteControlMessages.tooltip.nonPresentCount;
  return (
    <div style={styles.activityIndicatorContainer}>
      <ActivityIndicator isActive={isActive} />
      <Text style={{ fontSize: theme.fontSize }} values={{ value: counter }}>{message}</Text>
    </div>
  );
});


const extractTitleFromSelectionListProp = prop => {
  const { data, fullProp } = prop || {};
  const valuesMap = {};
  _.forIn(_.get(fullProp, ['values']), option => {
    valuesMap[option.id] = option.getCementoTitle();
  });
  return _.keys(data).map(key => valuesMap[key]).join(', ');
};

const extractDataFromPopulatedEmployee = object => {
  const populatedFields = _.get(object, ['populatedObjects', 0, 'universal'], {});
  return {
    avatar: _.get(populatedFields, ['avatar', 'data', 'uri']),
    fullName: _.get(populatedFields, ['fullName', 'data']),
    companyName: _.get(populatedFields, ['employeeCompany', 'type']) === propertyTypesConst.SELECTION_LIST
      ? extractTitleFromSelectionListProp(populatedFields.employeeCompany)
      : _.get(populatedFields, ['employeeCompany', 'data']),
    profession: _.get(populatedFields, ['employeeCompany', 'type']) === propertyTypesConst.SELECTION_LIST
      ? extractTitleFromSelectionListProp(populatedFields.profession)
      : _.get(populatedFields, ['profession', 'data'])
  };
};


const styles = {
  innerAvatarBoxContainer: {
    minHeight: theme.rowHeight,
    paddingRight: theme.padding,
    paddingLeft: theme.padding,
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: theme.lineHeight,
    borderBottomColor: theme.separatorColor,
    borderBottomStyle: 'solid',
    alignItems: 'center'
  },
  avatarAltText: {
    padding: theme.padding,
    fontSize: theme.fontSizeH1,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.bold
  },
  avatar: {
    flex: 1,
    maxWidth: theme.circleBorderRadius
  },
  avatarTitle: {
    fontSize: theme.fontSizeH6,
    fontWeight: theme.strongBold,
    marginBottom: theme.verticalMargin
  },
  avatarDetails: {
    fontSize: theme.fontSize,
    minHeight: theme.fontSize
  },
  counterBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.padding,
    paddingLeft: theme.padding,
    height: theme.rowHeight
  },
  activityIndicatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};
