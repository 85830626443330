import { CircularProgress } from '@material-ui/core';
import theme from '../../../common/app/theme';
import Text from '../CementoComponents/Text';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

const LOADING_INDICATOR_SIZE = 60;

let LoadingIndicator = (props) => {
  const { intl, loadingProgress } = props;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}>
      <div style={styles.loadingIndicatorContainer}>
        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress thickness={2} size={LOADING_INDICATOR_SIZE} style={{ color: theme.brandPrimary }} />
          <Text
            style={{
              position: 'absolute',
              ...styles.textStyle,
              textAlign: 'center',
            }}>
            {Math.round(loadingProgress)}%
          </Text>
        </div>

        <Text style={{ fontSize: theme.fontSizeH7, ...styles.textStyle }}>
          {intl.formatMessage({ id: 'system.loading.message' })}
        </Text>
      </div>
    </div>
  );
};

const styles = {
  loadingIndicatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.padding,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, 0)',
  },
  textStyle: {
    color: theme.brandPrimary,
    fontWeight: theme.bold,
  },
};

const enhance = compose(injectIntl);

export default enhance(LoadingIndicator);
