import { useContext, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';

// Cemento imports
import { ObjectsWrapperManipulationContext } from '../../../ObjectsWrapper';
import DragAlongCell from './components/DragAlongCell';
import DraggableTableHeader from './components/DraggableTableHeader';
import { fuzzyFilter, getData } from './util';
import ExpandableTableHeader from './components/ExpandableTableHeader';
import ExpandableTableCell from './components/ExpandableTableCell';
import useIntl from '../../../../intl/useIntl';
import _ from 'lodash';

const ObjectsWrapperListViewTanStackTable = ({ data: rawData }) => {
  const intl = useIntl();
  const { groupBy, columnDefinitions } = useContext(ObjectsWrapperManipulationContext);
  const isRTL = useSelector((state) => state.app.rtl);

  const { rowData, columns } = useMemo(() => {
    const rowData = getData(rawData, groupBy);

    const headerGroups = _.groupBy(columnDefinitions, 'sectionId');

    const columns = Object.entries(headerGroups).reduce((acc, [groupKey, columnDefinitions]) => {
      const nestedColumns = columnDefinitions.reduce((acc, columnDefinition) => {
        const columnKey = columnDefinition.key;
        const isGroupBy = groupBy.indexOf(columnKey) + 1;

        const title = columnDefinition?.title?.id
          ? intl.formatMessage(columnDefinition.title)
          : columnDefinition?.getCementoTitle() ?? columnKey;

        let column = {
          id: columnKey,
          accessorKey: columnKey,
          filterFn: 'includesString', //note: normal non-fuzzy filter column - case insensitive
          header: title,
          footer: () => columnKey,
          visibility: true,
          groupKey,
          ordinalNo: columnDefinition.ordinalNo,
        };

        if (isGroupBy) {
          column.filterFn = 'fuzzy';
          column.header = ({ table }) => <ExpandableTableHeader table={table} title={title} />;
          column.cell = ({ cell, getValue }) => <ExpandableTableCell cell={cell} getValue={getValue} />;
          column.isGroupBy = true;
          column.groupByIndex = groupBy.indexOf(columnKey);
          column.pinned = isGroupBy === 1 ? (isRTL ? 'right' : 'left') : null;
          acc.unshift(column);
        } else {
          acc.push(column);
        }

        // Just for demo purposes
        // if (columnKey === 'attachments') {
        //   const expanded = {
        //     ...column,
        //     id: title + '_expanded_1',
        //     header: columnKey + '_expanded_1',
        //     visibility: false,
        //   };
        //   const expanded1 = {
        //     ...column,
        //     id: columnKey + '_expanded_2',
        //     header: title + '_expanded_2',
        //     cementoParent: columnKey,
        //     visibility: false,
        //   };
        //   column.cementoChildren = [expanded.id, expanded1.id];
        //   acc.push(expanded, expanded1);
        // }

        return acc;
      }, []);

      acc.push({
        id: groupKey,
        accessorKey: groupKey,
        filterFn: 'fuzzy',
        header: groupKey,
        footer: () => groupKey,
        visibility: true,
        columns: nestedColumns.sort((a, b) => a.groupByIndex - b.groupByIndex),
        isHeaderGroup: true,
      });

      return acc;
    }, []);

    return {
      rowData,
      columns,
    };
  }, [rawData, groupBy]);

  const parentRef = useRef(null);
  const [expanded, setExpanded] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');

  // const [columnVisibility, setColumnVisibility] = useState(
  //   columns.reduce((acc, c) => {
  //     acc[c.id] = c.visibility;
  //     return acc;
  //   }, {})
  // );

  const [columnOrder, setColumnOrder] = useState(() => columns.map((c) => c.id));

  const table = useReactTable({
    data: rowData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      expanded: !groupBy?.length ? true : expanded,
      columnOrder,
      // columnVisibility,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 100,
      },
    },
    defaultColumn: {
      minSize: 60,
      maxSize: 800,
    },
    columnResizeMode: 'onChange',
    columnResizeDirection: isRTL ? 'rtl' : 'ltr',
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    onExpandedChange: setExpanded,
    // onColumnVisibilityChange: setColumnVisibility,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnOrderChange: setColumnOrder,
    filterFromLeafRows: true, // filter and search through sub-rows
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    // maxLeafRowFilterDepth: 0,
  });

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   */
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  // reorder columns after drag & drop
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 20,
  });

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}>
      <div ref={parentRef} className='ObjectsWrapperListViewTanStackTable_root p-2'>
        {/* <div
          className='ObjectsWrapperListViewTanStackTable_column-controls inline-block border border-black shadow rounded'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}>
          <div className='px-1 border-b border-black'>
            <label>
              <input
                {...{
                  type: 'checkbox',
                  checked: table.getIsAllColumnsVisible(),
                  onChange: table.getToggleAllColumnsVisibilityHandler(),
                }}
              />{' '}
              Toggle All
            </label>
          </div>
          {table.getAllFlatColumns().map((column) => {
            return (
              <div key={column.id} className='px-1'>
                <label>
                  <input
                    {...{
                      type: 'checkbox',
                      checked: column.getIsVisible(),
                      onChange: column.getToggleVisibilityHandler(),
                    }}
                  />{' '}
                  {column.id}
                </label>
              </div>
            );
          })}
        </div> */}
        {/* <div className='ObjectsWrapperListViewTanStackTable_global-search'>
          <input
            value={globalFilter ?? ''}
            onChange={(e) => setGlobalFilter(String(e.target.value))}
            className='p-2 font-lg shadow border border-block'
            style={{
              borderRadius: '0.5rem',
              border: '1px solid #ccc',
              padding: '0.5rem',
              margin: '1rem 0',
            }}
            placeholder='Search all columns...'
          />
        </div> */}
        <table
          className='ObjectsWrapperListViewTanStackTable_table AnalyticsTable innerTable'
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}>
          <thead className='ObjectsWrapperListViewTanStackTable_table_thead'>
            {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => {
              return (
                <tr key={headerGroup.id}>
                  <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                    {headerGroup.headers.map((header) => {
                      const isFirst = groupBy.includes(header.id);
                      return (
                        <DraggableTableHeader
                          key={header.id}
                          headerGroupIndex={headerGroupIndex}
                          header={header}
                          table={table}
                          className={`TableData ObjectsWrapperListViewTanStackTable_table_cell ObjectsWrapperListViewTanStackTable_table_cell__th ${
                            isFirst ? 'FirstColumn' : ''
                          }`}
                        />
                      );
                    })}
                  </SortableContext>
                </tr>
              );
            })}
          </thead>
          <tbody className='ObjectsWrapperListViewTanStackTable_table_tbody'>
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];

              return (
                <tr
                  className='ObjectsWrapperListViewTanStackTable_table_tr tableRowBackground tableHoverBackground'
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                  }}
                  key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const isFirst = groupBy.includes(cell.column.id);
                    return (
                      <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                        <DragAlongCell
                          key={cell.id}
                          cell={cell}
                          className={`TableData ObjectsWrapperListViewTanStackTable_table_cell ObjectsWrapperListViewTanStackTable_table_cell__td ${
                            isFirst ? 'FirstColumn' : ''
                          }`}
                        />
                      </SortableContext>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='ObjectsWrapperListViewTanStackTable_footer-controls flex items-center gap-2'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}>
            {'<<'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            {'<'}
          </button>
          <button className='border rounded p-1' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {'>'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}>
            {'>>'}
          </button>
          <span className='flex items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className='flex items-center gap-1'>
            | Go to page:
            <input
              type='number'
              min='1'
              max={table.getPageCount()}
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50, 100, 200, 500, 1000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>{table.getRowModel().rows.length} Rows</div>
        <label>Expanded State:</label>
        <pre>{JSON.stringify(expanded, null, 2)}</pre>
        <label>Row Selection State:</label>
        <pre>{JSON.stringify(table.getState().rowSelection, null, 2)}</pre>
      </div>
    </DndContext>
  );
};

export default ObjectsWrapperListViewTanStackTable;
