import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../views/Dashboard/Dashboard';
import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import PostsPage from '../../../../views/Posts/PostsPage';
import ObjectsWrapper from '../../../../../common/ObjectWrapper/ObjectsWrapper';
import ObjectsWrapperRemoteConfig from '../../../../../common/ObjectWrapper/helpers/ObjectWrapperRemoteConfig';

export default {
  path: 'issues',
  children: [
    {
      index: true,
      element: <Navigate to={'dashboard'} />,
    },
    {
      path: 'dashboard',
      element: <Dashboard contentType={'issues'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_ObjectsWrapper_issues_analytics'}
          contentType={'issues'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'objects/:reportId?',
      element: (
        <ObjectsWrapper key={'project_ObjectsWrapper_issues_analytics'} contentType={'issues'} section={'analytics'} />
      ),
    },
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId/:floorId/:unitId',
          element: <PostsPage locationsAggregationView={true} contentType={'issues'} />,
        },
      ],
    },
  ],
};
