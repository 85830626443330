import _ from 'lodash';
import { instanceDataToString } from '../propertiesInstances/funcs';

/**
 *
 * @param {({ id: string, updatedTS: number } & Record<string, any>)[]} objects
 * @param {{ id: string, updatedTS: number, data: any, propId: string, propType: string }[]} instances
 * @param {{ id: string, type: string, universalId: string } & Record<string, any>} propTypes
 * @returns ({ updatedTS: number, id: string } & Record<string, any>)[]
 */

export const preProcessInstances = (objects, instances, propTypes) => {
  if (!objects?.length || !propTypes) {
    return [];
  }
  const instancesByParentId = _.groupBy(instances, 'parentId');
  const propUniversalIdByPropId = _.reduce(
    propTypes,
    (acc, { universalId, id }) => {
      acc[id] = universalId ?? id;
      return acc;
    },
    {}
  );
  const processedObjects = _.reduce(
    objects,
    (acc, object = {}) => {
      const instances = instancesByParentId[object.id] || [];
      let nextGenInstance = _.cloneDeep(object);

      instances.forEach(({ data, propId }) => {
        const propUniversalId = propUniversalIdByPropId[propId];
        _.set(nextGenInstance, propUniversalId, data);
      });

      acc.push(nextGenInstance);
      return acc;
    },
    []
  );

  return processedObjects;
};

export const getNextGenValue = (valueId, propData, intl) => {
  if (_.isNil(valueId) || !propData.values) {
    return instanceDataToString(propData, valueId, intl);
  }
  const richValue = propData.values?.find?.((v) => v.id === valueId);
  const nextGen = {
    id: valueId,
    cementoValue: richValue,
  };
  return nextGen;
};
