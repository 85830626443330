import { onError } from "../app/funcs";
import { envParams, realmInstance, lokiInstance } from "../configureMiddleware";
import { platformActions } from "../platformActions";
import _ from "lodash";
import { removeEmpty, unsubscribeToLastUpdates } from '../lib/utils/utils';
import { debugParams, subscribeToLastUpdates, replaceMaxUpdateTSIfNeeded, notifyLongFunctionDuration } from '../lib/utils/utils';
import serverSDK from '@cemento-sdk/server';
import trackEmployees, { EMPLOYEES_EVENTS } from './trackEmployees';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { getNewId } from '../lib/api';

const getLastUpdateTS = (realmInstance, lokiInstance, scopeId) => {

  var lastUpdateTS = 0;
	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('employees').chain().find({projectId: scopeId, isLocal: { '$ne' : true }}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	}
	else {
		lastUpdateTS = realmInstance.employees.objects('employee1').filtered('projectId = "' + scopeId + '"').max('updatedTS');
	}

  return lastUpdateTS || 0;
}

const saveToRealm = (employees, projectId, realmInstance, cleanAll) => {
	if (!cleanAll && Object.keys(employees || {}).length == 0) return;
	
	employees = Object.values(employees || {}).sort((employeeA, employeeB) => (employeeA.updatedTS || 0) > (employeeB.updatedTS || 0) ? -1 : 1);
	let currBatchMaxLastUpdateTS = _.get(employees, [0 , 'updatedTS'], 0);

	let realm = realmInstance.employees;
	realm.write(() => {
		if (cleanAll) {
			let allEmployees = realm.objects('employee1').filtered(`projectId = "${projectId}"`);
			realm.delete(allEmployees);
		}
		const deletedIdList = []
		
		employees.forEach(curr => {
			if (curr && curr.id) {
				if (!curr.isDeleted) {
					realm.create('employee1', {...curr.realmToObject(), projectId, projectId_objId:projectId + "_" + curr.id}, true)
				}
				else {
					deletedIdList.push(curr.id)
				}
			}
			else
				console.warn('employees missing ID'); // TODO: Send to bugsnag
		});
		if (deletedIdList?.length) {
			try {
				const allDeletedInstances = realm
					.objects('employee1')
					.filtered(`projectId = "${projectId}" AND id IN {"${deletedIdList.join('", "')}"}`);

				realm.delete(allDeletedInstances);
			} catch (error) {
				console.log('____ERRROR', error);
			}
		}
		replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'employee1', `projectId = "${projectId}"`);
	});
}

const saveToLoki = (employees = {}, projectId, lokiInstance, cleanAll) => {
	if (!cleanAll && Object.keys(employees || {}).length == 0) return;
	
	employees = Object.values(employees).sort((employeeA, employeeB) => employeeA.updatedTS > employeeB.updatedTS ? -1 : 1);
		
	let allEmployees = [];
	let deletedIds = [];
	employees.forEach(curr => {
		curr.isDeleted ? 
			deletedIds.push(curr.id) : 
			allEmployees.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) { 
		lokiInstance.getCollection('employees').cementoDelete({ projectId });
	}
	if (deletedIds.length > 0) lokiInstance.getCollection('employees').cementoDelete({ projectId, id: { '$in' : deletedIds }});
	lokiInstance.getCollection('employees').cementoUpsert(allEmployees);
}

const setEmployeesValues = (employees, projectId, realmInstance, lokiInstance, platformActions, cleanAll) => {
	if (platformActions.app.getPlatform() == "web")
		saveToLoki(employees, projectId, lokiInstance, cleanAll);
	else
		notifyLongFunctionDuration(() => saveToRealm(employees, projectId, realmInstance, cleanAll),
			`saveToRealm ~ The function took too long`, `employees`)
}

export const startEmployeesListener = (viewer, projectId, cleanAll) => {

  if (cleanAll)
    setEmployeesValues({}, projectId, realmInstance, lokiInstance, platformActions, true);

  const scopeParams = { scope: 'projects', scopeId: projectId };

  const saveFunc = (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave) return;
    setEmployeesValues(_data, projectId, realmInstance, lokiInstance, platformActions, isRevoke);
  };

  trackEmployees(EMPLOYEES_EVENTS.START_EMPLOYEES_LISTENER, scopeParams);

  return subscribeToLastUpdates(
    viewer,
    scopeParams,
    { 
      subject: 'employees',
      getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId),
      getData: (lastUpdateTS) => {
        trackEmployees(EMPLOYEES_EVENTS.FETCH_EMPLOYEES, { ...scopeParams, lastUpdateTS });
        return serverSDK.employees.getEmployees({ ...scopeParams, lastUpdateTS });
      },
      isObjectUseTransactionalDB: true,
    },
    saveFunc,
  );
}

export const endEmployeesListener = (scopeId) => {	
  const scopeParams = { scope: 'projects', scopeId };
  unsubscribeToLastUpdates(
    scopeParams,
    { subject: 'employees' },
  );

  trackEmployees(EMPLOYEES_EVENTS.END_EMPLOYEES_LISTENER, scopeParams);
}

const saveEmployees = (projectId, newEmployees, originalEmployees = null) => {
	if (projectId && Object.values(newEmployees || {}).length) {
    trackEmployees(EMPLOYEES_EVENTS.SAVE_EMPLOYEES, { projectId, newEmployees, originalEmployees });
		newEmployees = Object.values(newEmployees).filter(Boolean).map(employee => ({ ...employee, projectId, projectId_objId: projectId + "_" + employee.id}));
		
		uploadObjectsDispatcher({
			projectId,
			objectsToSave: newEmployees,
			originalObjects: originalEmployees,
			schemaInfo: schemasInfo.employees,
		});
	}
}

export const upsertEmployee = (inEmployee, projectId) => {
  if (!inEmployee) return;

  let employeeToSave = { ...inEmployee.realmToObject(), projectId };
  if (!employeeToSave.id) {
    const id = getNewId()
    employeeToSave.id = id;
  }

  employeeToSave = removeEmpty(employeeToSave, 'upsertEmployee');

  saveEmployees(projectId, [employeeToSave]);
}

export const deleteEmployee = (employeeId, projectId) => {
  const employeeToSave = { id: employeeId, isDeleted: true };
  trackEmployees(EMPLOYEES_EVENTS.DELETE_EMPLOYEE, { projectId, employeeToSave });

  saveEmployees(projectId, [employeeToSave]);
}

/**
 * @param {string} projectId
 * @param {string} employeeIdNumber - ID number of the employee
 * @param {string} employeeNationality - Selection list value from pinchas clali nationality prop (the key of the data object)
 * @returns {Promise<{} | { properties: Object<string, any>, projects: { [projectId: string]: { id: string } } }>}
 */
export const getExistingEmployee = async (projectId, employeeIdNumber, employeeNationality) => {
  const { apiServer } = envParams;

  let ret = {};
  try {
    let resp = await platformActions.net.fetch(apiServer + '/v1/alternative/employees/isExist', {
      method: 'POST',
      body: JSON.stringify({
        projectId,
        idNumber: employeeIdNumber,
        nationality: employeeNationality,
        includeExpelDetails: true,
      }),
    });

    if (resp && resp.getJson) ret = await resp.getJson();
  } catch (error) {
    onError({
      errorMessage: 'error while fetching from api server /v1/alternative/employees/isExist',
      error,
      methodMetaData: {
        name: 'getExistingEmployee',
        args: { projectId, employeeIdNumber, employeeNationality },
      },
    });
    throw error;
  }

  return ret;
};
