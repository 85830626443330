import * as usersActions from '../users/actions';
import { REPLACE_USER } from '../auth/actions';
import * as appActions from '../app/actions';

import Member from './member';
import Project from '../projects/project';
import { Seq, Map } from 'immutable';
import { Record } from '../transit';
import { membersEvents } from './membersEvents';

const InitialState = Record({
  map: Map(),
  inProcessMap: Map(),
  lastClientUpdatePerProject: Map(),
  reviews: Map(),
  thumbsUpRecived: Map(),
  thumbsUpGiven: Map(),
  images: Map(),
  likes: Map(),
}, 'members');

const initialState = new InitialState;

export default function membersReducer(state = new InitialState, action) {

  switch (action.type) {
    case membersEvents.SEARCH_USERS_BY_PHONE: {
      if (!action.payload || !action.payload.members)
        return state;

      const { members } = action.payload;
      const membersMap = state.getIn(['map'], new Map());
      const newMembersMap = membersMap.withMutations(mutableMap => {
        Object.values(members).forEach((curr) => {
          if (curr && curr.DBMember && curr.localContact) {
            const member = new Member({
              localDisplayName: curr.localContact.displayName,
              localAvatar: curr.localContact.avatar,
              ...curr.DBMember,
              localId: curr.localContact.id, 
            });
            mutableMap.set(curr.DBMember.id, member);
          }
        });
      });

      state = state.setIn(['map'], newMembersMap);
      
      return state;
    }

    case membersEvents.SEARCH_UNLOCAL_USERS_BY_UIDS + '_ERROR':
    case membersEvents.SEARCH_UNLOCAL_USERS_BY_UIDS + '_START': {
      const uids = action.getNested(['meta', 'action','uids']);
      if (!uids)
        return state;

      Object.keys(uids).forEach(uid => {
        state = state.setIn(['inProcessMap', uid], true);
      });
      return state;
    }

    case membersEvents.SEARCH_PROJECT_USERS: 
    case membersEvents.SEARCH_UNLOCAL_USERS_BY_UIDS + '_SUCCESS': {
      if (!action.payload || !action.payload.DBMembers)
        return state;

      var last_updated_user_ts = 0;
      const { projectId, DBMembers } = action.payload;
      const membersMap = state.getIn(['map'], new Map());
      const newMembersMap = membersMap.withMutations(mutableMap => {
        Object.values(DBMembers).map(DBMember => {
          if (!DBMember.id)
            return;

          if (DBMember.updated_at && (!last_updated_user_ts || new Date(last_updated_user_ts) < new Date(DBMember.updated_at)))
            last_updated_user_ts = DBMember.updated_at;

          const prev = mutableMap.getIn(['map', DBMember.id], {});
          const member = new Member({
            localAvatar: prev.localAvatar,
            localDisplayName: prev.localDisplayName,
            ...DBMember,
            localId: prev.localId,
          });

          mutableMap.set(DBMember.id, member);

          state = state.setIn(['inProcessMap', DBMember.id], false);
        });
      });

      state = state.setIn(['map'], newMembersMap);

      if (action.type == membersEvents.SEARCH_PROJECT_USERS && projectId && last_updated_user_ts) {
        var datePlusMinisec = new Date(new Date(last_updated_user_ts).getTime() + 1).toISOString(); // + 1 ms
        state = state.setIn(['lastClientUpdatePerProject', projectId], datePlusMinisec);
      }
        
      return state;
    }

    case membersEvents.ON_OTHER_USER_PROFILE_UPDATE + "_SUCCESS": {   
      const { DBMember, uid } = action.payload;

      if (state.map.get(uid) == null)
        return state;

      var prev = state.getNested(['map', DBMember.id], {});
      var member = new Member({
        localAvatar: prev.localAvatar,
        localDisplayName: prev.localDisplayName,
        activated: prev.activated,
        ...DBMember,
        localId: prev.localId,
      }) 
      
      state = state.setIn(['map', member.id], member);  
      return state;
    }

    case usersActions.AUTH0_SAVE_USER + "_SUCCESS": 
    case usersActions.GET_MY_USER_DETAILS: {
      const { user } = action.payload;
      if (!user)
        return state;
      
      var prev = state.getNested(['map', user.id], {});
      var myMemberRecord = new Member({
        localAvatar: prev.localAvatar,
        localDisplayName: prev.localDisplayName,
        projects: user.projects,
        ...user, 
        localId: prev.localId,
      });

      return state.setIn(['map', user.id], myMemberRecord);
    }

    case usersActions.UPDATE_MY_METADATA + '_SUCCESS': {
      const { key, newValue, values, myId } = action.payload;
      if (!myId)
        return state;
      
      if (values) {
        values.loopEach((key, curr) => {
          try {
            state = state.updateIn(['map', myId, key], value => curr);
          }
          catch (error) {
            console.log('error: ' + error)
          }
        })
      }

      if (key && newValue)
        state = state.updateIn(['map', myId, key], value => newValue);

      return state;
    }

    case appActions.CLEAN_CACHE: {
      return state.setIn(['lastClientUpdatePerProject'], new Map());
    }
  
    case membersEvents.CLEAN_CACHED_MEMBERS:
    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
	}
	
  return state;
}
