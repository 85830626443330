import { flexRender } from '@tanstack/react-table';

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable';
import { getCommonCellStyles } from '../util';

const DragAlongCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = getCommonCellStyles({ column: cell.column, isDragging, transform });

  return (
    <td style={style} className={`DragAlongCell ${className}`} ref={setNodeRef}>
      <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
    </td>
  );
};
export default DragAlongCell;
