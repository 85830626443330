import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import VirtualList from '../../components/List/react-tiny-virtual-list.es'; // 'react-tiny-virtual-list';
//import Post from './Post';
import MinPost from './MinPost';
import { GridContainer, GridItem } from '../../components';
import issuesMessages from '../../../common/issues/issuesMessages';
import postsMenuMessages from '../../../common/posts/postsMenuMessages';
import companiesMessages from '../../../common/companies/companiesMessages';
import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import Text from '../../components/CementoComponents/Text';
import theme from '../../assets/css/theme';
import collapse from '../../assets/img/icons/collapse.png';
import radioEmpty from '../../assets/img/icons/radio-empty.png';
import radioFull from '../../assets/img/icons/radio-full.png';
import { CountBar } from '../../components/CementoComponents/CountBar';

const NO_DATE = 'noDate';
const YESTERDAY_OR_LATER = 'yesterdayOrLater';
const TODAY = 'today';
const THIS_WEEK = 'thisWeek';
const NEXT_WEEK = 'nextWeek';
const NEXT_30_DAYS = 'next30Days';
const MORE_THEN_30_DAYS = 'moreThen30Days';
const YESTERDAY = 'yesterday';
const LAST_WEEK = 'lastWeek';
const WEEK_BEFORE_LAST = 'weekBeforeLast';
const LAST_30_DAYS = 'last30Days';
const MORE_THEN_30_DAYS_AGO = 'moreThen30DaysAgo';

class Posts extends React.Component {
	constructor(props) {
		super(props);
		this.getRef = this.getRef.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPostSelect = this.onPostSelect.bind(this);
		this.scrollToIndex = this.scrollToIndex.bind(this);
		this.dateDiffInDays = this.dateDiffInDays.bind(this);
		this.onHeaderSelect = this.onHeaderSelect.bind(this);
		this.getDateGroup = this.getDateGroup.bind(this);
		this.getPostsRows = this.getPostsRows.bind(this);
		this.getSelectedGroupsPosts = this.getSelectedGroupsPosts.bind(this);
		this.handleExportGroupsSelect = this.handleExportGroupsSelect.bind(this);

		this.state = {
			scrollToIndex: 0,
			selectedExportGroups: {},
			groupsHeadersExpanded: {},
			postsRows: [],
			expandedRows: [],
			groupsHeadersIndices: [],
			selectedPostPosition: null,
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	componentDidMount() {
		if (this.props.getRef) this.props.getRef(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.expandedRows && this.props.selectedPostId && prevProps.postsPerRow != this.props.postsPerRow) {
			let wantedIndex = null;
			this.state.expandedRows.loopEach((lineIndex, r) => {
				r.loopEach((i, post) => {
					if (post && post.id == this.props.selectedPostId) wantedIndex = lineIndex;
				});
			});

			if (wantedIndex) this.scrollToIndex(wantedIndex);
		}
	}

	getPostsRows(nextProps, timeFilter, returnOnlyHeaders) {
		const { postsArray, groupBy, filterFunc, dataSortFunc, intl, postsPerRow, sortDirection } = nextProps;
		let postsRows = [];
		let expandedRows = [];
		let groupsHeadersExpanded = {};
		let groupsHeadersIndices = [];
		let totalPostsCount = 0;
		let groups = {};
		let sortedData = postsArray.slice();
		if (dataSortFunc) sortedData = sortedData.sort(dataSortFunc);

		const currDate = new Date();
		sortedData.forEach(post => {
			if ((!filterFunc || filterFunc(post)) && (!timeFilter || post.createdAt <= timeFilter)) {
				totalPostsCount++;
				let groupKey = post.getNested(groupBy.valuePath, groupBy.defaultGroupKey);
				let defaultOrder = null;
				let groupNoValueTitle = null;
				let groupId = null;

				if (groupBy.isDate) {
					let future = false;
					if (groupBy.key == 'dueDate') future = true;

					let dataGroup = this.getDateGroup(groupKey ? new Date(groupKey) : null, currDate, future);
					groupNoValueTitle = intl.formatMessage(postsMenuMessages[dataGroup.key]);
					defaultOrder = dataGroup.defaultOrder;
				} else {
					let groupNoValueTitleObject = groupBy.titleFetchLocation.getNested([groupKey]);
					if (groupNoValueTitleObject && groupBy.valuePath2) {
						let innerObj = null;
						groupBy.valuePath2.loopEach((i, path) => {
							if (innerObj) return;
							innerObj = groupNoValueTitleObject.getNested(path);
						});
						groupNoValueTitleObject = innerObj;
					}
					if (groupNoValueTitleObject && groupBy.titleFetchLocation2)
						groupNoValueTitleObject = groupBy.titleFetchLocation2.getNested([groupNoValueTitleObject]);
					groupNoValueTitle =
						groupNoValueTitleObject && groupBy.titleFetchKeyPath
							? groupNoValueTitleObject.getNested([groupBy.titleFetchKeyPath])
							: groupNoValueTitleObject;

					if (!groupNoValueTitle) {
						if (groupBy.key == 'unit') groupNoValueTitle = intl.formatMessage(issuesMessages.noUnit);
						else if (groupBy.key == 'floor')
							groupNoValueTitle = groupNoValueTitleObject?.getNested(['num'])
								? intl.formatMessage(issuesMessages.floorNumber, {
										floorNumber: groupNoValueTitleObject.getNested(['num']),
								  })
								: intl.formatMessage(issuesMessages.noFloor);
						else if (['company', 'ownerCompany'].includes(groupBy.key))
							groupNoValueTitle = intl.formatMessage(companiesMessages.unknownCompany);
						else {
							let type = intl.formatMessage(postsMenuMessages[groupBy.key]);
							groupNoValueTitle = intl.formatMessage(postsMenuMessages.notSet, {
								type,
							});
							defaultOrder = -1;
						}
					}
				}

				groupId = groupNoValueTitle;
				if (groupNoValueTitle && groupNoValueTitle.defaultMessage) groupId = groupKey;

				if (!groups[groupId])
					groups[groupId] = {
						title: groupNoValueTitle || 'NaN',
						/*groupKey: groupKey,*/ defaultOrder,
						values: [],
					};

				groups[groupId].values.push(post);
			}
		});

		let sortedGroupsKeys = Object.keys(groups);
		sortedGroupsKeys =
			groupBy && groupBy.sortFunc
				? sortedGroupsKeys.sort((a, b) => sortDirection * groupBy.sortFunc(a, b))
				: sortedGroupsKeys.sort((a, b) => {
						if (groups[a].defaultOrder || groups[b].defaultOrder)
							return sortDirection * ((groups[b].defaultOrder || 0) - (groups[a].defaultOrder || 0));
						else {
							return (
								sortDirection *
								String(
									groups[a].title && groups[a].title.defaultMessage
										? intl.formatMessage(groups[a].title)
										: groups[a].title,
								).localeCompare(
									String(
										groups[b].title && groups[b].title.defaultMessage
											? intl.formatMessage(groups[b].title)
											: groups[b].title,
									),
								)
							);
						}
				  });
		let defaultExpand = nextProps.expandAll;
		let maxGroupCount = 0;
		sortedGroupsKeys.forEach(gk => {
			groupsHeadersExpanded[gk] = groupsHeadersExpanded.hasOwnProperty(gk)
				? groupsHeadersExpanded[gk]
				: this.state.groupsHeadersExpanded.hasOwnProperty(gk)
				? this.state.groupsHeadersExpanded[gk]
				: defaultExpand;
			groupsHeadersIndices.push(postsRows.length);
			maxGroupCount = Math.max(maxGroupCount, groups[gk].values.length);
			let headerRow = {
				type: 'header',
				visible: true,
				id: gk,
				title: groups[gk].title,
				count: groups[gk].values.length,
			};
			let dataRow = {
				type: 'data',
				headerId: gk,
				values: [],
				visible: groupsHeadersExpanded[gk],
			};
			postsRows.push(headerRow);
			if (returnOnlyHeaders) return;
			postsRows.push(dataRow);
			if (headerRow.visible) expandedRows.push(headerRow);
			if (dataRow.visible) expandedRows.push(dataRow);
			groups[gk].values.forEach(post => {
				if (postsRows[postsRows.length - 1].values.length == postsPerRow) {
					let dataRow = {
						type: 'data',
						headerId: gk,
						values: [],
						visible: groupsHeadersExpanded[gk],
					};
					postsRows.push(dataRow);
					if (dataRow.visible) expandedRows.push(dataRow);
				}
				postsRows[postsRows.length - 1].values.push(post);
			});
		});

		let postsGroupsMap = {};
		if (returnOnlyHeaders) postsRows.forEach(header => (postsGroupsMap[header.id] = header));

		return {
			maxGroupCount,
			postsRows,
			expandedRows,
			groupsHeadersExpanded,
			groupsHeadersIndices,
			postsGroupsMap,
			totalPostsCount,
		};
	}

	handleExpandAll(oldGroupsHeadersExpanded, oldPostsRows, expandAll) {
		let newGroupsHeadersExpanded = {};
		let newExpandedRows = [];
		oldGroupsHeadersExpanded.loopEach((key, value) => {
			newGroupsHeadersExpanded[key] = expandAll;
		});
		oldPostsRows.loopEach((index, row) => {
			let visible = row.type == 'header' ? true : newGroupsHeadersExpanded[row.headerId];
			row.visible = visible;
			if (visible) newExpandedRows.push(row);
		});

		return {
			expandedRows: newExpandedRows,
			groupsHeadersExpanded: newGroupsHeadersExpanded,
		};
	}

	setComponentData(props, nextProps) {
		const {
			postsArray,
			selectedLocationId,
			exportSelectionMode,
			groupBy,
			filterFunc,
			dataSortFunc,
			viewMode,
			postsPerRow,
			selectedPostId,
			scrollToTop,
			sortDirection,
			relativeDate,
		} = nextProps;
		let newStateChanges = {};

		if (
			postsArray &&
			groupBy &&
			(props.postsArray != postsArray ||
				props.viewMode != viewMode ||
				props.sortDirection != sortDirection ||
				props.groupBy != groupBy ||
				props.filterFunc != filterFunc ||
				props.dataSortFunc != dataSortFunc ||
				props.postsPerRow != postsPerRow ||
				props.selectedLocationId != selectedLocationId)
		) {
			let result = this.getPostsRows(nextProps, null, viewMode == 'chart');
			newStateChanges.postsRows = result.postsRows;
			newStateChanges.expandedRows = result.expandedRows;
			newStateChanges.maxGroupCount = result.maxGroupCount;
			newStateChanges.groupsHeadersExpanded = result.groupsHeadersExpanded;
			newStateChanges.groupsHeadersIndices = result.groupsHeadersIndices;
			if (props.expandAll != nextProps.expandAll || props.selectedLocationId != selectedLocationId)
				Object.assign(
					newStateChanges,
					this.handleExpandAll(result.groupsHeadersExpanded, result.postsRows, nextProps.expandAll),
				);
		}
		// ExpandAll prop change
		else if (props.expandAll !== nextProps.expandAll) {
			let oldGroupsHeadersExpanded = this.state.groupsHeadersExpanded;
			let oldPostsRows = this.state.postsRows;
			Object.assign(newStateChanges, this.handleExpandAll(oldGroupsHeadersExpanded, oldPostsRows, nextProps.expandAll));
		}

		// RelativeDate prop change - only for viewMode=chart
		if (relativeDate && (props.relativeDate != relativeDate || newStateChanges.postsRows != this.state.postsRows)) {
			let millisecondsInDay = 1000 * 60 * 60 * 24;
			let result = this.getPostsRows(this.props, relativeDate + millisecondsInDay, true);
			let relativeGroupsMap = result.postsGroupsMap;
			let newPostsRows = [];
			(newStateChanges.postsRows || this.state.postsRows).forEach(p => {
				let clone = Object.assign({}, p);
				clone.relativeCount = relativeGroupsMap.getNested([p.id, 'count'], 0);
				newPostsRows.push(clone);
			});
			newStateChanges.postsRows = newPostsRows;
		}

		if (props.selectedPostId != selectedPostId && !selectedPostId) {
			newStateChanges.selectedPostPosition = null;
			newStateChanges.scrollToIndex = null;
		}

		if (props.exportSelectionMode != exportSelectionMode) newStateChanges.selectedExportGroups = {};

		if (props.scrollToTop != scrollToTop) newStateChanges.scrollToIndex = scrollToTop;

		if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
	}

	onHeaderSelect(headerId) {
		const { postsRows, groupsHeadersExpanded } = this.state;
		const { onAllGroupsCollapseOrExpand } = this.props;

		let newPostsRows = postsRows.slice();
		let bIsAllExpand = true;
		let bIsAllCollapse = true;

		let expandedRows = [];
		for (let i = 0; i < newPostsRows.length; i++) {
			if (newPostsRows[i].headerId == headerId) newPostsRows[i].visible = !newPostsRows[i].visible;
			if (newPostsRows[i].visible) expandedRows.push(newPostsRows[i]);
		}

		let newGroupsHeadersExpanded = Object.assign({}, groupsHeadersExpanded);
		newGroupsHeadersExpanded[headerId] = Boolean(!newGroupsHeadersExpanded[headerId]);
		newGroupsHeadersExpanded.loopEach((key, value) => {
			bIsAllExpand = bIsAllExpand && value;
			bIsAllCollapse = bIsAllCollapse && !value;
		});
		if (bIsAllExpand && onAllGroupsCollapseOrExpand) onAllGroupsCollapseOrExpand(true);
		if (bIsAllCollapse && onAllGroupsCollapseOrExpand) onAllGroupsCollapseOrExpand(false);

		this.setState({
			postsRows: newPostsRows,
			groupsHeadersExpanded: newGroupsHeadersExpanded,
			expandedRows,
		});
	}

	dateDiffInDays(a, b, future) {
		const _MS_PER_DAY = 1000 * 60 * 60 * 24;

		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
		const timeDifference = future ? utc1 - utc2 : utc2 - utc1;
		const result = Math.floor(timeDifference / _MS_PER_DAY);

		return result;
	}

	handleExportGroupsSelect(groupTitleId, event) {
		const { selectedExportGroups } = this.state;
		let newselectedExportGroups = Object.assign({}, selectedExportGroups);
		// preventDefault!!!!
		event.preventDefault();
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		if (newselectedExportGroups[groupTitleId]) delete newselectedExportGroups[groupTitleId];
		else newselectedExportGroups[groupTitleId] = true;
		this.setState({ selectedExportGroups: newselectedExportGroups });
	}

	getSelectedGroupsPosts() {
		const { postsRows, selectedExportGroups } = this.state;
		let groupPosts = [];
		let currentGroupTitleId = null;
		for (let rowIndex = 0; rowIndex < postsRows.length; rowIndex++) {
			if (postsRows[rowIndex].type == 'header') currentGroupTitleId = postsRows[rowIndex].id;
			else if (selectedExportGroups[currentGroupTitleId])
				(postsRows[rowIndex].values || []).forEach(p => groupPosts.push(p));
		}

		return groupPosts;
	}

	getDateGroup(targetDate, currDate, future) {
		let returnValue = { key: NO_DATE, defaultOrder: 6 };

		if (targetDate && currDate) {
			const currDiff = this.dateDiffInDays(targetDate, currDate, future);
			if (future) {
				if (currDiff < 0)
					// the key is the lowest on the current range
					returnValue = { key: YESTERDAY_OR_LATER, defaultOrder: 5 };
				else if (currDiff == 0) returnValue = { key: TODAY, defaultOrder: 4 };
				else if (currDiff > 0 && currDiff <= 6) returnValue = { key: THIS_WEEK, defaultOrder: 3 };
				else if (currDiff > 6 && currDiff <= 13) returnValue = { key: NEXT_WEEK, defaultOrder: 2 };
				else if (currDiff > 13 && currDiff <= 30) returnValue = { key: NEXT_30_DAYS, defaultOrder: 1 };
				else returnValue = { key: MORE_THEN_30_DAYS, defaultOrder: 0 };
			} else {
				if (currDiff == 0) returnValue = { key: TODAY, defaultOrder: 5 };
				else if (currDiff == 1) returnValue = { key: YESTERDAY, defaultOrder: 4 };
				else if (currDiff > 1 && currDiff <= 6) returnValue = { key: LAST_WEEK, defaultOrder: 3 };
				else if (currDiff > 6 && currDiff <= 13) returnValue = { key: WEEK_BEFORE_LAST, defaultOrder: 2 };
				else if (currDiff > 13 && currDiff <= 30) returnValue = { key: LAST_30_DAYS, defaultOrder: 1 };
				else returnValue = { key: MORE_THEN_30_DAYS_AGO, defaultOrder: 0 };
			}
		}

		return returnValue;
	}

	onKeyDown(event) {
		const { selectedPostPosition, groupsHeadersIndices, expandedRows } = this.state;
		const { postsPerRow, selectedPostId } = this.props;

		const keyName = event.key;
		let additions = {
			ArrowRight: 1,
			ArrowLeft: -1,
			ArrowUp: -postsPerRow,
			ArrowDown: postsPerRow,
		};

		if (!additions[keyName] || !selectedPostId) return null;

		let nextPosition = selectedPostPosition ? selectedPostPosition : 0;
		nextPosition += additions[keyName];
		let row = Math.floor(nextPosition / postsPerRow);
		let col = nextPosition % postsPerRow;
		(groupsHeadersIndices || []).forEach(i => {
			if (i <= row) row++;
		});

		if (expandedRows[row] && expandedRows[row].values && expandedRows[row].values[col]) {
			let post = expandedRows[row].values[col];
			this.setState({
				selectedPostPosition: nextPosition,
				scrollToIndex: row - 1,
			});
			this.onPostSelect(post);
		}
	}

	onPostSelect(post) {
		const { onPostSelect } = this.props;
		if (onPostSelect) onPostSelect(post);
	}

	scrollToIndex(index) {
		this.setState({ scrollToIndex: index });
	}

	getRef(node) {
		if (node) this.containerRef = node;
		this.containerRef.addEventListener('keydown', this.onKeyDown);
	}

	calcPost = post => {
		const { trades, rowHeight, onCommentCreated, selectedPostId, selectedPosts, viewMode } = this.props;

		return (
			<MinPost
				postHeight={rowHeight}
				isSelected={selectedPostId === post.id || Boolean((selectedPosts || {})[post.id])}
				onSelect={this.props.onPostSelect ? this.onPostSelect : null}
				onCommentCreated={onCommentCreated}
				post={post}
				viewMode={viewMode}
				trades={trades}
				minMode={true}
			/>
		);
	};

	render() {
		const {
			rowHeight,
			sectionTitleHeight,
			scrollRef,
			postsPerRow,
			viewMode,
			rtl,
			exportSelectionMode,
			height,
			width,
			isNoGroups,
			fullWidthPosts,
			isLocationMode = false,
		} = this.props;
		const {
			postsRows,
			expandedRows,
			selectedExportGroups,
			maxGroupCount,
			groupsHeadersExpanded,
			scrollToIndex,
		} = this.state;

		if (!postsRows?.length) return <NoItemsFound locationMode={isLocationMode} />;

		const rowsToRender = !isNoGroups ? expandedRows : postsRows.filter(row => row.type !== 'header');
		if (isNoGroups)
			return rowsToRender.map((row, index) => {
				return (
					<div key={index}>
						<div style={viewMode === 'lines' || fullWidthPosts ? {} : { paddingLeft: 36, paddingRight: 36 }}>
							<div style={{ padding: theme.padding }}>
								{row.values.map((post, i) => (
									<div key={i + '_' + post.id || i}>{this.calcPost(post)}</div>
								))}
							</div>
						</div>
					</div>
				);
			});
		
		const ctnStyle = {
			width,
			height,
		};
		return (
			<div style={ctnStyle} tabIndex={0} onKeyDown={this.onKeyDown}>
				<VirtualList
					width={width}
					height={height}
					itemCount={rowsToRender.length}
					itemSize={index => {
						return rowsToRender[index].type === 'header' ? sectionTitleHeight : rowHeight;
					}}
					overscanCount={20}
					parentref={scrollRef}
					scrollToAlignment={'start'}
					scrollparentreftoindex={scrollToIndex}
					stickyIndices={[] /*groupsHeadersIndices*/}
					renderItem={({ index, style }) => (
						<GridContainer key={index} style={style}>
							{rowsToRender[index].type === 'data' ? (
								<GridItem
									xs={12}
									style={viewMode === 'lines' || fullWidthPosts ? {} : { paddingLeft: 36, paddingRight: 36 }}
								>
									<GridContainer spacing={3}>
										{rowsToRender[index].values.map((post, i) => (
											<GridItem key={i + '_' + post.id || i} xs={12 / postsPerRow}>
												{this.calcPost(post)}
											</GridItem>
										))}
									</GridContainer>
								</GridItem>
							) : (
								<GridItem
									key={index + 'group'}
									id='rowParent'
									xs={12}
									style={{
										cursor: 'pointer',
										paddingTop: theme.paddingSize,
										borderBottom: groupsHeadersExpanded[expandedRows[index].id]
											? 'none'
											: theme.borderLineNeutralLight + '40',
									}}
									onClick={() => this.onHeaderSelect(expandedRows[index].id)}
								>
									<div
										className='row'
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											paddingLeft: 36,
											paddingRight: 36,
										}}
									>
										<div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
											{Boolean(exportSelectionMode) && (
												<img
													src={selectedExportGroups[expandedRows[index].id] ? radioFull : radioEmpty}
													style={{
														height: 16,
														width: 16,
														[rtl ? 'marginLeft' : 'marginRight']: 10,
													}}
													onClick={e => this.handleExportGroupsSelect(expandedRows[index].id, e)}
												/>
											)}
											<img
												src={collapse}
												style={{
													transform: groupsHeadersExpanded[expandedRows[index].id]
														? 'rotate(90deg)'
														: rtl
														? 'rotate(180deg)'
														: '',
													height: 10,
													transition: '0.2s',
													[rtl ? 'marginLeft' : 'marginRight']: 10,
												}}
											/>
											<Text values={{ count: expandedRows[index].count }} style={{ fontWeight: 500, fontSize: 16 }}>
												{expandedRows[index].title}
											</Text>
										</div>
										<CountBar value={expandedRows[index].count} totalValue={maxGroupCount} />
									</div>
								</GridItem>
							)}
						</GridContainer>
					)}
				/>
			<style jsx>{`
				#rowParent:hover > .row {
					color: ${theme.brandPrimary};
					transition: color 0.1s ease;
				}
			`}</style>
			</div>
		);
	}
}

// let styles = {
// }
Posts.defaultProps = {
	height: 'auto',
	width: '100%',
	isNoGroups: false,
};
Posts = injectIntl(Posts);
//Posts = withStyles(styles)(Posts);
const enhance = compose(
	connect(
		state => ({
			uiParams: state.ui.uiParams,
			rtl: state.app.rtl,
		}),
		{},
	),
);
export default enhance(Posts);
