import React, { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.css';
import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from './ObjectsWrapper';
import FilterBar from './components/FilterBar';
import ObjectsWrapperGridView from './views/grid/ObjectsWrapperGridView';
import ObjectsWrapperListViewReactTables from './views/table/ObjectsWrapperListViewReactTables';
import ObjectsWrapperListViewAGGrid from './views/table/ObjectsWrapperListViewAGGrid';
import ObjectsWrapperListViewTanStackTable from './views/table/TanStackTable/ObjectsWrapperListViewTanStackTable';

const ViewsMap = {
  tiles: ObjectsWrapperGridView,
  table: ObjectsWrapperListViewTanStackTable,
  reactTables: ObjectsWrapperListViewReactTables,
  agGrid: ObjectsWrapperListViewAGGrid,
};
/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const ObjectsWrapperView = (props) => {
  const { ItemComponent } = props;
  const { data } = useContext(ObjectsWrapperDataContext);
  const { viewType } = useContext(ObjectsWrapperManipulationContext);
  // START TEMP to showcase the reactTables view
  const [searchParams] = useSearchParams();
  // END TEMP
  const ViewWrapper = useMemo(() => {
    return ViewsMap[searchParams.get('tableVariant')] || ViewsMap[viewType];
  }, [viewType]);

  return (
    <main key='ObjectsWrapperView' className='ObjectsWrapperView_root' style={{ overflow: 'auto', width: '100%', maxWidth: '100vw', paddingTop: '50px' }}>
      <FilterBar viewType={viewType} />
      {Object.keys(data).length ? <ViewWrapper ItemComponent={ItemComponent} data={data} /> : null}
    </main>
  );
};

export default ObjectsWrapperView;
