import { REPLACE_USER } from '../auth/actions';
import Building from './building';
import { OrderedMap } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';
import { buildingsEvents } from './buildingsEvents';

const InitialState = Record(
  {
    map: OrderedMap(),
  },
  'buildings'
);

const initialState = new InitialState();

export default function buildingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case buildingsEvents.GET_BUILDINGS: {
      if (!action.payload?.projectId) return state;
      const { projectId, buildings } = action.payload;

      if (action.payload?.buildings) {
        let buildingsMap = new OrderedMap();

        buildings.forEach((building) => {
          let buildingToSet = new Building({ ...building });
          buildingsMap = buildingsMap.set(building.id, buildingToSet);
        });
        state = state.setIn(['map', projectId], buildingsMap);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
