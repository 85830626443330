import React from "react";
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import withStyles from "@material-ui/core/styles/withStyles";
import _ from 'lodash';

// Components
import NoItemsFound from "../../components/CementoComponents/NoItemsFound";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import ChecklistRow from "./ChecklistRow.js";
import CollapsibleSection from '../../components/CementoComponents/CollapsibleSection';
import CollapsableChecklistSection from "./CollapsibleChecklist";

// Others
import { lokiInstance } from '../../../common/configureMiddleware';
import { startToast } from '../../../common/app/actions';
import { ChecklistPageContext } from '../../../common/checklists/contexts'
import { ChecklistContext } from '../../../common/checklists/contexts'
import { getChecklistItemInstances } from '../../../common/checklists/funcs'
import * as instancesStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus";
import { bulkStatusUpdate } from "../../../common/checklistItemsInstances/funcs";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages"
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import theme from '../../assets/css/theme';
import * as permissionsFunc from '../../../common/permissions/funcs';
import {withChecklistItemInstances} from "../../../common/posts/hooks/useChecklistItemInstances";

const noValueConstantArray = [];
class Checklists extends React.Component {
  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setChecklistsArray = this.setChecklistsArray.bind(this);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.handleCollectiveStatusChange = this.handleCollectiveStatusChange.bind(this);
    this.handleSelectSection = this.handleSelectSection.bind(this);
    this.mapChecklistsByStage = this.mapChecklistsByStage.bind(this);
    this.initChecklistStages = this.initChecklistStages.bind(this);
    this.makeStageObj = this.makeStageObj.bind(this);
    this.handleChecklistRowRef = this.handleChecklistRowRef.bind(this);
    this.state = {
      checklistsSections: [],
      selectedProjectLocations: {},
      instancesDocsAndIssues: {},
      instancesIdsArray: [],
      locationsArray: [],
      sortedTrades: [],
      currActiveSection: {},
      currFocusSection: {},
    };

    this.checklistContext = {
      extraDataEditMode: this.props.extraDataEditMode,
      selectedItemId: null,
      instancesDocsAndIssues: this.state.instancesDocsAndIssues,
    }
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff('checklistPostInstancesListener');
  }

  UNSAFE_componentWillMount() {
    this.lokiPosts = lokiInstance.getCollection('posts');
    this.lokiPosts.cementoOn('checklistPostInstancesListener', this.lokiPostsListener);
    this.setComponentData({}, this.props)
  }

  
  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { stages, checklists, checklistItems, checklistInstances, locationsData, filteredChecklists, aggregateMode, buildings, floors, units, selectedProjectId } = props;    
    let newStateChanges = {};
    
    if (nextProps.sideCardClosed && props.isValDiff(nextProps, ['sideCardClosed'])) {
      newStateChanges.selectedItemId = null;
      newStateChanges.selectedChecklistId = null;
      newStateChanges.focusedChecklistId = null;
      newStateChanges.currFocusSection = {};
      newStateChanges.currActiveSection = {};
    }

    if ((nextProps.sideCardClosed && props.isValDiff(nextProps, ['sideCardClosed'])) || (locationsData != nextProps.locationsData))
      newStateChanges.selectedChecklistIndex = null;

    if (locationsData != nextProps.locationsData ||
        aggregateMode != nextProps.aggregateMode || 
        buildings != nextProps.buildings || 
        floors != nextProps.floors || 
        units != nextProps.units || 
        stages != nextProps.stages ||
        checklists != nextProps.checklists || 
        checklistItems != nextProps.checklistItems ||
        filteredChecklists != nextProps.filteredChecklists ||
        checklistInstances != nextProps.checklistInstances) {
      let res = this.setChecklistsArray(nextProps);
      newStateChanges.lastOrdinalNo = res.lastOrdinalNo;
      newStateChanges.instancesIdsArray = res.instancesIdsArray;
      newStateChanges.checklistsSections = res.checklistsSections;
      newStateChanges.checklistsMappedByStage = res.checklistsMappedByStage;
      newStateChanges.instancesDocsAndIssues = res.instancesDocsAndIssues;
      newStateChanges.myDefaultActionStatus = res.myDefaultActionStatus;
    }

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  initChecklistStages(stages, checklists) {
    const { intl } = this.props;

    let updatedStages = {};
    let updatedChecklists = {};
    let existingStageNameMap = {};

    stages.loopEach((i, stage) => {
      existingStageNameMap = existingStageNameMap.setNested([stage.getCementoTitle()], stage);
    });
    
    checklists.loopEach((checklistId, checklist) => {
      let checklistStageName = checklist.getNested(['stage'], 'None');
      let checklistStageId = checklist.getNested(['stageId'], null);
      let shouldChecklistUpdate = !(checklistStageId && stages.getNested([checklistStageId]));
      let checklistStage = stages.getNested([checklistStageId], existingStageNameMap.getNested([checklistStageName], null));

      if (!shouldChecklistUpdate)
        return;

      checklist = checklist.setNested(['stageId'], checklistStage.getNested(['id']));

      updatedChecklists = updatedChecklists.setNested([checklistId], checklist);
    })

    return { 
      updatedStages:     Boolean(Object.keys(updatedStages).length)     && updatedStages,
      updatedChecklists: Boolean(Object.keys(updatedChecklists).length) && updatedChecklists,
    }
  }

  onRowClick(selectedStage, selectedChecklist = null, selectedChecklistIndex = null, selectedChecklistItem = null, isNew = false) {
    const { onClick } = this.props;
    const { checklistsSections } = this.state;

      let locationId = checklistsSections.getNested([selectedChecklistIndex, 'locationId']);
      if (onClick)
        onClick(selectedStage, selectedChecklist, selectedChecklistItem, locationId, isNew);
  
      this.setState({
        selectedItemId: (selectedChecklistItem || {}).getNested(['id']),
        selectedChecklistId: !selectedChecklistItem ? (selectedChecklist || {}).getNested(['id']) : null,
        focusedChecklistId: (selectedChecklist || {}).getNested(['id']),
        currFocusSection: { [selectedStage.getNested(['id'])]: true },
        currActiveSection: selectedChecklist || selectedChecklistItem ? {} : { [selectedStage.getNested(['id'])]: true },
        selectedChecklistIndex
      });
  }
  
  lokiPostsListener(collectionName) {
    if (collectionName == 'posts') {
      let instancesDocsAndIssues = this.getInstancesDocsAndIssues(this.state.instancesIdsArray, this.props.selectedProjectId);
      this.setState({instancesDocsAndIssues});
    }
  }

  getInstancesDocsAndIssues(instancesIdsArray, projectId) {
    const { relevantPostsHandler } = this.props
    let posts = this.lokiPosts.cementoFind({'projectId' : projectId, 'checklistItemInstance.id': { '$in': instancesIdsArray } });
    let map = {};
    posts.forEach(p => {
      if (!map[p.checklistItemInstance.id])
        map[p.checklistItemInstance.id] = { issues: [], docs: [] };
      map[p.checklistItemInstance.id][p.isIssue?'issues':'docs'].push(p);
    });
    if (relevantPostsHandler) 
      relevantPostsHandler(posts);
    return map;
  }

  createChecklistObject(checklist, loc = {}, forceHideDuplicationNo) {
    let title = checklist.description;
    let duplicationNo = checklist.duplicationNo || (checklist.duplicatable ? 1 : null);
    let extraInfoText = checklist.getNested(['locations', loc.type, loc.id, "extraInfo", "text"], false);
    if (!forceHideDuplicationNo && duplicationNo)
      title += ` - ${duplicationNo}`;
    if (extraInfoText)
      title += ` (${extraInfoText})`;

    return { title, checklist, locationId: loc.id, locationTitle: loc.title, counters: { total: 0, grade: 0 }, trades: {}, items: [], rejected: 0, total: 0, grade: 0, resolved: 0, confirmed2: 0, confirmed: 0 };
  }

  setChecklistsArray(nextProps) {
    const { checklists, checklistItems, checklistInstancesArr, locationsData, filteredChecklists, filteredChecklistsItem, viewer, selectedProjectId } = nextProps;
    let checklistMap = {};
    let locationsArray = [];
    let instancesIdsArray = [];
    let lastItem = null;
    let highestOrdinalNo = 0;


    if (Array.isArray(locationsData))
      locationsArray = locationsData;
    else {
      let locationDataType = locationsData.type + 's';
      let locationDataId = locationsData[locationsData.type + 'Id'];
      locationsArray = [{ type: locationDataType, id: locationDataId }]
    }
    const _checklists = permissionsFunc.safeToJS(checklists);
    const checklistsByOriginChecklistId = _.groupBy(checklists, 'originChecklist');
    _.values(permissionsFunc.safeToJS(checklistItems)).forEach((item) => {
      if (item.isDeleted || filteredChecklistsItem && !filteredChecklistsItem[item.id])
        return;
      lastItem = item;
      _.keys(item.checklistIds).forEach((checklistId) => {
        if (filteredChecklists && !filteredChecklists[checklistId])
          return;
        let parentChecklist = _checklists[checklistId];
        locationsArray.forEach(loc => {
          let isInLocation = (parentChecklist && parentChecklist.getNested(['locations', loc.type, loc.id]) && !item.getNested(['onlyLocations'])) || item.getNested(['onlyLocations', loc.type, loc.id]);
          if (parentChecklist && isInLocation) {  
            const isChecklistDuplicate = Boolean(parentChecklist.originChecklist);
            const hasDuplicationOnLocation = Boolean(checklistsByOriginChecklistId[parentChecklist.id]?.filter?.(chk => chk.locations?.[loc.type]?.[loc.id])?.length)
            const forceHideDuplicationNo = !(isChecklistDuplicate || hasDuplicationOnLocation);
            if (parentChecklist.getNested(['ordinalNo'], -1) > highestOrdinalNo)
              highestOrdinalNo = parentChecklist.ordinalNo;

            let sectionId = checklistId + loc.id;
            if (!checklistMap[sectionId]) {
              checklistMap[sectionId] =  this.createChecklistObject(parentChecklist, loc, forceHideDuplicationNo);
            }
            let filteredChecklistInstances = checklistInstancesArr.filter(
							checklistInstance => checklistInstance.checklistItemId === item.id && checklistInstance.locationId === loc.id,
						);
            
            let currInstance = getChecklistItemInstances(parentChecklist.id, filteredChecklistInstances);
            
            let currStatus = currInstance ? currInstance.status : null;
            
            if (currInstance) instancesIdsArray.push(currInstance.id);
            
            if (currStatus != instancesStatus.CLI_STATUS_IRRELEVANT)
              checklistMap[sectionId].counters.total += (item.weight||1);

            if (currStatus && currStatus != instancesStatus.CLI_STATUS_NONE && currStatus != instancesStatus.CLI_STATUS_IRRELEVANT) {
              if (!checklistMap[sectionId].counters[currStatus]) checklistMap[sectionId].counters[currStatus] = 0;
              checklistMap[sectionId].counters[currStatus] += (item.weight||1);
            }

            checklistMap[sectionId].items.push({ item, currInstance, title: item.description, locationId:loc.id, initialParentChecklistId: parentChecklist.getNested(['id']) });
            checklistMap[sectionId].trades[item.trade] = nextProps.trades.getNested([item.trade, 'getTitle']);
          }
        })
      })
    })

    let myDefaultActionStatus = null;
    if (lastItem) {
      if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm2", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_CONFIRMED_2;
      else if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_CONFIRMED;
      else if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "resolve", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_RESOLVED;
    }

    let instancesDocsAndIssues = this.getInstancesDocsAndIssues(instancesIdsArray, selectedProjectId)
    let sortedArray = Object.values(checklistMap);
    sortedArray = sortedArray.sort((a, b) =>
      (a.checklist.ordinalNo == b.checklist.ordinalNo) ?
        (a.checklist.duplicationNo - b.checklist.duplicationNo) :
        (a.checklist.ordinalNo - b.checklist.ordinalNo)
    );
    sortedArray.forEach(element => element.items = element.items.sort((a,b) => (a.item.ordinalNo || 0) - (b.item.ordinalNo || 0)));
    let checklistsMappedByStage = this.mapChecklistsByStage(sortedArray, nextProps);

    return { checklistsSections: sortedArray, instancesDocsAndIssues, instancesIdsArray, myDefaultActionStatus, checklistsMappedByStage, lastOrdinalNo: highestOrdinalNo };
  }

  async handleCollectiveStatusChange(newStatus, locationId, allRelevantItems, allRelevantInstances, checklistId) {
    const { selectedProjectId, startToast } = this.props;
    let { requirementsExists } = await bulkStatusUpdate({
      newStatus,
      projectId: selectedProjectId,
      locationId,
      allRelevantItems,
      allRelevantInstances,
      immediateSave: true,
      checklistId,
    });
    if (requirementsExists) startToast({ title: checklistItemMessages.cannotBulkChangeMessage.message, type: 'error' });
  }

  makeStageObj(stage, lang) {
    if (!stage || stage.getNested(['isDeleted'], false))
      return false;

    let stageTitle = stage.getNested(['title'], {});
    return {
      stageTitle: stageTitle[lang] ? stageTitle[lang] : Object.values(stageTitle)[0],
      stageId: stage.getNested(['id']),
      stage,
      checklists: [],
    };
  }

  mapChecklistsByStage(checklistSections, props) {
    const { setChecklistsCompleteInfo, stages, lang, setChecklistsMappedByStage } = props;

    let checklistsMappedByStage = {};
    let completeChecklistsInfo = {};
    let hiddenStagesId = {};

    let duplicatedChecklistIdsMap = {};
    checklistSections.forEach(checklistInfo => {
      let currChecklistId = checklistInfo.getNested(['checklist', 'id']);
      let currStageId = checklistInfo.getNested(['checklist', 'stageId'], null);
      let originChecklistId = checklistInfo.getNested(['checklist', 'originChecklist'], false);

      if (!currStageId || !originChecklistId)
        return;
      
      duplicatedChecklistIdsMap[originChecklistId] = _.concat(duplicatedChecklistIdsMap.getNested([originChecklistId], []), [currChecklistId]);
    });
    
    checklistSections.forEach((checklistInfo, i) => {
      let currChecklistId = checklistInfo.getNested(['checklist', 'id']);
      let currStageId = checklistInfo.getNested(['checklist', 'stageId'], null);
      let currStage = stages.getNested([currStageId], null);
      let originChecklistId = checklistInfo.getNested(['checklist', 'originChecklist'], false);
      let currChecklistItemIds = checklistInfo.getNested(['items'], []).map(item => item.getNested(['item', 'id']));
      let duplicatedChecklistIds = duplicatedChecklistIdsMap.getNested([currChecklistId], false);
      

      completeChecklistsInfo = completeChecklistsInfo.setNested([currChecklistId], { id: currChecklistId, itemsIds: currChecklistItemIds, originChecklistId, duplicatedChecklistIds });
      
      if (!checklistsMappedByStage.getNested([currStageId], null) && currStageId) {
        const stageObject = this.makeStageObj(currStage, lang);
        if (stageObject)
          checklistsMappedByStage[currStageId] = stageObject;
      }
      
      if (!checklistsMappedByStage[currStageId])
        return;

      checklistInfo = checklistInfo.setNested(['duplicatedChecklistIds'], duplicatedChecklistIds);
      checklistInfo = checklistInfo.setNested(['globalCheckListIndex'], i);
      checklistsMappedByStage[currStageId].checklists.push(checklistInfo);
    });

    let toRet = Object.values(checklistsMappedByStage);

    if (setChecklistsCompleteInfo) setChecklistsCompleteInfo(completeChecklistsInfo);
    if (setChecklistsMappedByStage) setChecklistsMappedByStage(toRet);

    return toRet;
  }


  handleSelectSection(key) {
		const { currActiveSection } = this.state;

		if (!currActiveSection[key]) this.setState({ currActiveSection: { [key]: true } });
  }

  handleChecklistRowRef(key, component) {
    const { handleChecklistRowRef } = this.props;
    if (typeof handleChecklistRowRef === 'function')
      handleChecklistRowRef(key, component);
  }

  render() {
    const { enableCollectiveAction, rtl, minMode, openAll, width, maxCharsInItem, intl, locationsData, hideStage, extraDataEditMode, toggleExtraDataEditMode, isAggregated, setHasChanges } = this.props;
    const { checklistsSections, focusedChecklistId, selectedChecklistId, selectedItemId, instancesDocsAndIssues, myDefaultActionStatus, checklistsMappedByStage, currActiveSection, currFocusSection, selectedChecklistIndex } = this.state;

    let defaultOpen = (checklistsSections.length == 1 || openAll);
    let miniEmptyComp = <NoItemsFound compStyles={{ height: theme.headerHeight }} locationMode={Boolean(!locationsData || Object.values(locationsData).length == 0)}/>;
    if (!checklistsMappedByStage || checklistsMappedByStage.length == 0)
        return <NoItemsFound locationMode={Boolean(!locationsData || Object.values(locationsData).length == 0)}/>;
    
    
    this.checklistContext.extraDataEditMode = extraDataEditMode;
    this.checklistContext.selectedItemId = selectedItemId;
    this.checklistContext.instancesDocsAndIssues = instancesDocsAndIssues;

    return (
    <>
      {/* <div onClick={() => {
        const { duplicateChecklist } = this.props;
        duplicateChecklist('-MB-RRbpaV3ARVn4Kzlz', '-MCl3WGDHjxRY5w4gSyv', 'unit', "-MB-SwuGE2L8BrsnJaQ6", { 'text': 'text' });
      }}>Click here</div> */}
          {checklistsMappedByStage.map((currStage, sectionIndex) => {
          return (
            <CollapsibleSection
              childrenOnly={hideStage}
              key={currStage.stageId}
              keyId={currStage.stageId}
              section={{ title: currStage.stageTitle }}
              onSelect={() => {this.handleSelectSection(currStage.stageId); this.onRowClick(currStage.stage);}}
              isSelected={currActiveSection.getNested([currStage.stageId], false)}
              isFocused={currFocusSection.getNested([currStage.stageId], false)}
              mainContainerStyle={{ paddingBottom: hideStage ? 0 : theme.paddingSize }}
              footerContainerStyle={{ display: 'flex', justifyContent: 'center' }}
            >
                {currStage.checklists.map((currChecklistSection, currChecklistIndex) => {
                  let isChecklistSelected = isAggregated ? (selectedChecklistIndex === currChecklistSection.globalCheckListIndex) : (selectedChecklistId === currChecklistSection.checklist.id);
                  let isChecklistFocused = !isAggregated && focusedChecklistId === currChecklistSection.checklist.id;
                  return (
                    <GridContainer
                      key={'checklistWrapperNumber' + currChecklistSection.getNested(['checklist', 'id'], currChecklistIndex) + currChecklistIndex}
                      style={{
                        maxWidth: 1000,
                        width: width || 'auto',
                        transition: "all 300ms ease 0s"
                      }}
                    >
                      <ChecklistContext.Provider value={{
                        ...this.checklistContext,
                        locationsData,
                        itemsArr: _.get(checklistsMappedByStage, [sectionIndex, 'checklists', currChecklistIndex, 'items'], noValueConstantArray),
                      }}>
                        <CollapsableChecklistSection
                        rtl={rtl}
                        intl={intl}
                        open={isChecklistSelected || defaultOpen}
                        onCollectiveAction={(newStatus) => this.handleCollectiveStatusChange(
                          newStatus,
                          currChecklistSection.locationId,
                          currChecklistSection.items.map(curr => curr.item),
                          currChecklistSection.items.map(curr => curr.currInstance),
                          currChecklistSection.checklist.id
                        )}
                        onClick={() => {
                          this.onRowClick(currStage.stage, currChecklistSection.checklist, currChecklistSection.globalCheckListIndex);
                        }}
                        isSelected={isChecklistSelected}
                        isFocused={isChecklistFocused}
                        enableCollectiveAction={enableCollectiveAction && currChecklistSection.items.filter(item => ((item.currInstance == null || item.currInstance.status == instancesStatus.CLI_STATUS_NONE) && Object.keys(item.getNested(['item', 'extraData'], {})).length == 0)).length > 0}
                        defaultActionStatus={myDefaultActionStatus}
                        checklistSection={currChecklistSection}
                        minMode={minMode}
                        footerContainerStyle={{ display: 'flex', justifyContent: 'center', paddingTop: theme.verticalMargin, paddingBottom: theme.verticalMargin }}
                      >
                          {currChecklistSection.items.map((curr, currItemIndex) => (
                            <ChecklistRow
                              key={curr.item.id}
                              onRef={component => this.handleChecklistRowRef(curr.item.id, component)}
                              keyId={curr.item.id}
                              currItemId={curr.item.id}
                              checklistId={currChecklistSection.checklist.id}
                              linksMode="hide"
                              isDraggable={true}
                              minTrades={minMode}
                              maxChars={maxCharsInItem}
                              minMode={minMode}
                              onClick={() => this.onRowClick(currStage.stage, currChecklistSection.checklist, currChecklistSection.globalCheckListIndex, curr.item)}
                              parentChecklistFocused={currChecklistSection.checklist.id === focusedChecklistId}
                              checklistType={currChecklistSection.checklist.type}
                              toggleExtraDataEditMode={toggleExtraDataEditMode}
                              setHasChanges={setHasChanges}
                            />
                          ))}
                      </CollapsableChecklistSection>
                      </ChecklistContext.Provider>
                    </GridContainer>
                  );
                })}
            </CollapsibleSection>
          );
        })}
    </>
    );
  }
}

Checklists = withStyles(buttonStyle)(Checklists);
Checklists = injectIntl(Checklists);
const enhance = compose(
  withChecklistItemInstances,
  connectContext(ProjectContext.Consumer),
  connectContext(ChecklistPageContext.Consumer),
  connect(state => ({
    rtl: state.app.rtl,
  }), 
  { startToast,
  })
);

export default enhance(Checklists);