import { subscribeToLastUpdates } from '../lib/utils/utils';
import { batchDispatch } from '../app/funcs';
import serverSDK from '@cemento-sdk/server';
import { checklistItemsEvents } from './checklistItemsEvents';

export async function getChecklistItems(viewer, projectId) {
  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'checklistItems',
    getData: () => {
      return serverSDK.checklists.getChecklistItems({ projectId });
    },
  };

  const onData = (data) => {
    batchDispatch([{ type: checklistItemsEvents.GET_CHECKLIST_ITEMS, payload: { projectId, checklistItems: data } }]);
  };

  const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);

  if (result) {
    onData(result);
  }

  return { projectId };
}
