import React from 'react';
import { createContext } from 'react';
import { useObjectsManipulations, useProcessedObjects, useSmartObjects } from './hooks';
import InstancesPreProcessorHOC from './helpers/InstancesPreProcessorHOC';

export const ObjectsWrapperDataContext = createContext({
  data: [],
});

export const ObjectsWrapperManipulationContext = createContext({
  groupByOptions: null,
  filterOptions: null,
  groupBy: null,
  sortBy: null,
  filters: null,
  skip: null,
  step: null,
});

/**
 *
 * @param {import('react').PropsWithChildren<{ objects: any[], propTypes: any, propMapping: any }>} props
 * @returns
 */
export const ObjectsWrapper = (props) => {
  const { objects, propTypes, children } = props;

  const smartObjects = useSmartObjects(objects, propTypes);
  const objectsManipulationsData = useObjectsManipulations(smartObjects, propTypes);
  const processedObjects = useProcessedObjects(smartObjects, objectsManipulationsData);
  return (
    <ObjectsWrapperDataContext.Provider value={{ data: processedObjects }}>
      <ObjectsWrapperManipulationContext.Provider value={objectsManipulationsData}>
        {children}
      </ObjectsWrapperManipulationContext.Provider>
    </ObjectsWrapperDataContext.Provider>
  );
};

export default InstancesPreProcessorHOC(ObjectsWrapper);
