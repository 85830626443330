import SignatureDialog from "./SignatureDialog"
import { useCallback, useRef, useContext, useState, useEffect, useMemo } from 'react';
import { getDispatch, lokiInstance } from '../configureMiddleware';
import { ProjectContext } from '../projects/contexts';
import { track } from '../lib/reporting/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { REPORT_STATE_CLOSED, REPORT_STATE_AWAITING_SIGNATURE } from '../forms/formsStatusStates';
import { upsertForm } from '../forms/funcs';
import { getRoundedDate } from '../lib/utils/utils';
import moment from 'moment-timezone';
import { startToast, startLoading, hideLoading } from '../app/actions';
import systemMessages from '../app/systemMessages';

const DailyReportSignatureModal = (props) => {
    const { 
        isOpen,
        handleClose,
        signObjectId,
        projectId,
        formReportDate,
        formType,
        intl,
        formTemplateId,
        track,
    } = props
    const { viewer, configurations } = useContext(ProjectContext);

    const lokiObjectAnalytics = lokiInstance.getCollection('forms');
    const currentForm = lokiObjectAnalytics.cementoFind({ id: signObjectId })[0];
    const formConfigurationsSignatures = useMemo(
      () => configurations?.forms?.[formTemplateId]?.signatures || {},
      [configurations?.forms?.[formTemplateId]?.signatures]
    );
    const dispatch = getDispatch();
    const signatures = useMemo(
        () => Object.values(formConfigurationsSignatures),
        [formConfigurationsSignatures, currentForm]
    );
  
    const unsignedSignatures = useMemo(() => {
      return signatures
        .reduce((acc, signature) => {
          if (!currentForm.signatures?.[signature.id]) {
            acc.push(signature);
          }
          return acc;
        }, [])
        .sort((a, b) => {
          return a.ordinalNo - b.ordinalNo;
        });
    }, [signatures]);

    const onSign = useCallback(
        async (uploadedSignatures) => {
            const roundedDate = getRoundedDate();

            const currDate = moment(roundedDate.timestamp).utc();
            let localDate = new Date(currDate.format('YYYY-MM-DD'));
            localDate.setHours(0, new Date().getTimezoneOffset() * -1, 0, 0);
            const newFormStatus = unsignedSignatures.length > 1 ? REPORT_STATE_AWAITING_SIGNATURE : REPORT_STATE_CLOSED;
            track('Signed form in web', {
              formId: signObjectId,
              formTemplateId,
              formType,
              didFinishSign: unsignedSignatures.length === 1,
            });
      
            let updatedForm = {
              id: signObjectId,
              status: newFormStatus,
              formTemplateId: currentForm.formTemplateId,
              signatures: {
                ...uploadedSignatures,
                ...currentForm.signatures,
              },
              reportDate: formReportDate || localDate.getTime(),
              type: formType,
            };
            await dispatch(upsertForm(projectId, viewer, updatedForm, formType));
            handleClose();
      
            dispatch(startToast({ title: intl.formatMessage(systemMessages.signedSuccessfully), type: 'success' }));
      }
    , [signObjectId, formType, formReportDate, formTemplateId, unsignedSignatures, viewer, projectId, currentForm, intl, dispatch, handleClose]);
    
    return (
        <SignatureDialog
        isOpen={isOpen}
        handleClose={handleClose}
        signatureObjs={unsignedSignatures}
        onSign={onSign}
        shouldUploadImmediately={true}
        >

        </SignatureDialog>
    )
}

const enhance = compose(connect(null, { track }), injectIntl);

export default enhance(DailyReportSignatureModal);