import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';

export const LOAD_PROJECT_TRADES = 'LOAD_PROJECT_TRADES';
export const GET_TRADES = 'GET_TRADES';
export const TRADES_LOADING = 'TRADES_LOADING';
export const SET_RECENT_TRADES = 'SET_RECENT_TRADES';

export function setProjectTrades(projectId, members, projectChecklistItems) {

  let trades = {};
  (members || []).map(member => {
    Object.keys(member.trades || {}).forEach(tradeId => {
      if (Boolean(!trades[tradeId]))
        trades[tradeId] = { id: tradeId };
    });
  });

  if (projectChecklistItems)
    projectChecklistItems.loopEach((id, checklistItem) => {
      if (checklistItem && checklistItem.trade && !trades[checklistItem.trade])
        trades[checklistItem.trade] = { id: checklistItem.trade };
    });

  return {
    type: LOAD_PROJECT_TRADES,
    payload: { projectId, trades }
  };
}

