import Button from './standardComponents/Button';
import closeIcon from "../assets/img/icons/close.png"

const CloseButton = ({ onClick, size, buttonStyle = {}, testid = "close-button" }) => {
    return (
        <Button
            testid={testid}
            iconStyle={{filter: 'brightness(0) invert(1)', height: 10}}
            style={{ width: size, height: size, minWidth: size, minHeight: size, border: 'none', ...buttonStyle}}
            icon={closeIcon}
            shouldInvertColors={true}
            onClick={onClick}>
        </Button>
    );
};

export default CloseButton;