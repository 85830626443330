
import { CementoRecordObject, Record } from '../transit';
import * as lastUpdatesActions from '../lastUpdates/actions';

import * as appActions from '../app/actions';
import * as checklistActions from '../checklists/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import { CHECKLIST_ITEMS_INSTANCES_EVENTS } from './trackChecklistItemsInstances';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record({
  map: new CementoRecordObject,
  sync: new CementoRecordObject,
  lastSynced: new CementoRecordObject,
  isAdmin: false,
}, 'checklistItemsInstances', false);

const initialState = new InitialState;

export default function checklistItemsInstancesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['checklistInstances'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], null)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }
    
    case lastUpdatesActions.SYNCED + "_CHECKLIST_INSTANCES_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case appActions.CLEAN_PERMISSION_BASED_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case CHECKLIST_ITEMS_INSTANCES_EVENTS.CLEAN_CACHED_ITEMS_INSTANCES:
    case checklistActions.CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES+ "_SUCCESS":
    case CHECKLIST_ITEMS_INSTANCES_EVENTS.CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA + "_SUCCESS":
    case appActions.CLEAN_CACHE + "_SUCCESS": { 
      state = initialState;
      return state;
    }
  }

  return state;
}


