import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import _ from "lodash";

// Components
import MultiCheckSelect from "../MultiCheckSelect";
import Text from "../Text";
import CategoryTab from "./CategoryTab";
import CategoryTabValues from "./CategoryTabValues";
import TextToggle from "./TextToggle";

// Messages
import systemMessages from "../../../../common/app/systemMessages";
import postsMenuMessages from "../../../../common/posts/postsMenuMessages";

// Icons
import closeIcon from "../../../assets/img/icons/close.png";

// Others
import theme from '../../../assets/css/theme';
import { BORDER } from '.';
import { getDateString, isEmptyValue, safeFormatMessage } from '../../../../common/app/funcs';
import * as propertyTypes from '../../../../common/propertiesTypes/propertiesTypes';
import StandardInput from '../StandardInput';
import { injectIntl } from '../../../../common/app/myInjectIntl';
import { getLocationTitlesParams } from '../../../../common/locations/func';
import { ProjectContext } from '../../../../common/projects/contexts';
import { connectContext } from 'react-connect-context';
import DateRangePicker from '../DateRangePicker';
import moment from 'moment';
import withRouterHOC from "../../Router/util/withRouterHOC";


export const SUPPORTED_FILTER_TYPES = [
  propertyTypes.SELECTION_LIST,
  propertyTypes.STRING,
  propertyTypes.NUMBER,
  propertyTypes.DATE,
  propertyTypes.BOOLEAN,
  propertyTypes.LOCATION,
  propertyTypes.DATE_RANGE,
];

/**
 * 
 * @param {number[]} values 
 * @returns 
 */
const getMinAndMaxDateFromFilter = (filter) => {
  const filterValues = _.isArray(filter) ? filter : (filter?.['AND'] || []);
  const dates = filterValues.map(v => _.toNumber(v.substring(1)));

  return {
    min: Math.min(...dates) || 0,
    max: Math.max(...dates)|| 0,
  };
};

// collect all checked nested ids in structure like { [id1]: id1, [idN]: idN, ... }
const getCheckedValuesRecursively = (items) => {

  const optionsToGoOver = _.values(items);
  let allCheckedMap = {};
  while (optionsToGoOver.length) {
    const option = optionsToGoOver.pop();
    if (option.checked && option.id) {
      allCheckedMap[option.id] = option.id;
    }
    if (option.nested?.length) {
      optionsToGoOver.push(...option.nested);
    }
  }

  return allCheckedMap;
};

class FilterMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilterSet: {},
      selectedCategory: {},
      value: null,
    };
  }

  UNSAFE_componentWillMount() {
    
    let newStateChanges = this.setComponentData(
      { firstMount: true },
      this.props
    );

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newStateChanges = this.setComponentData(this.props, nextProps);

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  setComponentData = (props, nextProps) => {
    const { firstMount } = props;
    let newStateChanges = {};

    let nextValue = nextProps.value;

    // Initialize the selectedCategory
    if (firstMount || !_.isEqual(props.filters, nextProps.filters)) {
      const selectedFilterSetId = nextValue ? Object.keys(nextValue)[0] : null;
      newStateChanges.selectedFilterSet = (selectedFilterSetId ? Object.values(nextProps.filters || []).filter(f => f.id === selectedFilterSetId)[0] : Object.values(nextProps.filters || [])[0]) || {};
      newStateChanges.selectedCategory = _.values(newStateChanges.selectedFilterSet.categories || {})
        .sort((a, b) => a.ordinalNo - b.ordinalNo)
        .find(category => category.type !== propertyTypes.SELECTION_LIST || Boolean((category.options || []))) || {};
    }

    if (props.isValDiff(nextProps, ["isShow"]) && nextProps.isShow)
      newStateChanges.value = nextValue;

    return newStateChanges;
  };

  handleSelectFilterSetClick = (selectedFilterSet) => {
    let defaultSelectedCategory = (selectedFilterSet.categories || [])[0];
    this.setState({
      selectedFilterSet,
      selectedCategory: defaultSelectedCategory,
    });
  };

  handleCategoryClick = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  handleCategoryInputChange = (filterSet, category, categoryValue) => {
    const { value } = this.state;

    let newValue = Object.assign({}, value);
        newValue[filterSet.id] = { ...(newValue[filterSet.id] || {}) };

    if (isEmptyValue(categoryValue))
      delete newValue[filterSet.id][category.id];
    else
      newValue[filterSet.id][category.id] = categoryValue;

    if (_.isEmpty(newValue[filterSet.id])) {
      delete newValue[filterSet.id];
    }

    this.setState({ value: newValue });
  };

  handleDateRangeChange = (filterSet, category, newRange) => {
    let newValue = null;
    if (newRange) {
      const rangeStart = newRange.start;
      const rangeEnd = moment(newRange.end).hours(23).minutes(59).seconds(59).milliseconds(999).valueOf();
      newValue = {
        AND: [`>${rangeStart}`, `<${rangeEnd}`],
      };
      _.values(category.existingValues).forEach(existingTS => {
        existingTS = _.toNumber(existingTS);
        existingTS = _.isNaN(existingTS) ? null : existingTS;
        if (!existingTS)
          return;

        const isDateInRange = moment(existingTS).isBetween(rangeStart, rangeEnd);

        if (isDateInRange && !newValue[existingTS])
          newValue[existingTS] = existingTS;
      });
    }
    this.handleCategoryInputChange(filterSet, category, newValue);
  }

  handleMultiSelectChange = (filterSet, category, fullMap) => {
    if (!fullMap)
      return;
    const newValue = getCheckedValuesRecursively(fullMap);
    this.handleCategoryInputChange(filterSet, category, newValue);
  }

  handleApplyClick = () => {
    const { onChange } = this.props;
    const { value, selectedFilterSet } = this.state;

    if (onChange) {
      const valueToSend = Object.entries(value || {})
        .filter(([key, val]) => key === selectedFilterSet.id)
        .reduce((acc, [key, val]) => _.set(acc, [key], val), {});
      onChange(valueToSend);
      this.handleCloseClick();
    }
  };

  handleCloseClick = () => {
    const { onClose } = this.props;

    if (onClose) onClose();
  };

  handleResetClick = () => {
    const { defaultResetValue } = this.props;

    this.setState({ value: defaultResetValue || null });
  };

  calcCategoryTab = category => {
    const { hideEmptyCategory, intl, buildings, floors, units, rtl } = this.props;
    const { selectedCategory, value, selectedFilterSet } = this.state;
    const isCategoryEmpty = Boolean(category.type === propertyTypes.SELECTION_LIST && !(category.options || []).length);

    if (
      (hideEmptyCategory && isCategoryEmpty) ||
      category.isHidden ||
      !SUPPORTED_FILTER_TYPES.includes(category.type) ||
      !_.get(category, ['inputSettings', 'filterVisibility', 'web'], true)
    )
      return null;

    const filterValue = _.get(value, [selectedFilterSet.id, category.id]);
    const hasValue = !isEmptyValue(filterValue);
     
    /** @type {string[] | null} */
    let values = null;
    switch (category.type) {
      case propertyTypes.SELECTION_LIST: {
        if (hasValue) {
          const checkNested = (items, acc) => {
            if (!items) {
              return;
            }
            items.forEach(item => {
              if (item.checked) {
                const optionTitle = _.get(item, ['title', 'defaultMessage']) ? item.title : item.getCementoTitle();
                const optionTitleString = String(safeFormatMessage(intl, optionTitle));

                if (!isEmptyValue(optionTitleString)) {
                  acc.push(optionTitleString);
                }
              } else if (item.nested?.length) {
                checkNested(item.nested);
              }
            })
          };
          values = (category.options || []).reduce((acc, currOption) => {
            if (currOption.nested?.length) {
              checkNested(currOption.nested, acc);
            }

            if (_.values(filterValue).some(val => currOption.id == val)) { // == in the case of string and number
              const optionTitle = _.get(currOption, ['title', 'defaultMessage']) ? currOption.title : currOption.getCementoTitle();
              const optionTitleString = String(safeFormatMessage(intl, optionTitle));
              if (!isEmptyValue(optionTitleString))
                acc.push(optionTitleString);
            }

            return acc;
          }, []);
        }

        break;
      }

      case propertyTypes.DATE: {
        if (hasValue)
          values = [getDateString(filterValue, intl, systemMessages.fullDateFormat)];
        break;
      }

      case propertyTypes.DATE_RANGE: {
        if (hasValue) {
          const { min, max } = getMinAndMaxDateFromFilter(_.first(_.values(filterValue)));
          let datesStringArr = [getDateString(min, intl, systemMessages.fullDateFormat), getDateString(max, intl, systemMessages.fullDateFormat)];
          datesStringArr = rtl ? datesStringArr.reverse() : datesStringArr;
          values = [datesStringArr.join(' - ')];
        }
        break;
      }

      case propertyTypes.LOCATION: {
        if (hasValue) {
          const locationTitlesParams = getLocationTitlesParams(intl, buildings, floors, units, _.keys(filterValue));
          values = [];
          locationTitlesParams.forEach(titleParams => {
            let name = '';
            if (titleParams.titleRoot)
              name += `${titleParams.titleRoot} `;
            if (titleParams.titles.length)
              titleParams.titles.forEach(title => values.push(name + title))
            else if (name)
              values.push(name);
          });
        }
      }
    }

    const isActive = category.id === selectedCategory.id;
    return (
      <CategoryTab
        title={category.title}
        valuesSlot={
          Boolean(hasValue) &&
          (<CategoryTabValues
            heighlightValues={hasValue}
            values={values}
          />
          )
        }
        isActive={isActive}
        onClick={() => this.handleCategoryClick(category)}
      />
    );
  };
  
  // return options with its predefined values
  getMultiCheckItems(category) {
    const { value, selectedFilterSet } = this.state;

    const categoryValue = _.get(value, [selectedFilterSet.id, category.id]);
    const selected = _.flatten(_.values(categoryValue).map(item => String(item).split(',')));

    let fullNestedMap = [];
    let optionsToCheck = category.options.map(option => ({ option, parentIndex: undefined }));
    while (optionsToCheck.length) {
      const { option, parentIndex } = optionsToCheck.shift();
      const newOption = {
        ...option,
        nested: undefined,
        checked: _.difference(String(option.id).split(','), selected).length === 0,
      }

      if (parentIndex !== undefined) {
        const parent = fullNestedMap[parentIndex];
        if (!parent.nested) {
          parent.nested = [];
        }
        
        parent.nested.push(newOption);
      } else {
        const currOptionIndex = fullNestedMap.push(newOption);
        if (option.nested?.length) {
          optionsToCheck.push(...option.nested.map(option => ({ option, parentIndex: currOptionIndex })));
        }
      }
    }

    return fullNestedMap;
  }

  calcCategoryInput = category => {
    const { value, selectedFilterSet } = this.state;

    let input = null;

    switch (category.type) {
      case propertyTypes.SELECTION_LIST:
        input = (
          <MultiCheckSelect
            key={category.id}
            items={this.getMultiCheckItems(category)}
            height={'100%'}
            onChange={(fullMap) => this.handleMultiSelectChange(selectedFilterSet, category, fullMap)}
          />
        );
        break;

      case propertyTypes.LOCATION:
        input = (
          <StandardInput
            renderBuildingDirectly // for location
            key={category.id}
            type={category.type}
            value={_.get(value, [selectedFilterSet.id, category.id])}
            onChange={newVal => this.handleCategoryInputChange(selectedFilterSet, category, newVal)}
            containerStyle={{ height: '100%', paddingBottom: theme.paddingSize }}
            inputComponentContainer={{ height: '100%' }}
          />
        );
        break;

      case propertyTypes.DATE_RANGE: {
        let _value = null;
        if (_.get(value, [selectedFilterSet.id, category.id])) {
          const { max, min } = getMinAndMaxDateFromFilter(_.first(_.values(_.get(value, [selectedFilterSet.id, category.id]))));
          _value = { start: min, end: max };
        }

        input = (
          <DateRangePicker
            isPickerStandAlone
            isDoneOnEndDate
            key={category.id}
            value={_value}
            maxDate={null}
            onChange={(newRange) => this.handleDateRangeChange(selectedFilterSet, category, newRange)}
            onClear={() => this.handleDateRangeChange(selectedFilterSet, category, null)}
          />
        );
        break;
      }

      case propertyTypes.DATE: {
        const _value = _.chain(value)
          .get([selectedFilterSet.id, category.id])
          .keys()
          .first()
          .toNumber()
          .value() || null;
        input = (
          <StandardInput
            renderBuildingDirectly // for location
            key={category.id}
            type={category.type}
            settings={category.inputSettings}
            value={_value}
            onChange={newVal => this.handleCategoryInputChange(selectedFilterSet, category, newVal ? { [newVal]: newVal } : null)}
          />
        );
        break;
      }
    }



    return input;
  }

  render() {
    const { filters, rtl, isShow } = this.props;
    const { selectedCategory, selectedFilterSet } = this.state;
    if (!isShow) return null;

    return (
      <div
        style={{ ...styles.backgroundDrop }}
        onClick={(e) => {
          e.stopPropagation();
          this.handleCloseClick();
        }}
      >
        <div
          style={{ ...styles.menuMainContainer }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ ...styles.filterHeaderMainContainer }}>
            <div style={{ ...styles.headerLeftContainer }}>
              <Text
                style={{
                  fontWeight: theme.strongBold,
                  fontSize: theme.fontSizeH5,
                }}
              >
                {postsMenuMessages.filter}
              </Text>
            </div>
            <div style={{ ...styles.headerRightContainer }}>
              <div style={{ ...styles.headerButtons }}>
                <Text
                  style={{
                    ...styles.headerButton,
                    border: `1px solid rgba(0, 0, 0, 0.3)`,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                  onClick={this.handleResetClick}
                >
                  {systemMessages.reset}
                </Text>
                <Text
                  style={{
                    ...styles.headerButton,
                    color: "white",
                    backgroundColor: theme.brandPrimary,
                    fontWeight: theme.strongBold,
                  }}
                  onClick={this.handleApplyClick}
                >
                  {systemMessages.apply}
                </Text>
              </div>
              <div
                style={{ ...styles.headerButton, ...styles.headerCross }}
                onClick={this.handleCloseClick}
              >
                <img src={closeIcon} />
              </div>
            </div>
          </div>
          <div style={{ ...styles.filterMainContainer }}>
            <div
              style={{
                ...styles.filterLeftSide,
                [`border${rtl ? "Left" : "Right"}`]: BORDER,
              }}
            >
              {Boolean((filters || []).length > 1) && (
                <CategoryTab
                  title={postsMenuMessages.filters.view}
                  mainContainerStyle={{ cursor: "unset" }}
                  valuesSlot={
                    <TextToggle
                      options={filters}
                      defaultValue={selectedFilterSet}
                      onChange={this.handleSelectFilterSetClick}
                    />
                  }
                />
              )}
              {
                Object.values(_.get(selectedFilterSet, ['categories'], []))
                  .sort((a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0))
                  .map(this.calcCategoryTab)
              }
            </div>
            <div style={{ ...styles.filterRightSide }}>
              {Boolean(selectedCategory) && this.calcCategoryInput(selectedCategory)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const enhance = compose(
  injectIntl,
  connectContext(ProjectContext),
  connect(state => ({
    rtl: state.app.rtl,
  }), {}),
);

export default enhance(withRouterHOC(FilterMenuComponent));

const styles = {
  backgroundDrop: {
    position: "fixed",
    bottom: 0,
    left: 0,
    height: `calc(100vh - ${theme.headerHeight}px)`,
    width: "100vw",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: theme.zIndexes.filterMenuBackdrop,
    display: "flex",
    justifyContent: "flex-end",
  },
  menuMainContainer: {
    minWidth: 500,
    minHeight: 400,
    maxWidth: 700,
    backgroundColor: theme.backgroundColorBright,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 35%',
  },

  // Header
  filterHeaderMainContainer: {
    height: theme.headerHeightSecondary,
    backgroundColor: theme.backgroundColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.paddingSize}px`,
  },
  headerLeftContainer: {},
  headerRightContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  headerButtons: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  headerButton: {
    margin: theme.verticalMargin,
    fontSize: theme.fontSizeH6,
    cursor: "pointer",
    padding: `${theme.padding - 5}px ${theme.paddingSize}px`,
    borderRadius: 50,
  },
  headerCross: {
    margin: theme.margin,
  },

  // FilterContainer
  filterMainContainer: {
    flex: 1,
    minWidth: 400,
    display: 'flex',
    overflow: 'hidden',
  },

  filterLeftSide: {
    flex: '1 1 35%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },

  filterRightSide: {
    flex: '1 1 65%',
    padding: theme.paddingSize,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
};
