import { Navigate } from 'react-router-dom';
import ObjectsWrapper from '../../../../../common/ObjectWrapper/ObjectsWrapper';
import ObjectsWrapperRemoteConfig from '../../../../../common/ObjectWrapper/helpers/ObjectWrapperRemoteConfig';

export default {
  path: 'forms',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics/dailyReport'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_ObjectsWrapper_forms_analytics'}
          contentType={'forms'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'objects/:reportId?',
      element: (
        <ObjectsWrapper key={'project_ObjectsWrapper_forms_analytics'} contentType={'forms'} section={'analytics'} />
      ),
    },
  ],
};
