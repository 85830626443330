import propertiesMessages from './propertiesMessages';
import * as propertyTypes from './propertiesTypes';
import { platformActions } from '../platformActions';
import _ from 'lodash';
import { envParams, getAppState, getDispatch } from '../configureMiddleware';
import { getArrayInnerValueTitle, getArrayNameProp } from './ArrayComponentHOC';
import { isPermitted } from '../permissions/funcs';
import { getPropertiesTypeById } from './actions';
import {
  POSTS_TYPES,
  POSTS_TYPES_TO_HIDE_IN_CREATION_MENU,
  POST_TYPES_SUB_GROUP_TAG,
  SAFETY_TRADE_ID,
} from '../app/constants';
import newPostMessages from '../posts/newPostMessages';
import { batchDispatch } from '../app/funcs';
import { getSnapshotData, writeLogOnce } from '../lib/utils/utils';
import { shouldLastUpdateV2Fetch } from '../lastUpdatesV2/funcs';
import { propertiesTypesEvents } from './propertiesTypesEvents';

export async function getPropertiesTypes(viewer, projectId, subjectName) {
  const dispatch = getDispatch();

  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    resourceName: 'properties',
    firebasePath: `properties/types/projects/${projectId}/${subjectName}`,
    forceMSClientConfig: true,
    queryParams: { subjectName },
  };

  const onData = (data) => {
    dispatch({ type: propertiesTypesEvents.GET_PROPERTIES_TYPES, payload: { projectId, types: data, subjectName } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);

  if (result) {
    onData(result);
  }

  return { projectId, subjectName };
}

export function getSortFunc(type, columnId) {
  const nullOrUndefinedCompare = (a, b, compareFunc) => {
    if (typeof a['values'][columnId] != 'undefined' && typeof b['values'][columnId] == 'undefined') return -1;
    else if (typeof a['values'][columnId] == 'undefined' && typeof b['values'][columnId] != 'undefined') return 1;
    else if (typeof a['values'][columnId] == 'undefined' && typeof b['values'][columnId] == 'undefined')
      return String(a.title || '').localeCompare(String(b.title || ''));
    else if (!Boolean(a['values'][columnId]) && Boolean(b['values'][columnId])) return 1;
    else if (Boolean(a['values'][columnId]) && !Boolean(b['values'][columnId])) return -1;
    else return compareFunc(a, b);
  };

  switch (type) {
    case propertyTypes.STRING: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        return (a ? a.getNested(['values', columnId], "") : "").localeCompare(b ? b.getNested(['values', columnId], "") : "");
      });
    }    
    case propertyTypes.PICTURE: 
    case propertyTypes.VIDEO:
    case propertyTypes.PDF: 
    case propertyTypes.FILES_ARRAY:
    case propertyTypes.DRAWINGS_ARRAY: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (a.getNested(['values', columnId, 'length'], 0) == b.getNested(['values', columnId, 'length'], 0))
            return 0;
          else if (a.getNested(['values', columnId, 'length'], 0) < b.getNested(['values', columnId, 'length'], 0))
            return 1;
          else return -1;
        });
    }
    case propertyTypes.SELECTION_LIST: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (
            Object.keys(a['values'][columnId] || { ' ': '' })[0] == Object.keys(b['values'][columnId] || { ' ': '' })[0]
          )
            return 0;
          else if (
            Object.keys(a['values'][columnId] || { ' ': '' })[0] < Object.keys(b['values'][columnId] || { ' ': '' })[0]
          )
            return 1;
          else return -1;
        });
    }
    case propertyTypes.CERTIFICATION: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (
            Object.values(a['values'][columnId] || { ' ': { certificationTTL: 0 } })[0].certificationTTL ==
            Object.values(b['values'][columnId] || { ' ': { certificationTTL: 0 } })[0].certificationTTL
          )
            return 0;
          else if (
            Object.values(a['values'][columnId] || { ' ': { certificationTTL: 0 } })[0].certificationTTL <
            Object.values(b['values'][columnId] || { ' ': { certificationTTL: 0 } })[0].certificationTTL
          ) {
            return 1;
          } else {
            return -1;
          }
        });
    }
    case propertyTypes.BOOLEAN: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (a['values'][columnId] === b['values'][columnId]) return 0;
          return a['values'][columnId] ? -1 : 1;
        });
    }
    case propertyTypes.NUMBER: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (parseInt(a['values'][columnId] || 0) == parseInt(b['values'][columnId] || 0)) return 0;
          else if (parseInt(a['values'][columnId] || 0) < parseInt(b['values'][columnId] || 0)) return 1;
          else return -1;
        });
    }
    case propertyTypes.DATE:
    default: {
      return (x, y) =>
        nullOrUndefinedCompare(x, y, (a, b) => {
          if (a['values'][columnId] == b['values'][columnId]) return 0;
          else if (a['values'][columnId] < b['values'][columnId]) return 1;
          else return -1;
        });
    }
  }

  return null;
}

export const getPropPermissions = (projectId, prop) => {
  const appState = getAppState();
  const viewer = appState?.users?.viewer;

  return {
    read: isPermitted(viewer, projectId, 'propertiesTypes', 'read', prop, 'propertyType'),
    write: isPermitted(viewer, projectId, 'propertiesTypes', 'write', prop, 'propertyType'),
  };
};

export const fillBusinessType = (prop, { trades, companies, members, subCategories }) => {
  let businessTypeValues = getBusinessTypeSelectionListValues(prop, { trades, companies, members, subCategories });
  if (businessTypeValues?.length) prop = { ...prop, values: businessTypeValues };
  return prop;
};

export const getBusinessTypeSelectionListValues = (prop, { trades, companies, members, subCategories }) => {
  let values = [];

  switch (prop?.businessType) {
    case propertyTypes.BUSINESS_TYPES.trades: {
      values = Object.values(trades?.toJS() || {});
      break;
    }

    case propertyTypes.BUSINESS_TYPES.status: {
      const appIntl = getAppState().getNested(['app', 'intl']);
      Object.values(propertyTypes.STATUS_VALUES).forEach(({ id, title }) => {
        const translatedTitle = appIntl.formatMessage(title);
        values.push({
          id,
          title: Object.values(propertyTypes.LANGUAGES_MAP).reduce((acc, { code }) => {
            acc[code] = translatedTitle;
            return acc;
          }, {}),
        });
      });
      break;
    }

    case propertyTypes.BUSINESS_TYPES.companies: {
      Object.values(companies?.safeToJS() || {}).map((company) => {
        values.push({
          id: company.id,
          title: Object.values(propertyTypes.LANGUAGES_MAP).reduce((acc, { code }) => {
            acc[code] = company.name;
            return acc;
          }, {}),
        });
      });
      break;
    }

    case propertyTypes.BUSINESS_TYPES.members: {
      Object.values(members?.safeToJS() || {}).forEach((member) => {
        values.push({
          id: member.id,
          title: Object.values(propertyTypes.LANGUAGES_MAP).reduce((acc, { code }) => {
            acc[code] = member.displayName;
            return acc;
          }, {}),
        });
      });
      break;
    }

    case propertyTypes.BUSINESS_TYPES.severity: {
      const appIntl = getAppState().getNested(['app', 'intl']);
      Object.values(propertyTypes.SEVERITY_VALUES).forEach(({ id, title }) => {
        const translatedTitle = appIntl.formatMessage(title);
        values.push({
          id,
          title: Object.values(propertyTypes.LANGUAGES_MAP).reduce((acc, { code }) => {
            acc[code] = translatedTitle;
            return acc;
          }, {}),
        });
      });
      break;
    }

    case propertyTypes.BUSINESS_TYPES.subTrade: {
      const tradeId = prop.getNested(['settings', 'tradeId']);
      const subCategoryValues = subCategories?.safeToJS?.()?.[tradeId] || {};
      values = Object.values(subCategoryValues);
      break;
    }

    default:
      break;
  }

  return values;
};

export const isPropReadOnly = (projectId, prop) => {
  const isSerialNumberProp = prop?.businessType === propertyTypes.AVAILABLE_BUSINESS_TYPES.serialNumber;
  return Boolean(
    _.get(prop, 'editable', true) === false || isSerialNumberProp || !getPropPermissions(projectId, prop).write
  );
};

let numberRegExp = new RegExp('^[0-9]+(.[0-9]+)?$');
export function checkError(value, prop, subjectSections, subjectProperties, intl, projectId) {
  let errorMessages = [];

  if (!prop || isPropReadOnly(projectId, prop)) return null;

  let propName = prop.getNested(['getTitle']) || prop.getNested(['title']) || '';
  if (propName && typeof propName === 'object' && propName.defaultMessage) propName = intl.formatMessage(propName);

  const propSection = subjectSections?.[prop.getNested(['sectionId'])];

  if (prop.mandatory && (value == undefined || value == null || String(value) == ''))
    errorMessages.push(intl.formatMessage(propertiesMessages.errors.mandatory, { propName }));

  if (prop.extraTypes && subjectProperties && prop.type == 'Certification' && value && Array.isArray(value)) {
    let primaryValue = value;
    primaryValue = value[value.length - 1];
    if (primaryValue != undefined && primaryValue != null) {
      prop.extraTypes.forEach((extraProp) => {
        let extraPropId = extraProp && extraProp.id ? extraProp.id : extraProp;
        let lastCert = (value || []).slice();
        lastCert = lastCert[lastCert.length - 1] || {};
        let extraValue = lastCert[extraPropId];
        let errors = checkError(
          extraValue,
          subjectProperties[extraPropId],
          subjectSections,
          subjectProperties,
          intl,
          projectId
        );
        if (errors) errorMessages = errorMessages.concat(errors);
      });
    }
  }

  if (errorMessages.length > 0) return errorMessages;

  let type = prop.type;
  switch (type) {
    case propertyTypes.STRING: {
      const propertyRegex = _.get(prop, ['settings', 'validation', 'regex']);
      if (propertyRegex) {
        const regexExp = new RegExp(propertyRegex);
        if (regexExp.test(value))
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.forbiddenChars, { propName }));
      }
      break;
    }
    case propertyTypes.NUMBER: {
      if (value != null && value != undefined && !numberRegExp.test(value))
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.numbersOnly, { propName }));
      break;
    }
    case propertyTypes.CERTIFICATION: {
      let lastCert = null;
      if (value && Array.isArray(value)) lastCert = value[value.length - 1];

      if (!lastCert || (!lastCert.certificationTTL && _.get(prop, ['settings', 'isExpiration'])))
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveTTL, { propName }));

      if (
        lastCert &&
        _.get(prop, ['settings', 'isFileMandatory']) &&
        lastCert.signatureBehaviour !== 'cementoSign' &&
        !(lastCert.attachments || []).length
      )
        // Missing file
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveFile));

      break;
    }

    case propertyTypes.TIME_RANGE: {
      if (value) {
        if (!(value.startTS && value.endTS))
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveStartAndEndTime, { propName }));

        if (value.startTS > value.endTS)
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.rangeEndMustBeBiggerThanStart, { propName }));
      }

      break;
    }

    case propertyTypes.ARRAY: {
      if (value && subjectProperties) {
        const arrayNameProp = getArrayNameProp(prop);
        _.values(value).forEach((innerVal) => {
          if (!innerVal || innerVal.isDeleted) return;

          const innerValTitle = getArrayInnerValueTitle(intl, arrayNameProp, value, innerVal.id);
          const innerProp = subjectProperties[innerVal.propId];
          if (innerProp) {
            let errors = checkError(innerVal.data, innerProp, null, subjectProperties, intl, projectId);
            if (errors) {
              if (innerValTitle)
                errors = errors.map((error) =>
                  intl.formatMessage(propertiesMessages.errors.withSectionName, { sectionName: innerValTitle, error })
                );
              errorMessages = errorMessages.concat(errors);
            }
          }
        });
      }
      break;
    }

    case propertyTypes.COMPLEX: {
      // add complex card name to errors + why same error showing multiple times
      if (subjectProperties && value) {
        Object.keys(prop.innerTypes || {}).forEach((innterPropId) => {
          const innterProp = subjectProperties[innterPropId];
          if (innterProp) {
            const innerValue = value[innterPropId];
            const errors = checkError(innerValue, innterProp, null, subjectProperties, intl, projectId);
            if (errors) errorMessages = errorMessages.concat(errors);
          }
        });
      }
      break;
    }

    case propertyTypes.PICTURE: 
    case propertyTypes.VIDEO: 
    case propertyTypes.PDF: 
    case propertyTypes.DRAWINGS_ARRAY: 
    case propertyTypes.FILES_ARRAY:
    case propertyTypes.SELECTION_LIST:
    case propertyTypes.BOOLEAN:
    case propertyTypes.DATE:
    case propertyTypes.SIGNATURE:
    default: {
      break;
    }
  }

  if (errorMessages?.length && propSection) {
    const sectionName = propSection.getCementoTitle();

    if (sectionName)
      errorMessages = errorMessages.map((error) =>
        intl.formatMessage(propertiesMessages.errors.withSectionName, { error, sectionName })
      );
  }

  return errorMessages?.length ? errorMessages : null;
}

export const validatePropType = (propOrType) => {
  let isValid = false;

  let typeToCheck = propOrType;
  if (!typeToCheck) return isValid;

  if (propOrType instanceof Object) typeToCheck = propOrType.type;

  if (propertyTypes.VALID_PROPERTIES_ARRAY.includes(typeToCheck)) isValid = true;

  return isValid;
};

export async function getCompanyPropertiesTypesAndSections(companyId) {
  const firebase = platformActions.firebase.getFirebase();
  const companySubjects =
    (await firebase.database().ref(`properties/types/companies/${companyId}`).once('value')).val() || {};
  writeLogOnce('info', 'getCompanyPropertiesTypesAndSections - get Firebase', {
    scope: 'companies',
    scopeId: companyId,
    type: 'properties_types',
  });

  let companySubjectSections = {};
  let companySubjectProperties = {};
  Object.entries(companySubjects).forEach(([subjectName, propsAndSections]) => {
    companySubjectSections[subjectName] = propsAndSections.sections || {};
    companySubjectProperties[subjectName] = propsAndSections.properties || {};
  });

  return {
    companySubjectSections,
    companySubjectProperties,
  };
}

export async function getProjectsSelectionListValues(projectId) {
  let resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/valuesLists?projectId=${projectId}`);
  return resp.json();
}

export const getBaseSelectionListId = (propId) => `${propId}_valuesList`; // if you change this function, change it also in apiServer!!!

export const filterIrrelevantLocationExtraData = ({
  groupId,
  extraData,
  propertiesMappings,
  forceShowAllExtraData,
}) => {
  if (forceShowAllExtraData) return extraData;

  return _.pickBy(extraData, (prop, propId) => {
    return (
      prop.subjectName !== 'locationsInfo' ||
      _.get(propertiesMappings, [prop.subjectName, 'groups', groupId, 'properties'], []).includes(propId)
    );
  });
};

export const checkAndFetchSpecificProps = (params) => {
  const { viewer, projectId, nextSpecificPropertiesUpdatesAvailable } = params;

  const nextProjectsSpecificPropertiesUpdatesAvailable = _.get(nextSpecificPropertiesUpdatesAvailable, projectId, {});
  _.forIn(nextProjectsSpecificPropertiesUpdatesAvailable, (subjectProps, subjectName) => {
    const _shouldLastUpdateV2Fetch = (propId) =>
      shouldLastUpdateV2Fetch('projects', projectId, `properties/${propId}`, { subjectName });
    _.forIn(subjectProps, (_prop, propId) => {
      if (_shouldLastUpdateV2Fetch(propId)) {
        batchDispatch([getPropertiesTypeById(viewer, projectId, subjectName, propId)]);
      }
    });
  });
};

export const getPropTypeByTag = ({ projectId, subjectName, tag, groupId }) => {
  const appState = getAppState();
  const projectProps = appState.getNested(['propertiesTypes', 'projectProperties', projectId, subjectName], {});
  let subGroupProps = _.pickBy(projectProps, (prop) => _.get(prop, ['tags', tag]));
  if (groupId) {
    const currGroupPropertiesArr = appState.getNested(
      ['propertiesMappings', 'map', projectId, subjectName, 'groups', groupId, 'properties'],
      []
    );
    subGroupProps = _.pickBy(subGroupProps, (prop) => currGroupPropertiesArr.includes(prop?.id));
  }
  return subGroupProps;
};

export const isMultiTypesPostsConfigured = (projectId) =>
  !_.isEmpty(
    getPropTypeByTag({ projectId, subjectName: propertyTypes.SUBJECT_NAMES.posts, tag: POST_TYPES_SUB_GROUP_TAG })
  );

const DEFAULT_POST_TYPE = POSTS_TYPES.ISSUE;

export const getPostGroupId = (post) => {
  const { isIssue, tradeId: _tradeId, trade } = (post || {})?.safeToJS();

  const tradeId = _tradeId || trade?.id;
  const isSafetyIssue = tradeId === SAFETY_TRADE_ID;
  let groupId = DEFAULT_POST_TYPE;

  if (isIssue) {
    groupId = isSafetyIssue ? POSTS_TYPES.SAFETY_TASK : POSTS_TYPES.ISSUE;
  } else {
    groupId = isSafetyIssue ? POSTS_TYPES.SAFETY_RECORD : POSTS_TYPES.RECORD;
  }

  return groupId;
};

const DEFAULT_POSTS_CREATION_OPTIONS = {
  'issue': {
    'title': newPostMessages.newPostTypes.task,
    'id': 'issue',
    'subGroups': {
      'issue_inner': {
        'id': 'issue_inner',
        'title': newPostMessages.newPostTypes.task,
      },
    },
  },
  'record': {
    'title': newPostMessages.newPostTypes.record,
    'id': 'record',
    'subGroups': {
      'record_inner': {
        'title': newPostMessages.newPostTypes.record,
        'id': 'record_inner',
      },
    },
  },
};

const shouldIgnorePostGroup = (groupId) => _.has(POSTS_TYPES_TO_HIDE_IN_CREATION_MENU, _.toUpper(groupId));

export const getPostCreationOptions = ({ groups, mapping, subGroupsProperties, postGroupId }) => {
  let optionsByGroup = {};

  let groupsValues = groups?.values
  
  if(!Boolean(postGroupId)) {
    groupsValues = _.pickBy(groupsValues, group => !shouldIgnorePostGroup(group?.id));
  }
  const projectHasNonMigratedPostGroups = Boolean(groupsValues) && !_.every(groupsValues, group => _.has(POSTS_TYPES, _.toUpper(group.id)));

  _.forIn(groupsValues, (group, key) => {
    if (postGroupId && group.id != postGroupId && !projectHasNonMigratedPostGroups) {
      return;
    }
    const groupProperties = _.get(mapping, ['groups', group.id, 'properties']);
    const subGroupsPropId = _.find(groupProperties, (propId) => _.has(subGroupsProperties, propId));
    const subGroups = _.get(subGroupsProperties, [subGroupsPropId, 'values']);
    _.set(optionsByGroup, [group.id], { title: group.getCementoTitle?.(), id: group.id, subGroupsPropId });
    _.forIn(subGroups, (subGroup) => {
      _.set(optionsByGroup, [group.id, 'subGroups', subGroup.id], {
        id: subGroup.id,
        title: subGroup.getCementoTitle?.(),
      });
    });
  });

  if (projectHasNonMigratedPostGroups) optionsByGroup = _.set({}, [POSTS_TYPES.ISSUE, 'subGroups'], optionsByGroup);

  if (_.isEmpty(optionsByGroup) || projectHasNonMigratedPostGroups)
    optionsByGroup = _.assign({}, DEFAULT_POSTS_CREATION_OPTIONS, optionsByGroup);

  if (postGroupId) optionsByGroup = _.pick(optionsByGroup, [postGroupId]);

  return { optionsByGroup, projectHasNonMigratedPostGroups };
};

const getMappedPropertiesSet = (mapping, groupId, groupValueId) => {
  let ret = new Set();

  const addToSet = (_key, _val) => {
    const props = _.get(mapping, [_key, _val, 'properties']);
    props?.forEach(ret.add, ret);
  };

  if (groupValueId) addToSet(groupId, groupValueId);
  else _.forIn(_.get(mapping, [groupId]), (map, value) => addToSet(groupId, value));

  return ret;
};

export const getAllMappedProperties = (propertiesMappings, subjectName, groupId = 'groups', groupValueId) => {
  let maps = _.get(propertiesMappings.safeToJS(), [subjectName]);
  let ret = getMappedPropertiesSet(maps, groupId, groupValueId);
  let relevantPropIds = Array.from(ret);
  let alreadyChecked = {};
  if (groupId) alreadyChecked[groupId] = true;

  while (relevantPropIds.length) {
    const nextPropIdToCheck = relevantPropIds.pop();

    if (alreadyChecked[nextPropIdToCheck]) continue;
    else alreadyChecked[nextPropIdToCheck] = true;

    const currPropMappedProps = getMappedPropertiesSet(maps, nextPropIdToCheck);
    relevantPropIds.push(...Array.from(currPropMappedProps));
    relevantPropIds.forEach(ret.add, ret);
  }

  return Array.from(ret);
};
