import { platformActions } from '../../../platformActions';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import useLokiCompanies from './useLokiCompanies';
import useRealmCompanies from './useRealmCompanies';

/**
 * @typedef Filters
 * @property {string[]} [inIds]
 * @property {string} [companyName]
 *
 * @param {Filters} filters
 *
 * @returns
 */
const useCompanies = (filters) => {
  const isNative = !!platformActions?.app.isNative();
  const { companies } = (isNative ? useRealmCompanies : useLokiCompanies)(filters);
  return useMemoizedValue({
    companies,
  });
};

export default useCompanies;

/**
 * @callback ChildrenRenderProps
 * @param {{ companies: any[] }}
 */
/**
 *
 * @param {{ filters: Filters, children: ChildrenRenderProps }} props
 * @returns { ChildrenRenderProps }
 */
export const CompaniesHOC = (WrappedComponent) => {
  const WithCompanies = (props) => {
    const companiesProps = useCompanies(props.filters);
    return <WrappedComponent {...companiesProps} {...props} />;
  };

  return WithCompanies;
};
