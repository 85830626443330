import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { getUsersByUids } from "../../../common/members/actions";
import { getCompaniesById } from "../../../common/companies/funcs";
import companiesMessages from "../../../common/companies/companiesMessages";
import Select, { components } from "react-select";
import User from "./User";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import theme from "../../assets/css/theme";
import CompanyAvatar from "./CompanyAvatar";
import CompanyName from "./CompanyName";
import { CompaniesHOC } from "../../../common/companies/hooks/useCompanies/index.js";

class SelectableUsers extends React.Component {
  constructor(props) {
    super(props);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.renderSelectableOption = this.renderSelectableOption.bind(this);
    this.renderSelectableGroupHeader =
      this.renderSelectableGroupHeader.bind(this);
    this.renderSelectedOption = this.renderSelectedOption.bind(this);

    let selected =
      props.defaultValue && props.defaultValue.id
        ? { value: { user: { id: props.defaultValue.id } } }
        : null; // props.defaultValue.setNested(['value', 'user','id']);
    this.state = {
      isOpen: Boolean(props.autoFocus),
      selected,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { getUsersByUids, selectedProjectId } = nextProps;
    let newStateChanges = {};
    if(nextProps.defaultValue !== props.defaultValue && !nextProps.isMulti) {
      const selected = nextProps.defaultValue && nextProps.defaultValue.id
        ? { value: { user: { id: nextProps.defaultValue.id } } }
        : null;
      newStateChanges.selected = selected;
    }
    if (nextProps.clean > (props.clean || 0) )
      // workaround
      newStateChanges.selected = null;

    if (
      nextProps.users &&
      (props.users != nextProps.users ||
        props.projectMembers != nextProps.projectMembers ||
        props.companies != nextProps.companies)
    ) {
      let groups = {};
      let usersToGet = {};
      let companiesToGet = {};
      nextProps.users.loopEach((k, m) => {
        if (!nextProps.getNested(["projectMembers", m.id])) {
          usersToGet[m.id] = m.id;
        } else {
          let currMember = nextProps.getNested(["projectMembers", m.id]);
          let memberCompanyId =
            nextProps.getNested([
              "projectMembers",
              m.id,
              "projects",
              selectedProjectId,
              "companyId",
            ]) || nextProps.getNested(["projectMembers", m.id, "companyId"]);
          if (
            memberCompanyId &&
            !nextProps.getNested(["companies", memberCompanyId])
          )
            companiesToGet[memberCompanyId] = memberCompanyId;
          else {
            let companyName = nextProps.getNested([
              "companies",
              memberCompanyId,
              "name",
            ]);
            companyName = companyName
              ? companyName
              : companiesMessages.unknownCompany;
            if (!groups[companyName])
              groups[companyName] = {
                companyId: memberCompanyId,
                companyName: companyName,
                members: [],
              };
            groups[companyName].members.push(currMember);
          }
        }
      });

      if (Object.keys(usersToGet).length) getUsersByUids(usersToGet);

      if (Object.keys(companiesToGet).length) getCompaniesById(companiesToGet);

      newStateChanges.selectableValues = [];
      let sortedKeys = Object.keys(groups).sort((a, b) =>
        groups[a].companyName.defaultMessage
          ? 1
          : groups[b].companyName.defaultMessage
          ? -1
          : a.localeCompare(b)
      );
      sortedKeys.forEach((k) => {
        let value = groups[k];
        newStateChanges.selectableValues.push({
          type: "title",
          label: value.companyName,
          id: value.companyId,
        });
        value.members.loopEach((index, curr) => {
          newStateChanges.selectableValues.push({
            type: "user",
            user: curr,
            id: curr.id,
          });
        });
      });
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClose(e) {
    this.setState({ isOpen: false });
  }

  onChange(e) {
    const { onChange, isMulti } = this.props;
    const selected = isMulti ? e : e && e.value;
    this.setState({ selected: e, isOpen: isMulti }, () => {
      if (isMulti || (selected && selected.type == "user")) {
        if (onChange) onChange(selected);
      } else if (!e) onChange(null);
    });
  }

  renderSelectableGroupHeader(props) {
    const { data } = props;
    return (
      <div>
        <div
          style={{
            display: "flex",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            margin: 5,
            alignItems: "center",
          }}
        >
          <CompanyAvatar companyId={data.lable} />
          <CompanyName
            companyId={data.lable}
            style={{
              ...theme.defaultFont,
              fontWeight: theme.bold,
              color: theme.brandNeutral,
              marginLeft: 5,
              marginRight: 5,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          />
        </div>
        <components.Group {...props} />
      </div>
    );
  }

  renderSelectableOption(props) {
    const { value } = props;
    return (
      <components.Option {...props}>
        <User
          companyMode={false}
          hideCompany={true}
          userId={value.user.id}
        ></User>
      </components.Option>
    );
  }

  renderSelectedOption(props) {
    const { data } = props;
    if (data.getNested(["value", "user", "id"]))
      return (
        <components.SingleValue {...props}>
          <User
            companyMode={false}
            hideCompany={true}
            userId={data.value.user.id}
          ></User>
        </components.SingleValue>
      );
    return null;
  }

  render() {
    const { classes, autoFocus, placeholder, intl, showLine, isClearable, isMulti } =
      this.props;
    const { selectableValues, isOpen, selected } = this.state;

    const intlPlaceholder =
      placeholder &&
      typeof placeholder === "object" &&
      placeholder.defaultMessage
        ? intl.formatMessage(placeholder)
        : placeholder;

    let options = [];
    (selectableValues || []).loopEach((i, item) => {
      if (item.type == "title") options.push({ options: [], lable: item.id });
      else
        options[options.length - 1].options.push({
          value: item,
          label: item && item.user ? item.user.displayName : "-",
        });
    });

    return (
      <Select
        isMulti={isMulti}
        components={{
          SingleValue: this.renderSelectedOption,
          Option: this.renderSelectableOption,
          Group: this.renderSelectableGroupHeader,
        }}
        placeholder={intlPlaceholder}
        openMenuOnFocus={true}
        autoFocus={autoFocus}
        menuIsOpen={isOpen}
        onBlur={this.onClose}
        onMenuClose={this.onClose}
        onMenuOpen={this.onOpen}
        //onFocus={this.onOpen}
        menuPlacement={"bottom" /*'top'*/}
        value={selected}
        isClearable={isClearable}
        onChange={this.onChange}
        styles={showLine ? CustomInputSelectStyles : selectStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: theme.brandPrimary,
          },
        })}
        options={options}
        isOptionDisabled={(option) => option.value.type == "title"}
      />
    );
  }
}

let styles = {
  menuItemGroupTitle: { paddingBottom: "0px" },
};
const minOptionSize = 250;

const selectStyles = {
  container: (styles) => ({ ...styles, width: "100%" }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "0px",
    boxShadow: "0px",
    cursor: "pointer",
  }),
  valueContainer: (styles) => ({ ...styles, padding: "0px", paddingLeft: 6 }),
  clearIndicator: (styles) => ({ ...styles, margin: 0, padding: 0 }),
  indicatorSeparator: (styles) => ({ visibility: "hidden" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
    marginLeft: 4,
  }),
  option: (styles) => ({
    ...styles,
    cursor: "pointer",
    paddingLeft: 40,
    paddingRight: 40,
    minWidth: minOptionSize,
    overflowX: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    zIndex: theme.zIndexes.selectableUsers,
    minWidth: minOptionSize,
    overflowX: "hidden",
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: theme.zIndexes.selectableUsers,
    minWidth: minOptionSize,
    overflowX: "hidden",
  }),
};
SelectableUsers = withStyles(styles)(SelectableUsers);
SelectableUsers = withStyles(buttonStyle)(SelectableUsers);
SelectableUsers = injectIntl(SelectableUsers);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  CompaniesHOC,
  connect(
    null,
    { getUsersByUids }
  )
);
export default enhance(SelectableUsers);

let CustomInputSelectStyles = {
  valueContainer: (styles) => ({
    ...styles,
    padding: "0px",
    margin: "0px",
    border: "0px",
    borderRadius: "0px",
    paddingLeft: 6,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    border: "0px",
    borderRadius: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: theme.zIndexes.selectableUsers,
      cursor: "pointer",
      backgroundColor: theme.backgroundColorBright,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? theme.brandPrimary
        : isFocused
        ? theme.brandPrimary
        : theme.brandNeutralDark,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      border: "0px",
      borderBottom:
        isFocused || isSelected
          ? "1.5px solid " + theme.brandPrimary
          : "1px solid hsl(0,0%,80%)",
      borderRadius: "0px",
      boxShadow: "0px",
      cursor: "pointer",
    };
  },
  menuList: (styles) => ({ ...styles, zIndex: theme.zIndexes.selectableUsers }),
  menu: (styles) => ({ ...styles, zIndex: theme.zIndexes.selectableUsers }),
};
