import IndeterminateCheckbox from './IndeterminateCheckbox';
import toggleRight from '../../../../../../web/assets/img/icons/toggleRight.png';

const ExpandableTableHeader = ({ title, table }) => (
  <div
    // className='ExpandableTableCell'
    // style={{
    //   width: '100%',
    //   height: '100%',
    //   position: 'relative',
    // }}
    onClick={table.getToggleAllRowsExpandedHandler()}>
    {/* <IndeterminateCheckbox
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />{' '} */}

    {/* {table.getIsAllRowsExpanded() ? (
      <div style={{ cursor: 'pointer' }}>
        <img
          className='ExpandableTableCell_arrowIcon'
          src={toggleRight}
          style={{
            transform: table.getIsAllRowsExpanded() ? 'rotate(270deg)' : 'rotate(90deg)',
            // [rtl ? 'left' : 'right']: theme.margin,
          }}
        />
      </div>
    ) : null} */}
    {title}
  </div>
);

export default ExpandableTableHeader;
