import { Navigate } from 'react-router-dom';
import ObjectsWrapper from '../../../../../common/ObjectWrapper/ObjectsWrapper';
import ObjectsWrapperRemoteConfig from '../../../../../common/ObjectWrapper/helpers/ObjectWrapperRemoteConfig';

export default {
  path: 'siteControl',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_ObjectsWrapper_siteControl_analytics'}
          contentType={'siteControl'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'objects/:reportId?',
      element: (
        <ObjectsWrapper
          key={'project_ObjectsWrapper_siteControl_analytics'}
          contentType={'siteControl'}
          section={'analytics'}
        />
      ),
    },
  ],
};
