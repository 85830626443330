import _ from 'lodash';
import { getNewId } from '../lib/api';
import { saveInstances } from './funcs';
import { PROPERTIES_INSTANCES_EVENTS } from './trackPropertiestInstances';

export async function removePropertiesInstancesFromLoki(lokiInstance, subjectName, legacyInstancesDelete) {
  await lokiInstance.getCollection('propertyInstances').cementoFullDelete({ subjectName: subjectName });
}

export function uploadPropertiesInstances(projectId, propertiesInstancesArray, subjectName, shouldWaitForUpload = true) {
  const getPromise = async () => {
    if (!projectId || !propertiesInstancesArray || propertiesInstancesArray.length === 0)
      return; 
      
    let propertiesInstancesToUpload = [];
    
    propertiesInstancesArray.forEach(currPropInstance => {
      if (!currPropInstance)
        return; // TODO: report

      currPropInstance = currPropInstance.realmToObject();
      if (currPropInstance.id) {
        if (_.isNil(currPropInstance.data))
          currPropInstance.isDeleted = true;
        propertiesInstancesToUpload.push(currPropInstance.realmToObject());
      }
      else {
        let newId = getNewId()
        propertiesInstancesToUpload.push({ id: newId, ...currPropInstance });
      }
    });
    
    const promise = saveInstances(projectId, subjectName, propertiesInstancesToUpload);
    if (!shouldWaitForUpload)
      return {
        instances: propertiesInstancesToUpload,
        projectId,
        subjectName,
        success: undefined
      };
    else
      return await promise;
  };
  
  return { 
    type: PROPERTIES_INSTANCES_EVENTS.UPDATE_PROPERTIES_INSTANCES,
    payload: getPromise()
  };
}

export function setRecentlySigningUsers(userId, projectId, signaturesContext){
  return {
    type: PROPERTIES_INSTANCES_EVENTS.SET_RECENTLY_SIGNING_USERS,
    payload: { userId, projectId, signaturesContext }
  }
}
