import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies';
import _ from 'lodash';
import { getCompaniesById } from '../../../common/companies/funcs';

const CompanyName = (props) => {
  const { companyId, style, companies } = props;
  const [name, setName] = useState(null);

  useEffect(() => {
    if (name) return;

    if (companyId) {
      const company = companies.getNested([companyId]);

      if (_.size(companies) && !company) {
        getCompaniesById({ [companyId]: companyId });
      } else if (company) {
        const newName = companies.getNested([companyId, 'name']);
        setName(newName);
      }
    }
  }, [companyId, companies]);

  if (name) return <div style={style}>{name}</div>;
  else return null;
};

const enhance = compose(CompaniesHOC);
export default enhance(CompanyName);
