import Stage from './stage';
import { CementoRecordObject, Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';
import { stagesEvents } from './stagesEvents';

const InitialState = Record(
  {
    map: new CementoRecordObject(),
  },
  'stages',
  false
);

const initialState = new InitialState();

export default function stageReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case stagesEvents.GET_STAGES: {
      if (!action.payload?.projectId) return state;

      const { projectId, stages } = action.payload || {};

      if (stages) {
        let stagesMap = new CementoRecordObject();
        stages.loopEach((stageId, currStage) => {
          currStage = currStage.toJS ? currStage.toJS() : currStage;

          stagesMap = stagesMap.set(currStage.id, new Stage({ ...currStage }));
        });
        state = state.setIn(['map', projectId], stagesMap);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
