import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { filterByQuery } from '../permissions/funcs';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../web/app/constants';
import useIntl from '../intl/useIntl';
import postsMenuMessages from '../posts/postsMenuMessages';
import analyticsMessages from '../analytics/analyticsMessages';
import newPostMessages from '../posts/newPostMessages';
import { getNextGenValue } from './utils';

export const useObjectsManipulations = (objects, propTypes) => {
  const intl = useIntl();
  const [filters, setFilters] = useState(null);
  const [groupBy, setGroupBy] = useState(['trade', 'assignTo']);
  const [sortBy, setSortBy] = useState({ column: 'trade', direction: 'asc' });
  const [search, setSearch] = useState(null);
  const [viewType, setViewType] = useState('table');
  const [columnDefinitions, setColumnDefinitions] = useState({});
  const [groupByOptions, _setGroupByOptions] = useState([]);
  const [filterOptions, _setFilterOptions] = useState({});
  const [skip, setSkip] = useState(null);
  const [step, setStep] = useState(null);
  // Derive unique groupByOptions and filterOptions from object properties

  const deriveColumns = (propTypes) => {
    const columns = Object.keys(propTypes).map((key) => {
      const prop = propTypes[key];
      const column = {
        key,
        title: prop?.title,
        ordinalNo: prop?.ordinalNumber,
        sectionId: prop?.sectionId,
        type: prop?.type,
      };
      return column;
    }, []);

    setColumnDefinitions(columns);
  };

  useEffect(() => {
    const { groupByOptions, filterOptions } = calculateOptions({
      objects,
      propTypes,
      intl,
    });
    deriveColumns(propTypes);
    _setGroupByOptions(groupByOptions);
    _setFilterOptions(filterOptions);
  }, [objects, propTypes]);

  return {
    // Values
    groupByOptions,
    filterOptions,
    filters,
    columnDefinitions,
    groupBy,
    sortBy,
    search,
    viewType,
    skip,
    step,
    // Methods
    setFilters,
    setGroupBy,
    setSortBy,
    setSearch,
    setViewType,
    setSkip,
    setStep,
  };
};

export const useSmartObjects = (objects, propTypes) => {
  const intl = useIntl();
  return useMemo(() => {
    return objects.map((obj) => {
      let cachedConversions = {};
      return new Proxy(obj, {
        get: (target, property) => {
          const prop = propTypes[property];

          let ret = null;
          if (prop) {
            ret = getNextGenValue(_.get(target, prop.universalId ?? prop.id), prop, intl);
          } else {
            ret = target[property];
          }

          cachedConversions[property] = ret;
          return ret;
        },
      });
    });
  }, [objects, propTypes]);
};
const sortObjects = (objects, sortBy) => {
  if (!objects?.length || !sortBy) {
    return objects;
  }

  const sortedObjects = [...objects].sort((a, b) => {
    const aValue = a[sortBy.column];
    const bValue = b[sortBy.column];

    if (aValue < bValue) {
      return sortBy.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortBy.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return sortedObjects;
};

export const useProcessedObjects = (objects, options) => {
  const { groupBy, sortBy, filters, skip, step } = options;

  const filteredObjects = useMemo(() => {
    if (!objects?.length || !filters) {
      return objects;
    }
    // TODO filterByQuery doesnt fully support smart objects, needs small tweaks
    const filteredObjects = filterByQuery(objects, filters.newSearch, FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER);
    const res = Object.values(filteredObjects);
    return res;
  }, [objects, filters]);

  const paginatedObjects = useMemo(() => {
    if (!filteredObjects?.length || typeof skip !== 'number' || typeof step !== 'number') {
      return filteredObjects;
    }

    return filteredObjects.slice(skip, skip + step);
  }, [filteredObjects, skip, step]);

  const groupedData = useMemo(() => {
    if (!groupBy?.length || !paginatedObjects?.length) {
      return { all: { rows: paginatedObjects } };
    }

    return paginatedObjects.reduce((acc, obj) => {
      let currentGroup = acc;

      groupBy.forEach((group, index) => {
        const value = obj[group];
        const groupId = value?.displayName ?? value?.id ?? value;
        const cementoTitle = typeof value === 'string' ? value : value?.cementoValue?.getCementoTitle();
        const groupTitle = cementoTitle?.length ? cementoTitle : '-';
        const isLastGroup = index === groupBy.length - 1;

        if (!currentGroup[groupTitle]) {
          if (!isLastGroup) {
            currentGroup[groupTitle] = {
              id: groupId,
              title: groupTitle,
              index,
              groupName: group,
              groups: {},
            };
          } else if (isLastGroup) {
            currentGroup[groupTitle] = {
              id: groupId,
              title: groupTitle,
              groupName: group,
              index,
              rows: [],
            };
          }
        }

        currentGroup = isLastGroup ? currentGroup[groupTitle] : currentGroup[groupTitle].groups;
      });
      currentGroup.rows = sortObjects([...(currentGroup.rows ?? []), obj], sortBy);

      return acc;
    }, {});
  }, [paginatedObjects, groupBy, sortBy]);

  return groupedData;
};

const getLegacyGroupByOptions = ({ intl }) => {
  let menu = [
    { title: postsMenuMessages['trade'], key: 'trade', ordinalNo: 1 },
    {
      title: `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByUser
      )}`,
      key: 'owner',
      ordinlaNo: 6,
    },
    { title: postsMenuMessages['createdAt'], key: 'createdAt', ordinalNo: 7 },
    { title: postsMenuMessages['floor'], key: 'floor', ordinalNo: 9 },
    { title: postsMenuMessages['unit'], key: 'unit', ordinalNo: 10 },
  ];

  // if (contentType === 'safety') {
  menu.push({ title: newPostMessages.subCategory, key: 'subCategory', ordinalNo: 2 });
  // }

  // if (isIssuesPage) {
  menu.push(
    {
      title: `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(postsMenuMessages.company)}`,
      key: 'assignToCompany',
      ordinalNo: 5,
    },
    {
      title: `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByCompany
      )}`,
      key: 'ownerCompany',
      ordinlaNo: 6.5,
    }
  );
  menu.push(
    { title: postsMenuMessages['issueState'], key: 'issueState', ordinalNo: 3 },
    {
      title: `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByUser
      )}`,
      key: 'assignTo',
      ordinalNo: 4,
    },

    { title: postsMenuMessages['dueDate'], key: 'dueDate', ordinalNo: 8 }
  );
  // }

  return menu;
};

const calculateOptions = ({ objects, propTypes, intl }) => {
  const groupByOptions = getLegacyGroupByOptions({ intl });
  const filterOptions = {};

  objects.forEach((obj) => {
    Object.entries(propTypes).forEach(([propName, propData]) => {
      if (!propData) {
        return;
      }
      let value = _.get(obj, propData.id);

      const propTitle = propData.title?.id ? intl.formatMessage(propData.title) : propData.title?.en;

      if (!filterOptions[propName])
        filterOptions[propName] = {
          id: propData.id,
          type: propData.type === 'String' ? 'SelectionList' : propData.type, // TODO Questionable
          inputSettings: propData.settings,
          ordinalNumber: propData.ordinalNumber,
          title: propTitle,
          options: [],
        };

      if (value) {
        let id = value;
        let title = value ?? '-';

        if (typeof value === 'object') {
          id = value.id;
          title = value.cementoValue?.getCementoTitle() ?? '-';
        }

        filterOptions[propName].options.push({ id, title });
      }
    });
  });

  return {
    groupByOptions,
    filterOptions: [
      {
        id: 'defaultView',
        title: 'defaultView',
        categories: Object.values(filterOptions),
      },
    ],
  };
};
