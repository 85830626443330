import { getAppState, getDispatch } from "../configureMiddleware";
import { platformActions } from "../platformActions";
import { envParams } from '../configureMiddleware';
import _ from 'lodash';
import moment from 'moment-timezone';
import getPlatformTheme from '../platformTheme';
import { startProjectFirebaseListener } from '../lib/utils/utils';
import { hideLoading, startLoading } from '../app/actions';
import siteControlMessages from "../siteControl/siteControlMessages";
import { onError } from "../app/funcs";
import systemMessages from "../app/systemMessages";

export const EXIT = 'exit';
export const ENTRANCE = 'entrance';
const SYNC_TIMEOUT = 5 * 60 * 1000;

const SYNC_STATUS_MESSAGES = {
  'SUCCESS': siteControlMessages.employeeSyncStatus.syncSuccess,
  'AWAITING_SYNC': siteControlMessages.employeeSyncStatus.awaitingSync,
  'FAILED': siteControlMessages.employeeSyncStatus.syncFailed,
};

const SYNC_ERRORS = {
  'pupilDistanceTooSmall': siteControlMessages.employeeSyncStatus.syncErrors.invalidImage,
  'faceDetectFailed': siteControlMessages.employeeSyncStatus.syncErrors.invalidImage,
  'invalidOperation': siteControlMessages.employeeSyncStatus.syncErrors.unknownError,
  'default': siteControlMessages.employeeSyncStatus.syncErrors.unknownError
};

const CAMERAS_STATUS = {
  'ACTIVE': 'activeActivityIndicator',
  'PARTIALLY_ACTIVE': 'partiallyActiveActivityIndicator',
  'INACTIVE': 'nonActiveActivityIndicator'
};

const getEmployeeSyncError = function (emp, intl) {
  const errorSubStatusCode = _.get(emp, ['syncFailed', 'entrance', 'errorSubStatusCode']) || _.get(emp, ['syncFailed', 'exit', 'errorSubStatusCode']);
  return intl.formatMessage(SYNC_ERRORS[errorSubStatusCode] || SYNC_ERRORS['default']);
};

export const getEmployeeStatusMessage = function (emp, empStatus, intl) {
  let message = intl.formatMessage(SYNC_STATUS_MESSAGES[empStatus]);
  if (empStatus === employeesSyncStatuses.FAILED)
    message = `${message} - ${getEmployeeSyncError(emp, intl)}`;
  return message;
} 

export const shouldPresentSiteControl = function (projectId) {
  const cameras = getAppState().getNested(['configurations', 'map', projectId, 'cameras'], {});
  return _.some(cameras, cam => !cam.disabled);
};

export const getTSArrayByTimeUnit = function ({ startTS, endTS, unit = 'day' }) {
  if (!(startTS && endTS)) return;
  let startDate = moment(startTS).startOf(unit);
  let dates = [];
  let nextDate = startDate.valueOf();

  while (nextDate < endTS) {
    dates.push(nextDate);
    nextDate = startDate.add(1, unit).valueOf();
  }

  return dates;
};

export const getEmployeesPresence = async function ({ projectId, ids, startTS, endTS, includeMissingDays, excludeEmptyLogs, populate, includeMonitor, silent, onError: _onError }) {

  if (!projectId)
    return;
  
  let res;
  let url = `${envParams.apiServer}/v1`;
  if (populate)
    url += '/gateway';
  url += `/siteControl/employees?projectId=${projectId}&startTS=${startTS}&endTS=${endTS}&includeMonitor=${includeMonitor}&v=2`;
  if (ids)
    url += `&ids=${JSON.stringify(_.values(ids))}`;

  try {
    res = await platformActions.net.fetch(url);
    res = await res.getJson();

    if (_.isEmpty(res.employees))
      return res;

    if (excludeEmptyLogs)
      res.employees = _.pickBy(res.employees, emp => !_.isEmpty(emp.log));

    if (includeMissingDays) {
      const days = getTSArrayByTimeUnit({ startTS, endTS });
      let emptyDays = {};
      _.forEach(days, dayTS => _.set(emptyDays, [dayTS], {}));
      res.employees = _.mapValues(res.employees, emp => {
        const log = _.assign({}, emptyDays, emp.log);
        const newEmp = _.assign({}, emp, { log });
        return newEmp;
      });
    }
  }
  catch (error) {

    const alertParams = {
      title: siteControlMessages.presenceLogs.loadingFailed,
      type: 'error',
      message: res
        ? siteControlMessages.table.noPresence
        : systemMessages.errors.fetchingErrorContent
    };

    onError({
      errorMessage: "Failed to fetch employees presence",
      alertParams: !silent ? alertParams : undefined,
      error,
      errorMetaData: { projectId, ids, startTS, endTS, populate, includeMonitor, url }
    });

    if (_.isFunction(_onError))
      _onError(error)

  }

  return res;
};

export const getEmployeesSyncStatus = async function ({ projectId, skipLoading }) {

  if (!projectId)
    return;

  const getEmployeesSyncStatus_LOADING_OPERATION_ID = 'getEmployeesSyncStatus';


  const dispatch = getDispatch();
  if (!skipLoading)
    dispatch(startLoading({ title: siteControlMessages.employeeSyncStatus.loading, overlay: true, operationId: getEmployeesSyncStatus_LOADING_OPERATION_ID }));

  let res;
  let url = `${envParams.apiServer}/v1`;
  url += `/siteControl/services/sync/status?projectId=${projectId}&lean=true`;
  try {
    res = await platformActions.net.fetch(url);
    res = await res.getJson();
  }
  catch (error) {
    console.log(`ERROR IS ${JSON.stringify(error)}`);
    const alertParams = {
      type: 'error',
      message: siteControlMessages.employeeSyncStatus.loadingFailed,
    };

    onError({
      errorMessage: siteControlMessages.employeeSyncStatus.loadingFailed,
      alertParams,
      error,
      errorMetaData: { projectId, url }
    });
  }
  finally {
    dispatch(hideLoading(getEmployeesSyncStatus_LOADING_OPERATION_ID));
  }
  return res;
};

export const splitDailyLogsToVisits = function (logs, shouldSquash) {
  let presence = [];
  let curr = {};

  _.forIn(logs, (cameraId, eventTS) => {
    const eventType = cameraId?.startsWith(ENTRANCE) ? ENTRANCE : EXIT;
    if (eventType == ENTRANCE && curr[EXIT] || curr[eventType]) {
      presence.push(curr);
      curr = {};
    }

    curr[eventType] = Number(eventTS);
  });

  if (!_.isEmpty(curr)) {
    presence.push(curr);
  }


  if (shouldSquash && presence.length) {
    const lastIndex = presence.length - 1;
    presence = [{
      [ENTRANCE]: _.get(presence, [0, ENTRANCE]),
      [EXIT]: _.get(presence, [lastIndex, EXIT])
    }];
  }


  return presence;
};



export const splitLogsToVisits = function (logs, shouldSquashDaily) {
  const visits = {};
  _.forIn(logs, (dailyLogs, dayTS) => {
    visits[dayTS] = splitDailyLogsToVisits(dailyLogs, shouldSquashDaily);
  });

  if (!_.isEmpty(visits))
    return visits;
};

export const employeesSyncStatuses = {
  SUCCESS: "SUCCESS",
  AWAITING_SYNC: "AWAITING_SYNC",
  FAILED: "FAILED"
};

export const getEmployeesSyncStatusColor = status => {
  const theme = getPlatformTheme();
  const colors = {
    [employeesSyncStatuses.SUCCESS]: theme.brandSuccess,
    [employeesSyncStatuses.AWAITING_SYNC]: theme.brandWarning,
    [employeesSyncStatuses.FAILED]: theme.brandRealDanger,
    default: theme.brandWarning
  };

  return colors[status || 'default'];
};

export const employeesPresenceStatuses = {
  PRESENT: "PRESENT",
  NOT_PRESENT: "NOT_PRESENT",
  ERROR: "ERROR"
};

export const getEmployeesPresenceStatusColor = status => {
  const theme = getPlatformTheme();
  const colors = {
    [employeesPresenceStatuses.PRESENT]: theme.brandSuccess,
    [employeesPresenceStatuses.NOT_PRESENT]: theme.darkSeparatorColor,
    [employeesPresenceStatuses.ERROR]: theme.darkSeparatorColor, //theme.brandRealDanger,
    default: theme.darkSeparatorColor
  };

  return colors[status || 'default'];
};


export const getEmployeeSyncStatus = (employee) => {
  let status = employeesSyncStatuses.AWAITING_SYNC;
  if (_.get(employee, ['syncFailed']))
    status = employeesSyncStatuses.FAILED;
  else if (_.get(employee, ['cameraSync']))
    status = employeesSyncStatuses.SUCCESS;

  return status;
};

export const getEmployeesPresenceStatus = ({ logs, ts }) => {
  const dayTS = moment(Number(ts) || Date.now()).startOf('day').valueOf();
  const isToday = moment(dayTS).isSame(Date.now(), 'day');
  const dayLogs = _.get(logs, [dayTS]);
  const latestLogTS = _.max(_.keys(dayLogs));
  const firstLogTS = _.min(_.keys(dayLogs));
  let status = employeesPresenceStatuses.NOT_PRESENT;

  if (isToday && _.get(dayLogs, [latestLogTS]) == ENTRANCE)
    status = employeesPresenceStatuses.PRESENT;
  else if (_.get(dayLogs, [firstLogTS]) == EXIT)
    status = employeesPresenceStatuses.ERROR;

  return status;
};

export const getCamerasStatus = (camerasMonitorData) => {
  const areAllCamerasActive = _.values(camerasMonitorData).every(currCamera => _.get(currCamera, ['isActive']));
  const areSomeCamerasActive = _.values(camerasMonitorData).some(currCamera => _.get(currCamera, ['isActive']));
  if (areAllCamerasActive)
    return CAMERAS_STATUS['ACTIVE'];
  else if (areSomeCamerasActive)
    return CAMERAS_STATUS['PARTIALLY_ACTIVE'];

  return CAMERAS_STATUS['INACTIVE'];

};


export const msToHoursDurationString = ms => (_.isNil(ms) || _.isNaN(ms)) ? undefined : moment.utc(ms).format('H:mm');

const tsToTimeString = ts => (_.isNil(ts) || _.isNaN(ts)) ? undefined : moment(ts).format('H:mm');

export const getVisitsInfo = (dailyLogs) => {
  const visits = splitDailyLogsToVisits(dailyLogs);
  const firstEntrance = _.get(_.head(visits), ['entrance']);
  const lastExit = _.get(_.last(visits), ['exit']);
  const total = lastExit - firstEntrance;
  const totalNet = _.reduce(visits, (acc, currVisit) => (acc + (currVisit.exit - currVisit.entrance)), 0);
  return {
    visits,
    firstEntrance: tsToTimeString(firstEntrance),
    lastExit: tsToTimeString(lastExit),
    total: msToHoursDurationString(total),
    totalNet: msToHoursDurationString(totalNet),
  };
};


export const prepareEmployeePresenceDataForTable = employeesPresence => {
  let data = {};
  _.forIn(employeesPresence, (employee, employeeKey) => {
    const employeeData = _.pick(employee, ['id', 'fullName', 'companyName', 'companyId']);

    _.forIn(employee.log, (dayLogs, dayTS) => {
      const date = moment(Number(dayTS)).format('L');
      const dayVisitsInfo = getVisitsInfo(dayLogs);
      _.set(employeeData, ['log', date], dayVisitsInfo);
    });

    _.set(data, [employeeKey], employeeData);
  });

  return data;
};


export const getLastSyncTSFromCamerasMonitor = (monitor) => {
  let ts;
  _.forIn(monitor, camera => {
    const { lastSync, lastCameraKeepAlive } = camera;
    const newMaxTs = Math.max(lastSync, lastCameraKeepAlive);
    if ((!ts || newMaxTs > ts))
      ts = newMaxTs;
  });
  return ts;
};

export const syncCameras = ({ projectId, from, to = Date.now() }) => {
  return new Promise(async (resolve, reject) => {

    if (!(projectId && from && to))
      return reject('missing params');



    let closeListenerFuncsArray = [];
    let timeoutId;
    const listenersRemovalCallBack = (value, key) => {
      if (key !== 'lastSync' && key != 'lastCameraKeepAlive')
        return;

      closeListenerFuncsArray.forEach(closeListenerFunc => {
        if (_.isFunction(closeListenerFunc))
          closeListenerFunc();
      });

      if (_.isUndefined(value))
        reject("timeout while waiting to syncCameras results");
      else
        resolve(value);

      clearTimeout(timeoutId);
    };

    timeoutId = setTimeout(() => {
      listenersRemovalCallBack();
    }, SYNC_TIMEOUT);

    
    const camerasArr = _.keys(getAppState().getNested(['configurations', 'map', projectId, 'cameras'], {}));
    camerasArr.map(cameraName => {
      const pathInDB = `monitor/cameras/projects/${projectId}/${cameraName}`;
      const closeListenerFunc = startProjectFirebaseListener(projectId, pathInDB, 'child_changed', (val, key) => {
        listenersRemovalCallBack(val, key);
      });
      closeListenerFuncsArray.push(closeListenerFunc);
    });

    await platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/services/routine`, {
      method: 'POST',
      body: JSON.stringify({ projectId }),
    });

  });
};

export const startMonitorListener = ({ projectId, callback, event = 'child_changed' }) => {
  const pathInDB = `monitor/cameras/projects/${projectId}/entrance`;
  return startProjectFirebaseListener(projectId, pathInDB, event, callback);
};


export const getEmployeesTablesMetadata = (employeesPresence) => {
  let ret = {};

  _.forIn(employeesPresence, (emp, empKey) => {
    const { companyName, log } = emp;
    _.forIn(log, (currDayLogs, dayTS) => {
      const wasPresent = !_.isEmpty(currDayLogs);
      if (!wasPresent)
        return;
      const isToday = moment().isSame(Number(dayTS), 'day');
      const isPresentNow = isToday && _.last(_.values(currDayLogs)) !== EXIT;

      const dayPresentCount = _.get(ret, [dayTS, 'present'], 0);
      const dayNonPresentCount = _.get(ret, [dayTS, 'nonPresent'], 0);
      const companyPresentCount = _.get(ret, [dayTS, 'companies', companyName, 'present'], 0);
      const companyNonPresentCount = _.get(ret, [dayTS, 'companies', companyName, 'nonPresent'], 0);

      _.set(ret, [dayTS, isPresentNow ? 'present' : 'nonPresent'], 1 + (isPresentNow ? dayPresentCount : dayNonPresentCount));
      _.set(ret, [dayTS, 'companies', companyName, isPresentNow ? 'present' : 'nonPresent'], 1 + (isPresentNow ? companyPresentCount : companyNonPresentCount));
    });
  });

  return ret;
};


const getTimestampForDay = (startOfMonth, day) => {
  return moment(startOfMonth).date(day).startOf('day').valueOf();
};

const findMatchedTimestamp = (fetchedLogs, id, timestamp) => {
  return Object.keys(fetchedLogs[id]?.log || {}).find((logTimestamp) =>
      moment(Number(logTimestamp)).startOf('day').valueOf() === timestamp
  );
};

const getDayLog = (fetchedLogs, id, matchedTimestamp) => {
  return matchedTimestamp ? fetchedLogs[id].log[matchedTimestamp] : {};
};

export async function handleGetEmployeesPresence(props, state, setState) {
  const { selectedProjectId: projectId, objectId: id } = props;
  const { selectedMonth } = state;

  if (!selectedMonth) return;

  let employeesPresenceLogs = {};

  try {
      const startOfMonth = selectedMonth.startTS;
      let endOfMonth = selectedMonth.endTS;

      const currentMonth = moment().startOf('month').isSame(moment(startOfMonth), 'month');
      if (currentMonth) {
          endOfMonth = Math.min(endOfMonth, moment().valueOf());
      }

      let fetchedLogs = await getEmployeesPresence({
          projectId,
          startTS: startOfMonth,
          endTS: endOfMonth,
          ids: [id],
      });
      fetchedLogs = fetchedLogs.employees;

      const daysInMonth = currentMonth ? moment().date() : moment(startOfMonth).daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        const timestamp = getTimestampForDay(startOfMonth, day);
        let dayLog = {};
    
        if (fetchedLogs[id] && fetchedLogs[id].log) {
            const matchedTimestamp = findMatchedTimestamp(fetchedLogs, id, timestamp);
            dayLog = getDayLog(fetchedLogs, id, matchedTimestamp);
        }
    
        employeesPresenceLogs[timestamp] = dayLog;
    }

      setState({
          employeesPresenceLogs,
          employeesPresenceSuccess: true,
      });
  } catch (error) {
    
    onError({
        error,
        errorMessage: 'Error fetching employees presence data',
        errorMetaData: { projectId, objectId: id, selectedMonth },
        methodMetaData: {
            args: { props, state },
            name: 'handleGetEmployeesPresence',
        },
    });

    setState({
        employeesPresenceLogs: {},
        employeesPresenceSuccess: false
    });
  }
}
