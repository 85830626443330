export const lastUpdateTSSchema = {
	schemaVersion: 3,
	schema: {
		name: 'lastUpdateTS',
		primaryKey: 'id',
		properties: {
			id: 'string',
			type: 'string',
			projectId: 'string',
			lastUpdateTS: { type: 'int', default: 0 },
		},
	},
};

export const lastUpdatesV2Schema = {
	schemaVersion: 4,
	schema: {
		name: 'lastUpdatesV2',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true }, // type_scopeId_subject
			type: { type: 'string', indexed: true }, // revokes | lastUpdates
			scopeId: { type: 'string', indexed: true }, // projectId
			subject: { type: 'string', indexed: true },
			lastUpdateAvailable: { type: 'int', default: 0, optional: true }, // Last lastUpdateTS available from DB
			lastUpdated: { type: 'int', default: 0, optional: true }, // Last lastUpdateTS recorded update to the data
			lastSavedUpdate: { type: 'int', default: 0, optional: true }, // Last lastUpdateTS saved to local DB -> used to determine if we managed to save the data to local db yet
		},
	},
};

export const employeeSchema = {
	schemaVersion: 10,
	schema: {
		name: 'employee1',
		primaryKey: 'projectId_objId',
		properties: {
			id: { type: 'string', indexed: true },
			projectId: { type: 'string', indexed: true },
			projectId_objId: { type: 'string', indexed: true },
			updatedTS: { type: 'int', default: 0 },
			isDeleted: { type: 'bool', optional: true },
			name: 'string?',
			lastUploadTS: { type: 'int', default: 0, indexed: true },
			isLocal: { type: 'bool', optional: true },
		},
	},
};

export const equipmentSchema = {
	schemaVersion: 9,
	schema: {
		name: 'equipment1',
		primaryKey: 'projectId_objId',
		properties: {
			id: { type: 'string', indexed: true },
			isDeleted: { type: 'bool', optional: true },
			projectId_objId: { type: 'string', indexed: true },
			projectId: { type: 'string', indexed: true },
			updatedTS: { type: 'int', default: 0 },
			isLocal: { type: 'bool', optional: true },
			lastUploadTS: { type: 'int', default: 0, indexed: true },
		},
	},
};

export const checklistItemsInstanceSchema = {
	schemaVersion: 13,
	schema: {
		name: 'checklistItemsInstance1',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true },
			projectId: { type: 'string', indexed: true },
			checklistItemId: { type: 'string', indexed: true },
			status: { type: 'int', default: 300 },
			updatedTS: { type: 'int', default: 0, optional: true },
			createdTS: { type: 'int', default: 0, optional: true },
			targetTS: { type: 'int', optional: true },
			isDeleted: { type: 'bool', optional: true },
			isLocal: { type: 'bool', indexed: true, optional: true },
			locationId: { type: 'string', indexed: true, optional: true },
			checklistId: { type: 'string', indexed: true, optional: true },
			extraData: 'extraDataField[]',
		},
	},
};

export const extraDataFieldSchema = {
	schemaVersion: 5,
	schema: {
		name: 'extraDataField',
		properties: {
			id: 'string',
			instanceId: 'string',
			ordinalNo: 'int?',
			optional: 'bool?',
			readOnly: 'bool?',
		},
	},
};

export const propertyInstanceSchema = {
	schemaVersion: 11, // original version was 8
	schema: {
		name: 'propertyInstance1',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true },
			projectId: { type: 'string', indexed: true },
			subjectName: { type: 'string', indexed: true },
			propId: { type: 'string', indexed: true },
			propType: { type: 'string', indexed: true, optional: true },
			data: { type: 'string', optional: true },
			updatedTS: { type: 'int', default: 0 }, // TODO: should we actually include save to server for this one?
			createdTS: { type: 'int', default: 0 },
			isDeleted: { type: 'bool', optional: true },
			isLocal: { type: 'bool', optional: true },
			lastUploadTS: { type: 'int', default: 0, indexed: true },
			parentId: { type: 'string', indexed: true, optional: true },
		},
	},
};

export const memberSchema = {
	schemaVersion: 3,
	schema: {
		name: 'member',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true },
			displayName: 'string?',
			companyId: { type: 'string', indexed: true, optional: true },
			companyName: 'string?',
		},
	},
};

export const slimUserSchema = {
	schemaVersion: 2,
	schema: {
		name: 'slimUser',
		properties: {
			id: { type: 'string', indexed: true },
		},
	},
};

export const tradeSchema = {
	schemaVersion: 2,
	schema: {
		name: 'trade1',
		properties: {
			id: { type: 'string', indexed: true },
		},
	},
};

export const subCategorySchema = {
	schemaVersion: 1,
	schema: {
		name: 'subCategory',
		properties: {
			id: { type: 'string', indexed: true },
		},
	},
};

export const requiredActionSchema = {
	schemaVersion: 3,
	schema: {
		name: 'requiredAction',
		properties: {
			id: { type: 'string', indexed: true },
			customText: { type: 'string', optional: true },
		},
	},
};

export const fineSchema = {
	schemaVersion: 2,
	schema: {
		name: 'fine',
		properties: {
			amount: { type: 'int', default: 0 },
			description: { type: 'string', optional: true },
			fineReciver: { type: 'object', objectType: 'slimUser', optional: true },
		},
	},
};

export const taggedCompanySchema = {
	schemaVersion: 2,
	schema: {
		name: 'taggedCompany',
		properties: {
			id: { type: 'string', indexed: true },
		},
	},
};

export const imagesSchema = {
	schemaVersion: 2,
	schema: {
		name: 'images1',
		properties: {
			id: 'string',
			height: { type: 'int', default: 0 },
			width: { type: 'int', default: 0 },
			uri: 'string?',
		},
	},
};

export const attachmentsSchema = {
	schemaVersion: 3,
	schema: {
		name: 'attachments',
		properties: {
			id: 'string',
			uri: 'string?',
			title: 'string?',
			thumbnail: 'string?',
			extension: 'string?',
			type: 'string?',
		},
	},
};

export const slimChecklistItemInstanceSchema = {
	schemaVersion: 2,
	schema: {
		name: 'slimChecklistItemInstance',
		properties: {
			id: { type: 'string', indexed: true },
		},
	},
};

export const locationSchema = {
	schemaVersion: 2,
	schema: {
		name: 'location',
		properties: {
			building: 'locationId?',
			floor: 'locationId?',
			unit: 'locationId?',
		},
	},
};

export const locationIdSchema = {
	schemaVersion: 2,
	schema: {
		name: 'locationId',
		properties: {
			id: { type: 'string', indexed: true },
			num: 'float?',
		},
	},
};

export const commentDataSchema = {
	schemaVersion: 2,
	schema: {
		name: 'commentDataSchema2',
		properties: {
			newAssignTo: 'slimUser?',
			status: 'int?',
		},
	},
};

export const commentSchema = {
	schemaVersion: 5,
	schema: {
		primaryKey: 'projectId_objId',
		name: 'comment5',
		properties: {
			id: { type: 'string', indexed: true },
			projectId_objId: { type: 'string', indexed: true },
			createdAt: { type: 'int', default: 0 },
			owner: 'slimUser',
			content: 'string?',
			images: 'images1[]',
			type: 'string?',
			data: 'commentDataSchema2',
			projectId: { type: 'string', indexed: true },
			parentId: { type: 'string', indexed: true },
			parentType: 'string?',
			isDeleted: 'bool?',
			isLocal: 'bool?'
		}
	}
};

export const postRefSchema = {
	schemaVersion: 2,
	schema: {
		name: 'postRef',
		properties: {
			id: { type: 'string', indexed: true },
			subjectName: { type: 'string', indexed: true },
			parentId: { type: 'string', indexed: true },
			propId: { type: 'string', indexed: true, optional: true },
			certificationVersionId: { type: 'string', indexed: true, optional: true },
		},
	},
};

export const postsSchema = {
	schemaVersion: 15,
	schema: {
		name: 'post24',
		primaryKey: 'id',
		properties: {
			id: 'string',
			projectId: { type: 'string', indexed: true },
			checklistItemInstance: 'slimChecklistItemInstance',
			isDeleted: { type: 'bool', optional: true },
			locationId: 'string?',
			createdAt: { type: 'date', indexed: true, optional: true },
			editedAt: 'date?',
			updatedTS: { type: 'date', indexed: true, optional: true },
			dueDate: 'date?',
			owner: 'slimUser',
			title: 'string?',
			images: 'images1[]',
			attachments: 'attachments[]',
			comments: 'comment5[]',
			commentsCounter: { type: 'int', default: 0 },
			trade: { type: 'object', objectType: 'trade1', optional: true },
			taggedCompanies: 'taggedCompany[]',
			type: 'string?',
			isIssue: { type: 'bool', optional: true },
			issueState: { type: 'int', default: 0 }, // THIS IS THE STATUS OF THE ISSUE: 100 - Closed, 200 - Resolved, 300 - Open Issue
			isReported: { type: 'bool', optional: true },
			location: 'location?',
			assignTo: 'member',
			isLocal: { type: 'bool', optional: true },
			editedAtOrCreatedAt: { type: 'date', indexed: true, optional: true },
			subCategory: { type: 'object', objectType: 'subCategory', optional: true },
			requiredAction: { type: 'object', objectType: 'requiredAction', optional: true },
			fine: { type: 'object', objectType: 'fine', optional: true },
			severity: { type: 'int', default: 0 },
			refs: 'postRef[]',
			isUploading: 'bool?',
		},
	},
};

export const retryObjectsSchema = {
	schemaVersion: 3,
	schema: {
		name: 'retryObjects',
		properties: {
			id: 'string',
			json: 'string',
			type: { type: 'string', indexed: true },
			projectId: { type: 'string', indexed: true },
		},
	},
};


export const logObjectsSchema = {
	schemaVersion: 1,
	schema: {
		name: 'logObjects',
		primaryKey: 'id',
		properties: {
			id: 'string',
			json: 'string',
		},
  },
};

export const reducerPersistSchema = {
	schemaVersion: 1,
	schema: {
		name: 'reducerPersist',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true },
			scopeId: { type: 'string', indexed: true },
			feature: 'string',
			json: 'string',
		},
	},
};

export const companiesSchema = {
	schemaVersion: 3,
	schema: {
		name: 'companies',
		primaryKey: 'id',
		properties: {
			id: 'string',
			name: 'string',
			trades: 'mixed?',
			projects: 'mixed?',
			types: 'mixed?',
			logo: 'string?',
			darkLogo: 'string?',
			isDeleted: { type: 'bool', optional: true },
			updatedTS: { type: 'int', default: 0 },
		},
	},
};
export const urlCacheSchema = {
	schemaVersion: 1,
	schema: {
		name: 'urlCache',
		primaryKey: 'id',
		properties: {
			id: { type: 'string', indexed: true },
			uri: 'string',
			uploadTS: { type: 'int', default: 0 },
		},
	},
}

export const formsSchema = {
  schemaVersion: 3,
  schema: {
    name: 'forms',
    primaryKey: 'id',
    properties: {
      id: { type: 'string', indexed: true },
      isDeleted: { type: 'bool', default: false },
      type: { type: 'string', indexed: true },
      projectId: { type: 'string', indexed: true },
      isLocal: 'bool?',
      uri: 'string?',
      updatedTS: { type: 'int', default: 0 },
      readyToGenerateTS: 'int?',
      isDailyReportV1: 'bool?',
      universalIds: 'mixed?',
      parentId: 'string?',
      formTemplateId: 'string?',
      generator: 'slimUser',
      reportDate: 'int?',
      status: 'int?',
      createdTS: 'int?',
      posts: 'formsPosts?',
      targetEmails: 'mixed?',
      location: 'location?',
      signatures: 'mixed?',
      isDocx: 'bool?',
      inspector: 'slimUser?',
    },
  },
};  


export const formsPostsSchema = {
  schemaVersion: 3,
  schema: {
    name: 'formsPosts',
    properties: {
      userSelectedIssues: 'mixed?',
      openedFromReport: 'mixed?',
			records: 'mixed?',
			issues: 'mixed?',

    },
  },
};
