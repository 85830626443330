import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "{date} - Daily Report",
    id: 'reports.reports.title'
  },
  rclTitle: {
    defaultMessage: "{date} - Routine CL Report",
    id: 'reports.reports.rclTitle'
  },
  newReport: {
    title:{
      defaultMessage: 'Create New',
      id: 'reports.newReport.title'
    },
    reportExistsExplenation: {
      defaultMessage: ' - Report exists',
      id: 'reports.newReport.reportExistsExplenation'
    },
    signedReportExplenation: {
      defaultMessage: ' - Signed daily report',
      id: 'reports.newReport.signedReportExplenation'
    },
    unSignedReportExplenation: {
      defaultMessage: ' - Unsigned daily report',
      id: 'reports.newReport.unSignedReportExplenation'
    },
  },
  signedReport: {
    defaultMessage: 'Signed report',
    id: 'reports.reports.signedReport'
  },
  previewReport: {
    defaultMessage: 'Preview',
    id: 'reports.reports.previewReport'
  },
  editTitle: {
    defaultMessage: "Edit Daily Reports {date}",
    id: 'reports.reports.editTitle'
  },
  empty: {
    defaultMessage: 'There are no reports to display.',
    id: 'reports.reports.empty'
  },
  createNew: {
    defaultMessage: 'Press the orange button to create a new report',
    id: 'reports.reports.createNew'
  },
  loading: {
    defaultMessage: 'Loading reports',
    id: 'reports.reports.loading'
  },
  checklistsCounter: {
    defaultMessage: '{checklistsCounter} Checklists',
    id: 'reports.checklists.counter'
  },
  checked: {
    defaultMessage: 'Checked',
    id: 'reports.checklists.summary.checked'
  },
  rejected: {
    defaultMessage: 'Rejected',
    id: 'reports.checklists.summary.rejected'
  },
  subCounter: {
    defaultMessage: '{subCounter} subs, {subWorkersCounter} workers',
    id: 'reports.subs.counter'
  },
  workers: {
    defaultMessage: 'Workers',
    id: 'reports.subs.workers'
  },
  workersCounter: {
    defaultMessage: '{counter} Workers & Formen',
    id: 'reports.subs.workersCounter'
  },
  men: {
    defaultMessage: "{workers, plural, =0 {N/A} one {{workers} man} other {{workers} men}}",
    id: 'reports.subs.men'
  },
  workingHours: {
    defaultMessage: 'Working hours',
    id: 'reports.subs.workingHours'
  },
  startTime: {
    defaultMessage: 'Time in',
    id: 'reports.subs.startTime'
  },
  endTime: {
    defaultMessage: 'Time out',
    id: 'reports.subs.endTime'
  },
  issuesCounter: {
    defaultMessage: '{issueCounter} tasks, {documentationCounter} docs',
    id: 'reports.issues.counter'
  },
  noImage: {
    defaultMessage: 'No image',
    id: 'reports.image.missing'
  },  
  captions: {
    inProcess: {
      defaultMessage: '{date}, In process',
      id: 'reports.captions.inProcess'
    },
    approvedAndSent: {
      defaultMessage: '{date}, Signed',
      id: 'reports.captions.approvedAndSent'
    },
    awaitingApproval: {
      defaultMessage: '{date}, Awaiting Approval',
      id: 'reports.captions.awaitingApprval'
    },
    emptyCaption: {
      defaultMessage: '{date}',
      id: 'reports.captions.empty'
    },
    today: {
      defaultMessage: '{date}, Today',
      id: 'reports.captions.today'
    },
  },
  selectors: {
    approver: {
      defaultMessage: 'Superintendent',
      id: 'reports.selectors.approver'
    },
    competentPerson: {
      defaultMessage: 'Competent Person',
      id: 'reports.selectors.competentPerson'
    },
    weather: {
      defaultMessage: 'weather',
      id: 'reports.selectors.weather'
    },
    additionalNotes: {
      defaultMessage: 'Additional notes:',
      id: 'reports.selectors.additionalNotes'
    },
    siteSafetyItems: {
      defaultMessage: 'Site safety items',
      id: 'reports.selectors.siteSafetyItems'
    },
    onSitePresent: {
      defaultMessage: 'On site present',
      id: 'reports.selectors.onSitePresent'
    },
    tasks: {
      defaultMessage: 'Tasks',
      id: 'reports.selectors.tasks'
    },
    documentations: {
      defaultMessage: 'Records',
      id: 'reports.selectors.documentations'
    },
    records: {
      defaultMessage: 'Records',
      id: 'reports.selectors.documentations'
    },  
    tasksOrDoc: {
      defaultMessage: 'Tasks/Records',
      id: 'reports.selectors.tasksOrDocumentations'
    }, 
    signature: {
      defaultMessage: 'Sign form',
      id: 'reports.selectors.signature'
    },
    signWithTitle: {
      defaultMessage: 'Sign: {value}',
      id: 'reports.selectors.signWithTitle'
    },
    notes: {
      defaultMessage: 'Notes',
      id: 'reports.selectors.notes'
    },
    general: {
      defaultMessage: 'General',
      id: 'reports.selectors.general'
    },
    settings: {
      defaultMessage: 'General info',
      id: 'reports.selectors.settings'
    },
    serialNumber: {
      defaultMessage: 'Serial number',
      id: 'reports.selectors.serialNumber'
    },
  },
  placeholders: {
    additionalNotes: {
      defaultMessage: 'Write additional notes',
      id: 'reports.placeholders.additionalNotes'
    },
    siteSafetyItems: {
      defaultMessage: 'Write safety issues',
      id: 'reports.placeholders.siteSafetyItems'
    },
    weather: {
      defaultMessage: 'Write weather description',
      id: 'reports.placeholders.weather'
    },
    signature: {
      defaultMessage: 'Please draw your signature',
      id: 'reports.placeholders.signature'
    },
    subNotes: {
      defaultMessage: 'Write work description here',
      id: 'reports.placeholders.subNotes'
    },
  },
  buttons: {
    create: {
      tasks: {
        defaultMessage: 'Create new task',
        id: 'reports.buttons.create.tasks'  
      },
      records: {
        defaultMessage: 'Create new record',
        id: 'reports.buttons.create.records'
      }
    },
    select: {
      tasks: {
        defaultMessage: 'Attach existing tasks',
        id: 'reports.buttons.select.tasks'
      },
      records: {
        defaultMessage: 'Attach existing records',
        id: 'reports.buttons.select.records',
      },
      all: {
        defaultMessage: 'Attach existing tasks/records',
        id: 'reports.buttons.select.all'
      }
    },
    copyFromPreviousDaily: {
      defaultMessage: 'Copy from previous daily report',
      id: 'reports.buttons.copyFromPreviousDaily'
    },
    addTask: {
      defaultMessage: 'Create new issue',
      id: 'reports.buttons.addTask'
    },
    addDocumentation: {
      defaultMessage: 'Create new record',
      id: 'reports.buttons.addDocumentation'
    },
    addDocOrTask: {
      defaultMessage: 'Attach existing issues',
      id: 'reports.buttons.addDocOrTask'
    },
    showSubs: {
      defaultMessage: 'Show subcontractors',
      id: 'reports.buttons.showSubs'
    },
    showMoreSubs: {
      defaultMessage: 'Show more subcontractors',
      id: 'reports.buttons.showMoreSubs'
    },
    moveToSignature: {
      defaultMessage: 'Go to final review',
      id: 'reports.buttons.moveToSignature'
    },
    cancleSignature: {
      defaultMessage: 'Cancel',
      id: 'reports.buttons.cancle'
    },
    approveAndSend: {
      defaultMessage: 'Approve and send',
      id: 'reports.buttons.approveAndSend'
    },
    sendToSignature: {
      defaultMessage: 'Request signature',
      id: 'reports.buttons.sendToSignature'
    },
    createNewReport: {
      defaultMessage: 'Create new report',
      id: 'reports.buttons.createNewReport'
    },
    onSite: {
      defaultMessage: 'on site',
      id: 'reports.buttons.onSite'
    },
    offSite: {
      defaultMessage: 'off site',
      id: 'reports.buttons.offSite'
    },
  },
  toast: {
    disabledCopyFromPreviousDaily: {
      defaultMessage: 'No relevant record in the last daily log',
      id: 'reports.toast.disabledCopyFromPreviousDaily'
    }
  },
  issueSelector: {
    selectIssuesTitle: {
      defaultMessage: 'Select issues',
      id: 'reports.issueSelector.selectIssuesTitle'
    },
    leaveAlertTitle: {
      defaultMessage: 'Discard changes',
      id: 'reports.leaveAlertTitle.title'
    },
    leaveAlertContent: {
      defaultMessage: 'Are you sure you want to leave this page? Your changes will not be saved',
      id: 'reports.leaveAlertTitle.content'
    },
  },
  alert: {
    existing: {
      title: {
        defaultMessage: 'Existing report',
        id: 'reports.existingAlert.title'
      },
      content: {
        defaultMessage: 'There is already a report for {date}. Would you like to open it instead?',
        id: 'reports.existingAlert.content'
      },
      yes: {
        defaultMessage: 'Open report',
        id: 'reports.existingAlert.yes'
      },
    },
    save: {
      title: {
        defaultMessage: 'Save and Approve report',
        id: 'reports.saveAlert.title'
      },
      content: {
        defaultMessage: 'You are about to submit your report. This action can not be un-done. Would you like to continue?',
        id: 'reports.saveAlert.content'
      },
      yes: {
        defaultMessage: 'Yes',
        id: 'reports.saveAlert.yes'
      },
    },
    missingSignatureTitle: {
      defaultMessage: 'Missing signature',
      id: 'reports.alert.missingSignatureTitle'
    },
    missingSignature: {
      defaultMessage: 'Are you sure you want to approve this report without your signature?',
      id: 'reports.alert.missingSignature'
    },
  },
  exportTitle: {
    defaultMessage: 'Export report',
    id: 'reports.export.title'
  },
  exportSubTitle: {
    defaultMessage: 'Send report via email',
    id: 'reports.export.subtitle'
  },
  emailOnTimeoutModalText: {
    defaultMessage: 'The PDF is taking time to generate. We will send it to your email when it is ready.',
    id: 'reports.export.emailOnTimeoutModalText'
  },
  savedSetFormEmail: {
    defaultMessage: 'The email address has been saved, the report will be sent to this address.',
    id: 'reports.export.savedSetFormEmail'
  },
  exportSectionsSelectionTitle: {
    defaultMessage: 'Choose the sections to generate',
    id: 'reports.export.sectionsSelectionTitle'
  },
  exportReportSections: {
    0: {
      defaultMessage: 'Full report',
      id: 'reports.export.fullReport'
    },
    1: {
      defaultMessage: 'Section 1',
      id: 'reports.export.section1'
    },
    2: {
      defaultMessage: 'Section 2',
      id: 'reports.export.section2'
    },
    3: {
      defaultMessage: 'Section 3',
      id: 'reports.export.section3'
    },
    4: {
      defaultMessage: 'Section 4',
      id: 'reports.export.section4'
    },
    5: {
      defaultMessage: 'Section 5',
      id: 'reports.export.section5'
    },
    6: {
      defaultMessage: 'Section 6',
      id: 'reports.export.section6'
    },
    7: {
      defaultMessage: 'Section 7',
      id: 'reports.export.section7'
    },
    8: {
      defaultMessage: 'Section 8',
      id: 'reports.export.section8'
    },
    9: {
      defaultMessage: 'Section 9',
      id: 'reports.export.section9'
    },
  },
  willReceiveReportInMail: { 
    defaultMessage: 'The report will be sent to your inbox when it is ready.',
    id: 'reports.export.willReceiveReportInMail'
  },
  exportGeneralReport: {
    defaultMessage: 'Export general report',
    id: 'reports.export.generalReport',
  },
  signedSafetySurvey: {
    defaultMessage: 'Signed safety survey',
    id: 'reports.signedSafetySurvey.title'
  },
  finalReview: {
    defaultMessage: 'Final review',
    id: 'reports.finalReview.title'
  },
  sign: {
    defaultMessage: 'Sign',
    id: 'reports.finalReview.sign'
  },
  addSignatureHere: {
    defaultMessage: 'Please sign here:',
    id: 'reports.finalReview.addSignatureHere'
  },
  savingReport: {
    defaultMessage: 'Saving report',
    id: 'reports.finalReview.savingReport'
  },
  saveSuccess: {
    defaultMessage: 'Daily report saved & sent',
    id: 'reports.finalReview.saveSuccess'
  },
  surveyReporter: {
    defaultMessage: 'Survey reporter: ',
    id: 'reports.finalReview.surveyReporter'
  },
  
  share: {
    title: {
      defaultMessage: 'Send daily report',
      id: 'reports.share.title'
    },
    subject: {
      defaultMessage: 'Daily Report: {projectName}, {reportsDate}',
      id: 'reports.share.subject'
    },
    content: {
      defaultMessage: 'Hi,\n\nPlease find daily report attached using Cemento.AI.\nProject: {projectName}\nDate: {reportsDate}\n\nRegards,\n{userName}',
      id: 'reports.share.content'
    },
  },
  notice: {
    notTodaysReport: {
      defaultMessage: 'Attention - The displayed report is NOT of today',
      id: 'reports.notice.notTodaysReport'
    },
    maintenanceReadOnly: {
      defaultMessage: 'Old Report - View Only',
      id: 'reports.notice.maintenanceReadOnly'
    },
  },
  inAndOutTimes: {
    defaultMessage: 'In/Out',
    id: 'reports.titles.inAndOutTimes'
  },
  shift: {
    defaultMessage: 'Shift',
    id: 'reports.titles.shift'
  },  
  exportErrors: {
    setFromEmailFailed: {
      defaultMessage: 'Due to a connectivity issue, the report could not be sent. Please try again later.',
      id: 'reports.exportErrors.setFromEmailFailed'
    },
    title: {
      defaultMessage: 'Report was not generated',
      id: 'reports.exportErrors.title'
    },
    specialTitle: {
      defaultMessage: 'Report failed to generate',
      id: 'reports.exportErrors.specialTitle'
    },
    content: {
      defaultMessage: 'Due to low signal on your device, the requested report cannot be generated',
      id: 'reports.exportErrors.content'
    },
    contentWithCode: {
      defaultMessage: 'Something went wrong when trying to generate the report (code: {errorCode})',
      id: 'reports.exportErrors.contentWithCode'
    },
    localPayloadTitle: {
      defaultMessage: 'Checklist data is still uploading. Please wait.',
      id: 'reports.exportErrors.localPayloadTitle'
    },
    localPayloadMessage: {
      defaultMessage: 'Checklist items that are still uploading:\n\n{localPayload}',
      id: 'reports.exportErrors.contentWithCode'
    }
  },
  notifyWhenReady: {
    title: {
      defaultMessage: 'Report creation in progress',
      id: 'reports.notifyWhenReady.title',
    },
    content: {
      defaultMessage: 'This operation will take a couple of minutes to be completed. Once completed, the report will be available for you in the "Archive"',
      id: 'reports.notifyWhenReady.content',
    },
  },
  reports: {
    defaultMessage: 'Reports',
    id: 'reports.reports'
  },
  reportReadyWithoutSignatureNotification: {
    title: {
      defaultMessage: 'Report is ready',
      id: 'reports.reportReadyWithoutSignatureNotification.title',
    },
    content: {
      defaultMessage: '{locationTitle} - The report is available in the archive.',
      id: 'reports.reportReadyWithoutSignatureNotification.content',
    }
  },
  reportReadyWithSignatureNotification: {
    title: {
      defaultMessage: 'Report is available for signature',
      id: 'reports.reportReadyWithSignatureNotification.title',
    },
    content: {
      defaultMessage: '{locationTitle} - The report is available in the archive.',
      id: 'reports.reportReadyWithSignatureNotification.content',
    }
  },
  extraWorkers: {
    defaultMessage: 'More workers',
    id: 'reports.extraWorkers'
  },
  reportTitles: { // By formType
    dailyReport: {
      defaultMessage: 'Daily Report',
      id: 'reports.reportTitles.dailyReport'
    },
  },
  reportDateTitle: {
    defaultMessage: 'MMMM  YYYY DD',
    id: 'reports.reportDateTitle'
  },
  selectADate: {
    defaultMessage: 'Select a date',
    id: 'reports.selectADate',
  },
  removeReport: {
    title: {
      defaultMessage: 'Delete report',
      id: 'reports.removeReport.title',
    },
    message: {
      defaultMessage: 'Are you sure you want to remove this report?',
      id: 'reports.removeReport.message',
    },
    deleteSuccess: {
      defaultMessage: 'Report deleted successfully',
      id: 'reports.removeReport.deleteSuccess',
    },
  },
  offlineModeError: {
    defaultMessage: 'You are offline, please go back online in order to create new report',
      id: 'reports.offlineModeError.message',
  },
  inspections: {
    selectDate: {
      defaultMessage: 'Select report date',
      id: 'reports.inspections.selectDate',
    },
    regenerate: {
      defaultMessage: 'Regenerate',
      id: 'reports.inspections.regenerate',
    }
  },
  columnsPicker: {
    tableColumns: {
      defaultMessage: 'Table columns',
      id: 'reports.columnsPicker.tableColumns',
    },
    sidecardColumns: {
      defaultMessage: 'Sidecard columns',
      id: 'reports.columnsPicker.sidecardColumns',
    },
    saveSettings: {
      defaultMessage: 'Save report',
      id: 'reports.columnsPicker.saveSettings',
    },
    reportTitle: {
      defaultMessage: 'Report title:',
      id: 'reports.columnsPicker.reportTitle',
    },
    ordinalNumber: {
      defaultMessage: 'Ordinal number:',
      id: 'reports.columnsPicker.ordinalNumber',
    },
  },
  noReport: {
    title: {
      defaultMessage: 'Welcome to the daily report screen',
      id: 'reports.noReport.title',
    },
    content: {
      defaultMessage: "In the daily report screen you can report daily site\nactivities such as weather, labor, equipment, significates events, issues and more.\nThe daily report is customized to fit the company and\nsite needs.\nTo start using the daily report screens, please contact Cemento team at customers@cemento.ai",
      id: 'reports.noReport.content',
    }
  }
});

