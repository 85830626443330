import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import Unit from './unit'
import { OrderedMap } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';
import { unitsEvents } from './unitsEvents';

const InitialState = Record(
  {
    map: OrderedMap(),
  },
  'units'
);

const initialState = new InitialState();

export default function unitReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case unitsEvents.GET_UNITS: {
      if (!action.payload?.projectId) return state;

      const { projectId, units } = action.payload;

      if (units) {
        let unitsMap = new OrderedMap();

        let unitsGroupedByBuildings = _.groupBy(units, 'buildingId');
        for (const buildingId of Object.keys(unitsGroupedByBuildings)) {
          let buildingUnitsMap = new OrderedMap();
          let buildingUnits = unitsGroupedByBuildings[buildingId];

          for (const unit of buildingUnits) {
            let unitToSet = new Unit({...unit})
            buildingUnitsMap = buildingUnitsMap.set(unit.id, unitToSet);
          }
          unitsMap = unitsMap.set(buildingId, buildingUnitsMap);
        }
        state = state.setIn(['map', projectId], unitsMap);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
